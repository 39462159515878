
//------------IP ADDRESS--------------//
export const GET_IP_ADDRESS = 'GET_IP_ADDRESS';
export const GET_IP_ADDRESS_SUCCESS = 'GET_IP_ADDRESS_SUCCESS';
export const GET_IP_ADDRESS_FAILURE = 'GET_IP_ADDRESS_FAILURE';

//------------AUTH ACTIONS--------------//
export const GET_USERS = 'GET_USERS';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const RELOGIN = 'RELOGIN';
export const RELOGIN_SUCCESS = 'RELOGIN_SUCCESS';
export const RELOGIN_FAILURE = 'RELOGIN_FAILURE';

export const SOCIETY_FORGOT_PASSWORD = 'SOCIETY_FORGOT_PASSWORD';
export const SOCIETY_FORGOT_PASSWORD_SUCCESS = 'SOCIETY_FORGOT_PASSWORD_SUCCESS';
export const SOCIETY_FORGOT_PASSWORD_FAILURE = 'SOCIETY_FORGOT_PASSWORD_FAILURE';

export const PASSWORD_RESET = 'PASSWORD_RESET';
export const PASSWORD_RESET_SUCCESS = 'PASSWORD_RESET_SUCCESS';
export const PASSWORD_RESET_FAILURE = 'PASSWORD_RESET_FAILURE';

export const LOGOUT = 'LOGOUT';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';


//------------SETUP AND RECORDS ACTIONS--------------//
//------------BUILDING ACTIONS--------------//
//-----SOCIETY DETAILS ACTIONS---------//

export const GET_SOCIETY_PROFILE = 'GET_SOCIETY_PROFILE';
export const GET_SOCIETY_PROFILE_SUCCESS = 'GET_SOCIETY_PROFILE_SUCCESS';
export const GET_SOCIETY_PROFILE_FAILURE = 'GET_SOCIETY_PROFILE_FAILURE';

export const UPDATE_SOCIETY_PROFILE = 'UPDATE_SOCIETY_PROFILE';
export const UPDATE_SOCIETY_PROFILE_SUCCESS = 'UPDATE_SOCIETY_PROFILE_SUCCESS';
export const UPDATE_SOCIETY_PROFILE_FAILURE = 'UPDATE_SOCIETY_PROFILE_FAILURE';

export const SOCIETY_CHANGE_PASSWORD = 'SOCIETY_CHANGE_PASSWORD';
export const SOCIETY_CHANGE_PASSWORD_SUCCESS = 'SOCIETY_CHANGE_PASSWORD_SUCCESS';
export const SOCIETY_CHANGE_PASSWORD_FAILURE = 'SOCIETY_CHANGE_PASSWORD_FAILURE';

export const GET_SOCIETY_PROFILE_AUDIT_LOGS = 'GET_SOCIETY_PROFILE_AUDIT_LOGS';
export const GET_SOCIETY_PROFILE_AUDIT_LOGS_SUCCESS = 'GET_SOCIETY_PROFILE_AUDIT_LOGS_SUCCESS';
export const GET_SOCIETY_PROFILE_AUDIT_LOGS_FAILURE = 'GET_SOCIETY_PROFILE_AUDIT_LOGS_FAILURE';

export const GET_CURRENCY_LIST = 'GET_CURRENCY_LIST';
export const GET_CURRENCY_LIST_SUCCESS = 'GET_CURRENCY_LIST_SUCCESS';
export const GET_CURRENCY_LIST_FAILURE = 'GET_CURRENCY_LIST_FAILURE';

export const GET_PROJECTS_LIST = 'GET_PROJECTS_LIST';
export const GET_PROJECTS_LIST_SUCCESS = 'GET_PROJECTS_LIST_SUCCESS';
export const GET_PROJECTS_LIST_FAILURE = 'GET_PROJECTS_LIST_FAILURE';

export const GET_COUNTRY_STATE_CITY_LIST = 'GET_COUNTRY_STATE_CITY_LIST';
export const GET_COUNTRY_STATE_CITY_LIST_SUCCESS = 'GET_COUNTRY_STATE_CITY_LIST_SUCCESS';
export const GET_COUNTRY_STATE_CITY_LIST_FAILURE = 'GET_COUNTRY_STATE_CITY_LIST_FAILURE';

//-----SPECIFIC DETAILS ACTIONS---------//
export const GET_SPECIFIC_DETAILS = 'GET_SPECIFIC_DETAILS';
export const GET_SPECIFIC_DETAILS_SUCCESS = 'GET_SPECIFIC_DETAILS_SUCCESS';
export const GET_SPECIFIC_DETAILS_FAILURE = 'GET_SPECIFIC_DETAILS_FAILURE';

export const UPDATE_SPECIFIC_DETAILS = 'UPDATE_SPECIFIC_DETAILS';
export const UPDATE_SPECIFIC_DETAILS_SUCCESS = 'UPDATE_SPECIFIC_DETAILS_SUCCESS';
export const UPDATE_SPECIFIC_DETAILS_FAILURE = 'UPDATE_SPECIFIC_DETAILS_FAILURE';

export const GET_SPECIFIC_DETAILS_AUDIT_LOGS = 'GET_SPECIFIC_DETAILS_AUDIT_LOGS';
export const GET_SPECIFIC_DETAILS_AUDIT_LOGS_SUCCESS = 'GET_SPECIFIC_DETAILS_AUDIT_LOGS_SUCCESS';
export const GET_SPECIFIC_DETAILS_AUDIT_LOGS_FAILURE = 'GET_SPECIFIC_DETAILS_AUDIT_LOGS_FAILURE';

//-----WINGS AND FLATS ACTIONS---------//

export const GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME = 'GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME';
export const GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_SUCCESS = 'GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_SUCCESS';
export const GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_FAILURE = 'GET_WINGS_AND_FLATS_DETAILS_WITH_USERNAME_FAILURE';


export const GET_WINGS_AND_FLATS_DETAILS = 'GET_WINGS_AND_FLATS_DETAILS';
export const GET_WINGS_AND_FLATS_DETAILS_SUCCESS = 'GET_WINGS_AND_FLATS_DETAILS_SUCCESS';
export const GET_WINGS_AND_FLATS_DETAILS_FAILURE = 'GET_WINGS_AND_FLATS_DETAILS_FAILURE';

export const ADD_WINGS = 'ADD_WINGS';
export const ADD_WINGS_SUCCESS = 'ADD_WINGS_SUCCESS';
export const ADD_WINGS_FAILURE = 'ADD_WINGS_FAILURE';

export const GET_WINGS_AUDIT_LOGS = 'GET_WINGS_AUDIT_LOGS';
export const GET_WINGS_AUDIT_LOGS_SUCCESS = 'GET_WINGS_AUDIT_LOGS_SUCCESS';
export const GET_WINGS_AUDIT_LOGS_FAILURE = 'GET_WINGS_AUDIT_LOGS_FAILURE';

export const DELETE_WING = 'DELETE_WING';

export const DOWNLOAD_WINGS_EXCEL_SHEET = 'DOWNLOAD_WINGS_EXCEL_SHEET';
export const DOWNLOAD_WINGS_EXCEL_SHEET_SUCCESS = 'DOWNLOAD_WINGS_EXCEL_SHEET_SUCCESS';
export const DOWNLOAD_WINGS_EXCEL_SHEET_FAILURE = 'DOWNLOAD_WINGS_EXCEL_SHEET_FAILURE';


export const GET_FLATS_DETAILS = 'GET_FLATS_DETAILS';
export const GET_FLATS_DETAILS_SUCCESS = 'GET_FLATS_DETAILS_SUCCESS';
export const GET_FLATS_DETAILS_FAILURE = 'GET_FLATS_DETAILS_FAILURE';

export const ADD_FLATS_DETAILS = 'ADD_FLATS_DETAILS';
export const ADD_FLATS_DETAILS_SUCCESS = 'ADD_FLATS_DETAILS_SUCCESS';
export const ADD_FLATS_DETAILS_FAILURE = 'ADD_FLATS_DETAILS_FAILURE';

export const UPLOAD_FLATS_EXCEL_SHEET = 'UPLOAD_FLATS_EXCEL_SHEET';
export const UPLOAD_FLATS_EXCEL_SHEET_SUCCESS = 'UPLOAD_FLATS_EXCEL_SHEET_SUCCESS';
export const UPLOAD_FLATS_EXCEL_SHEET_FAILURE = 'UPLOAD_FLATS_EXCEL_SHEET_FAILURE';

export const DELETE_FLAT = 'DELETE_FLAT';

export const DOWNLOAD_FLATS_EXCEL_SHEET = 'DOWNLOAD_FLATS_EXCEL_SHEET';
export const DOWNLOAD_FLATS_EXCEL_SHEET_SUCCESS = 'DOWNLOAD_FLATS_EXCEL_SHEET_SUCCESS';
export const DOWNLOAD_FLATS_EXCEL_SHEET_FAILURE = 'DOWNLOAD_FLATS_EXCEL_SHEET_FAILURE';

export const GET_BILL_TARIFF_DETAILS = 'GET_BILL_TARIFF_DETAILS';
export const GET_BILL_TARIFF_DETAILS_SUCCESS = 'GET_BILL_TARIFF_DETAILS_SUCCESS';
export const GET_BILL_TARIFF_DETAILS_FAILURE = 'GET_BILL_TARIFF_DETAILS_FAILURE';

export const UPDATE_BILL_TARIFF_DETAILS = 'UPDATE_BILL_TARIFF_DETAILS';
export const UPDATE_BILL_TARIFF_DETAILS_SUCCESS = 'UPDATE_BILL_TARIFF_DETAILS_SUCCESS';
export const UPDATE_BILL_TARIFF_DETAILS_FAILURE = 'UPDATE_BILL_TARIFF_DETAILS_FAILURE';

export const UPLOAD_BILL_TARIFF_EXCEL_SHEET = 'UPLOAD_BILL_TARIFF_EXCEL_SHEET';
export const UPLOAD_BILL_TARIFF_EXCEL_SHEET_SUCCESS = 'UPLOAD_BILL_TARIFF_EXCEL_SHEET_SUCCESS';
export const UPLOAD_BILL_TARIFF_EXCEL_SHEET_FAILURE = 'UPLOAD_BILL_TARIFF_EXCEL_SHEET_FAILURE';

export const DOWNLOAD_BILL_TARIFF_EXCEL_SHEET = 'DOWNLOAD_BILL_TARIFF_EXCEL_SHEET';

export const GET_HEAD_LIST = 'GET_HEAD_LIST';
export const GET_HEAD_LIST_SUCCESS = 'GET_HEAD_LIST_SUCCESS';
export const GET_HEAD_LIST_FAILURE = 'GET_HEAD_LIST_FAILURE';

export const GET_UTILITY_READING_DETAILS = 'GET_UTILITY_READING_DETAILS';
export const GET_UTILITY_READING_DETAILS_SUCCESS = 'GET_UTILITY_READING_DETAILS_SUCCESS';
export const GET_UTILITY_READING_DETAILS_FAILURE = 'GET_UTILITY_READING_DETAILS_FAILURE';

export const UPDATE_UTILITY_READING_DETAILS = 'UPDATE_UTILITY_READING_DETAILS';
export const UPDATE_UTILITY_READING_DETAILS_SUCCESS = 'UPDATE_UTILITY_READING_DETAILS_SUCCESS';
export const UPDATE_UTILITY_READING_DETAILS_FAILURE = 'UPDATE_UTILITY_READING_DETAILS_FAILURE';

export const UPLOAD_UTILITY_READING_EXCEL_SHEET = 'UPLOAD_UTILITY_READING_EXCEL_SHEET';
export const UPLOAD_UTILITY_READING_EXCEL_SHEET_SUCCESS = 'UPLOAD_UTILITY_READING_EXCEL_SHEET_SUCCESS';
export const UPLOAD_UTILITY_READING_EXCEL_SHEET_FAILURE = 'UPLOAD_UTILITY_READING_EXCEL_SHEET_FAILURE';

//-----BANK DETAILS---------//
export const GET_BANK_DETAIL_AND_TYPE_LIST = 'GET_BANK_DETAIL_AND_TYPE_LIST';
export const GET_BANK_DETAIL_AND_TYPE_LIST_SUCCESS = 'GET_BANK_DETAIL_AND_TYPE_LIST_SUCCESS';
export const GET_BANK_DETAIL_AND_TYPE_LIST_FAILURE = 'GET_BANK_DETAIL_TYPE_AND_NAME_LIST_FAILURE';

export const GET_BANK_DETAILS = 'GET_BANK_DETAILS';
export const GET_BANK_DETAILS_SUCCESS = 'GET_BANK_DETAILS_SUCCESS';
export const GET_BANK_DETAILS_FAILURE = 'GET_BANK_DETAILS_FAILURE';

export const ADD_BANK_DETAILS = 'ADD_BANK_DETAILS';
export const ADD_BANK_DETAILS_SUCCESS = 'ADD_BANK_DETAILS_SUCCESS';
export const ADD_BANK_DETAILS_FAILURE = 'ADD_BANK_DETAILS_FAILURE';

export const GET_BANK_DETAILS_AUDIT_LOGS = 'GET_BANK_DETAILS_AUDIT_LOGS';
export const GET_BANK_DETAILS_AUDIT_LOGS_SUCCESS = 'GET_BANK_DETAILS_AUDIT_LOGS_SUCCESS';
export const GET_BANK_DETAILS_AUDIT_LOGS_FAILURE = 'GET_BANK_DETAILS_AUDIT_LOGS_FAILURE';

export const DELETE_BANK_DATA = 'DELETE_BANK_DATA';

export const GET_BANK_NAME_LIST = 'GET_BANK_NAME_LIST';
export const GET_BANK_NAME_LIST_SUCCESS = 'GET_BANK_NAME_LIST_SUCCESS';
export const GET_BANK_NAME_LIST_FAILURE = 'GET_BANK_NAME_LIST_FAILURE';

export const ADD_BANK_NAME = 'ADD_BANK_NAME';
export const ADD_BANK_NAME_SUCCESS = 'ADD_BANK_NAME_SUCCESS';
export const ADD_BANK_NAME_FAILURE = 'ADD_BANK_NAME_FAILURE';

//------------OCCUPANTS AND TENANTS ACTIONS--------------//
//-----OCCUPANTS INFO ACTIONS---------//
export const GET_TITLE_LIST = 'GET_TITLE_LIST';
export const GET_TITLE_LIST_SUCCESS = 'GET_TITLE_LIST_SUCCESS';
export const GET_TITLE_LIST_FAILURE = 'GET_TITLE_LIST_FAILURE';

export const GET_OCCUPANTS_DETAILS_LIST = 'GET_OCCUPANTS_DETAILS_LIST';
export const GET_OCCUPANTS_DETAILS_LIST_SUCCESS = 'GET_OCCUPANTS_DETAILS_LIST_SUCCESS';
export const GET_OCCUPANTS_DETAILS_LIST_FAILURE = 'GET_OCCUPANTS_DETAILS_LIST_FAILURE';

export const DOWNLOAD_OCCUPANTS_DETAILS_LIST = 'DOWNLOAD_OCCUPANTS_DETAILS_LIST';
export const DOWNLOAD_OCCUPANTS_DETAILS_LIST_SUCCESS = 'DOWNLOAD_OCCUPANTS_DETAILS_LIST_SUCCESS';
export const DOWNLOAD_OCCUPANTS_DETAILS_LIST_FAILURE = 'DOWNLOAD_OCCUPANTS_DETAILS_LIST_FAILURE';

export const SEND_EMAIL_TO_OCCUPANT = 'SEND_EMAIL_TO_OCCUPANT';
export const SEND_EMAIL_TO_OCCUPANT_SUCCESS = 'SEND_EMAIL_TO_OCCUPANT_SUCCESS';
export const SEND_EMAIL_TO_OCCUPANT_FAILURE = 'SEND_EMAIL_TO_OCCUPANT_FAILURE';

export const CHANGE_OCCUPANT_STATUS = 'CHANGE_OCCUPANT_STATUS';
export const CHANGE_OCCUPANT_STATUS_SUCCESS = 'CHANGE_OCCUPANT_STATUS_SUCCESS';
export const CHANGE_OCCUPANT_STATUS_FAILURE = 'CHANGE_OCCUPANT_STATUS_FAILURE';

export const CHANGE_OCCUPANT_STATUS_IN_BULK = 'CHANGE_OCCUPANT_STATUS_IN_BULK';
export const CHANGE_OCCUPANT_STATUS_IN_BULK_SUCCESS = 'CHANGE_OCCUPANT_STATUS_IN_BULK_SUCCESS';
export const CHANGE_OCCUPANT_STATUS_IN_BULK_FAILURE = 'CHANGE_OCCUPANT_STATUS_IN_BULK_FAILURE';

export const GET_MEMBERS_PRIVACY = 'GET_MEMBERS_PRIVACY';
export const GET_MEMBERS_PRIVACY_SUCCESS = 'GET_MEMBERS_PRIVACY_SUCCESS';
export const GET_MEMBERS_PRIVACY_FAILURE = 'GET_MEMBERS_PRIVACY_FAILURE';

export const UPDATE_MEMBERS_PRIVACY = 'UPDATE_MEMBERS_PRIVACY';
export const UPDATE_MEMBERS_PRIVACY_SUCCESS = 'UPDATE_MEMBERS_PRIVACY_SUCCESS';
export const UPDATE_MEMBERS_PRIVACY_FAILURE = 'UPDATE_MEMBERS_PRIVACY_FAILURE';

export const ADD_OCCUPANTS = 'ADD_OCCUPANTS';
export const ADD_OCCUPANTS_SUCCESS = 'ADD_OCCUPANTS_SUCCESS';
export const ADD_OCCUPANTS_FAILURE = 'ADD_OCCUPANTS_FAILURE';

export const DELETE_OCCUPANT = 'DELETE_OCCUPANT';
export const DELETE_OCCUPANT_SUCCESS = 'DELETE_OCCUPANT_SUCCESS';
export const DELETE_OCCUPANT_FAILURE = 'DELETE_OCCUPANT_FAILURE';

export const GET_OCCUPANTS_AUDIT_LOGS = 'GET_OCCUPANTS_AUDIT_LOGS';
export const GET_OCCUPANTS_AUDIT_LOGS_SUCCESS = 'GET_OCCUPANTS_AUDIT_LOGS_SUCCESS';
export const GET_OCCUPANTS_AUDIT_LOGS_FAILURE = 'GET_OCCUPANTS_AUDIT_LOGS_FAILURE';

export const GET_EXISTING_OCCUPANTS = 'GET_EXISTING_OCCUPANTS';
export const GET_EXISTING_OCCUPANTS_SUCCESS = 'GET_EXISTING_OCCUPANTS_SUCCESS';
export const GET_EXISTING_OCCUPANTS_FAILURE = 'GET_EXISTING_OCCUPANTS_FAILURE';

export const ADD_EXISTING_OCCUPANTS = 'ADD_EXISTING_OCCUPANTS';
export const ADD_EXISTING_OCCUPANTS_SUCCESS = 'ADD_EXISTING_OCCUPANTS_SUCCESS';
export const ADD_EXISTING_OCCUPANTS_FAILURE = 'ADD_EXISTING_OCCUPANTS_FAILURE';

export const GET_ARCHIVE_OCCUPANTS = 'GET_ARCHIVE_OCCUPANTS';
export const GET_ARCHIVE_OCCUPANTS_SUCCESS = 'GET_ARCHIVE_OCCUPANTS_SUCCESS';
export const GET_ARCHIVE_OCCUPANTS_FAILURE = 'GET_ARCHIVE_OCCUPANTS_FAILURE';

export const GET_OCCUPANTS_DETAILS = 'GET_OCCUPANTS_DETAILS';
export const GET_OCCUPANTS_DETAILS_SUCCESS = 'GET_OCCUPANTS_DETAILS_SUCCESS';
export const GET_OCCUPANTS_DETAILS_FAILURE = 'GET_OCCUPANTS_DETAILS_FAILURE';

export const UPDATE_OCCUPANTS_DETAILS = 'UPDATE_OCCUPANTS_DETAILS';
export const UPDATE_OCCUPANTS_DETAILS_SUCCESS = 'UPDATE_OCCUPANTS_DETAILS_SUCCESS';
export const UPDATE_OCCUPANTS_DETAILS_FAILURE = 'UPDATE_OCCUPANTS_DETAILS_FAILURE';

export const GET_MORTGAGE_DETAILS = 'GET_MORTGAGE_DETAILS';
export const GET_MORTGAGE_DETAILS_SUCCESS = 'GET_MORTGAGE_DETAILS_SUCCESS';
export const GET_MORTGAGE_DETAILS_FAILURE = 'GET_MORTGAGE_DETAILS_FAILURE';

export const ADD_MORTGAGE_DETAILS = 'ADD_MORTGAGE_DETAILS';
export const ADD_MORTGAGE_DETAILS_SUCCESS = 'ADD_MORTGAGE_DETAILS_SUCCESS';
export const ADD_MORTGAGE_DETAILS_FAILURE = 'ADD_MORTGAGE_DETAILS_FAILURE';

//-----Family INFO ACTIONS---------//
export const GET_FAMILY_MEMBERS_LIST = 'GET_FAMILY_MEMBERS_LIST';
export const GET_FAMILY_MEMBERS_LIST_SUCCESS = 'GET_FAMILY_MEMBERS_LIST_SUCCESS';
export const GET_FAMILY_MEMBERS_LIST_FAILURE = 'GET_FAMILY_MEMBERS_LIST_FAILURE';

export const VIEW_FAMILY_MEMBERS_DETAILS = 'VIEW_FAMILY_MEMBERS_DETAILS';
export const VIEW_FAMILY_MEMBERS_DETAILS_SUCCESS = 'VIEW_FAMILY_MEMBERS_DETAILS_SUCCESS';
export const VIEW_FAMILY_MEMBERS_DETAILS_FAILURE = 'VIEW_FAMILY_MEMBERS_DETAILS_FAILURE';

export const DOWNLOAD_FAMILY_MEMBERS_LIST = 'DOWNLOAD_FAMILY_MEMBERS_LIST';
export const DOWNLOAD_FAMILY_MEMBERS_LIST_SUCCESS = 'DOWNLOAD_FAMILY_MEMBERS_LIST_SUCCESS';
export const DOWNLOAD_FAMILY_MEMBERS_LIST_FAILURE = 'DOWNLOAD_FAMILY_MEMBERS_LIST_FAILURE';

export const ADD_FAMILY_MEMBER = 'ADD_FAMILY_MEMBER';
export const ADD_FAMILY_MEMBER_SUCCESS = 'ADD_FAMILY_MEMBER_SUCCESS';
export const ADD_FAMILY_MEMBER_FAILURE = 'ADD_FAMILY_MEMBER_FAILURE';

export const DELETE_FAMILY_MEMBER = 'DELETE_FAMILY_MEMBER';
export const DELETE_FAMILY_MEMBER_SUCCESS = 'DELETE_FAMILY_MEMBER_SUCCESS';
export const DELETE_FAMILY_MEMBER_FAILURE = 'DELETE_FAMILY_MEMBER_FAILURE';

export const UPDATE_FAMILY_MEMBER = 'UPDATE_FAMILY_MEMBER';
export const UPDATE_FAMILY_MEMBER_SUCCESS = 'UPDATE_FAMILY_MEMBER_SUCCESS';
export const UPDATE_FAMILY_MEMBER_FAILURE = 'UPDATE_FAMILY_MEMBER_FAILURE';

export const ADD_FAMILY_MEMBER_THROUGH_EXCEL = 'ADD_FAMILY_MEMBER_THROUGH_EXCEL';
export const ADD_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS = 'ADD_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS';
export const ADD_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE = 'ADD_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE';

export const ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL = 'ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL';
export const ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS = 'ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS';
export const ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE = 'ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE';

export const UPDATE_FAMILY_MEMBER_THROUGH_EXCEL = 'UPDATE_FAMILY_MEMBER_THROUGH_EXCEL';
export const UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS = 'UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS';
export const UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE = 'UPDATE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE';

export const DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE = 'DOWNLOAD_FAMILY_MEMBER_EXCEL_SAMPLE';

export const GET_FLAT_DOCUMENTS = 'GET_FLAT_DOCUMENTS';
export const GET_FLAT_DOCUMENTS_SUCCESS = 'GET_FLAT_DOCUMENTS_SUCCESS';
export const GET_FLAT_DOCUMENTS_FAILURE = 'GET_FLAT_DOCUMENTS_FAILURE';

export const UPDATE_FLAT_DOCUMENTS = 'UPDATE_FLAT_DOCUMENTS';
export const UPDATE_FLAT_DOCUMENTS_SUCCESS = 'UPDATE_FLAT_DOCUMENTS_SUCCESS';
export const UPDATE_FLAT_DOCUMENTS_FAILURE = 'UPDATE_FLAT_DOCUMENTS_FAILURE';

export const GET_MY_HOUSE_DETAILS = 'GET_MY_HOUSE_DETAILS';
export const GET_MY_HOUSE_DETAILS_SUCCESS = 'GET_MY_HOUSE_DETAILS_SUCCESS';
export const GET_MY_HOUSE_DETAILS_FAILURE = 'GET_MY_HOUSE_DETAILS_FAILURE';

export const GET_OCCUPANTS_STAFF_DETAILS = 'GET_OCCUPANTS_STAFF_DETAILS';
export const GET_OCCUPANTS_STAFF_DETAILS_SUCCESS = 'GET_OCCUPANTS_STAFF_DETAILS_SUCCESS';
export const GET_OCCUPANTS_STAFF_DETAILS_FAILURE = 'GET_OCCUPANTS_STAFF_DETAILS_FAILURE';

export const VIEW_NOMINEE_DETAILS = 'VIEW_NOMINEE_DETAILS';
export const VIEW_NOMINEE_DETAILS_SUCCESS = 'VIEW_NOMINEE_DETAILS_SUCCESS';
export const VIEW_NOMINEE_DETAILS_FAILURE = 'GET_OCCUPANTS_STAFF_DETAILS_FAILURE';

export const ADD_UPDATE_NOMINEE_DETAILS = 'ADD_UPDATE_NOMINEE_DETAILS';
export const ADD_UPDATE_NOMINEE_DETAILS_SUCCESS = 'ADD_UPDATE_NOMINEE_DETAILS_SUCCESS';
export const ADD_UPDATE_NOMINEE_DETAILS_FAILURE = 'ADD_UPDATE_NOMINEE_DETAILS_FAILURE';

export const DELETE_NOMINEE_DETAILS = 'DELETE_NOMINEE_DETAILS';
export const DELETE_NOMINEE_DETAILS_SUCCESS = 'DELETE_NOMINEE_DETAILS_SUCCESS';
export const DELETE_NOMINEE_DETAILS_FAILURE = 'DELETE_NOMINEE_DETAILS_FAILURE';

export const UPDATE_VEHICLE_DETAILS = 'UPDATE_VEHICLE_DETAILS';
export const UPDATE_VEHICLE_DETAILS_SUCCESS = 'UPDATE_VEHICLE_DETAILS_SUCCESS';
export const UPDATE_VEHICLE_DETAILS_FAILURE = 'UPDATE_VEHICLE_DETAILS_FAILURE';

export const UPLOAD_VEHICLE_DETAILS = 'UPLOAD_VEHICLE_DETAILS';
export const UPLOAD_VEHICLE_DETAILS_SUCCESS = 'UPLOAD_VEHICLE_DETAILS_SUCCESS';
export const UPLOAD_VEHICLE_DETAILS_FAILURE = 'UPLOAD_VEHICLE_DETAILS_FAILURE';

export const BULK_ADD_VEHICLE_DETAILS = 'BULK_ADD_VEHICLE_DETAILS';
export const BULK_ADD_VEHICLE_DETAILS_SUCCESS = 'BULK_ADD_VEHICLE_DETAILS_SUCCESS';
export const BULK_ADD_VEHICLE_DETAILS_FAILURE = 'BULK_ADD_VEHICLE_DETAILS_FAILURE';

export const DELETE_VEHICLE_DETAILS = 'DELETE_VEHICLE_DETAILS';
export const DELETE_VEHICLE_DETAILS_SUCCESS = 'DELETE_VEHICLE_DETAILS_SUCCESS';
export const DELETE_VEHICLE_DETAILS_FAILURE = 'DELETE_VEHICLE_DETAILS_FAILURE';

export const GET_VEHICLE_DETAILS = 'GET_VEHICLE_DETAILS';
export const GET_VEHICLE_DETAILS_SUCCESS = 'GET_VEHICLE_DETAILS_SUCCESS';
export const GET_VEHICLE_DETAILS_FAILURE = 'GET_VEHICLE_DETAILS_FAILURE';

//-----TENANTS INFO ACTIONS---------//
export const GET_TENANTS_DETAILS_LIST = 'GET_TENANTS_DETAILS_LIST';
export const GET_TENANTS_DETAILS_LIST_SUCCESS = 'GET_TENANTS_DETAILS_LIST_SUCCESS';
export const GET_TENANTS_DETAILS_LIST_FAILURE = 'GET_TENANTS_DETAILS_LIST_FAILURE';

export const DOWNLOAD_TENANTS_LIST = 'DOWNLOAD_TENANTS_LIST';

export const UPLOAD_TENANTS = 'UPLOAD_TENANTS';
export const UPLOAD_TENANTS_SUCCESS = 'UPLOAD_TENANTS_SUCCESS';
export const UPLOAD_TENANTS_FAILURE = 'UPLOAD_TENANTS_FAILURE';
export const UPLOAD_TENANTS_CLEAR_ERROR = 'UPLOAD_TENANTS_CLEAR_ERROR';

export const ADD_TENANT = 'ADD_TENANT';
export const ADD_TENANT_SUCCESS = 'ADD_TENANT_SUCCESS';
export const ADD_TENANT_FAILURE = 'ADD_TENANT_FAILURE';

export const DELETE_TENANT = 'DELETE_TENANT';
export const DELETE_TENANT_SUCCESS = 'DELETE_TENANT_SUCCESS';
export const DELETE_TENANT_FAILURE = 'DELETE_TENANT_FAILURE';

export const DELETE_TENANT_IN_BULK = 'DELETE_TENANT_IN_BULK';
export const DELETE_TENANT_IN_BULK_SUCCESS = 'DELETE_TENANT_IN_BULK_SUCCESS';
export const DELETE_TENANT_IN_BULK_FAILURE = 'DELETE_TENANT_IN_BULK_FAILURE';

export const CHANGE_TENANTS_STATUS_IN_BULK = 'CHANGE_TENANTS_STATUS_IN_BULK';
export const CHANGE_TENANTS_STATUS_IN_BULK_SUCCESS = 'CHANGE_TENANTS_STATUS_IN_BULK_SUCCESS';
export const CHANGE_TENANTS_STATUS_IN_BULK_FAILURE = 'CHANGE_TENANTS_STATUS_IN_BULK_FAILURE';

export const CHANGE_TENANTS_STATUS = 'CHANGE_TENANTS_STATUS';
export const CHANGE_TENANTS_STATUS_SUCCESS = 'CHANGE_TENANTS_STATUS_SUCCESS';
export const CHANGE_TENANTS_STATUS_FAILURE = 'CHANGE_TENANTS_STATUS_FAILURE';

export const SEND_EMAIL_TO_TENANTS = 'SEND_EMAIL_TO_TENANTS';
export const SEND_EMAIL_TO_TENANTS_SUCCESS = 'SEND_EMAIL_TO_TENANTS_SUCCESS';
export const SEND_EMAIL_TO_TENANTS_FAILURE = 'SEND_EMAIL_TO_TENANTS_FAILURE';

export const GET_TENANT_DETAILS = 'GET_TENANT_DETAILS';
export const GET_TENANT_DETAILS_SUCCESS = 'GET_TENANT_DETAILS_SUCCESS';
export const GET_TENANT_DETAILS_FAILURE = 'GET_TENANT_DETAILS_FAILURE';

export const UPDATE_TENANT_DETAILS = 'UPDATE_TENANT_DETAILS';
export const UPDATE_TENANT_DETAILS_SUCCESS = 'UPDATE_TENANT_DETAILS_SUCCESS';
export const UPDATE_TENANT_DETAILS_FAILURE = 'UPDATE_TENANT_DETAILS_FAILURE';

export const GET_EXISTING_TENANTS = 'GET_EXISTING_TENANTS';
export const GET_EXISTING_TENANTS_SUCCESS = 'GET_EXISTING_TENANTS_SUCCESS';
export const GET_EXISTING_TENANTS_FAILURE = 'GET_EXISTING_TENANTS_FAILURE';

export const ADD_EXISTING_TENANTS = 'ADD_EXISTING_TENANTS';
export const ADD_EXISTING_TENANTS_SUCCESS = 'ADD_EXISTING_TENANTS_SUCCESS';
export const ADD_EXISTING_TENANTS_FAILURE = 'ADD_EXISTING_TENANTS_FAILURE';

export const GET_TENANTS_AUDIT_LOGS = 'GET_TENANTS_AUDIT_LOGS';
export const GET_TENANTS_AUDIT_LOGS_SUCCESS = 'GET_TENANTS_AUDIT_LOGS_SUCCESS';
export const GET_TENANTS_AUDIT_LOGS_FAILURE = 'GET_TENANTS_AUDIT_LOGS_FAILURE';

export const GET_ARCHIVED_TENANTS = 'GET_ARCHIVED_TENANTS';
export const GET_ARCHIVED_TENANTS_SUCCESS = 'GET_ARCHIVED_TENANTS_SUCCESS';
export const GET_ARCHIVED_TENANTS_FAILURE = 'GET_ARCHIVED_TENANTS_FAILURE';

export const ARCHIVE_TENANT = 'ARCHIVE_TENANT';
export const ARCHIVE_TENANT_SUCCESS = 'ARCHIVE_TENANT_SUCCESS';
export const ARCHIVE_TENANT_FAILURE = 'ARCHIVE_TENANT_FAILURE';

export const GET_MANAGING_COMITTEE_TYPES_LIST = 'GET_MANAGING_COMITTEE_TYPES_LIST';
export const GET_MANAGING_COMITTEE_TYPES_LIST_SUCCESS = 'GET_MANAGING_COMITTEE_TYPES_LIST_SUCCESS';
export const GET_MANAGING_COMITTEE_TYPES_LIST_FAILURE = 'GET_MANAGING_COMITTEE_TYPES_LIST_FAILURE';

export const GET_EMPLOYEES_TYPE_LIST_IN_TENANTS = 'GET_EMPLOYEES_TYPE_LIST_IN_TENANTS';
export const GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_SUCCESS = 'GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_SUCCESS';
export const GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_FAILURE = 'GET_EMPLOYEES_TYPE_LIST_IN_TENANTS_FAILURE';

export const GET_TENANCY_EXPIRY_REMINDER_DATA = 'GET_TENANCY_EXPIRY_REMINDER_DATA';
export const GET_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS = 'GET_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS';
export const GET_TENANCY_EXPIRY_REMINDER_DATA_FAILURE = 'GET_TENANCY_EXPIRY_REMINDER_DATA_FAILURE';

export const UPDATE_TENANCY_EXPIRY_REMINDER_DATA = 'UPDATE_TENANCY_EXPIRY_REMINDER_DATA';
export const UPDATE_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS = 'UPDATE_TENANCY_EXPIRY_REMINDER_DATA_SUCCESS';
export const UPDATE_TENANCY_EXPIRY_REMINDER_DATA_FAILURE = 'UPDATE_TENANCY_EXPIRY_REMINDER_DATA_FAILURE';

export const RENEW_FORM = 'RENEW_FORM';
export const RENEW_FORM_SUCCESS = 'RENEW_FORM_SUCCESS';
export const RENEW_FORM_FAILURE = 'RENEW_FORM_FAILURE';

export const RENT_PAYMENT_ONLINE_REGISTRATION_LIST = 'RENT_PAYMENT_ONLINE_REGISTRATION_LIST';
export const RENT_PAYMENT_ONLINE_REGISTRATION_LIST_SUCCESS = 'RENT_PAYMENT_ONLINE_REGISTRATION_LIST_SUCCESS';
export const RENT_PAYMENT_ONLINE_REGISTRATION_LIST_FAILURE = 'RENT_PAYMENT_ONLINE_REGISTRATION_LIST_FAILURE';

export const GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA = 'GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA';
export const GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS = 'GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS';
export const GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE = 'GET_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE';

export const UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA = 'UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA';
export const UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS = 'UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS';
export const UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE = 'UPDATE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE';

export const DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA = 'DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA';
export const DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS = 'DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_SUCCESS';
export const DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE = 'DELETE_RENT_PAYMENT_ONLINE_REGISTRATION_DATA_FAILURE';

export const GET_ACCESS_SETTING_DATA = 'GET_ACCESS_SETTING_DATA';
export const GET_ACCESS_SETTING_DATA_SUCCESS = 'GET_ACCESS_SETTING_DATA_SUCCESS';
export const GET_ACCESS_SETTING_DATA_FAILURE = 'GET_ACCESS_SETTING_DATA_FAILURE';

export const UPDATE_ACCESS_SETTING = 'UPDATE_ACCESS_SETTING';
export const UPDATE_ACCESS_SETTING_SUCCESS = 'UPDATE_ACCESS_SETTING_SUCCESS';
export const UPDATE_ACCESS_SETTING_FAILURE = 'UPDATE_ACCESS_SETTING_FAILURE';

export const PRINT_TENANTS_LIST = 'PRINT_TENANTS_LIST';
export const PRINT_TENANTS_LIST_SUCCESS = 'PRINT_TENANTS_LIST_SUCCESS';
export const PRINT_TENANTS_LIST_FAILURE = 'PRINT_TENANTS_LIST_FAILURE';

//-----LEAVE AND LICENSE ACTIONS---------//
export const DOWNLOAD_LEAVE_AND_LICENSE = 'DOWNLOAD_LEAVE_AND_LICENSE';
export const DOWNLOAD_LEAVE_AND_LICENSE_SUCCESS = 'DOWNLOAD_LEAVE_AND_LICENSE_SUCCESS';
export const DOWNLOAD_LEAVE_AND_LICENSE_FAILURE = 'DOWNLOAD_LEAVE_AND_LICENSE_FAILURE';

export const GET_LEAVE_AND_LICENSE_LIST = 'GET_LEAVE_AND_LICENSE_LIST';
export const GET_LEAVE_AND_LICENSE_LIST_SUCCESS = 'GET_LEAVE_AND_LICENSE_LIST_SUCCESS';
export const GET_LEAVE_AND_LICENSE_LIST_FAILURE = 'GET_LEAVE_AND_LICENSE_LIST_FAILURE';

//-----APPLY FOR TENANCY ACTIONS---------//
export const APPLY_FOR_TENANCY = 'APPLY_FOR_TENANCY';
export const APPLY_FOR_TENANCY_SUCCESS = 'APPLY_FOR_TENANCY_SUCCESS';
export const APPLY_FOR_TENANCY_FAILURE = 'APPLY_FOR_TENANCY_FAILURE';


//-----VIEW INVITIES ACTIONS---------//
export const GET_INVITED_OCCUPANTS_AND_TENANTS_LIST = 'GET_INVITED_OCCUPANTS_AND_TENANTS_LIST';
export const GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_SUCCESS = 'GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_SUCCESS';
export const GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_FAILURE = 'GET_INVITED_OCCUPANTS_AND_TENANTS_LIST_FAILURE';

export const SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS = 'SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS';
export const SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_SUCCESS = 'SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_SUCCESS';
export const SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_FAILURE = 'SEND_INVITE_EMAIL_TO_OCCUPANTS_AND_TENANTS_FAILURE';

//------------STAFF MANAGEMENT ACTIONS--------------//
//-----STAFF PERSONNEL ACTIONS---------//
export const GET_SOCIETY_STAFF_LIST = 'GET_SOCIETY_STAFF_LIST';
export const GET_SOCIETY_STAFF_LIST_SUCCESS = 'GET_SOCIETY_STAFF_LIST_SUCCESS';
export const GET_SOCIETY_STAFF_LIST_FAILURE = 'GET_SOCIETY_STAFF_LIST_FAILURE';


export const GET_SOCIETY_STAFF_PROFILE_LIST = 'GET_SOCIETY_STAFF_PROFILE_LIST';
export const GET_SOCIETY_STAFF_PROFILE_LIST_SUCCESS = 'GET_SOCIETY_STAFF_PROFILE_LIST_SUCCESS';
export const GET_SOCIETY_STAFF_PROFILE_LIST_FAILURE = 'GET_SOCIETY_STAFF_PROFILE_LIST_FAILURE';

export const GET_SOCIETY_STAFF_DETAILS = 'GET_SOCIETY_STAFF_DETAILS';
export const GET_SOCIETY_STAFF_DETAILS_SUCCESS = 'GET_SOCIETY_STAFF_DETAILS_SUCCESS';
export const GET_SOCIETY_STAFF_DETAILS_FAILURE = 'GET_SOCIETY_STAFF_DETAILS_FAILURE';

export const ADD_SOCIETY_STAFF = 'ADD_SOCIETY_STAFF';
export const ADD_SOCIETY_STAFF_SUCCESS = 'ADD_SOCIETY_STAFF_SUCCESS';
export const ADD_SOCIETY_STAFF_FAILURE = 'ADD_SOCIETY_STAFF_FAILURE';

export const DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET = 'DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET';
export const DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET_SUCCESS = 'DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET_SUCCESS';
export const DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET_FAILURE = 'DOWNLOAD_SOCIETY_STAFF_EXCEL_SHEET_FAILURE';

export const GET_SOCIET_STAFF_RATING_DATA = 'GET_SOCIET_STAFF_RATING_DATA';
export const GET_SOCIET_STAFF_RATING_DATA_SUCCESS = 'GET_SOCIET_STAFF_RATING_DATA_SUCCESS';
export const GET_SOCIET_STAFF_RATING_DATA_FAILURE = 'GET_SOCIET_STAFF_RATING_DATA_FAILURE';

export const GET_SOCIET_STAFF_RATING_PARAMETERS = 'GET_SOCIET_STAFF_RATING_PARAMETERS';
export const GET_SOCIET_STAFF_RATING_PARAMETERS_SUCCESS = 'GET_SOCIET_STAFF_RATING_PARAMETERS_SUCCESS';
export const GET_SOCIET_STAFF_RATING_PARAMETERS_FAILURE = 'GET_SOCIET_STAFF_RATING_PARAMETERS_FAILURE';

export const UPDATE_SOCIET_STAFF_RATING_DATA = 'UPDATE_SOCIET_STAFF_RATING_DATA';
export const UPDATE_SOCIET_STAFF_RATING_DATA_SUCCESS = 'UPDATE_SOCIET_STAFF_RATING_DATA_SUCCESS';
export const UPDATE_SOCIET_STAFF_RATING_DATA_FAILURE = 'UPDATE_SOCIET_STAFF_RATING_DATA_FAILURE';

export const UPDATE_SOCIETY_STAFF = 'UPDATE_SOCIETY_STAFF';
export const UPDATE_SOCIETY_STAFF_SUCCESS = 'UPDATE_SOCIETY_STAFF_SUCCESS';
export const UPDATE_SOCIETY_STAFF_FAILURE = 'UPDATE_SOCIETY_STAFF_FAILURE';

export const DELETE_SOCIETY_STAFF = 'DELETE_SOCIETY_STAFF';
export const DELETE_SOCIETY_STAFF_SUCCESS = 'DELETE_SOCIETY_STAFF_SUCCESS';
export const DELETE_SOCIETY_STAFF_FAILURE = 'DELETE_SOCIETY_STAFF_FAILURE';

export const GET_SOCIETY_STAFF_AUDIT_LOG = 'GET_SOCIETY_STAFF_AUDIT_LOG';
export const GET_SOCIETY_STAFF_AUDIT_LOG_SUCCESS = 'GET_SOCIETY_STAFF_AUDIT_LOG_SUCCESS';
export const GET_SOCIETY_STAFF_AUDIT_LOG_FAILURE = 'GET_SOCIETY_STAFF_AUDIT_LOG_FAILURE';

export const GET_SOCIETY_WING_FLAT_LIST = 'GET_SOCIETY_WING_FLAT_LIST';
export const GET_SOCIETY_WING_FLAT_LIST_SUCCESS = 'GET_SOCIETY_WING_FLAT_LIST_SUCCESS';
export const GET_SOCIETY_WING_FLAT_LIST_FAILURE = 'GET_SOCIETY_WING_FLAT_LIST_FAILURE';

export const PRINT_SOCIETY_STAFF_CARD = 'PRINT_SOCIETY_STAFF_CARD';
export const PRINT_SOCIETY_STAFF_CARD_SUCCESS = 'PRINT_SOCIETY_STAFF_CARD_SUCCESS';
export const PRINT_SOCIETY_STAFF_CARD_FAILURE = 'PRINT_SOCIETY_STAFF_CARD_FAILURE';

export const GET_APPROVAL_SETTINGS_DATA = 'GET_APPROVAL_SETTINGS_DATA';
export const GET_APPROVAL_SETTINGS_DATA_SUCCESS = 'GET_APPROVAL_SETTINGS_DATA_SUCCESS';
export const GET_APPROVAL_SETTINGS_DATA_FAILURE = 'GET_APPROVAL_SETTINGS_DATA_FAILURE';

export const UPDATE_APPROVAL_SETTINGS_DATA = 'UPDATE_APPROVAL_SETTINGS_DATA';
export const UPDATE_APPROVAL_SETTINGS_DATA_SUCCESS = 'UPDATE_APPROVAL_SETTINGS_DATA_SUCCESS';
export const UPDATE_APPROVAL_SETTINGS_DATA_FAILURE = 'UPDATE_APPROVAL_SETTINGS_DATA_FAILURE';

export const UPDATE_APPROVAL_STATUS_DATA = 'UPDATE_APPROVAL_STATUS_DATA';
export const UPDATE_APPROVAL_STATUS_DATA_SUCCESS = 'UPDATE_APPROVAL_STATUS_DATA_SUCCESS';
export const UPDATE_APPROVAL_STATUS_DATA_FAILURE = 'UPDATE_APPROVAL_STATUS_DATA_FAILURE';

export const DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT = 'DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT';
export const DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_SUCCESS = 'DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_SUCCESS';
export const DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_FAILURE = 'DOWNLOAD_SOCIETY_STAFF_SUMMARY_REPORT_FAILURE';


//-----MAINTENANCE AGENCY ACTIONS---------//
export const GET_MAINTENANCE_STAFF_LIST = 'GET_MAINTENANCE_STAFF_LIST';
export const GET_MAINTENANCE_STAFF_LIST_SUCCESS = 'GET_MAINTENANCE_STAFF_LIST_SUCCESS';
export const GET_MAINTENANCE_STAFF_LIST_FAILURE = 'GET_MAINTENANCE_STAFF_LIST_FAILURE';

export const GET_AGENCY_LIST = 'GET_AGENCY_LIST';
export const GET_AGENCY_LIST_SUCCESS = 'GET_AGENCY_LIST_SUCCESS';
export const GET_AGENCY_LIST_FAILURE = 'GET_AGENCY_LIST_FAILURE';

export const ADD_UPDATE_MAINTENANCE_STAFF = 'ADD_UPDATE_MAINTENANCE_STAFF';
export const ADD_UPDATE_MAINTENANCE_STAFF_SUCCESS = 'ADD_UPDATE_MAINTENANCE_STAFF_SUCCESS';
export const ADD_UPDATE_MAINTENANCE_STAFF_FAILURE = 'ADD_UPDATE_MAINTENANCE_STAFF_FAILURE';

export const GET_MAINTENANCE_STAFF_DETAILS = 'GET_MAINTENANCE_STAFF_DETAILS';
export const GET_MAINTENANCE_STAFF_DETAILS_SUCCESS = 'GET_MAINTENANCE_STAFF_DETAILS_SUCCESS';
export const GET_MAINTENANCE_STAFF_DETAILS_FAILURE = 'GET_MAINTENANCE_STAFF_DETAILS_FAILURE';

export const DELETE_MAINTENANCE_STAFF_OR_AGENCY = 'DELETE_MAINTENANCE_STAFF_OR_AGENCY';
export const DELETE_MAINTENANCE_STAFF_OR_AGENCY_SUCCESS = 'DELETE_MAINTENANCE_STAFF_OR_AGENCY_SUCCESS';
export const DELETE_MAINTENANCE_STAFF_OR_AGENCY_FAILURE = 'DELETE_MAINTENANCE_STAFF_OR_AGENCY_FAILURE';

export const GET_AGENCY_CATEGORY_LIST = 'GET_AGENCY_CATEGORY_LIST';
export const GET_AGENCY_CATEGORY_LIST_SUCCESS = 'GET_AGENCY_CATEGORY_LIST_SUCCESS';
export const GET_AGENCY_CATEGORY_LIST_FAILURE = 'GET_AGENCY_CATEGORY_LIST_FAILURE';

export const GET_AGENCY_DETAILS = 'GET_AGENCY_DETAILS';
export const GET_AGENCY_DETAILS_SUCCESS = 'GET_AGENCY_DETAILS_SUCCESS';
export const GET_AGENCY_DETAILS_FAILURE = 'GET_AGENCY_DETAILS_FAILURE';

export const ADD_UPDATE_AGENCY = 'ADD_UPDATE_AGENCY';
export const ADD_UPDATE_AGENCY_SUCCESS = 'ADD_UPDATE_AGENCY_SUCCESS';
export const ADD_UPDATE_AGENCY_FAILURE = 'ADD_UPDATE_AGENCY_FAILURE';

//-----EMPLOYEE MANAGEMENT ACTIONS---------//
export const GET_EMPLOYEE_LIST = 'GET_EMPLOYEE_LIST';
export const GET_EMPLOYEE_LIST_SUCCESS = 'GET_EMPLOYEE_LIST_SUCCESS';
export const GET_EMPLOYEE_LIST_FAILURE = 'GET_EMPLOYEE_LIST_FAILURE';

export const ADD_EMPLOYEE = 'ADD_EMPLOYEE';
export const ADD_EMPLOYEE_SUCCESS = 'ADD_EMPLOYEE_SUCCESS';
export const ADD_EMPLOYEE_FAILURE = 'ADD_EMPLOYEE_FAILURE';

export const GET_EMPLOYEE_TYPES_LIST = 'GET_EMPLOYEE_TYPES_LIST';
export const GET_EMPLOYEE_TYPES_LIST_SUCCESS = 'GET_EMPLOYEE_TYPES_LIST_SUCCESS';
export const GET_EMPLOYEE_TYPES_LIST_FAILURE = 'GET_EMPLOYEE_TYPES_LIST_FAILURE';

export const CHANGE_EMPLOYEE_STATUS = 'CHANGE_EMPLOYEE_STATUS';
export const CHANGE_EMPLOYEE_STATUS_SUCCESS = 'CHANGE_EMPLOYEE_STATUS_SUCCESS';
export const CHANGE_EMPLOYEE_STATUS_FAILURE = 'CHANGE_EMPLOYEE_STATUS_FAILURE';

export const DELETE_EMPLOYEE = 'DELETE_EMPLOYEE';
export const DELETE_EMPLOYEE_SUCCESS = 'DELETE_EMPLOYEE_SUCCESS';
export const DELETE_EMPLOYEE_FAILURE = 'DELETE_EMPLOYEE_FAILURE';

export const GET_EMPLOYEE_AUDIT_LOGS = 'GET_EMPLOYEE_AUDIT_LOGS';
export const GET_EMPLOYEE_AUDIT_LOGS_SUCCESS = 'GET_EMPLOYEE_AUDIT_LOGS_SUCCESS';
export const GET_EMPLOYEE_AUDIT_LOGS_FAILURE = 'GET_EMPLOYEE_AUDIT_LOGS_FAILURE';

export const GET_EMPLOYEE_DETAILS = 'GET_EMPLOYEE_DETAILS';
export const GET_EMPLOYEE_DETAILS_SUCCESS = 'GET_EMPLOYEE_DETAILS_SUCCESS';
export const GET_EMPLOYEE_DETAILS_FAILURE = 'GET_EMPLOYEE_DETAILS_FAILURE';

export const UPDATE_EMPLOYEE_DATA = 'UPDATE_EMPLOYEE_DATA';
export const UPDATE_EMPLOYEE_DATA_SUCCESS = 'UPDATE_EMPLOYEE_DATA_SUCCESS';
export const UPDATE_EMPLOYEE_DATA_FAILURE = 'UPDATE_EMPLOYEE_DATA_FAILURE';

export const GET_EMPLOYEE_USERNAMES_LIST = 'GET_EMPLOYEE_USERNAMES_LIST';
export const GET_EMPLOYEE_USERNAMES_LIST_SUCCESS = 'GET_EMPLOYEE_USERNAMES_LIST_SUCCESS';
export const GET_EMPLOYEE_USERNAMES_LIST_FAILURE = 'GET_EMPLOYEE_USERNAMES_LIST_FAILURE';

export const GET_EMPLOYEE_NAMES_LIST = 'GET_EMPLOYEE_NAMES_LIST';
export const GET_EMPLOYEE_NAMES_LIST_SUCCESS = 'GET_EMPLOYEE_NAMES_LIST_SUCCESS';
export const GET_EMPLOYEE_NAMES_LIST_FAILURE = 'GET_EMPLOYEE_NAMES_LIST_FAILURE';

export const GET_EXISTING_EMPLOYEE_LIST = 'GET_EXISTING_EMPLOYEE_LIST';
export const GET_EXISTING_EMPLOYEE_LIST_SUCCESS = 'GET_EXISTING_EMPLOYEE_LIST_SUCCESS';
export const GET_EXISTING_EMPLOYEE_LIST_FAILURE = 'GET_EXISTING_EMPLOYEE_LIST_FAILURE';

export const ADD_FROM_EXISTING_EMPLOYEE = 'ADD_FROM_EXISTING_EMPLOYEE';
export const ADD_FROM_EXISTING_EMPLOYEE_SUCCESS = 'ADD_FROM_EXISTING_EMPLOYEE_SUCCESS';
export const ADD_FROM_EXISTING_EMPLOYEE_FAILURE = 'ADD_FROM_EXISTING_EMPLOYEE_FAILURE';

export const GET_EMPLOYEEE_LOGIN_REPORT = 'GET_EMPLOYEEE_LOGIN_REPORT';
export const GET_EMPLOYEEE_LOGIN_REPORT_SUCCESS = 'GET_EMPLOYEEE_LOGIN_REPORT_SUCCESS';
export const GET_EMPLOYEEE_LOGIN_REPORT_FAILURE = 'GET_EMPLOYEEE_LOGIN_REPORT_FAILURE';

export const PRINT_EMPLOYEEE_LOGIN_REPORT = 'PRINT_EMPLOYEEE_LOGIN_REPORT';
export const PRINT_EMPLOYEEE_LOGIN_REPORT_SUCCESS = 'PRINT_EMPLOYEEE_LOGIN_REPORT_SUCCESS';
export const PRINT_EMPLOYEEE_LOGIN_REPORT_FAILURE = 'PRINT_EMPLOYEEE_LOGIN_REPORT_FAILURE';

export const DOWNLOAD_EMPLOYEEE_LOGIN_REPORT = 'DOWNLOAD_EMPLOYEEE_LOGIN_REPORT';
export const DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_SUCCESS = 'DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_SUCCESS';
export const DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_FAILURE = 'DOWNLOAD_EMPLOYEEE_LOGIN_REPORT_FAILURE';

export const GET_EMPLOYEEE_REPORT_RECORD = 'GET_EMPLOYEEE_REPORT_RECORD';
export const GET_EMPLOYEEE_REPORT_RECORD_SUCCESS = 'GET_EMPLOYEEE_REPORT_RECORD_SUCCESS';
export const GET_EMPLOYEEE_REPORT_RECORD_FAILURE = 'GET_EMPLOYEEE_REPORT_RECORD_FAILURE';

export const PRINT_EMPLOYEEE_REPORT_RECORD = 'PRINT_EMPLOYEEE_REPORT_RECORD';
export const PRINT_EMPLOYEEE_REPORT_RECORD_SUCCESS = 'PRINT_EMPLOYEEE_REPORT_RECORD_SUCCESS';
export const PRINT_EMPLOYEEE_REPORT_RECORD_FAILURE = 'GET_EMPLOYEEE_REPORT_RECORD_FAILURE';

export const SEND_STAFF_CODE_SMS_TO_STAFF = 'SEND_STAFF_CODE_SMS_TO_STAFF';
export const SEND_STAFF_CODE_SMS_TO_STAFF_SUCCESS = 'SEND_STAFF_CODE_SMS_TO_STAFF_SUCCESS';
export const SEND_STAFF_CODE_SMS_TO_STAFF_FAILURE = 'SEND_STAFF_CODE_SMS_TO_STAFF_FAILURE';

export const GET_ID_CARD_FIELDS_DATA = 'GET_ID_CARD_FIELDS_DATA';
export const GET_ID_CARD_FIELDS_DATA_SUCCESS = 'GET_ID_CARD_FIELDS_DATA_SUCCESS';
export const GET_ID_CARD_FIELDS_DATA_FAILURE = 'GET_ID_CARD_FIELDS_DATA_FAILURE';

export const UPDATE_ID_CARD_FIELDS_DATA = 'UPDATE_ID_CARD_FIELDS_DATA';
export const UPDATE_ID_CARD_FIELDS_DATA_SUCCESS = 'UPDATE_ID_CARD_FIELDS_DATA_SUCCESS';
export const UPDATE_ID_CARD_FIELDS_DATA_FAILURE = 'UPDATE_ID_CARD_FIELDS_DATA_FAILURE';


//-----MANAGING COMMITTEE ACTIONS---------//
export const GET_MANAGING_COMMITTIEE_DETAILED_LIST = 'GET_MANAGING_COMMITTIEE_DETAILED_LIST';
export const GET_MANAGING_COMMITTIEE_DETAILED_LIST_SUCCESS = 'GET_MANAGING_COMMITTIEE_DETAILED_LIST_SUCCESS';
export const GET_MANAGING_COMMITTIEE_DETAILED_LIST_FAILURE = 'GET_MANAGING_COMMITTIEE_DETAILED_LIST_FAILURE';

export const ADD_MANAGING_COMMITTIEE = 'ADD_MANAGING_COMMITTIEE';
export const ADD_MANAGING_COMMITTIEE_SUCCESS = 'ADD_MANAGING_COMMITTIEE_SUCCESS';
export const ADD_MANAGING_COMMITTIEE_FAILURE = 'ADD_MANAGING_COMMITTIEE_FAILURE';

export const GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE = 'GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE';
export const GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_SUCCESS = 'GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_SUCCESS';
export const GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_FAILURE = 'GET_OCCUPANTS_FOR_MANAGING_COMMITTIEE_FAILURE';

export const GET_ALL_COMMITTEE_AUDIT_LOG = 'GET_ALL_COMMITTEE_AUDIT_LOG';
export const GET_ALL_COMMITTEE_AUDIT_LOG_SUCCESS = 'GET_ALL_COMMITTEE_AUDIT_LOG_SUCCESS';
export const GET_ALL_COMMITTEE_AUDIT_LOG_FAILURE = 'GET_ALL_COMMITTEE_AUDIT_LOG_FAILURE';

export const GET_MANAGING_COMMITTIEE_AUDIT_LOGS = 'GET_MANAGING_COMMITTIEE_AUDIT_LOGS';
export const GET_MANAGING_COMMITTIEE_AUDIT_LOGS_SUCCESS = 'GET_MANAGING_COMMITTIEE_AUDIT_LOGS_SUCCESS';
export const GET_MANAGING_COMMITTIEE_AUDIT_LOGS_FAILURE = 'GET_MANAGING_COMMITTIEE_AUDIT_LOGS_FAILURE';

export const GET_MANAGING_COMMITTIEE_DETAILS = 'GET_MANAGING_COMMITTIEE_DETAILS';
export const GET_MANAGING_COMMITTIEE_DETAILS_SUCCESS = 'GET_MANAGING_COMMITTIEE_DETAILS_SUCCESS';
export const GET_MANAGING_COMMITTIEE_DETAILS_FAILURE = 'GET_MANAGING_COMMITTIEE_DETAILS_FAILURE';

export const GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER = 'GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER';
export const GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_SUCCESS = 'GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_SUCCESS';
export const GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_FAILURE = 'GET_ARCHIVE_MANAGING_COMMITTIEE_MEMBER_FAILURE';

export const UPDATE_MANAGING_COMMITTIEE = 'UPDATE_MANAGING_COMMITTIEE';
export const UPDATE_MANAGING_COMMITTIEE_SUCCESS = 'UPDATE_MANAGING_COMMITTIEE_SUCCESS';
export const UPDATE_MANAGING_COMMITTIEE_FAILURE = 'UPDATE_MANAGING_COMMITTIEE_FAILURE';

export const GET_MANAGING_COMMITTIEE_ACCESS_SETTING = 'GET_MANAGING_COMMITTIEE_ACCESS_SETTING';
export const GET_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS = 'GET_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS';
export const GET_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE = 'GET_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE';

export const UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING = 'UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING';
export const UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS = 'UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_SUCCESS';
export const UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE = 'UPDATE_MANAGING_COMMITTIEE_ACCESS_SETTING_FAILURE';

export const DELETE_MANAGING_COMMITTIEE = 'DELETE_MANAGING_COMMITTIEE';
export const DELETE_MANAGING_COMMITTIEE_SUCCESS = 'DELETE_MANAGING_COMMITTIEE_SUCCESS';
export const DELETE_MANAGING_COMMITTIEE_FAILURE = 'DELETE_MANAGING_COMMITTIEE_FAILURE';

export const RESIGN_MANAGING_COMMITTIEE = 'RESIGN_MANAGING_COMMITTIEE';
export const RESIGN_MANAGING_COMMITTIEE_SUCCESS = 'RESIGN_MANAGING_COMMITTIEE_SUCCESS';
export const RESIGN_MANAGING_COMMITTIEE_FAILURE = 'RESIGN_MANAGING_COMMITTIEE_FAILURE';

export const RETIRE_MANAGING_COMMITTIEE = 'RETIRE_MANAGING_COMMITTIEE';
export const RETIRE_MANAGING_COMMITTIEE_SUCCESS = 'RETIRE_MANAGING_COMMITTIEE_SUCCESS';
export const RETIRE_MANAGING_COMMITTIEE_FAILURE = 'RETIRE_MANAGING_COMMITTIEE_FAILURE';

export const VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER = 'VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER';
export const VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS = 'VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS';
export const VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE = 'VIEW_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE';

export const DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER = 'DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER';
export const DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS = 'DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_SUCCESS';
export const DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE = 'DELETE_ARCHIVED_MANAGING_COMMITTEE_MEMBER_FAILURE';

export const APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION = 'APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION';
export const APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_SUCCESS = 'APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_SUCCESS';
export const APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_FAILURE = 'APPROVE_AND_REJECT_MANAGING_COMMITTEE_MEMBER_RESIGNATION_FAILURE';

export const APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT = 'APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT';
export const APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_SUCCESS = 'APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_SUCCESS';
export const APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_FAILURE = 'APPROVE_MANAGING_COMMITTEE_MEMBER_RETIREMENT_FAILURE';

export const REAPPOINT_MANAGING_COMMITTEE_MEMBER = 'REAPPOINT_MANAGING_COMMITTEE_MEMBER';
export const REAPPOINT_MANAGING_COMMITTEE_MEMBER_SUCCESS = 'REAPPOINT_MANAGING_COMMITTEE_MEMBER_SUCCESS';
export const REAPPOINT_MANAGING_COMMITTEE_MEMBER_FAILURE = 'REAPPOINT_MANAGING_COMMITTEE_MEMBER_FAILURE';


//-----BILLING & INVOICE SETUP ACTIONS---------//
export const GET_GST_NOC_SETTING = 'GET_GST_NOC_SETTING';
export const GET_GST_NOC_SETTING_SUCCESS = 'GET_GST_NOC_SETTING_SUCCESS';
export const GET_GST_NOC_SETTING_FAILURE = 'GET_GST_NOC_SETTING_FAILURE';

export const UPDATE_GST_NOC_SETTING = 'UPDATE_GST_NOC_SETTING';
export const UPDATE_GST_NOC_SETTING_SUCCESS = 'UPDATE_GST_NOC_SETTING_SUCCESS';
export const UPDATE_GST_NOC_SETTING_FAILURE = 'UPDATE_GST_NOC_SETTING_FAILURE';

export const GET_INVOICE_HEAD_SETTING = 'GET_INVOICE_HEAD_SETTING_SETTING';
export const GET_INVOICE_HEAD_SETTING_SUCCESS = 'GET_INVOICE_HEAD_SETTING_SUCCESS';
export const GET_INVOICE_HEAD_SETTING_FAILURE = 'GET_INVOICE_HEAD_SETTING_FAILURE';

export const UPDATE_INVOICE_HEAD_SETTING = 'UPDATE_INVOICE_HEAD_SETTING';
export const UPDATE_INVOICE_HEAD_SETTING_SUCCESS = 'UPDATE_INVOICE_HEAD_SETTING_SUCCESS';
export const UPDATE_INVOICE_HEAD_SETTING_FAILURE = 'UPDATE_INVOICE_HEAD_SETTING_FAILURE';

export const GET_GL_ACCOUNT_LIST = 'GET_GL_ACCOUNT_LIST';
export const GET_GL_ACCOUNT_LIST_SUCCESS = 'GET_GL_ACCOUNT_LIST_SUCCESS';
export const GET_GL_ACCOUNT_LIST_FAILURE = 'GET_GL_ACCOUNT_LIST_FAILURE';

export const ADD_MORE_HEAD = 'ADD_MORE_HEAD';
export const ADD_MORE_HEAD_SUCCESS = 'ADD_MORE_HEAD_SUCCESS';
export const ADD_MORE_HEAD_FAILURE = 'ADD_MORE_HEAD_FAILURE';

export const GET_MORE_HEAD_DETAILS = 'GET_MORE_HEAD_DETAILS';
export const GET_MORE_HEAD_DETAILS_SUCCESS = 'GET_MORE_HEAD_DETAILS_SUCCESS';
export const GET_MORE_HEAD_DETAILS_FAILURE = 'GET_MORE_HEAD_DETAILS_FAILURE';

export const EDIT_MORE_HEAD_DETAILS = 'EDIT_MORE_HEAD_DETAILS';
export const EDIT_MORE_HEAD_DETAILS_SUCCESS = 'EDIT_MORE_HEAD_DETAILS_SUCCESS';
export const EDIT_MORE_HEAD_DETAILS_FAILURE = 'EDIT_MORE_HEAD_DETAILS_FAILURE';

export const DELETE_MORE_HEAD_DETAILS = 'DELETE_MORE_HEAD_DETAILS';
export const DELETE_MORE_HEAD_DETAILS_SUCCESS = 'DELETE_MORE_HEAD_DETAILS_SUCCESS';
export const DELETE_MORE_HEAD_DETAILS_FAILURE = 'DELETE_MORE_HEAD_DETAILS_FAILURE';

export const DELETE_UTILITY_SLAB = 'DELETE_UTILITY_SLAB';
export const DELETE_UTILITY_SLAB_SUCCESS = 'DELETE_UTILITY_SLAB_SUCCESS';
export const DELETE_UTILITY_SLAB_FAILURE = 'DELETE_UTILITY_SLAB_FAILURE';

export const ADD_UTILITY_SLAB = 'ADD_UTILITY_SLAB';
export const ADD_UTILITY_SLAB_SUCCESS = 'ADD_UTILITY_SLAB_SUCCESS';
export const ADD_UTILITY_SLAB_FAILURE = 'ADD_UTILITY_SLAB_FAILURE';

export const GET_INVOICE_FUNCTIONAL_SETTING = 'GET_INVOICE_FUNCTIONAL_SETTING';
export const GET_INVOICE_FUNCTIONAL_SETTING_SUCCESS = 'GET_INVOICE_FUNCTIONAL_SETTING_SUCCESS';
export const GET_INVOICE_FUNCTIONAL_SETTING_FAILURE = 'GET_INVOICE_FUNCTIONAL_SETTING_FAILURE';

export const SAVE_INVOICE_FUNCTIONAL_SETTING = 'SAVE_INVOICE_FUNCTIONAL_SETTING';
export const SAVE_INVOICE_FUNCTIONAL_SETTING_SUCCESS = 'SAVE_INVOICE_FUNCTIONAL_SETTING_SUCCESS';
export const SAVE_INVOICE_FUNCTIONAL_SETTING_FAILURE = 'SAVE_INVOICE_FUNCTIONAL_SETTING_FAILURE';

export const GET_INVOICE_SERIES_LIST = 'GET_INVOICE_SERIES_LIST';
export const GET_INVOICE_SERIES_LIST_SUCCESS = 'GET_INVOICE_SERIES_LIST_SUCCESS';
export const GET_INVOICE_SERIES_LIST_FAILURE = 'GET_INVOICE_SERIES_LIST_FAILURE';

export const DELETE_INVOICE_SERIES = 'DELETE_INVOICE_SERIES';
export const DELETE_INVOICE_SERIES_SUCCESS = 'DELETE_INVOICE_SERIES_SUCCESS';
export const DELETE_INVOICE_SERIES_FAILURE = 'DELETE_INVOICE_SERIES_FAILURE';

export const ADD_INVOICE_SERIES = 'ADD_INVOICE_SERIES';
export const ADD_INVOICE_SERIES_SUCCESS = 'ADD_INVOICE_SERIES_SUCCESS';
export const ADD_INVOICE_SERIES_FAILURE = 'ADD_INVOICE_SERIES_FAILURE';

export const GET_TAXABLE_FORMAT_LIST = 'GET_TAXABLE_FORMAT_LIST';
export const GET_TAXABLE_FORMAT_LIST_SUCCESS = 'GET_TAXABLE_FORMAT_LIST_SUCCESS';
export const GET_TAXABLE_FORMAT_LIST_FAILURE = 'GET_TAXABLE_FORMAT_LIST_FAILURE';

export const DELETE_TAXABLE_MAIN_HEAD = 'DELETE_TAXABLE_MAIN_HEAD';
export const DELETE_TAXABLE_MAIN_HEAD_SUCCESS = 'DELETE_TAXABLE_MAIN_HEAD_SUCCESS';
export const DELETE_TAXABLE_MAIN_HEAD_FAILURE = 'DELETE_TAXABLE_MAIN_HEAD_FAILURE';

export const ADD_TAXABLE_MAIN_HEAD = 'ADD_TAXABLE_MAIN_HEAD';
export const ADD_TAXABLE_MAIN_HEAD_SUCCESS = 'ADD_TAXABLE_MAIN_HEAD_SUCCESS';
export const ADD_TAXABLE_MAIN_HEAD_FAILURE = 'ADD_TAXABLE_MAIN_HEAD_FAILURE';

export const EDIT_TAXABLE_MAIN_HEAD = 'EDIT_TAXABLE_MAIN_HEAD';
export const EDIT_TAXABLE_MAIN_HEAD_SUCCESS = 'EDIT_TAXABLE_MAIN_HEAD_SUCCESS';
export const EDIT_TAXABLE_MAIN_HEAD_FAILURE = 'EDIT_TAXABLE_MAIN_HEAD_FAILURE';

export const ADD_TAXABLE_SUB_HEAD = 'ADD_TAXABLE_SUB_HEAD';
export const ADD_TAXABLE_SUB_HEAD_SUCCESS = 'ADD_TAXABLE_SUB_HEAD_SUCCESS';
export const ADD_TAXABLE_SUB_HEAD_FAILURE = 'ADD_TAXABLE_SUB_HEAD_FAILURE';

export const ACTION_TAXABLE_SUB_HEAD = 'ACTION_TAXABLE_SUB_HEAD';
export const ACTION_TAXABLE_SUB_HEAD_SUCCESS = 'ACTION_TAXABLE_SUB_HEAD_SUCCESS';
export const ACTION_TAXABLE_SUB_HEAD_FAILURE = 'ACTION_TAXABLE_SUB_HEAD_FAILURE';

export const CHANGE_ORDER_TAXABLE_HEAD = 'CHANGE_ORDER_TAXABLE_HEAD';
export const CHANGE_ORDER_TAXABLE_HEAD_SUCCESS = 'CHANGE_ORDER_TAXABLE_HEAD_SUCCESS';
export const CHANGE_ORDER_TAXABLE_HEAD_FAILURE = 'CHANGE_ORDER_TAXABLE_HEAD_FAILURE';

export const GET_INVOICE_TYPE_LIST = 'GET_INVOICE_TYPE_LIST';
export const GET_INVOICE_TYPE_LIST_SUCCESS = 'GET_INVOICE_TYPE_LIST_SUCCESS';
export const GET_INVOICE_TYPE_LIST_FAILURE = 'GET_INVOICE_TYPE_LIST_FAILURE';

export const ADD_INVOICE_TYPE = 'ADD_INVOICE_TYPE_LIST';
export const ADD_INVOICE_TYPE_SUCCESS = 'ADD_INVOICE_TYPE_LIST_SUCCESS';
export const ADD_INVOICE_TYPE_FAILURE = 'ADD_INVOICE_TYPE_LIST_FAILURE';

export const DELETE_INVOICE_TYPE = 'DELETE_INVOICE_TYPE_LIST';
export const DELETE_INVOICE_TYPE_SUCCESS = 'DELETE_INVOICE_TYPE_SUCCESS';
export const DELETE_INVOICE_TYPE_FAILURE = 'DELETE_INVOICE_TYPE_FAILURE';

export const GET_INVOICE_HEAD_LIST = 'GET_INVOICE_HEAD_LIST_LIST';
export const GET_INVOICE_HEAD_LIST_SUCCESS = 'GET_INVOICE_HEAD_LIST_SUCCESS';
export const GET_INVOICE_HEAD_LIST_FAILURE = 'GET_INVOICE_HEAD_LIST_FAILURE';

export const GET_BANK_DETAIL_LIST = 'GET_BANK_DETAIL_LIST';
export const GET_BANK_DETAIL_LIST_SUCCESS = 'GET_BANK_DETAIL_LIST_SUCCESS';
export const GET_BANK_DETAIL_LIST_FAILURE = 'GET_BANK_DETAIL_LIST_FAILURE';

export const GET_CREDIT_NOTE_SETTINGS_DETAIL = 'GET_CREDIT_NOTE_SETTINGS_DETAIL';
export const GET_CREDIT_NOTE_SETTINGS_DETAIL_SUCCESS = 'GET_CREDIT_NOTE_SETTINGS_DETAIL_SUCCESS';
export const GET_CREDIT_NOTE_SETTINGS_DETAIL_FAILURE = 'GET_CREDIT_NOTE_SETTINGS_DETAIL_FAILURE';

export const GET_MEMBER_LEDGER_LIST = 'GET_MEMBER_LEDGER_LIST_DETAIL';
export const GET_MEMBER_LEDGER_LIST_SUCCESS = 'GET_MEMBER_LEDGER_LIST_SUCCESS';
export const GET_MEMBER_LEDGER_LIST_FAILURE = 'GET_MEMBER_LEDGER_LIST_FAILURE';

//------------BULK UPLOAD ACTIONS--------------//
export const DOWNLOAD_EXCEL_FORMAT = 'DOWNLOAD_EXCEL_FORMAT';

export const UPLOAD_STAFF_EXCEL_SHEET = 'UPLOAD_STAFF_EXCEL_SHEET';
export const UPLOAD_STAFF_EXCEL_SHEET_SUCCESS = 'UPLOAD_STAFF_EXCEL_SHEET_SUCCESS';
export const UPLOAD_STAFF_EXCEL_SHEET_FAILURE = 'UPLOAD_STAFF_EXCEL_SHEET_FAILURE';

export const UPLOAD_NEW_OCCUPANT_EXCEL_SHEET = 'UPLOAD_NEW_OCCUPANT_EXCEL_SHEET';
export const UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_SUCCESS = 'UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_SUCCESS';
export const UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_FAILURE = 'UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_FAILURE';

export const UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET = 'UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET';
export const UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_SUCCESS = 'UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_SUCCESS';
export const UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_FAILURE = 'UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_FAILURE';

export const UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET = 'UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET';
export const UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_SUCCESS = 'UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_SUCCESS';
export const UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_FAILURE = 'UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_FAILURE';

export const DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE = 'DOWNLOAD_OCCUPANT_OPENING_BALANCE_SHEET_SAMPLE';

export const UPLOAD_CLEAR_ERROR = 'UPLOAD_CLEAR_ERROR';

//-----MANAGE ROLES AND RIGHTS ACTIONS---------//
export const GET_EMPLOYEE_ROLE_LIST = 'GET_EMPLOYEE_ROLE_LIST';
export const GET_EMPLOYEE_ROLE_LIST_SUCCESS = 'GET_EMPLOYEE_ROLE_LIST_SUCCESS';
export const GET_EMPLOYEE_ROLE_LIST_FAILURE = 'GET_EMPLOYEE_ROLE_LIST_FAILURE';

export const GET_EMPLOYEE_ROLE_DETAILS = 'GET_EMPLOYEE_ROLE_DETAILS';
export const GET_EMPLOYEE_ROLE_DETAILS_SUCCESS = 'GET_EMPLOYEE_ROLE_DETAILS_SUCCESS';
export const GET_EMPLOYEE_ROLE_DETAILS_FAILURE = 'GET_EMPLOYEE_ROLE_DETAILS_FAILURE';

export const ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS = 'ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS';
export const ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_SUCCESS = 'ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_SUCCESS';
export const ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_FAILURE = 'ADD_AND_UPDATE_EMPLOYEE_ROLE_DETAILS_FAILURE';

export const GET_OCCUPANT_ROLE_LIST = 'GET_OCCUPANT_ROLE_LIST';
export const GET_OCCUPANT_ROLE_LIST_SUCCESS = 'GET_OCCUPANT_ROLE_LIST_SUCCESS';
export const GET_OCCUPANT_ROLE_LIST_FAILURE = 'GET_OCCUPANT_ROLE_LIST_FAILURE';

export const GET_OCCUPANTS_ROLE_DETAILS = 'GET_OCCUPANTS_ROLE_DETAILS';
export const GET_OCCUPANTS_ROLE_DETAILS_SUCCESS = 'GET_OCCUPANTS_ROLE_DETAILS_SUCCESS';
export const GET_OCCUPANTS_ROLE_DETAILS_FAILURE = 'GET_OCCUPANTS_ROLE_DETAILS_FAILURE';

export const ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS = 'ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS';
export const ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_SUCCESS = 'ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_SUCCESS';
export const ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_FAILURE = 'ADD_AND_UPDATE_OCCUPANTS_ROLE_DETAILS_FAILURE';

export const GET_FAMILY_ROLE_DETAILS = 'GET_FAMILY_ROLE_DETAILS';
export const GET_FAMILY_ROLE_DETAILS_SUCCESS = 'GET_FAMILY_ROLE_DETAILS_SUCCESS';
export const GET_FAMILY_ROLE_DETAILS_FAILURE = 'GET_FAMILY_ROLE_DETAILS_FAILURE';

export const GET_FAMILY_ROLE_LIST = 'GET_FAMILY_ROLE_LIST';
export const GET_FAMILY_ROLE_LIST_SUCCESS = 'GET_FAMILY_ROLE_LIST_SUCCESS';
export const GET_FAMILY_ROLE_LIST_FAILURE = 'GET_FAMILY_ROLE_LIST_FAILURE';

export const ADD_AND_UPDATE_FAMILY_ROLE_DETAILS = 'ADD_AND_UPDATE_FAMILY_ROLE_DETAILS';
export const ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_SUCCESS = 'ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_SUCCESS';
export const ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_FAILURE = 'ADD_AND_UPDATE_FAMILY_ROLE_DETAILS_FAILURE';

export const GET_ROLE_RIGHT_SETTINGS = 'GET_ROLE_RIGHT_SETTINGS';
export const GET_ROLE_RIGHT_SETTINGS_SUCCESS = 'GET_ROLE_RIGHT_SETTINGS_SUCCESS';
export const GET_ROLE_RIGHT_SETTINGS_FAILURE = 'GET_ROLE_RIGHT_SETTINGS_FAILURE';

export const UPDATE_ROLE_RIGHT_STATUS = 'UPDATE_ROLE_RIGHT_STATUS';
export const UPDATE_ROLE_RIGHT_STATUS_SUCCESS = 'UPDATE_ROLE_RIGHT_STATUS_SUCCESS';
export const UPDATE_ROLE_RIGHT_STATUS_FAILURE = 'UPDATE_ROLE_RIGHT_STATUS_FAILURE';

export const DELETE_RIGHTS_AND_ROLES = 'DELETE_RIGHTS_AND_ROLES';
export const DELETE_RIGHTS_AND_ROLES_SUCCESS = 'DELETE_RIGHTS_AND_ROLES_SUCCESS';
export const DELETE_RIGHTS_AND_ROLES_FAILURE = 'DELETE_RIGHTS_AND_ROLES_FAILURE';

export const GET_SOCIETY_ACCESS_SETTINGS = 'GET_SOCIETY_ACCESS_SETTINGS';
export const GET_SOCIETY_ACCESS_SETTINGS_SUCCESS = 'GET_SOCIETY_ACCESS_SETTINGS_SUCCESS';
export const GET_SOCIETY_ACCESS_SETTINGS_FAILURE = 'GET_SOCIETY_ACCESS_SETTINGS_FAILURE';

export const UPDATE_SOCIETY_ACCESS_SETTINGS = 'UPDATE_SOCIETY_ACCESS_SETTINGS';
export const UPDATE_SOCIETY_ACCESS_SETTINGS_SUCCESS = 'UPDATE_SOCIETY_ACCESS_SETTINGS_SUCCESS';
export const UPDATE_SOCIETY_ACCESS_SETTINGS_FAILURE = 'UPDATE_SOCIETY_ACCESS_SETTINGS_FAILURE';

//------------SOCIETY FORMS AND BYE LAWS ACTIONS--------------//
export const GET_SOCIETY_FORMS_AND_BYE_LAWS = 'GET_SOCIETY_FORMS_AND_BYE_LAWS';
export const GET_SOCIETY_FORMS_AND_BYE_LAWS_SUCCESS = 'GET_SOCIETY_FORMS_AND_BYE_LAWS_SUCCESS';
export const GET_SOCIETY_FORMS_AND_BYE_LAWS_FAILURE = 'GET_SOCIETY_FORMS_AND_BYE_LAWS_FAILURE';



//------------SOCIAL AND COMMUNICATION ACTIONS--------------//
//------------LOBBY ACTIONS--------------//
//-----IMPORTANT CONTACTS ACTIONS---------//
export const GET_IMPORTANT_CONTACTS_LIST = "GET_IMPORTANT_CONTACTS_LIST"
export const GET_IMPORTANT_CONTACTS_LIST_SUCCESS = "GET_IMPORTANT_CONTACTS_LIST_SUCCESS"
export const GET_IMPORTANT_CONTACTS_LIST_FAILURE = "GET_IMPORTANT_CONTACTS_LIST_FAILURE"

export const GET_CONTACTS_CATEGORY_LIST = "GET_CONTACTS_CATEGORY_LIST"
export const GET_CONTACTS_CATEGORY_LIST_SUCCESS = "GET_CONTACTS_CATEGORY_LIST_SUCCESS"
export const GET_CONTACTS_CATEGORY_LIST_FAILURE = "GET_CONTACTS_CATEGORY_LIST_FAILURE"


// ------------------ NOTICE AND CIRCULAR ACTIONS -----------------------------
export const GET_NOTICE_CIRCULAR_LIST = "GET_NOTICE_CIRCULAR_LIST";
export const GET_NOTICE_CIRCULAR_LIST_SUCCESS = "GET_NOTICE_CIRCULAR_LIST_SUCCESS";
export const GET_NOTICE_CIRCULAR_LIST_FAILURE = "GET_NOTICE_CIRCULAR_LIST_FAILURE";

export const RESEND_NOTICE_CIRCULAR = "RESEND_RESEND_NOTICE_CIRCULAR";
export const RESEND_NOTICE_CIRCULAR_SUCCESS = "RESEND_RESEND_NOTICE_CIRCULAR_SUCCESS";
export const RESEND_NOTICE_CIRCULAR_FAILURE = "RESEND_RESEND_NOTICE_CIRCULAR_FAILURE";

export const DELETE_NOTICE_CIRCULAR = "DELETE_NOTICE_CIRCULAR";
export const DELETE_NOTICE_CIRCULAR_SUCCESS = "DELETE_NOTICE_CIRCULAR_SUCCESS";
export const DELETE_NOTICE_CIRCULAR_FAILURE = "DELETE_NOTICE_CIRCULAR_FAILURE";

export const GET_NOTICE_CIRCULAR_DETAILS = "GET_NOTICE_CIRCULAR_DETAILS";
export const GET_NOTICE_CIRCULAR_DETAILS_SUCCESS = "GET_NOTICE_CIRCULAR_DETAILS_SUCCESS";
export const GET_NOTICE_CIRCULAR_DETAILS_FAILURE = "GET_NOTICE_CIRCULAR_DETAILS_FAILURE";

export const PRINT_NOTICE_CIRCULAR = "PRINT_NOTICE_CIRCULAR";
export const PRINT_NOTICE_CIRCULAR_SUCCESS = "PRINT_NOTICE_CIRCULAR_SUCCESS";
export const PRINT_NOTICE_CIRCULAR_FAILURE = "PRINT_NOTICE_CIRCULAR_FAILURE";

export const POST_NOTICE_CIRCULAR_COMMENT = "POST_NOTICE_CIRCULAR_COMMENT";
export const POST_NOTICE_CIRCULAR_COMMENT_SUCCESS = "POST_NOTICE_CIRCULAR_COMMENT_SUCCESS";
export const POST_NOTICE_CIRCULAR_COMMENT_FAILURE = "POST_NOTICE_CIRCULAR_COMMENT_FAILURE";

export const ADD_NOTICE_CIRCULAR = "ADD_NOTICE_CIRCULAR";
export const ADD_NOTICE_CIRCULAR_SUCCESS = "ADD_NOTICE_CIRCULAR_SUCCESS";
export const ADD_NOTICE_CIRCULAR_FAILURE = "ADD_NOTICE_CIRCULAR_FAILURE";

export const UPDATE_NOTICE_CIRCULAR = "UPDATE_NOTICE_CIRCULAR";
export const UPDATE_NOTICE_CIRCULAR_SUCCESS = "UPDATE_NOTICE_CIRCULAR_SUCCESS";
export const UPDATE_NOTICE_CIRCULAR_FAILURE = "UPDATE_NOTICE_CIRCULAR_FAILURE";

export const GET_NOTICE_CIRCULAR_TYPES = "GET_NOTICE_CIRCULAR_TYPES";
export const GET_NOTICE_CIRCULAR_TYPES_SUCCESS = "GET_NOTICE_CIRCULAR_TYPES_SUCCESS";
export const GET_NOTICE_CIRCULAR_TYPES_FAILURE = "GET_NOTICE_CIRCULAR_TYPES_FAILURE";

export const GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE = "GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE";
export const GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_SUCCESS = "GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_SUCCESS";
export const GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_FAILURE = "GET_NOTICE_CIRCULAR_MANAGING_COMMITTEE_FAILURE";

export const GET_NOTICE_CIRCULAR_ARCHIVE_LIST = "GET_NOTICE_CIRCULAR_ARCHIVE_LIST";
export const GET_NOTICE_CIRCULAR_ARCHIVE_LIST_SUCCESS = "GET_NOTICE_CIRCULAR_ARCHIVE_LIST_SUCCESS";
export const GET_NOTICE_CIRCULAR_ARCHIVE_LIST_FAILURE = "GET_NOTICE_CIRCULAR_ARCHIVE_LIST_FAILURE";

export const DELETE_NOTICE_CIRCULAR_ARCHIVE = "DELETE_NOTICE_CIRCULAR_ARCHIVE";
export const DELETE_NOTICE_CIRCULAR_ARCHIVE_SUCCESS = "DELETE_NOTICE_CIRCULAR_ARCHIVE_SUCCESS";
export const DELETE_NOTICE_CIRCULAR_ARCHIVE_FAILURE = "DELETE_NOTICE_CIRCULAR_ARCHIVE_FAILURE";

export const ADD_ACKNOWLEDGEMENT_NOTICE = "ADD_ACKNOWLEDGEMENT_NOTICE";
export const ADD_ACKNOWLEDGEMENT_NOTICE_SUCCESS = "ADD_ACKNOWLEDGEMENT_NOTICE_SUCCESS";
export const ADD_ACKNOWLEDGEMENT_NOTICE_FAILURE = "ADD_ACKNOWLEDGEMENT_NOTICE_FAILURE";

export const GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST = "GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST";
export const GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_SUCCESS = "GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_SUCCESS";
export const GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_FAILURE = "GET_NOTICE_CIRCULAR_ACKNOWLEDGEMENT_LIST_FAILURE";

export const GET_NOTICE_CIRCULAR_AUDIT_LOG = 'GET_NOTICE_CIRCULAR_AUDIT_LOG';
export const GET_NOTICE_CIRCULAR_AUDIT_LOG_SUCCESS = 'GET_NOTICE_CIRCULAR_AUDIT_LOG_SUCCESS';
export const GET_NOTICE_CIRCULAR_AUDIT_LOG_FAILURE = 'GET_NOTICE_CIRCULAR_AUDIT_LOG_FAILURE';


// ------------------ FINANCE -----------------------------
// ------------------ MY INVOICE ------------//
export const GET_MY_INVOICE_LIST = 'GET_MY_INVOICE_LIST';
export const GET_MY_INVOICE_LIST_SUCCESS = 'GET_MY_INVOICE_LIST_SUCCESS';
export const GET_MY_INVOICE_LIST_FAILURE = 'GET_MY_INVOICE_LIST_FAILURE';

export const GET_MY_INVOICE_STATUS_LIST = 'GET_MY_INVOICE_STATUS_LIST';
export const GET_MY_INVOICE_STATUS_LIST_SUCCESS = 'GET_MY_INVOICE_STATUS_LIST_SUCCESS';
export const GET_MY_INVOICE_STATUS_LIST_FAILURE = 'GET_MY_INVOICE_STATUS_LIST_FAILURE';

export const GET_MY_INVOICE_PAYMENT_LIST = 'GET_MY_INVOICE_PAYMENT_LIST';
export const GET_MY_INVOICE_PAYMENT_LIST_SUCCESS = 'GET_MY_INVOICE_PAYMENT_LIST_SUCCESS';
export const GET_MY_INVOICE_PAYMENT_LIST_FAILURE = 'GET_MY_INVOICE_PAYMENT_LIST_FAILURE';

export const SINGLE_INVOICE_LIST = 'SINGLE_INVOICE_LIST';
export const SINGLE_INVOICE_LIST_SUCCESS = 'SINGLE_INVOICE_LIST_SUCCESS';
export const SINGLE_INVOICE_LIST_FAILURE = 'SINGLE_INVOICE_LIST_FAILURE';

export const GET_MY_INVOICE_PRINT_INVOICE = 'GET_MY_INVOICE_PRINT_INVOICE';
export const GET_MY_INVOICE_PRINT_INVOICE_SUCCESS = 'GET_MY_INVOICE_PRINT_INVOICE_SUCCESS';
export const GET_MY_INVOICE_PRINT_INVOICE_FAILURE = 'GET_MY_INVOICE_PRINT_INVOICE_FAILURE';

export const GET_INVOICE_RECEIPT = 'GET_INVOICE_RECEIPT';
export const GET_INVOICE_RECEIPT_SUCCESS = 'GET_INVOICE_RECEIPT_SUCCESS';
export const GET_INVOICE_RECEIPT_FAILURE = 'GET_INVOICE_RECEIPT_FAILURE';

// ------------------ Billing Management ------------//
// ------------------ Manage INVOICE ------------//

export const GET_MANAGE_INVOICE_CURRENT = 'GET_MANAGE_INVOICE_CURRENT';
export const GET_MANAGE_INVOICE_CURRENT_SUCCESS = 'GET_MANAGE_INVOICE_CURRENT_SUCCESS';
export const GET_MANAGE_INVOICE_CURRENT_FAILURE = 'GET_MANAGE_INVOICE_CURRENT_FAILURE';

export const GET_MANAGE_INVOICE_PREVIOUS = 'GET_MANAGE_INVOICE_PREVIOUS';
export const GET_MANAGE_INVOICE_PREVIOUS_SUCCESS = 'GET_MANAGE_INVOICE_PREVIOUS_SUCCESS';
export const GET_MANAGE_INVOICE_PREVIOUS_FAILURE = 'GET_MANAGE_INVOICE_PREVIOUS_FAILURE';

export const GET_MANAGE_INVOICE_DRAFT = 'GET_MANAGE_INVOICE_DRAFT';
export const GET_MANAGE_INVOICE_DRAFT_SUCCESS = 'GET_MANAGE_INVOICE_DRAFT_SUCCESS';
export const GET_MANAGE_INVOICE_DRAFT_FAILURE = 'GET_MANAGE_INVOICE_DRAFT_FAILURE';

export const GET_MANAGE_INVOICE_ACTIONS_LIST = 'GET_MANAGE_INVOICE_ACTIONS_LIST';
export const GET_MANAGE_INVOICE_ACTIONS_LIST_SUCCESS = 'GET_MANAGE_INVOICE_ACTIONS_LIST_SUCCESS';
export const GET_MANAGE_INVOICE_ACTIONS_LIST_FAILURE = 'GET_MANAGE_INVOICE_ACTIONS_LIST_FAILURE';

export const ADJUSTMENT_PAYMENT_DETAILS = 'ADJUSTMENT_PAYMENT_DETAILS';
export const ADJUSTMENT_PAYMENT_DETAILS_SUCCESS = 'ADJUSTMENT_PAYMENT_DETAILS_SUCCESS';
export const ADJUSTMENT_PAYMENT_DETAILS_FAILURE = 'ADJUSTMENT_PAYMENT_DETAILS_FAILURE';

export const ADJUSTMENT_PAYMENT_GET_AMOUNT = 'ADJUSTMENT_PAYMENT_GET_AMOUNT';
export const ADJUSTMENT_PAYMENT_GET_AMOUNT_SUCCESS = 'ADJUSTMENT_PAYMENT_GET_AMOUNT_SUCCESS';
export const ADJUSTMENT_PAYMENT_GET_AMOUNT_FAILURE = 'ADJUSTMENT_PAYMENT_GET_AMOUNT_FAILURE';

export const ADJUSTMENT_PAYMENT_SAVE = 'ADJUSTMENT_PAYMENT_SAVE';
export const ADJUSTMENT_PAYMENT_SAVE_SUCCESS = 'ADJUSTMENT_PAYMENT_SAVE_SUCCESS';
export const ADJUSTMENT_PAYMENT_SAVE_FAILURE = 'ADJUSTMENT_PAYMENT_SAVE_FAILURE';

export const DOWNLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SAMPLE = 'DOWNLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SAMPLE';

export const UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL = 'UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL';
export const UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS = 'UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS';
export const UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE = 'UPLOAD_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE';

export const SAVE_MANAGE_INVOICE_PAYMENT_EXCEL = 'SAVE_MANAGE_INVOICE_PAYMENT_EXCEL';
export const SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS = 'SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_SUCCESS';
export const SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE = 'SAVE_MANAGE_INVOICE_PAYMENT_EXCEL_FAILURE';

export const GET_EDIT_INVOICE_DETAILS = 'GET_EDIT_INVOICE_DETAILS';
export const GET_EDIT_INVOICE_DETAILS_SUCCESS = 'GET_EDIT_INVOICE_DETAILS_SUCCESS';
export const GET_EDIT_INVOICE_DETAILS_FAILURE = 'GET_EDIT_INVOICE_DETAILS_FAILURE';

export const EDIT_INVOICE_DETAILS = 'EDIT_INVOICE_DETAILS';
export const EDIT_INVOICE_DETAILS_SUCCESS = 'EDIT_INVOICE_DETAILS_SUCCESS';
export const EDIT_INVOICE_DETAILS_FAILURE = 'EDIT_INVOICE_DETAILS_FAILURE';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';

export const REJECT_INVOICE = 'REJECT_INVOICE';
export const REJECT_INVOICE_SUCCESS = 'REJECT_INVOICE_SUCCESS';
export const REJECT_INVOICE_FAILURE = 'REJECT_INVOICE_FAILURE';

export const UPDATE_INVOICE_REMARKS = 'UPDATE_INVOICE_REMARKS';
export const UPDATE_INVOICE_REMARKS_SUCCESS = 'UPDATE_INVOICE_REMARKS_SUCCESS';
export const UPDATE_INVOICE_REMARKS_FAILURE = 'UPDATE_INVOICE_REMARKS_FAILURE';

export const VERIFY_POST_PAYMENT_DETAILS = 'VERIFY_POST_PAYMENT_DETAILS';
export const VERIFY_POST_PAYMENT_DETAILS_SUCCESS = 'VERIFY_POST_PAYMENT_DETAILS_SUCCESS';
export const VERIFY_POST_PAYMENT_DETAILS_FAILURE = 'VERIFY_POST_PAYMENT_DETAILS_FAILURE';

export const VERIFY_POST_PAYMENT_UPDATE = 'VERIFY_POST_PAYMENT_UPDATE';
export const VERIFY_POST_PAYMENT_UPDATE_SUCCESS = 'VERIFY_POST_PAYMENT_UPDATE_SUCCESS';
export const VERIFY_POST_PAYMENT_UPDATE_FAILURE = 'VERIFY_POST_PAYMENT_UPDATE_FAILURE';

export const BULK_VERIFY_POST_PAYMENT_UPDATE = 'BULK_VERIFY_POST_PAYMENT_UPDATE';
export const BULK_VERIFY_POST_PAYMENT_UPDATE_SUCCESS = 'BULK_VERIFY_POST_PAYMENT_UPDATE_SUCCESS';
export const BULK_VERIFY_POST_PAYMENT_UPDATE_FAILURE = 'BULK_VERIFY_POST_PAYMENT_UPDATE_FAILURE';

export const SEND_RESEND_RECEIPT_EMAIL_SMS = 'SEND_RESEND_RECEIPT_EMAIL_SMS';
export const SEND_RESEND_RECEIPT_EMAIL_SMS_SUCCESS = 'SEND_RESEND_RECEIPT_EMAIL_SMS_SUCCESS';
export const SEND_RESEND_RECEIPT_EMAIL_SMS_FAILURE = 'SEND_RESEND_RECEIPT_EMAIL_SMS_FAILURE';

export const SEND_INVOICE_REMINDER_EMAIL_SMS = 'SEND_INVOICE_REMINDER_EMAIL_SMS';
export const SEND_INVOICE_REMINDER_EMAIL_SMS_SUCCESS = 'SEND_INVOICE_REMINDER_EMAIL_SMS_SUCCESS';
export const SEND_INVOICE_REMINDER_EMAIL_SMS_FAILURE = 'SEND_INVOICE_REMINDER_EMAIL_SMS_FAILURE';

export const SEND_INVOICE_EMAIL_SMS = 'SEND_INVOICE_EMAIL_SMS';
export const SEND_INVOICE_EMAIL_SMS_SUCCESS = 'SEND_INVOICE_EMAIL_SMS_SUCCESS';
export const SEND_INVOICE_EMAIL_SMS_FAILURE = 'SEND_INVOICE_EMAIL_SMS_FAILURE';

export const DOWNLOAD_INVOICE_DRAFT_EXCEL = 'DOWNLOAD_INVOICE_DRAFT_EXCEL';
export const DOWNLOAD_INVOICE_DRAFT_EXCEL_SUCCESS = 'DOWNLOAD_INVOICE_DRAFT_EXCEL_SUCCESS';
export const DOWNLOAD_INVOICE_DRAFT_EXCEL_FAILURE = 'DOWNLOAD_INVOICE_DRAFT_EXCEL_FAILURE';

// ------------------ GENERATE INVOICE ------------//

export const GET_BILL_PERIOD = 'GET_BILL_PERIOD';
export const GET_BILL_PERIOD_SUCCESS = 'GET_BILL_PERIOD_SUCCESS';
export const GET_BILL_PERIOD_FAILURE = 'GET_BILL_PERIOD_FAILURE';

export const INVOICE_GENERATOR_LIST = 'INVOICE_GENERATOR_LIST';
export const INVOICE_GENERATOR_LIST_SUCCESS = 'INVOICE_GENERATOR_LIST_SUCCESS';
export const INVOICE_GENERATOR_LIST_FAILURE = 'INVOICE_GENERATOR_LIST_FAILURE';

export const GENERATE_INVOICE = 'GENERATE_INVOICE';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILURE = 'GENERATE_INVOICE_FAILURE';

export const GET_CREDIT_NOTE_ADJUSTMENT = 'GET_CREDIT_NOTE_ADJUSTMENT';
export const GET_CREDIT_NOTE_ADJUSTMENT_SUCCESS = 'GET_CREDIT_NOTE_ADJUSTMENT_SUCCESS';
export const GET_CREDIT_NOTE_ADJUSTMENT_FAILURE = 'GET_CREDIT_NOTE_ADJUSTMENT_FAILURE';

export const DOWNLOAD_GENERATE_EXCEL_FORMAT = 'DOWNLOAD_GENERATE_EXCEL_FORMAT';

export const GET_DEBIT_NOTE_ADJUSTMENT = 'GET_DEBIT_NOTE_ADJUSTMENT';
export const GET_DEBIT_NOTE_ADJUSTMENT_SUCCESS = 'GET_DEBIT_NOTE_ADJUSTMENT_SUCCESS';
export const GET_DEBIT_NOTE_ADJUSTMENT_FAILURE = 'GET_DEBIT_NOTE_ADJUSTMENT_FAILURE';

export const UPLOAD_GENERATE_INVOICE_EXCEL = 'UPLOAD_GENERATE_INVOICE_EXCEL';
export const UPLOAD_GENERATE_INVOICE_EXCEL_SUCCESS = 'UPLOAD_GENERATE_INVOICE_EXCEL_SUCCESS';
export const UPLOAD_GENERATE_INVOICE_EXCEL_FAILURE = 'UPLOAD_GENERATE_INVOICE_EXCEL_FAILURE';

export const SAVE_GENERATE_INVOICE_EXCEL = 'SAVE_GENERATE_INVOICE_EXCEL';
export const SAVE_GENERATE_INVOICE_EXCEL_SUCCESS = 'SAVE_GENERATE_INVOICE_EXCEL_SUCCESS';
export const SAVE_GENERATE_INVOICE_EXCEL_FAILURE = 'SAVE_GENERATE_INVOICE_EXCEL_FAILURE';

// ------------------  Debit Note ------------//

export const GET_DEBIT_NOTE_CURRENT = 'GET_DEBIT_NOTE_CURRENT';
export const GET_DEBIT_NOTE_CURRENT_SUCCESS = 'GET_DEBIT_NOTE_CURRENT_SUCCESS';
export const GET_DEBIT_NOTE_CURRENT_FAILURE = 'GET_DEBIT_NOTE_CURRENT_FAILURE';

export const GET_DEBIT_NOTE_ACTION_LIST = 'GET_DEBIT_NOTE_ACTION_LIST';
export const GET_DEBIT_NOTE_ACTION_LIST_SUCCESS = 'GET_DEBIT_NOTE_ACTION_LIST_SUCCESS';
export const GET_DEBIT_NOTE_ACTION_LIST_FAILURE = 'GET_DEBIT_NOTE_ACTION_LIST_FAILURE';

export const BULK_VERIFY_POST_DEBIT_NOTE_UPDATE = 'BULK_VERIFY_POST_DEBIT_NOTE_UPDATE';
export const BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_SUCCESS = 'BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_SUCCESS';
export const BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_FAILURE = 'BULK_VERIFY_POST_DEBIT_NOTE_UPDATE_FAILURE';

export const RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS = 'RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS';
export const RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS = 'RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS';
export const RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE = 'RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE';

export const BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE = 'BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE';
export const BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_SUCCESS = 'BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_SUCCESS';
export const BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_FAILURE = 'BULK_MAKE_PAYMENT_DEBIT_NOTE_SAVE_FAILURE';

export const GET_DEBIT_NOTE_RECEIPT_PRINT = 'GET_DEBIT_NOTE_RECEIPT_PRINT';
export const GET_DEBIT_NOTE_RECEIPT_PRINT_SUCCESS = 'GET_DEBIT_NOTE_RECEIPT_PRINT_SUCCESS';
export const GET_DEBIT_NOTE_RECEIPT_PRINT_FAILURE = 'GET_DEBIT_NOTE_RECEIPT_PRINT_FAILURE';

export const RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS = 'RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS';
export const RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_SUCCESS = 'RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_SUCCESS';
export const RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_FAILURE = 'RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_FAILURE';

export const DELETE_DEBIT_NOTE_RECEIPT = 'DELETE_DEBIT_NOTE_RECEIPT';
export const DELETE_DEBIT_NOTE_RECEIPT_SUCCESS = 'DELETE_DEBIT_NOTE_RECEIPT_SUCCESS';
export const DELETE_DEBIT_NOTE_RECEIPT_FAILURE = 'DELETE_DEBIT_NOTE_RECEIPT_FAILURE';

export const GET_SINGLE_DEBIT_NOTE_LIST = 'GET_SINGLE_DEBIT_NOTE_LIST';
export const GET_SINGLE_DEBIT_NOTE_LIST_SUCCESS = 'GET_SINGLE_DEBIT_NOTE_LIST_SUCCESS';
export const GET_SINGLE_DEBIT_NOTE_LIST_FAILURE = 'GET_SINGLE_DEBIT_NOTE_LIST_FAILURE';

export const REJECT_SINGLE_RECEIPT_DEBIT_NOTE = 'REJECT_SINGLE_RECEIPT_DEBIT_NOTE';
export const REJECT_SINGLE_RECEIPT_DEBIT_NOTE_SUCCESS = 'REJECT_SINGLE_RECEIPT_DEBIT_NOTE_SUCCESS';
export const REJECT_SINGLE_RECEIPT_DEBIT_NOTE_FAILURE = 'REJECT_SINGLE_RECEIPT_DEBIT_NOTE_FAILURE';

export const EDIT_SINGLE_DEBIT_NOTE_DETAILS = 'EDIT_SINGLE_DEBIT_NOTE_DETAILS';
export const EDIT_SINGLE_DEBIT_NOTE_DETAILS_SUCCESS = 'EDIT_SINGLE_DEBIT_NOTE_DETAILS_SUCCESS';
export const EDIT_SINGLE_DEBIT_NOTE_DETAILS_FAILURE = 'EDIT_SINGLE_DEBIT_NOTE_DETAILS_FAILURE';

export const EDIT_SINGLE_DEBIT_NOTE_SAVE = 'EDIT_SINGLE_DEBIT_NOTE_SAVE';
export const EDIT_SINGLE_DEBIT_NOTE_SAVE_SUCCESS = 'EDIT_SINGLE_DEBIT_NOTE_SAVE_SUCCESS';
export const EDIT_SINGLE_DEBIT_NOTE_SAVE_FAILURE = 'EDIT_SINGLE_DEBIT_NOTE_SAVE_FAILURE';

export const ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE = 'ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE';
export const ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS = 'ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS';
export const ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE = 'ADJUSTMENT_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE';

export const ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE = 'ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE';
export const ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS = 'ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS';
export const ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_FAILURE = 'ADJUSTMENT_PAYMENT_SAVE_DEBIT_NOTE_FAILURE';

export const MAKE_PAYMENT_DETAILS_DEBIT_NOTE = 'MAKE_PAYMENT_DETAILS_DEBIT_NOTE';
export const MAKE_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS = 'MAKE_PAYMENT_DETAILS_DEBIT_NOTE_SUCCESS';
export const MAKE_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE = 'MAKE_PAYMENT_DETAILS_DEBIT_NOTE_FAILURE';

export const MAKE_PAYMENT_SAVE_DEBIT_NOTE = 'MAKE_PAYMENT_SAVE_DEBIT_NOTE';
export const MAKE_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS = 'MAKE_PAYMENT_SAVE_DEBIT_NOTE_SUCCESS';
export const MAKE_PAYMENT_SAVE_DEBIT_NOTE_FAILURE = 'MAKE_PAYMENT_SAVE_DEBIT_NOTE_FAILURE';

export const SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS = 'SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS';
export const SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS = 'SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_SUCCESS';
export const SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE = 'SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_FAILURE';

export const DELETE_DEBIT_NOTE_CURRENT = 'DELETE_DEBIT_NOTE_CURRENT';
export const DELETE_DEBIT_NOTE_CURRENT_SUCCESS = 'DELETE_DEBIT_NOTE_CURRENT_SUCCESS';
export const DELETE_DEBIT_NOTE_CURRENT_FAILURE = 'DELETE_DEBIT_NOTE_CURRENT_FAILURE';

export const UPLOAD_DEBIT_NOTE_CURRENT_EXCEL = 'UPLOAD_DEBIT_NOTE_CURRENT_EXCEL';
export const UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS = 'UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS';
export const UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE = 'UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE';

export const DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL = 'DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL';
export const DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS = 'DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_SUCCESS';
export const DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE = 'DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_FAILURE';

export const UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT = 'UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT';
export const UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS = 'UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS';
export const UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE = 'UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE';

export const DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT = 'DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT';
export const DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS = 'DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_SUCCESS';
export const DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE = 'DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_FAILURE';


export const GET_DEBIT_NOTE_PREVIOUS = 'GET_DEBIT_NOTE_PREVIOUS';
export const GET_DEBIT_NOTE_PREVIOUS_SUCCESS = 'GET_DEBIT_NOTE_PREVIOUS_SUCCESS';
export const GET_DEBIT_NOTE_PREVIOUS_FAILURE = 'GET_DEBIT_NOTE_PREVIOUS_FAILURE';

export const GET_DEBIT_NOTE_DRAFT = 'GET_DEBIT_NOTE_DRAFT';
export const GET_DEBIT_NOTE_DRAFT_SUCCESS = 'GET_DEBIT_NOTE_DRAFT_SUCCESS';
export const GET_DEBIT_NOTE_DRAFT_FAILURE = 'GET_DEBIT_NOTE_DRAFT_FAILURE';

export const EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE = 'EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE';
export const EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_SUCCESS = 'EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_SUCCESS';
export const EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_FAILURE = 'EDIT_SINGLE_DEBIT_NOTE_DRAFT_SAVE_FAILURE';

export const GET_DEBIT_NOTE_INVOICE_PRINT = 'GET_DEBIT_NOTE_INVOICE_PRINT';
export const GET_DEBIT_NOTE_INVOICE_PRINT_SUCCESS = 'GET_DEBIT_NOTE_INVOICE_PRINT_SUCCESS';
export const GET_DEBIT_NOTE_INVOICE_PRINT_FAILURE = 'GET_DEBIT_NOTE_INVOICE_PRINT_FAILURE';

// export const SINGLE_DEBIT_NOTE_LIST = 'SINGLE_DEBIT_NOTE_LIST';
// export const SINGLE_DEBIT_NOTE_LIST_SUCCESS = 'SINGLE_DEBIT_NOTE_LIST_SUCCESS';
// export const SINGLE_DEBIT_NOTE_LIST_FAILURE = 'SINGLE_DEBIT_NOTE_LIST_FAILURE';

// export const GET_DEBIT_NOTE_ACTIONS_LIST = 'GET_DEBIT_NOTE_ACTIONS_LIST';
// export const GET_DEBIT_NOTE_ACTIONS_LIST_SUCCESS = 'GET_DEBIT_NOTE_ACTIONS_LIST_SUCCESS';
// export const GET_DEBIT_NOTE_ACTIONS_LIST_FAILURE = 'GET_DEBIT_NOTE_ACTIONS_LIST_FAILURE';

export const GET_DEBIT_NOTE_TARRIF_LIST = 'GET_DEBIT_NOTE_TARRIF_LIST';
export const GET_DEBIT_NOTE_TARRIF_LIST_SUCCESS = 'GET_DEBIT_NOTE_TARRIF_LIST_SUCCESS';
export const GET_DEBIT_NOTE_TARRIF_LIST_FAILURE = 'GET_DEBIT_NOTE_TARRIF_LIST_FAILURE';

export const GET_DEBIT_NOTE_FLAT_WING_WISE_LIST = 'GET_DEBIT_NOTE_FLAT_WING_WISE_LIST';
export const GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_SUCCESS = 'GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_SUCCESS';
export const GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_FAILURE = 'GET_DEBIT_NOTE_FLAT_WING_WISE_LIST_FAILURE';

export const GET_DEBIT_NOTE_ARREAR_DETAILS = 'GET_DEBIT_NOTE_ARREAR_DETAILS';
export const GET_DEBIT_NOTE_ARREAR_DETAILS_SUCCESS = 'GET_DEBIT_NOTE_ARREAR_DETAILS_SUCCESS';
export const GET_DEBIT_NOTE_ARREAR_DETAILS_FAILURE = 'GET_DEBIT_NOTE_ARREAR_DETAILS_FAILURE';

export const ADD_DEBIT_NOTE = 'ADD_DEBIT_NOTE';
export const ADD_DEBIT_NOTE_SUCCESS = 'ADD_DEBIT_NOTE_SUCCESS';
export const ADD_DEBIT_NOTE_FAILURE = 'ADD_DEBIT_NOTE_FAILURE';

export const ADD_MULTIPLE_DEBIT_NOTE = 'ADD_MULTIPLE_DEBIT_NOTE';
export const ADD_MULTIPLE_DEBIT_NOTE_SUCCESS = 'ADD_MULTIPLE_DEBIT_NOTE_SUCCESS';
export const ADD_MULTIPLE_DEBIT_NOTE_FAILURE = 'ADD_MULTIPLE_DEBIT_NOTE_FAILURE';

// ------------------  Advance Note ------------//

export const GET_ADVANCE_NOTE_LIST = 'GET_ADVANCE_NOTE_LIST';
export const GET_ADVANCE_NOTE_LIST_SUCCESS = 'GET_ADVANCE_NOTE_LIST_SUCCESS';
export const GET_ADVANCE_NOTE_LIST_FAILURE = 'GET_ADVANCE_NOTE_LIST_FAILURE';

export const ADD_ADVANCE_NOTE = 'ADD_ADVANCE_NOTE';
export const ADD_ADVANCE_NOTE_SUCCESS = 'ADD_ADVANCE_NOTE_SUCCESS';
export const ADD_ADVANCE_NOTE_FAILURE = 'ADD_ADVANCE_NOTE_FAILURE';

export const UPDATE_ADVANCE_NOTE = 'UPDATE_ADVANCE_NOTE';
export const UPDATE_ADVANCE_NOTE_SUCCESS = 'UPDATE_ADVANCE_NOTE_SUCCESS';
export const UPDATE_ADVANCE_NOTE_FAILURE = 'UPDATE_ADVANCE_NOTE_FAILURE';

export const DELETE_ADVANCE_NOTE = 'DELETE_ADVANCE_NOTE';
export const DELETE_ADVANCE_NOTE_SUCCESS = 'DELETE_ADVANCE_NOTE_SUCCESS';
export const DELETE_ADVANCE_NOTE_FAILURE = 'DELETE_ADVANCE_NOTE_FAILURE';

export const GET_ADVANCE_NOTE_DETAILS = 'GET_ADVANCE_NOTE_DETAILS';
export const GET_ADVANCE_NOTE_DETAILS_SUCCESS = 'GET_ADVANCE_NOTE_DETAILS_SUCCESS';
export const GET_ADVANCE_NOTE_DETAILS_FAILURE = 'GET_ADVANCE_NOTE_DETAILS_FAILURE';

export const PRINT_ADVANCE_NOTE_RECIEPT = 'PRINT_ADVANCE_NOTE_RECIEPT';
export const PRINT_ADVANCE_NOTE_RECIEPT_SUCCESS = 'PRINT_ADVANCE_NOTE_RECIEPT_SUCCESS';
export const PRINT_ADVANCE_NOTE_RECIEPT_FAILURE = 'PRINT_ADVANCE_NOTE_RECIEPT_FAILURE';

export const GET_NON_MEMBER_LIST = 'GET_NON_MEMBER_LIST';
export const GET_NON_MEMBER_LIST_SUCCESS = 'GET_NON_MEMBER_LIST_SUCCESS';
export const GET_NON_MEMBER_LIST_FAILURE = 'GET_NON_MEMBER_LIST_FAILURE';

export const UPLOAD_ADVANCE_NOTE_EXCEL = 'UPLOAD_ADVANCE_NOTE_EXCEL';
export const UPLOAD_ADVANCE_NOTE_EXCEL_SUCCESS = 'UPLOAD_ADVANCE_NOTE_EXCEL_SUCCESS';
export const UPLOAD_ADVANCE_NOTE_EXCEL_FAILURE = 'UPLOAD_ADVANCE_NOTE_EXCEL_FAILURE';

export const SAVE_ADVANCE_NOTE_EXCEL = 'SAVE_ADVANCE_NOTE_EXCEL';
export const SAVE_ADVANCE_NOTE_EXCEL_SUCCESS = 'SAVE_ADVANCE_NOTE_EXCEL_SUCCESS';
export const SAVE_ADVANCE_NOTE_EXCEL_FAILURE = 'SAVE_ADVANCE_NOTE_EXCEL_FAILURE';

// ------------------  Credit Note ------------//

export const GET_CREDIT_NOTE_LIST = 'GET_CREDIT_NOTE_LIST';
export const GET_CREDIT_NOTE_LIST_SUCCESS = 'GET_CREDIT_NOTE_LIST_SUCCESS';
export const GET_CREDIT_NOTE_LIST_FAILURE = 'GET_CREDIT_NOTE_LIST_FAILURE';

export const ADD_CREDIT_NOTE = 'ADD_CREDIT_NOTE';
export const ADD_CREDIT_NOTE_SUCCESS = 'ADD_CREDIT_NOTE_SUCCESS';
export const ADD_CREDIT_NOTE_FAILURE = 'ADD_CREDIT_NOTE_FAILURE';

export const UPDATE_CREDIT_NOTE = 'UPDATE_CREDIT_NOTE';
export const UPDATE_CREDIT_NOTE_SUCCESS = 'UPDATE_CREDIT_NOTE_SUCCESS';
export const UPDATE_CREDIT_NOTE_FAILURE = 'UPDATE_CREDIT_NOTE_FAILURE';

export const DELETE_CREDIT_NOTE = 'DELETE_CREDIT_NOTE';
export const DELETE_CREDIT_NOTE_SUCCESS = 'DELETE_CREDIT_NOTE_SUCCESS';
export const DELETE_CREDIT_NOTE_FAILURE = 'DELETE_CREDIT_NOTE_FAILURE';

export const GET_CREDIT_NOTE_DETAILS = 'GET_CREDIT_NOTE_DETAILS';
export const GET_CREDIT_NOTE_DETAILS_SUCCESS = 'GET_CREDIT_NOTE_DETAILS_SUCCESS';
export const GET_CREDIT_NOTE_DETAILS_FAILURE = 'GET_CREDIT_NOTE_DETAILS_FAILURE';

export const PRINT_CREDIT_NOTE_INVOICE = 'PRINT_CREDIT_NOTE_INVOICE';
export const PRINT_CREDIT_NOTE_INVOICE_SUCCESS = 'PRINT_CREDIT_NOTE_INVOICE_SUCCESS';
export const PRINT_CREDIT_NOTE_INVOICE_FAILURE = 'PRINT_CREDIT_NOTE_INVOICE_FAILURE';

export const GET_FLAT_WISE_INVOICE_LIST = 'GET_FLAT_WISE_INVOICE_LIST';
export const GET_FLAT_WISE_INVOICE_LIST_SUCCESS = 'GET_FLAT_WISE_INVOICE_LIST_SUCCESS';
export const GET_FLAT_WISE_INVOICE_LIST_FAILURE = 'GET_FLAT_WISE_INVOICE_LIST_FAILURE';

export const DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE = 'DOWNLOAD_CREDIT_NOTE_EXCEL_SAMPLE';
export const DOWNLOAD_ADVANCE_EXCEL_SAMPLE = 'DOWNLOAD_ADVANCE_EXCEL_SAMPLE';

export const UPLOAD_CREDIT_NOTE_EXCEL = 'UPLOAD_CREDIT_NOTE_EXCEL';
export const UPLOAD_CREDIT_NOTE_EXCEL_SUCCESS = 'UPLOAD_CREDIT_NOTE_EXCEL_SUCCESS';
export const UPLOAD_CREDIT_NOTE_EXCEL_FAILURE = 'UPLOAD_CREDIT_NOTE_EXCEL_FAILURE';

export const SAVE_CREDIT_NOTE_EXCEL = 'SAVE_CREDIT_NOTE_EXCEL';
export const SAVE_CREDIT_NOTE_EXCEL_SUCCESS = 'SAVE_CREDIT_NOTE_EXCEL_SUCCESS';
export const SAVE_CREDIT_NOTE_EXCEL_FAILURE = 'SAVE_CREDIT_NOTE_EXCEL_FAILURE';

// ------------------ BILLING REPORT -------------//
export const GET_DEBIT_NOTES_REPORT = 'GET_DEBIT_NOTES_REPORT';
export const GET_DEBIT_NOTES_REPORT_SUCCESS = 'GET_DEBIT_NOTES_REPORT_SUCCESS';
export const GET_DEBIT_NOTES_REPORT_FAILURE = 'GET_DEBIT_NOTES_REPORT_FAILURE';

export const DOWNLOAD_DEBIT_NOTES_REPORT = 'DOWNLOAD_DEBIT_NOTES_REPORT';
export const DOWNLOAD_DEBIT_NOTES_REPORT_SUCCESS = 'DOWNLOAD_DEBIT_NOTES_REPORT_SUCCESS';
export const DOWNLOAD_DEBIT_NOTES_REPORT_FAILURE = 'DOWNLOAD_DEBIT_NOTES_REPORT_FAILURE';

export const GET_DELETED_RECEIPT = 'GET_DELETED_RECEIPT';
export const GET_DELETED_RECEIPT_SUCCESS = 'GET_DELETED_RECEIPT_SUCCESS';
export const GET_DELETED_RECEIPT_FAILURE = 'GET_DELETED_RECEIPT_FAILURE';

export const GET_RECIEPT_DETAILS = 'GET_RECIEPT_DETAILS';
export const GET_RECIEPT_DETAILS_SUCCESS = 'GET_RECIEPT_DETAILS_SUCCESS';
export const GET_RECIEPT_DETAILS_FAILURE = 'GET_RECIEPT_DETAILS_FAILURE';


export const GET_RECEIPT_DETAILS_VIEW = 'GET_RECEIPT_DETAILS_VIEW';
export const GET_RECEIPT_DETAILS_VIEW_SUCCESS = 'GET_RECEIPT_DETAILS_VIEW_SUCCESS';
export const GET_RECEIPT_DETAILS_VIEW_FAILURE = 'GET_RECEIPT_DETAILS_VIEW_FAILURE';




export const GET_INCOMPLETE_TRANSACTION_DETAILS = 'GET_INCOMPLETE_TRANSACTION_DETAILS';
export const GET_INCOMPLETE_TRANSACTION_DETAILS_SUCCESS = 'GET_INCOMPLETE_TRANSACTION_DETAILS_SUCCESS';
export const GET_INCOMPLETE_TRANSACTION_DETAILS_FAILURE = 'GET_INCOMPLETE_TRANSACTION_DETAILS_FAILURE';

export const GET_CHEQUE_DEPOSIT_SLIP_REPORT = 'GET_CHEQUE_DEPOSIT_SLIP_REPORT';
export const GET_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS = 'GET_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS';
export const GET_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE = 'GET_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE';

export const DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT = 'DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT';
export const DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS = 'DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_SUCCESS';
export const DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE = 'DOWNLOAD_CHEQUE_DEPOSIT_SLIP_REPORT_FAILURE';

export const GET_COUNTED_INVOICE_REPORT = 'GET_COUNTED_INVOICE_REPORT';
export const GET_COUNTED_INVOICE_REPORT_SUCCESS = 'GET_COUNTED_INVOICE_REPORT_SUCCESS';
export const GET_COUNTED_INVOICE_REPORT_FAILURE = 'GET_COUNTED_INVOICE_REPORT_FAILURE';

export const DOWNLOAD_COUNTED_INVOICE_REPORT = 'DOWNLOAD_COUNTED_INVOICE_REPORT';
export const DOWNLOAD_COUNTED_INVOICE_REPORT_SUCCESS = 'DOWNLOAD_COUNTED_INVOICE_REPORT_SUCCESS';
export const DOWNLOAD_COUNTED_INVOICE_REPORT_FAILURE = 'DOWNLOAD_COUNTED_INVOICE_REPORT_FAILURE';

export const GET_RECEIPTS_LIST = 'GET_RECEIPTS_LIST';
export const GET_RECEIPTS_LIST_SUCCESS = 'GET_RECEIPTS_LIST_SUCCESS';
export const GET_RECEIPTS_LIST_FAILURE = 'GET_RECEIPTS_LIST_FAILURE';

export const DOWNLOAD_RECEIPTS_LIST = 'DOWNLOAD_RECEIPTS_LIST';
export const DOWNLOAD_RECEIPTS_LIST_SUCCESS = 'DOWNLOAD_RECEIPTS_LIST_SUCCESS';
export const DOWNLOAD_RECEIPTS_LIST_FAILURE = 'DOWNLOAD_RECEIPTS_LIST_FAILURE';

export const GET_INCOMPLETE_TRANSACTION = 'GET_INCOMPLETE_TRANSACTION';
export const GET_INCOMPLETE_TRANSACTION_SUCCESS = 'GET_INCOMPLETE_TRANSACTION_SUCCESS';
export const GET_INCOMPLETE_TRANSACTION_FAILURE = 'GET_INCOMPLETE_TRANSACTION_FAILURE';

export const GET_MEMBER_DUE_SUMMARY = 'GET_MEMBER_DUE_SUMMARY';
export const GET_MEMBER_DUE_SUMMARY_SUCCESS = 'GET_MEMBER_DUE_SUMMARY_SUCCESS';
export const GET_MEMBER_DUE_SUMMARY_FAILURE = 'GET_MEMBER_DUE_SUMMARY_FAILURE';

export const DOWNLOAD_MEMBER_DUE_SUMMARY = 'DOWNLOAD_MEMBER_DUE_SUMMARY';
export const DOWNLOAD_MEMBER_DUE_SUMMARY_SUCCESS = 'DOWNLOAD_MEMBER_DUE_SUMMARY_SUCCESS';
export const DOWNLOAD_MEMBER_DUE_SUMMARY_FAILURE = 'DOWNLOAD_MEMBER_DUE_SUMMARY_FAILURE';

export const GET_MEMBER_BILL_DUE_REPORT = 'GET_MEMBER_BILL_DUE_REPORT';
export const GET_MEMBER_BILL_DUE_REPORT_SUCCESS = 'GET_MEMBER_BILL_DUE_REPORT_SUCCESS';
export const GET_MEMBER_BILL_DUE_REPORT_FAILURE = 'GET_MEMBER_BILL_DUE_REPORT_FAILURE';

export const DOWNLOAD_MEMBER_BILL_DUE_REPORT = 'DOWNLOAD_MEMBER_BILL_DUE_REPORT';
export const DOWNLOAD_MEMBER_BILL_DUE_REPORT_SUCCESS = 'DOWNLOAD_MEMBER_BILL_DUE_REPORT_SUCCESS';
export const DOWNLOAD_MEMBER_BILL_DUE_REPORT_FAILURE = 'DOWNLOAD_MEMBER_BILL_DUE_REPORT_FAILURE';

export const GET_OPENING_BALANCE_DATA = 'GET_OPENING_BALANCE_DATA';
export const GET_OPENING_BALANCE_DATA_SUCCESS = 'GET_OPENING_BALANCE_DATA_SUCCESS';
export const GET_OPENING_BALANCE_DATA_FAILURE = 'GET_OPENING_BALANCE_DATA_FAILURE';

export const UPDATE_OPENING_BALANCE_DATA = 'UPDATE_OPENING_BALANCE_DATA';
export const UPDATE_OPENING_BALANCE_DATA_SUCCESS = 'UPDATE_OPENING_BALANCE_DATA_SUCCESS';
export const UPDATE_OPENING_BALANCE_DATA_FAILURE = 'UPDATE_OPENING_BALANCE_DATA_FAILURE';

// ------------------ SETTINGS -------------//
export const GET_TARIFF_HEADER_LIST = 'GET_TARIFF_HEADER_LIST';
export const GET_TARIFF_HEADER_LIST_SUCCESS = 'GET_TARIFF_HEADER_LIST_SUCCESS';
export const GET_TARIFF_HEADER_LIST_FAILURE = 'GET_TARIFF_HEADER_LIST_FAILURE';

export const GET_TARIFF_HEADER_DETAILS = 'GET_TARIFF_HEADER_DETAILS';
export const GET_TARIFF_HEADER_DETAILS_SUCCESS = 'GET_TARIFF_HEADER_DETAILS_SUCCESS';
export const GET_TARIFF_HEADER_DETAILS_FAILURE = 'GET_TARIFF_HEADER_DETAILS_FAILURE';

export const GET_TARIFF_HEADER_AUDIT_LOGS = 'GET_TARIFF_HEADER_AUDIT_LOGS';
export const GET_TARIFF_HEADER_AUDIT_LOGS_SUCCESS = 'GET_TARIFF_HEADER_AUDIT_LOGS_SUCCESS';
export const GET_TARIFF_HEADER_AUDIT_LOGS_FAILURE = 'GET_TARIFF_HEADER_AUDIT_LOGS_FAILURE';

export const GET_TARIFF_HEADER_ALL_AUDIT_LOGS = 'GET_TARIFF_HEADER_ALL_AUDIT_LOGS';
export const GET_TARIFF_HEADER_ALL_AUDIT_LOGS_SUCCESS = 'GET_TARIFF_HEADER_ALL_AUDIT_LOGS_SUCCESS';
export const GET_TARIFF_HEADER_ALL_AUDIT_LOGS_FAILURE = 'GET_TARIFF_HEADER_ALL_AUDIT_LOGS_FAILURE';

export const UPDATE_TARIFF_HEADER = 'UPDATE_TARIFF_HEADER';
export const UPDATE_TARIFF_HEADER_SUCCESS = 'UPDATE_TARIFF_HEADER_SUCCESS';
export const UPDATE_TARIFF_HEADER_FAILURE = 'UPDATE_TARIFF_HEADER_FAILURE';

export const ADD_TARIFF_HEADER = 'ADD_TARIFF_HEADER';
export const ADD_TARIFF_HEADER_SUCCESS = 'ADD_TARIFF_HEADER_SUCCESS';
export const ADD_TARIFF_HEADER_FAILURE = 'ADD_TARIFF_HEADER_FAILURE';

export const GET_GL_MAPPING_LIST = 'GET_GL_MAPPING_LIST';
export const GET_GL_MAPPING_LIST_SUCCESS = 'GET_GL_MAPPING_LIST_SUCCESS';
export const GET_GL_MAPPING_LIST_FAILURE = 'GET_GL_MAPPING_LIST_FAILURE';

export const GET_DEBIT_NOTE_SETTINGS = 'GET_DEBIT_NOTE_SETTINGS';
export const GET_DEBIT_NOTE_SETTINGS_SUCCESS = 'GET_DEBIT_NOTE_SETTINGS_SUCCESS';
export const GET_DEBIT_NOTE_SETTINGS_FAILURE = 'GET_DEBIT_NOTE_SETTINGS_FAILURE';

export const GET_ACCOUNT_LEDGER_HEADER_LIST = 'GET_ACCOUNT_LEDGER_HEADER_LIST';
export const GET_ACCOUNT_LEDGER_HEADER_LIST_SUCCESS = 'GET_ACCOUNT_LEDGER_HEADER_LIST_SUCCESS';
export const GET_ACCOUNT_LEDGER_HEADER_LIST_FAILURE = 'GET_ACCOUNT_LEDGER_HEADER_LIST_FAILURE';

export const UPDATE_DEBIT_NOTE_SETTINGS = 'UPDATE_DEBIT_NOTE_SETTINGS';
export const UPDATE_DEBIT_NOTE_SETTINGS_SUCCESS = 'UPDATE_DEBIT_NOTE_SETTINGS_SUCCESS';
export const UPDATE_DEBIT_NOTE_SETTINGS_FAILURE = 'UPDATE_DEBIT_NOTE_SETTINGS_FAILURE';

export const GET_MASTER_HEAD_DETAILS = 'GET_MASTER_HEAD_DETAILS';
export const GET_MASTER_HEAD_DETAILS_SUCCESS = 'GET_MASTER_HEAD_DETAILS_SUCCESS';
export const GET_MASTER_HEAD_DETAILS_FAILURE = 'GET_MASTER_HEAD_DETAILS_FAILURE';

export const UPDATE_MASTER_HEAD_DETAILS = 'UPDATE_MASTER_HEAD_DETAILS';
export const UPDATE_MASTER_HEAD_DETAILS_SUCCESS = 'UPDATE_MASTER_HEAD_DETAILS_SUCCESS';
export const UPDATE_MASTER_HEAD_DETAILS_FAILURE = 'UPDATE_MASTER_HEAD_DETAILS_FAILURE';

export const UPDATE_INVOICE_DETAILS = 'UPDATE_INVOICE_DETAILS';
export const UPDATE_INVOICE_DETAILS_SUCCESS = 'UPDATE_INVOICE_DETAILS_SUCCESS';
export const UPDATE_INVOICE_DETAILS_FAILURE = 'UPDATE_INVOICE_DETAILS_FAILURE';


export const GET_DUE_REMINDER_DETAILS = 'GET_DUE_REMINDER_DETAILS';
export const GET_DUE_REMINDER_DETAILS_SUCCESS = 'GET_DUE_REMINDER_DETAILS_SUCCESS';
export const GET_DUE_REMINDER_DETAILS_FAILURE = 'GET_DUE_REMINDER_DETAILS_FAILURE';

export const UPDATE_DUE_REMINDER_DETAILS = 'UPDATE_DUE_REMINDER_DETAILS';
export const UPDATE_DUE_REMINDER_DETAILS_SUCCESS = 'UPDATE_DUE_REMINDER_DETAILS_SUCCESS';
export const UPDATE_DUE_REMINDER_DETAILS_FAILURE = 'UPDATE_DUE_REMINDER_DETAILS_FAILURE';

export const DELETE_DUE_REMINDER = 'DELETE_DUE_REMINDER';
export const DELETE_DUE_REMINDER_SUCCESS = 'DELETE_DUE_REMINDER_SUCCESS';
export const DELETE_DUE_REMINDER_FAILURE = 'DELETE_DUE_REMINDER_FAILURE';

export const GET_DEBIT_NOTE_DUE_REMINDER_DETAILS = 'GET_DEBIT_NOTE_DUE_REMINDER_DETAILS';
export const GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS = 'GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS';
export const GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE = 'GET_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE';

export const UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS = 'UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS';
export const UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS = 'UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_SUCCESS';
export const UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE = 'UPDATE_DEBIT_NOTE_DUE_REMINDER_DETAILS_FAILURE';

export const GET_FD_MATURITY_DUE_REMINDER_DETAILS = 'GET_FD_MATURITY_DUE_REMINDER_DETAILS';
export const GET_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS = 'GET_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS';
export const GET_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE = 'GET_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE';

export const UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS = 'UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS';
export const UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS = 'UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_SUCCESS';
export const UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE = 'UPDATE_FD_MATURITY_DUE_REMINDER_DETAILS_FAILURE';


export const GET_MEMBER_DUE_REMINDER_DETAILS = 'GET_MEMBER_DUE_REMINDER_DETAILS';
export const GET_MEMBER_DUE_REMINDER_DETAILS_SUCCESS = 'GET_MEMBER_DUE_REMINDER_DETAILS_SUCCESS';
export const GET_MEMBER_DUE_REMINDER_DETAILS_FAILURE = 'GET_MEMBER_DUE_REMINDER_DETAILS_FAILURE';

export const UPDATE_MEMBER_DUE_REMINDER_DETAILS = 'UPDATE_MEMBER_DUE_REMINDER_DETAILS';
export const UPDATE_MEMBER_DUE_REMINDER_DETAILS_SUCCESS = 'UPDATE_MEMBER_DUE_REMINDER_DETAILS_SUCCESS';
export const UPDATE_MEMBER_DUE_REMINDER_DETAILS_FAILURE = 'UPDATE_MEMBER_DUE_REMINDER_DETAILS_FAILURE';


export const GET_NOMINEE_DUE_REMINDER_DETAILS = 'GET_NOMINEE_DUE_REMINDER_DETAILS';
export const GET_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS = 'GET_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS';
export const GET_NOMINEE_DUE_REMINDER_DETAILS_FAILURE = 'GET_NOMINEE_DUE_REMINDER_DETAILS_FAILURE';

export const UPDATE_NOMINEE_DUE_REMINDER_DETAILS = 'UPDATE_NOMINEE_DUE_REMINDER_DETAILS';
export const UPDATE_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS = 'UPDATE_NOMINEE_DUE_REMINDER_DETAILS_SUCCESS';
export const UPDATE_NOMINEE_DUE_REMINDER_DETAILS_FAILURE = 'UPDATE_NOMINEE_DUE_REMINDER_DETAILS_FAILURE';

export const GET_MEMBER_LIST_ACCORDING_TO_ROLE = 'GET_MEMBER_LIST_ACCORDING_TO_ROLE';
export const GET_MEMBER_LIST_ACCORDING_TO_ROLE_SUCCESS = 'GET_MEMBER_LIST_ACCORDING_TO_ROLE_SUCCESS';
export const GET_MEMBER_LIST_ACCORDING_TO_ROLE_FAILURE = 'GET_MEMBER_LIST_ACCORDING_TO_ROLE_FAILURE';


//-----COMPLIANCE ACTIONS--------------//
//-----REGISTERS ACTIONS---------//

export const GET_MEMBER_LIST_FORMAT_I = 'GET_MEMBER_LIST_FORMAT_I';
export const GET_MEMBER_LIST_FORMAT_I_SUCCESS = 'GET_MEMBER_LIST_FORMAT_I_SUCCESS';
export const GET_MEMBER_LIST_FORMAT_I_FAILURE = 'GET_MEMBER_LIST_FORMAT_I_FAILURE';

export const GET_MEMBER_REGISTER_DETAILS = 'GET_MEMBER_REGISTER_DETAILS';
export const GET_MEMBER_REGISTER_DETAILS_SUCCESS = 'GET_MEMBER_REGISTER_DETAILS_SUCCESS';
export const GET_MEMBER_REGISTER_DETAILS_FAILURE = 'GET_MEMBER_REGISTER_DETAILS_FAILURE';

export const GET_MEMBER_LIST_FORMAT_J = 'GET_MEMBER_LIST_FORMAT_J';
export const GET_MEMBER_LIST_FORMAT_J_SUCCESS = 'GET_MEMBER_LIST_FORMAT_J_SUCCESS';
export const GET_MEMBER_LIST_FORMAT_J_FAILURE = 'GET_MEMBER_LIST_FORMAT_J_FAILURE';

export const GET_MEMBER_SHARE_REGISTER_LIST = 'GET_MEMBER_SHARE_REGISTER_LIST';
export const GET_MEMBER_SHARE_REGISTER_LIST_SUCCESS = 'GET_MEMBER_SHARE_REGISTER_LIST_SUCCESS';
export const GET_MEMBER_SHARE_REGISTER_LIST_FAILURE = 'GET_MEMBER_SHARE_REGISTER_LIST_FAILURE';

export const GET_MEMBER_MORTGAGE_REGISTER_LIST = 'GET_MEMBER_MORTGAGE_REGISTER_LIST';
export const GET_MEMBER_MORTGAGE_REGISTER_LIST_SUCCESS = 'GET_MEMBER_MORTGAGE_REGISTER_LIST_SUCCESS';
export const GET_MEMBER_MORTGAGE_REGISTER_LIST_FAILURE = 'GET_MEMBER_MORTGAGE_REGISTER_LIST_FAILURE';

export const GET_MEMBER_MORTGAGE_REGISTER_DETAILS = 'GET_MEMBER_MORTGAGE_REGISTER_DETAILS';
export const GET_MEMBER_MORTGAGE_REGISTER_DETAILS_SUCCESS = 'GET_MEMBER_MORTGAGE_REGISTER_DETAILS_SUCCESS';
export const GET_MEMBER_MORTGAGE_REGISTER_DETAILS_FAILURE = 'GET_MEMBER_MORTGAGE_REGISTER_DETAILS_FAILURE';

export const GET_NOMINATION_REGISTER_LIST = 'GET_NOMINATION_REGISTER_LIST';
export const GET_NOMINATION_REGISTER_LIST_SUCCESS = 'GET_NOMINATION_REGISTER_LIST_SUCCESS';
export const GET_NOMINATION_REGISTER_LIST_FAILURE = 'GET_NOMINATION_REGISTER_LIST_FAILURE';

export const GET_PROPERTY_REGISTER_DATA = 'GET_PROPERTY_REGISTER_DATA';
export const GET_PROPERTY_REGISTER_DATA_SUCCESS = 'GET_PROPERTY_REGISTER_DATA_SUCCESS';
export const GET_PROPERTY_REGISTER_DATA_FAILURE = 'GET_PROPERTY_REGISTER_DATA_FAILURE';

//-----MEETINGS ACTIONS---------//

export const GET_NOTICE_LIST = 'GET_NOTICE_LIST';
export const GET_NOTICE_LIST_SUCCESS = 'GET_NOTICE_LIST_SUCCESS';
export const GET_NOTICE_LIST_FAILURE = 'GET_NOTICE_LIST_FAILURE';

export const GET_SAVED_NOTICE_DETAILS = 'GET_SAVED_NOTICE_DETAILS';
export const GET_SAVED_NOTICE_DETAILS_SUCCESS = 'GET_SAVED_NOTICE_DETAILS_SUCCESS';
export const GET_SAVED_NOTICE_DETAILS_FAILURE = 'GET_SAVED_NOTICE_DETAILS_FAILURE';

export const DELETE_SAVED_NOTICE = 'DELETE_SAVED_NOTICE';
export const DELETE_SAVED_NOTICE_SUCCESS = 'DELETE_SAVED_NOTICE_SUCCESS';
export const DELETE_SAVED_NOTICE_FAILURE = 'DELETE_SAVED_NOTICE_FAILURE';

export const UPDATE_SAVED_NOTICE = 'UPDATE_SAVED_NOTICE';
export const UPDATE_SAVED_NOTICE_SUCCESS = 'UPDATE_SAVED_NOTICE_SUCCESS';
export const UPDATE_SAVED_NOTICE_FAILURE = 'UPDATE_SAVED_NOTICE_FAILURE';


// -----------------Desk Actions-----------------
// ------- lOg A Complaint Actions------------

export const GET_LOG_A_COMPLAINT_SETTING = "GET_LOG_A_COMPLAINT_SETTING"
export const GET_LOG_A_COMPLAINT_SETTING_SUCCESS = "LOG_A_COMPLAINT_SETTING_SUCCESS"
export const GET_LOG_A_COMPLAINT_SETTING_FAILURE = "LOG_A_COMPLAINT_SETTING_FAILURE"

export const GET_COMPLAINT_NATURE_LIST = "GET_COMPLAINT_NATURE_LIST"
export const GET_COMPLAINT_NATURE_LIST_SUCCESS = "GET_COMPLAINT_NATURE_LIST_SUCCESS"
export const GET_COMPLAINT_NATURE_LIST_FAILURE = "GET_COMPLAINT_NATURE_LIST_FAILURE"

export const GET_COMPLAINT_TYPE_LIST = "GET_COMPLAINT_TYPE_LIST"
export const GET_COMPLAINT_TYPE_LIST_SUCCESS = "GET_COMPLAINT_TYPE_LIST_SUCCESS"
export const GET_COMPLAINT_TYPE_LIST_FAILURE = "GET_COMPLAINT_TYPE_LIST_FAILURE"

export const GET_COMPLAINT_WINGS_LIST = "GET_COMPLAINT_WINGS_LIST"
export const GET_COMPLAINT_WINGS_LIST_SUCCESS = "GET_COMPLAINT_WINGS_LIST_SUCCESS"
export const GET_COMPLAINT_WINGS_LIST_FAILURE = "GET_COMPLAINT_WINGS_LIST_FAILURE"

export const GET_COMPLAINT_FLATS_LIST = "GET_COMPLAINT_FLATS_LIST"
export const GET_COMPLAINT_FLATS_LIST_SUCCESS = "GET_COMPLAINT_FLATS_LIST_SUCCESS"
export const GET_COMPLAINT_FLATS_LIST_FAILURE = "GET_COMPLAINT_FLATS_LIST_FAILURE"

export const ADD_LOG_A_COMPLAINT = "ADD_LOG_A_COMPLAINT"
export const ADD_LOG_A_COMPLAINT_SUCCESS = "ADD_LOG_A_COMPLAINT_SUCCESS"
export const ADD_LOG_A_COMPLAINT_FAILURE = "ADD_LOG_A_COMPLAINT_FAILURE"

export const ADD_LOG_A_COMPLAINT_AUDIT_LOG = "ADD_LOG_A_COMPLAINT_AUDIT_LOG"
export const ADD_LOG_A_COMPLAINT_AUDIT_LOG_SUCCESS = "ADD_LOG_A_COMPLAINT_AUDIT_LOG_SUCCESS"
export const ADD_LOG_A_COMPLAINT_AUDIT_LOG_FAILURE = "ADD_LOG_A_COMPLAINT_AUDIT_LOG_FAILURE"

export const EDIT_LOG_A_COMPLAINT = "EDIT_LOG_A_COMPLAINT"
export const EDIT_LOG_A_COMPLAINT_SUCCESS = "EDIT_LOG_A_COMPLAINT_SUCCESS"
export const EDIT_LOG_A_COMPLAINT_FAILURE = "EDIT_LOG_A_COMPLAINT_FAILURE"

export const GET_COMPLAINT_CATEGORY_LIST = "GET_COMPLAINT_CATEGORY_LIST"
export const GET_COMPLAINT_CATEGORY_LIST_SUCCESS = "GET_COMPLAINT_CATEGORY_LIST_SUCCESS"
export const GET_COMPLAINT_CATEGORY_LIST_FAILURE = "GET_COMPLAINT_CATEGORY_LIST_FAILURE"

export const GET_COMPLAINT_CATEGORY_DETAILS_LIST = "GET_COMPLAINT_CATEGORY_DETAILS_LIST"
export const GET_COMPLAINT_CATEGORY_DETAILS_LIST_SUCCESS = "GET_COMPLAINT_CATEGORY_DETAILS_LIST_SUCCESS"
export const GET_COMPLAINT_CATEGORY_DETAILS_LIST_FAILURE = "GET_COMPLAINT_CATEGORY_DETAILS_LIST_FAILURE"

export const ADD_COMPLAINT_CATEGORY_LIST = "ADD_COMPLAINT_CATEGORY_LIST"
export const ADD_COMPLAINT_CATEGORY_LIST_SUCCESS = "ADD_COMPLAINT_CATEGORY_LIST_SUCCESS"
export const ADD_COMPLAINT_CATEGORY_LIST_FAILURE = "ADD_COMPLAINT_CATEGORY_LIST_FAILURE"

export const EDIT_COMPLAINT_CATEGORY_LIST = "EDIT_COMPLAINT_CATEGORY_LIST"
export const EDIT_COMPLAINT_CATEGORY_LIST_SUCCESS = "EDIT_COMPLAINT_CATEGORY_LIST_SUCCESS"
export const EDIT_COMPLAINT_CATEGORY_LIST_FAILURE = "EDIT_COMPLAINT_CATEGORY_LIST_FAILURE"

export const DELETE_CATEGORY = 'DELETE_CATEGORY';
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS';
export const DELETE_CATEGORY_FAILURE = 'DELETE_CATEGORY_FAILURE';

export const GET_COMPLAINT_CATEGORY_AUDIT_LOG = 'GET_COMPLAINT_CATEGORY_AUDIT_LOG';
export const GET_COMPLAINT_CATEGORY_AUDIT_LOG_SUCCESS = 'GET_COMPLAINT_CATEGORY_AUDIT_LOG_SUCCESS';
export const GET_COMPLAINT_CATEGORY_AUDIT_LOG_FAILURE = 'GET_COMPLAINT_CATEGORY_AUDIT_LOG_FAILURE';

//-----------------document list--------------
export const GET_DOCUMENT_FOLDER_LIST = "GET_DOCUMENT_FOLDER_LIST"
export const GET_DOCUMENT_FOLDER_LIST_SUCCESS = "GET_DOCUMENT_FOLDER_LIST_SUCCESS"
export const GET_DOCUMENT_FOLDER_LIST_FAILURE = "GET_DOCUMENT_FOLDER_LIST_FAILURE"

export const GET_DOCUMENT_FILE_LIST = "GET_DOCUMENT_FILE_LIST_API"
export const GET_DOCUMENT_FILE_LIST_SUCCESS = "GET_DOCUMENT_FILE_LIST_SUCCESS"
export const GET_DOCUMENT_FILE_LIST_FAILURE = "GET_DOCUMENT_FILE_LIST_FAILURE"

export const CHANGE_FOLDER_ID = "CHANGE_FOLDER_ID"

export const ADD_FOLDER_NAME = 'ADD_FOLDER_NAME';
export const ADD_FOLDER_NAME_SUCCESS = 'ADD_FOLDER_NAME_SUCCESS';
export const ADD_FOLDER_NAME_FAILURE = 'ADD_FOLDER_NAME_FAILURE';

export const UPDATE_DOCUMENTS_LIST = 'UPDATE_DOCUMENTS_LIST';
export const UPDATE_DOCUMENTS_LIST_SUCCESS = 'UPDATE_DOCUMENTS_LIST_SUCCESS';
export const UPDATE_DOCUMENTS_LIST_FAILURE = 'UPDATE_DOCUMENTS_LIST_FAILURE';

export const GET_DOCUMENT_OCCUPANTS_LIST = "GET_DOCUMENT_OCCUPANTS_LIST";
export const GET_DOCUMENT_OCCUPANTS_LIST_SUCCESS = "GET_DOCUMENT_OCCUPANTS_LIST_SUCCESS";
export const GET_DOCUMENT_OCCUPANTS_LIST_FAILURE = "GET_DOCUMENT_OCCUPANTS_LIST_FAILURE";

export const Edit_DOCUMENTS_LIST = 'Edit_DOCUMENTS_LIST';
export const Edit_DOCUMENTS_LIST_SUCCESS = 'Edit_DOCUMENTS_LIST_SUCCESS';
export const Edit_DOCUMENTS_LIST_FAILURE = 'Edit_DOCUMENTS_LIST_FAILURE';

export const DELETE_DOCUMENT = 'DELETE_DOCUMENT';
export const DELETE_DOCUMENT_SUCCESS = 'DELETE_DOCUMENT_SUCCESS';
export const DELETE_DOCUMENT_FAILURE = 'DELETE_DOCUMENT_FAILURE';

// ------------complaint escalation settings  ---------------

export const GET_COMPLAINT_ESCALATION_LIST_SETTINGS = 'GET_COMPLAINT_ESCALATION_LIST_SETTINGS';
export const GET_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS = 'GET_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS';
export const GET_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE = 'GET_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE';

export const UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS = 'UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS';
export const UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS = 'UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_SUCCESS';
export const UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE = 'UPDATE_COMPLAINT_ESCALATION_LIST_SETTINGS_FAILURE';

// -------------complaint email sms settings -------------------------

export const GET_COMPLAINT_EMAIL_SMS_LIST = 'GET_COMPLAINT_EMAIL_SMS_LIST';
export const GET_COMPLAINT_EMAIL_SMS_LIST_SUCCESS = 'GET_COMPLAINT_EMAIL_SMS_LIST_SUCCESS';
export const GET_COMPLAINT_EMAIL_SMS_LIST_FAILURE = 'GET_COMPLAINT_EMAIL_SMS_LIST_FAILURE';

export const UPDATE_COMPLAINT_EMAIL_SMS_LIST = 'UPDATE_COMPLAINT_EMAIL_SMS_LIST';
export const UPDATE_COMPLAINT_EMAIL_SMS_LIST_SUCCESS = 'UPDATE_COMPLAINT_EMAIL_SMS_LIST_SUCCESS';
export const UPDATE_COMPLAINT_EMAIL_SMS_LIST_FAILURE = 'UPDATE_COMPLAINT_EMAIL_SMS_LIST_FAILURE';

// --------------- Complaint Dashboard ------------------------ 

export const GET_COMPLAINT_DASHBOARD_LIST = 'GET_COMPLAINT_DASHBOARD_LIST';
export const GET_COMPLAINT_DASHBOARD_LIST_SUCCESS = 'GET_COMPLAINT_DASHBOARD_LIST_SUCCESS';
export const GET_COMPLAINT_DASHBOARD_LIST_FAILURE = 'GET_COMPLAINT_DASHBOARD_LIST_FAILURE';

export const GET_COMPLAINT_DASHBOARD_CONTENT_LIST = 'GET_COMPLAINT_DASHBOARD_CONTENT_LIST';
export const GET_COMPLAINT_DASHBOARD_CONTENT_LIST_SUCCESS = 'GET_COMPLAINT_DASHBOARD_CONTENT_LIST_SUCCESS';
export const GET_COMPLAINT_DASHBOARD_CONTENT_LIST_FAILURE = 'GET_COMPLAINT_DASHBOARD_CONTENT_LIST_FAILURE';

export const GET_COMPLAINT_AGING_LIST = 'GET_COMPLAINT_AGING_LIST';
export const GET_COMPLAINT_AGING_LIST_SUCCESS = 'GET_COMPLAINT_AGING_LIST_SUCCESS';
export const GET_COMPLAINT_AGING_LIST_FAILURE = 'GET_COMPLAINT_AGING_LIST_FAILURE';

export const GET_COMPLAINT_LOGGED_TICKET = 'GET_COMPLAINT_LOGGED_TICKET';
export const GET_COMPLAINT_LOGGED_TICKET_SUCCESS = 'GET_COMPLAINT_LOGGED_TICKET_SUCCESS';
export const GET_COMPLAINT_LOGGED_TICKET_FAILURE = 'GET_COMPLAINT_LOGGED_TICKET_FAILURE';

export const GET_ASSIGNED_REQ_ROLES = 'GET_ASSIGNED_REQ_ROLES';
export const GET_ASSIGNED_REQ_ROLES_SUCCESS = 'GET_ASSIGNED_REQ_ROLES_SUCCESS';
export const GET_ASSIGNED_REQ_ROLES_FAILURE = 'GET_ASSIGNED_REQ_ROLES_FAILURE';

export const GET_ASSIGNED_REQ_SUB_ROLES = 'GET_ASSIGNED_REQ_SUB_ROLES';
export const GET_ASSIGNED_REQ_SUB_ROLES_SUCCESS = 'GET_ASSIGNED_REQ_SUB_ROLES_SUCCESS';
export const GET_ASSIGNED_REQ_SUB_ROLES_FAILURE = 'GET_ASSIGNED_REQ_SUB_ROLES_FAILURE';

export const GET_ASSIGNED_MEMBERS_LIST_ROLES = 'GET_ASSIGNED_MEMBERS_LIST_ROLES';
export const GET_ASSIGNED_MEMBERS_LIST_ROLES_SUCCESS = 'GET_ASSIGNED_MEMBERS_LIST_ROLES_SUCCESS';
export const GET_ASSIGNED_MEMBERS_LIST_ROLES_FAILURE = 'GET_ASSIGNED_MEMBERS_LIST_ROLES_FAILURE';

export const GET_ASSIGNED_COMPLAINT_DATA = 'GET_ASSIGNED_COMPLAINT_DATA';
export const GET_ASSIGNED_COMPLAINT_DATA_SUCCESS = 'GET_ASSIGNED_COMPLAINT_DATA_SUCCESS';
export const GET_ASSIGNED_COMPLAINT_DATA_FAILURE = 'GET_ASSIGNED_COMPLAINT_DATA_FAILURE';

export const ADD_ASSIGNED_COMPLAINT_DATA = 'ADD_ASSIGNED_COMPLAINT_DATA';
export const ADD_ASSIGNED_COMPLAINT_DATA_SUCCESS = 'ADD_ASSIGNED_COMPLAINT_DATA_SUCCESS';
export const ADD_ASSIGNED_COMPLAINT_DATA_FAILURE = 'ADD_ASSIGNED_COMPLAINT_DATA_FAILURE';

export const CHANGE_COMPLAINT_STATUS = 'CHANGE_COMPLAINT_STATUS';
export const CHANGE_COMPLAINT_STATUS_SUCCESS = 'CHANGE_COMPLAINT_STATUS_SUCCESS';
export const CHANGE_COMPLAINT_STATUS_FAILURE = 'CHANGE_COMPLAINT_STATUS_FAILURE';

export const DOWNLOAD_COMPLAINTS_EXCEL_SHEET = 'DOWNLOAD_COMPLAINTS_EXCEL_SHEET';
export const DOWNLOAD_COMPLAINTS_EXCEL_SHEET_SUCCESS = 'DOWNLOAD_COMPLAINTS_EXCEL_SHEET_SUCCESS';
export const DOWNLOAD_COMPLAINTS_EXCEL_SHEET_FAILURE = 'DOWNLOAD_COMPLAINTS_EXCEL_SHEET_FAILURE';

export const GET_POST_COMMENT_DATA = 'GET_POST_COMMENT_DATA';
export const GET_POST_COMMENT_DATA_SUCCESS = 'GET_POST_COMMENT_DATA_SUCCESS';
export const GET_POST_COMMENT_DATA_FAILURE = 'GET_POST_COMMENT_DATA_FAILURE';

export const ADD_POST_COMMENT_DATA = 'ADD_POST_COMMENT_DATA';
export const ADD_POST_COMMENT_DATA_SUCCESS = 'ADD_POST_COMMENT_DATA_SUCCESS';
export const ADD_POST_COMMENT_DATA_FAILURE = 'ADD_POST_COMMENT_DATA_FAILURE';

export const GET_COMPLAINT_STATUS_DATA = 'GET_COMPLAINT_STATUS_DATA';
export const GET_COMPLAINT_STATUS_DATA_SUCCESS = 'GET_COMPLAINT_STATUS_DATA_SUCCESS';
export const GET_COMPLAINT_STATUS_DATA_FAILURE = 'GET_COMPLAINT_STATUS_DATA_FAILURE';

export const GET_SOCIETY_LIST_DETAILS_DATA = 'GET_SOCIETY_LIST_DETAILS_DATA';
export const GET_SOCIETY_LIST_DETAILS_DATA_SUCCESS = 'GET_SOCIETY_LIST_DETAILS_DATA_SUCCESS';
export const GET_SOCIETY_LIST_DETAILS_DATA_FAILURE = 'GET_SOCIETY_LIST_DETAILS_DATA_FAILURE';

export const ADD_COMPLAINT_DASHBOARD_REVIEW = 'ADD_COMPLAINT_DASHBOARD_REVIEW';
export const ADD_COMPLAINT_DASHBOARD_REVIEW_SUCCESS = 'ADD_COMPLAINT_DASHBOARD_REVIEW_SUCCESS';
export const ADD_COMPLAINT_DASHBOARD_REVIEW_FAILURE = 'ADD_COMPLAINT_DASHBOARD_REVIEW_FAILURE';

// complaint Settting 

export const GET_COMPLAINT_SETTING_LIST = 'GET_COMPLAINT_SETTING_LIST';
export const GET_COMPLAINT_SETTING_LIST_SUCCESS = 'GET_COMPLAINT_SETTING_LIST_SUCCESS';
export const GET_COMPLAINT_SETTING_LIST_FAILURE = 'GET_COMPLAINT_SETTING_LIST_FAILURE';

export const UPDATE_COMPLAINT_SETTING_LIST = 'UPDATE_COMPLAINT_SETTING_LIST';
export const UPDATE_COMPLAINT_SETTING_LIST_SUCCESS = 'UPDATE_COMPLAINT_SETTING_LIST_SUCCESS';
export const UPDATE_COMPLAINT_SETTING_LIST_FAILURE = 'UPDATE_COMPLAINT_SETTING_LIST_FAILURE';

export const GET_COMPLAINT_TYPE_SETTING = 'GET_COMPLAINT_TYPE_SETTING';
export const GET_COMPLAINT_TYPE_SETTING_SUCCESS = 'GET_COMPLAINT_TYPE_SETTING_SUCCESS';
export const GET_COMPLAINT_TYPE_SETTING_FAILURE = 'GET_COMPLAINT_TYPE_SETTING_FAILURE';

export const UPDATE_COMPLAINT_TYPE_SETTING = 'UPDATE_COMPLAINT_TYPE_SETTING';
export const UPDATE_COMPLAINT_TYPE_SETTING_SUCCESS = 'UPDATE_COMPLAINT_TYPE_SETTING_SUCCESS';
export const UPDATE_COMPLAINT_TYPE_SETTING_FAILURE = 'UPDATE_COMPLAINT_TYPE_SETTING_FAILURE';

//-----CLUB ACTIONS---------//
export const GET_BOOKING_LIST = 'GET_BOOKING_LIST';
export const GET_BOOKING_LIST_SUCCESS = 'GET_BOOKING_LIST_SUCCESS';
export const GET_BOOKING_LIST_FAILURE = 'GET_BOOKING_LIST_FAILURE';

export const GET_BOOKING_STATUS_LIST = 'GET_BOOKING_STATUS_LIST';
export const GET_BOOKING_STATUS_LIST_SUCCESS = 'GET_BOOKING_STATUS_LIST_SUCCESS';
export const GET_BOOKING_STATUS_LIST_FAILURE = 'GET_BOOKING_STATUS_LIST_FAILURE';

export const GET_BOOKING_DETAILS = 'GET_BOOKING_DETAILS';
export const GET_BOOKING_DETAILS_SUCCESS = 'GET_BOOKING_DETAILS_SUCCESS';
export const GET_BOOKING_DETAILS_FAILURE = 'GET_BOOKING_DETAILS_FAILURE';

export const MAKE_BOOKING_ADJUSTMENT = 'MAKE_BOOKING_ADJUSTMENT';
export const MAKE_BOOKING_ADJUSTMENT_SUCCESS = 'MAKE_BOOKING_ADJUSTMENT_SUCCESS';
export const MAKE_BOOKING_ADJUSTMENT_FAILURE = 'MAKE_BOOKING_ADJUSTMENT_FAILURE';

export const GET_FACILTIY_ONLINE_PAYMENT_LINK = 'GET_FACILTIY_ONLINE_PAYMENT_LINK';
export const GET_FACILTIY_ONLINE_PAYMENT_LINK_SUCCESS = 'GET_FACILTIY_ONLINE_PAYMENT_LINK_SUCCESS';
export const GET_FACILTIY_ONLINE_PAYMENT_LINK_FAILURE = 'GET_FACILTIY_ONLINE_PAYMENT_LINK_FAILURE';

export const GET_BOOKING_INVOICE = 'GET_BOOKING_INVOICE';
export const GET_BOOKING_INVOICE_SUCCESS = 'GET_BOOKING_INVOICE_SUCCESS';
export const GET_BOOKING_INVOICE_FAILURE = 'GET_BOOKING_INVOICE_FAILURE';

export const MAKE_BOOKING_PAYMENT = 'MAKE_BOOKING_PAYMENT';
export const MAKE_BOOKING_PAYMENT_SUCCESS = 'MAKE_BOOKING_PAYMENT_SUCCESS';
export const MAKE_BOOKING_PAYMENT_FAILURE = 'MAKE_BOOKING_PAYMENT_FAILURE';

export const GET_FACILITY_REPORT = 'GET_FACILITY_REPORT';
export const GET_FACILITY_REPORT_SUCCESS = 'GET_FACILITY_REPORT_SUCCESS';
export const GET_FACILITY_REPORT_FAILURE = 'GET_FACILITY_REPORT_FAILURE';

export const GET_ALL_FACILITY_AUDIT_LOG = 'GET_ALL_FACILITY_AUDIT_LOG';
export const GET_ALL_FACILITY_AUDIT_LOG_SUCCESS = 'GET_ALL_FACILITY_AUDIT_LOG_SUCCESS';
export const GET_ALL_FACILITY_AUDIT_LOG_FAILURE = 'GET_ALL_FACILITY_AUDIT_LOG_FAILURE';

export const GET_FACILITY_LIST = 'GET_FACILITY_LIST';
export const GET_FACILITY_LIST_SUCCESS = 'GET_FACILITY_LIST_SUCCESS';
export const GET_FACILITY_LIST_FAILURE = 'GET_FACILITY_LIST_FAILURE';

export const GET_TIME_SLOT_LIST = 'GET_TIME_SLOT_LIST';
export const GET_TIME_SLOT_LIST_SUCCESS = 'GET_TIME_SLOT_LIST_SUCCESS';
export const GET_TIME_SLOT_LIST_FAILURE = 'GET_TIME_SLOT_LIST_FAILURE';

export const GET_BOOKING_FREQUENCY_LIST = 'GET_BOOKING_FREQUENCY_LIST';
export const GET_BOOKING_FREQUENCY_LIST_SUCCESS = 'GET_BOOKING_FREQUENCY_LIST_SUCCESS';
export const GET_BOOKING_FREQUENCY_LIST_FAILURE = 'GET_BOOKING_FREQUENCY_LIST_FAILURE';

export const GET_FACILITY_DETAILS = 'GET_FACILITY_DETAILS';
export const GET_FACILITY_DETAILS_SUCCESS = 'GET_FACILITY_DETAILS_SUCCESS';
export const GET_FACILITY_DETAILS_FAILURE = 'GET_FACILITY_DETAILS_FAILURE';

export const UPDATE_FACILITY = 'UPDATE_FACILITY';
export const UPDATE_FACILITY_SUCCESS = 'UPDATE_FACILITY_SUCCESS';
export const UPDATE_FACILITY_FAILURE = 'UPDATE_FACILITY_FAILURE';

export const GET_FACILITY_AUDIT_LOGS = 'GET_FACILITY_AUDIT_LOGS';
export const GET_FACILITY_AUDIT_LOGS_SUCCESS = 'GET_FACILITY_AUDIT_LOGS_SUCCESS';
export const GET_FACILITY_AUDIT_LOGS_FAILURE = 'GET_FACILITY_AUDIT_LOGS_FAILURE';

export const DELETE_FACILITY = 'DELETE_FACILITY';
export const DELETE_FACILITY_SUCCESS = 'DELETE_FACILITY_SUCCESS';
export const DELETE_FACILITY_FAILURE = 'DELETE_FACILITY_FAILURE';

export const ADD_UPDATE_TIME_SLOT = 'ADD_UPDATE_TIME_SLOT';
export const ADD_UPDATE_TIME_SLOT_SUCCESS = 'ADD_UPDATE_TIME_SLOT_SUCCESS';
export const ADD_UPDATE_TIME_SLOT_FAILURE = 'ADD_UPDATE_TIME_SLOT_FAILURE';

export const GET_RATE_CHART = 'GET_RATE_CHART';
export const GET_RATE_CHART_SUCCESS = 'GET_RATE_CHART_SUCCESS';
export const GET_RATE_CHART_FAILURE = 'GET_RATE_CHART_FAILURE';

export const ADD_UPDATE_RATE_CHART = 'ADD_UPDATE_RATE_CHART';
export const ADD_UPDATE_RATE_CHART_SUCCESS = 'ADD_UPDATE_RATE_CHART_SUCCESS';
export const ADD_UPDATE_RATE_CHART_FAILURE = 'ADD_UPDATE_RATE_CHART_FAILURE';

export const ADD_BOOKING = 'ADD_BOOKING';
export const ADD_BOOKING_SUCCESS = 'ADD_BOOKING_SUCCESS';
export const ADD_BOOKING_FAILURE = 'ADD_BOOKING_FAILURE';

export const CHECK_BOOKING_AVAILIBILITY = 'CHECK_BOOKING_AVAILIBILITY';
export const CHECK_BOOKING_AVAILIBILITY_SUCCESS = 'CHECK_BOOKING_AVAILIBILITY_SUCCESS';
export const CHECK_BOOKING_AVAILIBILITY_FAILURE = 'CHECK_BOOKING_AVAILIBILITY_FAILURE';
export const CLEAR_CHECK_BOOKING_AVAILIBILITY_DATA = 'CLEAR_CHECK_BOOKING_AVAILIBILITY_DATA';

export const GET_AVAILABLE_TICKETS = 'GET_AVAILABLE_TICKETS';
export const GET_AVAILABLE_TICKETS_SUCCESS = 'GET_AVAILABLE_TICKETS_SUCCESS';
export const GET_AVAILABLE_TICKETS_FAILURE = 'GET_AVAILABLE_TICKETS_FAILURE';
export const CLEAR_AVAILABLE_TICKETS_DATA = 'CLEAR_AVAILABLE_TICKETS_DATA';

export const CHANGE_BOOKING_STATUS = 'CHANGE_BOOKING_STATUS';
export const CHANGE_BOOKING_STATUS_SUCCESS = 'CHANGE_BOOKING_STATUS_SUCCESS';
export const CHANGE_BOOKING_STATUS_FAILURE = 'CHANGE_BOOKING_STATUS_FAILURE';

export const DOWNLOAD_FACILITY_REPORT_EXCEL = 'DOWNLOAD_FACILITY_REPORT_EXCEL';
export const DOWNLOAD_FACILITY_REPORT_EXCEL_SUCCESS = 'DOWNLOAD_FACILITY_REPORT_EXCEL_SUCCESS';
export const DOWNLOAD_FACILITY_REPORT_EXCEL_FAILURE = 'DOWNLOAD_FACILITY_REPORT_EXCEL_FAILURE';

export const GET_BOOKING_GL_MAPPING_LIST = 'GET_BOOKING_GL_MAPPING_LIST';
export const GET_BOOKING_GL_MAPPING_LIST_SUCCESS = 'GET_BOOKING_GL_MAPPING_LIST_SUCCESS';
export const GET_BOOKING_GL_MAPPING_LIST_FAILURE = 'GET_BOOKING_GL_MAPPING_LIST_FAILURE';

export const GET_BOOKING_BANK_ACCOUNT_LIST = 'GET_BOOKING_BANK_ACCOUNT_LIST';
export const GET_BOOKING_BANK_ACCOUNT_LIST_SUCCESS = 'GET_BOOKING_BANK_ACCOUNT_LIST_SUCCESS';
export const GET_BOOKING_BANK_ACCOUNT_LIST_FAILURE = 'GET_BOOKING_BANK_ACCOUNT_LIST_FAILURE';

export const GET_FACILITY_BOOKING_AUDIT = 'GET_FACILITY_BOOKING_AUDIT';
export const GET_FACILITY_BOOKING_AUDIT_SUCCESS = 'GET_FACILITY_BOOKING_AUDIT_SUCCESS';
export const GET_FACILITY_BOOKING_AUDIT_FAILURE = 'GET_FACILITY_BOOKING_AUDIT_FAILURE';

export const GET_ROLE_LIST = 'GET_ROLE_LIST';
export const GET_ROLE_LIST_SUCCESS = 'GET_ROLE_LIST_SUCCESS';
export const GET_ROLE_LIST_FAILURE = 'GET_ROLE_LIST_FAILURE';

export const GET_ROLE_WISE_LIST = 'GET_ROLE_WISE_LIST';
export const GET_ROLE_WISE_LIST_SUCCESS = 'GET_ROLE_WISE_LIST_SUCCESS';
export const GET_ROLE_WISE_LIST_FAILURE = 'GET_ROLE_WISE_LIST_FAILURE';

export const GET_VENDOR_CONCERNED_PERSONS_LIST = 'GET_VENDOR_CONCERNED_PERSONS_LIST';
export const GET_VENDOR_CONCERNED_PERSONS_LIST_SUCCESS = 'GET_VENDOR_CONCERNED_PERSONS_LIST_SUCCESS';
export const GET_VENDOR_CONCERNED_PERSONS_LIST_FAILURE = 'GET_VENDOR_CONCERNED_PERSONS_LIST_FAILURE';

export const ADD_FACILITY = 'ADD_FACILITY';
export const ADD_FACILITY_SUCCESS = 'ADD_FACILITY_SUCCESS';
export const ADD_FACILITY_FAILURE = 'ADD_FACILITY_FAILURE';

export const GET_TIME_SLOT_TYPE_LIST = 'GET_TIME_SLOT_TYPE_LIST';
export const GET_TIME_SLOT_TYPE_LIST_SUCCESS = 'GET_TIME_SLOT_TYPE_LIST_SUCCESS';
export const GET_TIME_SLOT_TYPE_LIST_FAILURE = 'GET_TIME_SLOT_TYPE_LIST_FAILURE';

export const ADD_TIME_SLOT_TYPE = 'ADD_TIME_SLOT_TYPE';
export const ADD_TIME_SLOT_TYPE_SUCCESS = 'ADD_TIME_SLOT_TYPE_SUCCESS';
export const ADD_TIME_SLOT_TYPE_FAILURE = 'ADD_TIME_SLOT_TYPE_FAILURE';

export const UPDATE_TIME_SLOT_TYPE = 'UPDATE_TIME_SLOT_TYPE';
export const UPDATE_TIME_SLOT_TYPE_SUCCESS = 'UPDATE_TIME_SLOT_TYPE_SUCCESS';
export const UPDATE_TIME_SLOT_TYPE_FAILURE = 'UPDATE_TIME_SLOT_TYPE_FAILURE';

export const DELETE_TIME_SLOT_TYPE = 'DELETE_TIME_SLOT_TYPE';
export const DELETE_TIME_SLOT_TYPE_SUCCESS = 'DELETE_TIME_SLOT_TYPE_SUCCESS';
export const DELETE_TIME_SLOT_TYPE_FAILURE = 'DELETE_TIME_SLOT_TYPE_FAILURE';

export const CHANGE_FACILITY_STATUS = 'CHANGE_FACILITY_STATUS';
export const CHANGE_FACILITY_STATUS_SUCCESS = 'CHANGE_FACILITY_STATUS_SUCCESS';
export const CHANGE_FACILITY_STATUS_FAILURE = 'CHANGE_FACILITY_STATUS_FAILURE';

export const DELETE_TIME_SLOT = 'DELETE_TIME_SLOT';
export const DELETE_TIME_SLOT_SUCCESS = 'DELETE_TIME_SLOT_SUCCESS';
export const DELETE_TIME_SLOT_FAILURE = 'DELETE_TIME_SLOT_FAILURE';

export const DELETE_RATE_CHART = 'DELETE_RATE_CHART';
export const DELETE_RATE_CHART_SUCCESS = 'DELETE_RATE_CHART_SUCCESS';
export const DELETE_RATE_CHART_FAILURE = 'DELETE_RATE_CHART_FAILURE';

export const SEND_MESSAGE_TO_ADMINISTRATOR = 'SEND_MESSAGE_TO_ADMINISTRATOR';
export const SEND_MESSAGE_TO_ADMINISTRATOR_SUCCESS = 'SEND_MESSAGE_TO_ADMINISTRATOR_SUCCESS';
export const SEND_MESSAGE_TO_ADMINISTRATOR_FAILURE = 'SEND_MESSAGE_TO_ADMINISTRATOR_FAILURE';

//-----GATE MANAGEMENT ACTIONS---------//
export const GET_FLAT_MEMBER_LIST = 'GET_FLAT_MEMBER_LIST';
export const GET_FLAT_MEMBER_LIST_SUCCESS = 'GET_FLAT_MEMBER_LIST_SUCCESS';
export const GET_FLAT_MEMBER_LIST_FAILURE = 'GET_FLAT_MEMBER_LIST_FAILURE';

export const GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA = 'GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA';
export const GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_SUCCESS = 'GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_SUCCESS';
export const GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_FAILURE = 'GET_VISITOR_OR_VEHICLE_MANAGEMENT_DASHBOARD_DATA_FAILURE';

export const GET_VISITOR_VEHICLE_LOG = 'GET_VISITOR_VEHICLE_LOG';
export const GET_VISITOR_VEHICLE_LOG_SUCCESS = 'GET_VISITOR_VEHICLE_LOG_SUCCESS';
export const GET_VISITOR_VEHICLE_LOG_FAILURE = 'GET_VISITOR_VEHICLE_LOG_FAILURE';

export const DOWNLOAD_VISITOR_VEHICLE_LOG = 'DOWNLOAD_VISITOR_VEHICLE_LOG';
export const DOWNLOAD_VISITOR_VEHICLE_LOG_SUCCESS = 'DOWNLOAD_VISITOR_VEHICLE_LOG_SUCCESS';
export const DOWNLOAD_VISITOR_VEHICLE_LOG_FAILURE = 'DOWNLOAD_VISITOR_VEHICLE_LOG_FAILURE';

export const GET_SOCIETY_ENTRY_GATE = 'GET_SOCIETY_ENTRY_GATE';
export const GET_SOCIETY_ENTRY_GATE_SUCCESS = 'GET_SOCIETY_ENTRY_GATE_SUCCESS';
export const GET_SOCIETY_ENTRY_GATE_FAILURE = 'GET_SOCIETY_ENTRY_GATE_FAILURE';

export const ADD_UPDATE_SOCIETY_ENTRY_GATE = 'ADD_UPDATE_SOCIETY_ENTRY_GATE';
export const ADD_UPDATE_SOCIETY_ENTRY_GATE_SUCCESS = 'ADD_UPDATE_SOCIETY_ENTRY_GATE_SUCCESS';
export const ADD_UPDATE_SOCIETY_ENTRY_GATE_FAILURE = 'ADD_UPDATE_SOCIETY_ENTRY_GATE_FAILURE';

export const DELETE_SOCIETY_ENTRY_GATE = 'DELETE_SOCIETY_ENTRY_GATE';
export const DELETE_SOCIETY_ENTRY_GATE_SUCCESS = 'DELETE_SOCIETY_ENTRY_GATE_SUCCESS';
export const DELETE_SOCIETY_ENTRY_GATE_FAILURE = 'DELETE_SOCIETY_ENTRY_GATE_FAILURE';

export const GET_GATE_ACCESS_DATA = 'GET_GATE_ACCESS_DATA';
export const GET_GATE_ACCESS_DATA_SUCCESS = 'GET_GATE_ACCESS_DATA_SUCCESS';
export const GET_GATE_ACCESS_DATA_FAILURE = 'GET_GATE_ACCESS_DATA_FAILURE';

export const ADD_UPDATE_GATE_ACCESS_DATA = 'ADD_UPDATE_GATE_ACCESS_DATA';
export const ADD_UPDATE_GATE_ACCESS_DATA_SUCCESS = 'ADD_UPDATE_GATE_ACCESS_DATA_SUCCESS';
export const ADD_UPDATE_GATE_ACCESS_DATA_FAILURE = 'ADD_UPDATE_GATE_ACCESS_DATA_FAILURE';

export const GET_VISITOR_LOG = 'GET_VISITOR_LOG';
export const GET_VISITOR_LOG_SUCCESS = 'GET_VISITOR_LOG_SUCCESS';
export const GET_VISITOR_LOG_FAILURE = 'GET_VISITOR_LOG_FAILURE';

export const DOWNLOAD_VISITOR_LOG = 'DOWNLOAD_VISITOR_LOG';
export const DOWNLOAD_VISITOR_LOG_SUCCESS = 'DOWNLOAD_VISITOR_LOG_SUCCESS';
export const DOWNLOAD_VISITOR_LOG_FAILURE = 'DOWNLOAD_VISITOR_LOG_FAILURE';

export const GET_FREQUENT_VISITOR_LIST = 'GET_FREQUENT_VISITOR_LIST';
export const GET_FREQUENT_VISITOR_LIST_SUCCESS = 'GET_FREQUENT_VISITOR_LIST_SUCCESS';
export const GET_FREQUENT_VISITOR_LIST_FAILURE = 'GET_FREQUENT_VISITOR_LIST_FAILURE';

export const GET_EXPECTED_VISITOR_LIST = 'GET_EXPECTED_VISITOR_LIST';
export const GET_EXPECTED_VISITOR_LIST_SUCCESS = 'GET_EXPECTED_VISITOR_LIST_SUCCESS';
export const GET_EXPECTED_VISITOR_LIST_FAILURE = 'GET_EXPECTED_VISITOR_LIST_FAILURE';

export const UPDATE_VISITOR_STATUS = 'UPDATE_VISITOR_STATUS';
export const UPDATE_VISITOR_STATUS_SUCCESS = 'UPDATE_VISITOR_STATUS_SUCCESS';
export const UPDATE_VISITOR_STATUS_FAILURE = 'UPDATE_VISITOR_STATUS_FAILURE';

export const GET_STAFF_DETAILS_BY_STAFF_CODE = 'GET_STAFF_DETAILS_BY_STAFF_CODE';
export const GET_STAFF_DETAILS_BY_STAFF_CODE_SUCCESS = 'GET_STAFF_DETAILS_BY_STAFF_CODE_SUCCESS';
export const GET_STAFF_DETAILS_BY_STAFF_CODE_FAILURE = 'GET_STAFF_DETAILS_BY_STAFF_CODE_FAILURE';

export const GET_VISITOR_PURPOSE_LIST = 'GET_VISITOR_PURPOSE_LIST';
export const GET_VISITOR_PURPOSE_LIST_SUCCESS = 'GET_VISITOR_PURPOSE_LIST_SUCCESS';
export const GET_VISITOR_PURPOSE_LIST_FAILURE = 'GET_VISITOR_PURPOSE_LIST_FAILURE';

export const DELETE_VISITOR_PURPOSE_LIST = 'DELETE_VISITOR_PURPOSE_LIST';
export const DELETE_VISITOR_PURPOSE_LIST_SUCCESS = 'DELETE_VISITOR_PURPOSE_LIST_SUCCESS';
export const DELETE_VISITOR_PURPOSE_LIST_FAILURE = 'DELETE_VISITOR_PURPOSE_LIST_FAILURE';

export const GET_VISITORS_DATA = 'GET_VISITORS_DATA';
export const GET_VISITORS_DATA_SUCCESS = 'GET_VISITORS_DATA_SUCCESS';
export const GET_VISITORS_DATA_FAILURE = 'GET_VISITORS_DATA_FAILURE';

export const SEND_OTP_TO_VISITOR = 'SEND_OTP_TO_VISITOR';
export const SEND_OTP_TO_VISITOR_SUCCESS = 'SEND_OTP_TO_VISITOR_SUCCESS';
export const SEND_OTP_TO_VISITOR_FAILURE = 'SEND_OTP_TO_VISITOR_FAILURE';

export const ADD_NEW_VISITOR = 'ADD_NEW_VISITOR';
export const ADD_NEW_VISITOR_SUCCESS = 'ADD_NEW_VISITOR_SUCCESS';
export const ADD_NEW_VISITOR_FAILURE = 'ADD_NEW_VISITOR_FAILURE';

export const STAFF_CHECKIN = 'STAFF_CHECKIN';
export const STAFF_CHECKIN_SUCCESS = 'STAFF_CHECKIN_SUCCESS';
export const STAFF_CHECKIN_FAILURE = 'STAFF_CHECKIN_FAILURE';

export const GET_VISITOR_PASS_DATA = 'GET_VISITOR_PASS_DATA';
export const GET_VISITOR_PASS_DATA_SUCCESS = 'GET_VISITOR_PASS_DATA_SUCCESS';
export const GET_VISITOR_PASS_DATA_FAILURE = 'GET_VISITOR_PASS_DATA_FAILURE';
export const CLEAR_GET_VISITOR_PASS_DATA = 'CLEAR_GET_VISITOR_PASS_DATA';

export const GET_PARKING_TYPE_LIST = 'GET_PARKING_TYPE_LIST';
export const GET_PARKING_TYPE_LIST_SUCCESS = 'GET_PARKING_TYPE_LIST_SUCCESS';
export const GET_PARKING_TYPE_LIST_FAILURE = 'GET_PARKING_TYPE_LIST_FAILURE';

export const GET_PARKING_TYPE_DETAILS = 'GET_PARKING_TYPE_DETAILS';
export const GET_PARKING_TYPE_DETAILS_SUCCESS = 'GET_PARKING_TYPE_DETAILS_SUCCESS';
export const GET_PARKING_TYPE_DETAILS_FAILURE = 'GET_PARKING_TYPE_DETAILS_FAILURE';

export const ADD_UPDATE_PARKING_TYPE_DETAILS = 'ADD_UPDATE_PARKING_TYPE_DETAILS';
export const ADD_UPDATE_PARKING_TYPE_DETAILS_SUCCESS = 'ADD_UPDATE_PARKING_TYPE_DETAILS_SUCCESS';
export const ADD_UPDATE_PARKING_TYPE_DETAILS_FAILURE = 'ADD_UPDATE_PARKING_TYPE_DETAILS_FAILURE';

export const DELETE_PARKING_TYPE_DETAILS = 'DELETE_PARKING_TYPE_DETAILS';
export const DELETE_PARKING_TYPE_DETAILS_SUCCESS = 'DELETE_PARKING_TYPE_DETAILS_SUCCESS';
export const DELETE_PARKING_TYPE_DETAILS_FAILURE = 'DELETE_PARKING_TYPE_DETAILS_FAILURE';

export const GET_PARKING_CHARGE_LIST = 'GET_PARKING_CHARGE_LIST';
export const GET_PARKING_CHARGE_LIST_SUCCESS = 'GET_PARKING_CHARGE_LIST_SUCCESS';
export const GET_PARKING_CHARGE_LIST_FAILURE = 'GET_PARKING_CHARGE_LIST_FAILURE';

export const ADD_UPDATE_PARKING_CHARGE_LIST = 'ADD_UPDATE_PARKING_CHARGE_LIST';
export const ADD_UPDATE_PARKING_CHARGE_LIST_SUCCESS = 'ADD_UPDATE_PARKING_CHARGE_LIST_SUCCESS';
export const ADD_UPDATE_PARKING_CHARGE_LIST_FAILURE = 'ADD_UPDATE_PARKING_CHARGE_LIST_FAILURE';

export const GET_PARKING_CHARGE_AUDIT_LOGS = 'GET_PARKING_CHARGE_AUDIT_LOGS';
export const GET_PARKING_CHARGE_AUDIT_LOGS_SUCCESS = 'GET_PARKING_CHARGE_AUDIT_LOGS_SUCCESS';
export const GET_PARKING_CHARGE_AUDIT_LOGS_FAILURE = 'GET_PARKING_CHARGE_AUDIT_LOGS_FAILURE';

// --------------------------- Reports ---------------------------------------
// --------------------------- Society Reports ---------------------------------------
export const GET_SOCIETY_EMAIL_REPORTS = 'GET_SOCIETY_EMAIL_REPORTS'
export const GET_SOCIETY_EMAIL_REPORTS_SUCCESS = 'GET_SOCIETY_EMAIL_REPORTS_SUCCESS'
export const GET_SOCIETY_EMAIL_REPORTS_FAILURE = 'GET_SOCIETY_EMAIL_REPORTS_FAILURE'

export const CLEAR_MEMBER_LEDGER_REPORTS = 'CLEAR_MEMBER_LEDGER_REPORTS'

export const GET_MEMBER_LEDGER_REPORTS = 'GET_MEMBER_LEDGER_REPORTS'
export const GET_MEMBER_LEDGER_REPORTS_SUCCESS = 'GET_MEMBER_LEDGER_REPORTS_SUCCESS'
export const GET_MEMBER_LEDGER_REPORTS_FAILURE = 'GET_MEMBER_LEDGER_REPORTS_FAILURE'

export const GET_SOCIETY_PARKING_REPORTS = 'GET_SOCIETY_PARKING_REPORTS'
export const GET_SOCIETY_PARKING_REPORTS_SUCCESS = 'GET_SOCIETY_PARKING_REPORTS_SUCCESS'
export const GET_SOCIETY_PARKING_REPORTS_FAILURE = 'GET_SOCIETY_PARKING_REPORTS_FAILURE'

export const DOWNLOAD_SOCIETY_EMAIL_REPORTS = 'DOWNLOAD_SOCIETY_EMAIL_REPORTS';
export const DOWNLOAD_SOCIETY_EMAIL_REPORTS_SUCCESS = 'DOWNLOAD_SOCIETY_EMAIL_REPORTS_SUCCESS';
export const DOWNLOAD_SOCIETY_EMAIL_REPORTS_FAILURE = 'DOWNLOAD_SOCIETY_EMAIL_REPORTS_FAILURE';

export const GET_APP_DOWNLOAD_REPORTS = 'GET_APP_DOWNLOAD_REPORTS';
export const GET_APP_DOWNLOAD_REPORTS_SUCCESS = 'GET_APP_DOWNLOAD_REPORTS_SUCCESS';
export const GET_APP_DOWNLOAD_REPORTS_FAILURE = 'GET_APP_DOWNLOAD_REPORTS_FAILURE';

export const APP_DOWNLOAD_REPORTS_XL = 'APP_DOWNLOAD_REPORTS_XL';
export const APP_DOWNLOAD_REPORTS_XL_SUCCESS = 'APP_DOWNLOAD_REPORTS_XL_SUCCESS';
export const APP_DOWNLOAD_REPORTS_XL_FAILURE = 'APP_DOWNLOAD_REPORTS_XL_FAILURE';

export const DOWNLOAD_NDC = 'DOWNLOAD_NDC'
export const DOWNLOAD_NDC_SUCCESS = 'DOWNLOAD_NDC_SUCCESS'
export const DOWNLOAD_NDC_FAILURE = 'DOWNLOAD_NDC_FAILURE'

//---------------------------- Finance Report --------------------------------
export const GET_DEBIT_NOTE_INVOICE_REPORTS = 'GET_DEBIT_NOTE_INVOICE_REPORTS';
export const GET_DEBIT_NOTE_INVOICE_REPORTS_SUCCESS = 'GET_DEBIT_NOTE_INVOICE_REPORTS_SUCCESS';
export const GET_DEBIT_NOTE_INVOICE_REPORTS_FAILURE = 'GET_DEBIT_NOTE_INVOICE_REPORTS_FAILURE';


export const GET_INVOICE_REPORTS = 'GET_INVOICE_REPORTS';
export const GET_INVOICE_REPORTS_SUCCESS = 'GET_INVOICE_REPORTS_SUCCESS';
export const GET_INVOICE_REPORTS_FAILURE = 'GET_INVOICE_REPORTS_FAILURE';

export const GET_RECEIPT_REPORTS = 'GET_RECEIPT_REPORTS';
export const GET_RECEIPT_REPORTS_SUCCESS = 'GET_RECEIPT_REPORTS_SUCCESS';
export const GET_RECEIPT_REPORTS_FAILURE = 'GET_RECEIPT_REPORTS_FAILURE';

export const DOWNLOAD_RECEIPT_REPORTS = 'DOWNLOAD_RECEIPT_REPORTS';

export const GET_SUPPLEMNTARY_BILL_REPORTS = 'GET_SUPPLEMNTARY_BILL_REPORTS';
export const GET_SUPPLEMNTARY_BILL_REPORTS_SUCCESS = 'GET_SUPPLEMNTARY_BILL_REPORTS_SUCCESS';
export const GET_SUPPLEMNTARY_BILL_REPORTS_FAILURE = 'GET_SUPPLEMNTARY_BILL_REPORTS_FAILURE';

export const GET_DEBIT_NOTE_RECEIPT_REPORTS = 'GET_DEBIT_NOTE_RECEIPT_REPORTS';
export const GET_DEBIT_NOTE_RECEIPT_REPORTS_SUCCESS = 'GET_DEBIT_NOTE_RECEIPT_REPORTS_SUCCESS';
export const GET_DEBIT_NOTE_RECEIPT_REPORTS_FAILURE = 'GET_DEBIT_NOTE_RECEIPT_REPORTS_FAILURE';

export const DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORTS = 'DOWNLOAD_DEBIT_NOTE_RECEIPT_REPORTS';

export const GET_DELETED_INVOICE_REPORTS = 'GET_DELETED_INVOICE_REPORTS';
export const GET_DELETED_INVOICE_REPORTS_SUCCESS = 'GET_DELETED_INVOICE_REPORTS_SUCCESS';
export const GET_DELETED_INVOICE_REPORTS_FAILURE = 'GET_DELETED_INVOICE_REPORTS_FAILURE';

export const GET_DELETED_DEBIT_NOTE_REPORTS = 'GET_DELETED_DEBIT_NOTE_REPORTS';
export const GET_DELETED_DEBIT_NOTE_REPORTS_SUCCESS = 'GET_DELETED_DEBIT_NOTE_REPORTS_SUCCESS';
export const GET_DELETED_DEBIT_NOTE_REPORTS_FAILURE = 'GET_DELETED_DEBIT_NOTE_REPORTS_FAILURE';

export const DOWNLOAD_DELETED_DEBIT_NOTE_REPORTS = 'DOWNLOAD_DELETED_DEBIT_NOTE_REPORTS';

export const GET_INVOICE_INTEREST_REPORTS = 'GET_INVOICE_INTEREST_REPORTS';
export const GET_INVOICE_INTEREST_REPORTS_SUCCESS = 'GET_INVOICE_INTEREST_REPORTS_SUCCESS';
export const GET_INVOICE_INTEREST_REPORTS_FAILURE = 'GET_INVOICE_INTEREST_REPORTS_FAILURE';
export const GET_PREV_INVOICE_INTEREST_REPORTS = 'GET_PREV_INVOICE_INTEREST_REPORTS';
export const GET_PREV_INVOICE_INTEREST_REPORTS_SUCCESS = 'GET_PREV_INVOICE_INTEREST_REPORTS_SUCCESS';
export const GET_PREV_INVOICE_INTEREST_REPORTS_FAILURE = 'GET_PREV_INVOICE_INTEREST_REPORTS_FAILURE';

export const GET_INTEREST_CALCULATION_REPORTS = 'GET_INTEREST_CALCULATION_REPORTS';
export const GET_INTEREST_CALCULATION_REPORTS_SUCCESS = 'GET_INTEREST_CALCULATION_REPORTS_SUCCESS';
export const GET_INTEREST_CALCULATION_REPORTS_FAILURE = 'GET_INTEREST_CALCULATION_REPORTS_FAILURE';

export const GET_RECONCILIATION_REPORTS = 'GET_RECONCILIATION_REPORTS';
export const GET_RECONCILIATION_REPORTS_SUCCESS = 'GET_RECONCILIATION_REPORTS_SUCCESS';
export const GET_RECONCILIATION_REPORTS_FAILURE = 'GET_RECONCILIATION_REPORTS_FAILURE';

export const GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS = 'GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS';
export const GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_SUCCESS = 'GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_SUCCESS';
export const GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_FAILURE = 'GET_DEBITNOTE_INTEREST_CALCULATION_REPORTS_FAILURE';

export const DOWNLOAD_DEBITNOTE_INTEREST_CALCULATION_REPORTS = 'DOWNLOAD_DEBITNOTE_INTEREST_CALCULATION_REPORTS';

export const GET_INVOICE_AGING_REPORTS = 'GET_INVOICE_AGING_REPORTS';
export const GET_INVOICE_AGING_REPORTS_SUCCESS = 'GET_INVOICE_AGING_REPORTS_SUCCESS';
export const GET_INVOICE_AGING_REPORTS_FAILURE = 'GET_INVOICE_AGING_REPORTS_FAILURE';

export const GET_INVOICE_AUDIT_REPORTS = 'GET_INVOICE_AUDIT_REPORTS';
export const GET_INVOICE_AUDIT_REPORTS_SUCCESS = 'GET_INVOICE_AUDIT_REPORTS_SUCCESS';
export const GET_INVOICE_AUDIT_REPORTS_FAILURE = 'GET_INVOICE_AUDIT_REPORTS_FAILURE';

export const GET_GST_REPORTS = 'GET_GST_REPORTS';
export const GET_GST_REPORTS_SUCCESS = 'GET_GST_REPORTS_SUCCESS';
export const GET_GST_REPORTS_FAILURE = 'GET_GST_REPORTS_FAILURE';



// ------------------------------ Old Financial REports ---------------------------
export const GET_BILL_TYPE_LIST = 'GET_BILL_TYPE_LIST';
export const GET_BILL_TYPE_LIST_SUCCESS = 'GET_BILL_TYPE_LIST_SUCCESS';
export const GET_BILL_TYPE_LIST_FAILURE = 'GET_BILL_TYPE_LIST_FAILURE';

export const GET_FINANCE_MEMBER_LIST = 'GET_FINANCE_MEMBER_LIST';
export const GET_FINANCE_MEMBER_LIST_SUCCESS = 'GET_FINANCE_MEMBER_LIST_SUCCESS';
export const GET_FINANCE_MEMBER_LIST_FAILURE = 'GET_FINANCE_MEMBER_LIST_FAILURE';

export const GET_BILL_GENERATION_VIEW_REPORTS = 'GET_BILL_GENERATION_VIEW_REPORTS';
export const GET_BILL_GENERATION_VIEW_REPORTS_SUCCESS = 'GET_BILL_GENERATION_VIEW_REPORTS_SUCCESS';
export const GET_BILL_GENERATION_VIEW_REPORTS_FAILURE = 'GET_BILL_GENERATION_VIEW_REPORTS_FAILURE';

export const DOWNLOAD_BILL_GENERATION_VIEW_REPORTS = 'DOWNLOAD_BILL_GENERATION_VIEW_REPORTS';
export const DOWNLOAD_BILL_GENERATION_VIEW_REPORTS_SUCCESS = 'DOWNLOAD_BILL_GENERATION_VIEW_REPORTS_SUCCESS';
export const DOWNLOAD_BILL_GENERATION_VIEW_REPORTS_FAILURE = 'DOWNLOAD_BILL_GENERATION_VIEW_REPORTS_FAILURE';

export const GET_MEMBERS_BILL_REPORTS = 'GET_MEMBERS_BILL_REPORTS';
export const GET_MEMBERS_BILL_REPORTS_SUCCESS = 'GET_MEMBERS_BILL_REPORTS_SUCCESS';
export const GET_MEMBERS_BILL_REPORTS_FAILURE = 'GET_MEMBERS_BILL_REPORTS_FAILURE';

export const GET_SOCIETY_BILL_REPORTS = 'GET_SOCIETY_BILL_REPORTS';
export const GET_SOCIETY_BILL_REPORTS_SUCCESS = 'GET_SOCIETY_BILL_REPORTS_SUCCESS';
export const GET_SOCIETY_BILL_REPORTS_FAILURE = 'GET_SOCIETY_BILL_REPORTS_FAILURE';

export const GET_INTEREST_STATEMENT_REPORTS = 'GET_INTEREST_STATEMENT_REPORTS';
export const GET_INTEREST_STATEMENT_REPORTS_SUCCESS = 'GET_INTEREST_STATEMENT_REPORTS_SUCCESS';
export const GET_INTEREST_STATEMENT_REPORTS_FAILURE = 'GET_INTEREST_STATEMENT_REPORTS_FAILURE';

export const GET_EINVOICE_REPORTS = 'GET_EINVOICE_REPORTS';
export const GET_EINVOICE_REPORTS_SUCCESS = 'GET_EINVOICE_REPORTS_SUCCESS';
export const GET_EINVOICE_REPORTS_FAILURE = 'GET_EINVOICE_REPORTS_FAILURE';

export const GET_EINVOICE_REPORTS_NEW = 'GET_EINVOICE_REPORTS_NEW';
export const GET_EINVOICE_REPORTS_NEW_SUCCESS = 'GET_EINVOICE_REPORTS_NEW_SUCCESS';
export const GET_EINVOICE_REPORTS_NEW_FAILURE = 'GET_EINVOICE_REPORTS_NEW_FAILURE';

export const GET_OUTSTANDING_REPORTS = 'GET_OUTSTANDING_REPORTS';
export const GET_OUTSTANDING_REPORTS_SUCCESS = 'GET_OUTSTANDING_REPORTS_SUCCESS';
export const GET_OUTSTANDING_REPORTS_FAILURE = 'GET_OUTSTANDING_REPORTS_FAILURE';

export const DOWNLOAD_OUTSTANDING_REPORTS = 'DOWNLOAD_OUTSTANDING_REPORTS';
export const DOWNLOAD_OUTSTANDING_REPORTS_SUCCESS = 'DOWNLOAD_OUTSTANDING_REPORTS_SUCCESS';
export const DOWNLOAD_OUTSTANDING_REPORTS_FAILURE = 'DOWNLOAD_OUTSTANDING_REPORTS_FAILURE';

export const GET_GROUP_TRAIL_BALANCE = 'GET_GROUP_TRAIL_BALANCE';
export const GET_GROUP_TRAIL_BALANCE_SUCCESS = 'GET_GROUP_TRAIL_BALANCE_SUCCESS';
export const GET_GROUP_TRAIL_BALANCE_FAILURE = 'GET_GROUP_TRAIL_BALANCE_FAILURE';

export const DOWNLOAD_CSV_GROUP_TRAIL_BALANCE = 'DOWNLOAD_CSV_GROUP_TRAIL_BALANCE';
export const DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_SUCCESS = 'DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_SUCCESS';
export const DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_FAILURE = 'DOWNLOAD_CSV_GROUP_TRAIL_BALANCE_FAILURE';

export const DOWNLOAD_PDF_GROUP_TRAIL_BALANCE = 'DOWNLOAD_GROUP_TRAIL_BALANCE';
export const DOWNLOAD_PDF_GROUP_TRAIL_BALANCE_SUCCESS = 'DOWNLOAD_GROUP_TRAIL_BALANCE_SUCCESS';
export const DOWNLOAD_PDF_GROUP_TRAIL_BALANCE_FAILURE = 'DOWNLOAD_GROUP_TRAIL_BALANCE_FAILURE';

export const GET_OPENING_BALANCE_REPORTS = 'GET_OPENING_BALANCE_REPORTS';
export const GET_OPENING_BALANCE_REPORTS_SUCCESS = 'GET_OPENING_BALANCE_REPORTS_SUCCESS';
export const GET_OPENING_BALANCE_REPORTS_FAILURE = 'GET_OPENING_BALANCE_REPORTS_FAILURE';

export const DOWNLOAD_CSV_OPENING_BALANCE_REPORTS = 'DOWNLOAD_CSV_OPENING_BALANCE_REPORTS';
export const DOWNLOAD_CSV_OPENING_BALANCE_REPORTS_SUCCESS = 'DOWNLOAD_CSV_OPENING_BALANCE_REPORTS_SUCCESS';
export const DOWNLOAD_CSV_OPENING_BALANCE_REPORTS_FAILURE = 'DOWNLOAD_CSV_OPENING_BALANCE_REPORTS_FAILURE';

export const DOWNLOAD_PDF_OPENING_BALANCE_REPORTS = 'DOWNLOAD_OPENING_BALANCE_REPORTS';
export const DOWNLOAD_PDF_OPENING_BALANCE_REPORTS_SUCCESS = 'DOWNLOAD_OPENING_BALANCE_REPORTS_SUCCESS';
export const DOWNLOAD_PDF_OPENING_BALANCE_REPORTS_FAILURE = 'DOWNLOAD_OPENING_BALANCE_REPORTS_FAILURE';

export const GET_RECEIPTS_PAYMENTS = 'GET_RECEIPTS_PAYMENTS';
export const GET_RECEIPTS_PAYMENTS_SUCCESS = 'GET_RECEIPTS_PAYMENTS_SUCCESS';
export const GET_RECEIPTS_PAYMENTS_FAILURE = 'GET_RECEIPTS_PAYMENTS_FAILURE';

export const DOWNLOAD_CSV_RECEIPTS_PAYMENTS = 'DOWNLOAD_CSV_RECEIPTS_PAYMENTS';
export const DOWNLOAD_CSV_RECEIPTS_PAYMENTS_SUCCESS = 'DOWNLOAD_CSV_RECEIPTS_PAYMENTS_SUCCESS';
export const DOWNLOAD_CSV_RECEIPTS_PAYMENTS_FAILURE = 'DOWNLOAD_CSV_RECEIPTS_PAYMENTS_FAILURE';

export const DOWNLOAD_PDF_RECEIPTS_PAYMENTS = 'DOWNLOAD_RECEIPTS_PAYMENTS';
export const DOWNLOAD_PDF_RECEIPTS_PAYMENTS_SUCCESS = 'DOWNLOAD_RECEIPTS_PAYMENTS_SUCCESS';
export const DOWNLOAD_PDF_RECEIPTS_PAYMENTS_FAILURE = 'DOWNLOAD_RECEIPTS_PAYMENTS_FAILURE';

export const GET_CASH_BANK_BOOK = 'GET_CASH_BANK_BOOK';
export const GET_CASH_BANK_BOOK_SUCCESS = 'GET_CASH_BANK_BOOK_SUCCESS';
export const GET_CASH_BANK_BOOK_FAILURE = 'GET_CASH_BANK_BOOK_FAILURE';

export const DOWNLOAD_CSV_CASH_BANK_BOOK = 'DOWNLOAD_CSV_CASH_BANK_BOOK';
export const DOWNLOAD_CSV_CASH_BANK_BOOK_SUCCESS = 'DOWNLOAD_CSV_CASH_BANK_BOOK_SUCCESS';
export const DOWNLOAD_CSV_CASH_BANK_BOOK_FAILURE = 'DOWNLOAD_CSV_CASH_BANK_BOOK_FAILURE';

export const DOWNLOAD_PDF_CASH_BANK_BOOK = 'DOWNLOAD_CASH_BANK_BOOK';
export const DOWNLOAD_PDF_CASH_BANK_BOOK_SUCCESS = 'DOWNLOAD_CASH_BANK_BOOK_SUCCESS';
export const DOWNLOAD_PDF_CASH_BANK_BOOK_FAILURE = 'DOWNLOAD_CASH_BANK_BOOK_FAILURE';

export const GET_JOURNALS = 'GET_JOURNALS';
export const GET_JOURNALS_SUCCESS = 'GET_JOURNALS_SUCCESS';
export const GET_JOURNALS_FAILURE = 'GET_JOURNALS_FAILURE';

export const DOWNLOAD_CSV_JOURNALS = 'DOWNLOAD_CSV_JOURNALS';
export const DOWNLOAD_CSV_JOURNALS_SUCCESS = 'DOWNLOAD_CSV_JOURNALS_SUCCESS';
export const DOWNLOAD_CSV_JOURNALS_FAILURE = 'DOWNLOAD_CSV_JOURNALS_FAILURE';

export const DOWNLOAD_PDF_JOURNALS = 'DOWNLOAD_PDF_JOURNALS';
export const DOWNLOAD_PDF_JOURNALS_SUCCESS = 'DOWNLOAD_PDF_JOURNALS_SUCCESS';
export const DOWNLOAD_PDF_JOURNALS_FAILURE = 'DOWNLOAD_PDF_JOURNALS_FAILURE';

export const GET_LEDGERS = 'GET_LEDGERS';
export const GET_LEDGERS_SUCCESS = 'GET_LEDGERS_SUCCESS';
export const GET_LEDGERS_FAILURE = 'GET_LEDGERS_FAILURE';

export const DOWNLOAD_CSV_LEDGERS = 'DOWNLOAD_CSV_LEDGERS';
export const DOWNLOAD_CSV_LEDGERS_SUCCESS = 'DOWNLOAD_CSV_LEDGERS_SUCCESS';
export const DOWNLOAD_CSV_LEDGERS_FAILURE = 'DOWNLOAD_CSV_LEDGERS_FAILURE';

export const DOWNLOAD_PDF_LEDGERS = 'DOWNLOAD_PDF_LEDGERS';
export const DOWNLOAD_PDF_LEDGERS_SUCCESS = 'DOWNLOAD_PDF_LEDGERS_SUCCESS';
export const DOWNLOAD_PDF_LEDGERS_FAILURE = 'DOWNLOAD_PDF_LEDGERS_FAILURE';

// export const GET_ALL_MEMBER_LEDGERS = 'GET_ALL_MEMBER_LEDGERS';
// export const GET_ALL_MEMBER_LEDGERS_SUCCESS = 'GET_ALL_MEMBER_LEDGERS_SUCCESS';
// export const GET_ALL_MEMBER_LEDGERS_FAILURE = 'GET_ALL_MEMBER_LEDGERS_FAILURE';

export const DOWNLOAD_CSV_ALL_MEMBERS_REPORT = 'DOWNLOAD_CSV_ALL_MEMBERS_REPORT';
export const DOWNLOAD_CSV_ALL_MEMBERS_REPORT_SUCCESS = 'DOWNLOAD_CSV_ALL_MEMBERS_REPORT_SUCCESS';
export const DOWNLOAD_CSV_ALL_MEMBERS_REPORT_FAILURE = 'DOWNLOAD_CSV_ALL_MEMBERS_REPORT_FAILURE';

export const GET_CHART_OF_ACCOUNT_BOOK_DATA = 'GET_CHART_OF_ACCOUNT_BOOK_DATA';
export const GET_CHART_OF_ACCOUNT_BOOK_DATA_SUCCESS = 'GET_CHART_OF_ACCOUNT_BOOK_DATA_SUCCESS';
export const GET_CHART_OF_ACCOUNT_BOOK_DATA_FAILURE = 'GET_CHART_OF_ACCOUNT_BOOK_DATA_FAILURE';

// Content Management 
export const GET_CONTENT_MANAGEMENT_TEMPLATE = "GET_CONTENT_MANAGEMENT_TEMPLATE";
export const GET_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS = "GET_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS";
export const GET_CONTENT_MANAGEMENT_TEMPLATE_FAILURE = "GET_CONTENT_MANAGEMENT_TEMPLATE_FAILURE";

export const VIEW_CONTENT_MANAGEMENT_TEMPLATE = "VIEW_CONTENT_MANAGEMENT_TEMPLATE";
export const VIEW_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS = "VIEW_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS";
export const VIEW_CONTENT_MANAGEMENT_TEMPLATE_FAILURE = "VIEW_CONTENT_MANAGEMENT_TEMPLATE_FAILURE";

export const UPDATE_CONTENT_MANAGEMENT_TEMPLATE = "UPDATE_CONTENT_MANAGEMENT_TEMPLATE";
export const UPDATE_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS = "UPDATE_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS";
export const UPDATE_CONTENT_MANAGEMENT_TEMPLATE_FAILURE = "UPDATE_CONTENT_MANAGEMENT_TEMPLATE_FAILURE";

export const AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE = "AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE";
export const AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS = "AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS";
export const AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_FAILURE = "AUDITLOG_CONTENT_MANAGEMENT_TEMPLATE_SUCCESS";

export const GET_BULK_MANAGE_INVOICE = "GET_BULK_MANAGE_INVOICE";
export const GET_BULK_MANAGE_INVOICE_SUCCESS = "GET_BULK_MANAGE_INVOICE_SUCCESS";
export const GET_BULK_MANAGE_INVOICE_FAILURE = "GET_BULK_MANAGE_INVOICE_FAILURE";

export const GET_BULK_CREATE_INVOICE = "GET_BULK_CREATE_INVOICE";
export const GET_BULK_CREATE_INVOICE_SUCCESS = "GET_BULK_CREATE_INVOICE_SUCCESS";
export const GET_BULK_CREATE_INVOICE_FAILURE = "GET_BULK_CREATE_INVOICE_FAILURE";


export const POST_E_INVOICE_GENERATOR = 'POST_E_INVOICE_GENERATOR';
export const POST_E_INVOICE_GENERATOR_SUCCESS = 'POST_E_INVOICE_GENERATOR_SUCCESS';
export const POST_E_INVOICE_GENERATOR_FAILURE = 'POST_E_INVOICE_GENERATOR_FAILURE';


export const GET_DOWNLOAD_UPLOAD_HISTORIES = "GET_DOWNLOAD_UPLOAD_HISTORIES";
export const GET_DOWNLOAD_UPLOAD_HISTORIES_SUCCESS = "GET_DOWNLOAD_UPLOAD_HISTORIES_SUCCESS";
export const GET_DOWNLOAD_UPLOAD_HISTORIES_FAILURE = "GET_DOWNLOAD_UPLOAD_HISTORIES_FAILURE";

export const PROJECT_WISE_SOCIETY_DETAILS = "PROJECT_WISE_SOCIETY_DETAILS";
export const PROJECT_WISE_SOCIETY_DETAILS_SUCCESS = "PROJECT_WISE_SOCIETY_DETAILS_SUCCESS";
export const PROJECT_WISE_SOCIETY_DETAILS_FAILURE = "PROJECT_WISE_SOCIETY_DETAILS_FAILURE";

export const ALL_TRANSACTION_REPORT = "ALL_TRANSACTION_REPORT";
export const ALL_TRANSACTION_REPORT_SUCCESS = "ALL_TRANSACTION_REPORT_SUCCESS";
export const ALL_TRANSACTION_REPORT_FAILURE = "ALL_TRANSACTION_REPORT_FAILURE";

export const DOWNLOAD_FILE_GCS = 'DOWNLOAD_FILE_GCS'
export const DOWNLOAD_FILE_GCS_SUCCESS = "DOWNLOAD_FILE_GCS_SUCCESS"
export const DOWNLOAD_FILE_GCS_FAILURE = "DOWNLOAD_FILE_GCS_FAILURE"
