import React from "react";
import Button from "../Button/Button";
import SocietyFileTable from "../TableComponent/SocietyFileTabel";

const FileModal = ({ isOpen, userId, title, onCancel, onConfirm, headCells, tableData }) => {
    if (!isOpen) return null;

    return (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center mt-10 ml-10  z-20"
         >
            <div className="bg-white p-2.5 rounded-lg " style={{
                border: "0.6px solid #00000038"
            }} >
                <h2 className="text-lg font-semibold mb-2 flex items-center justify-center">
                    {title}
                </h2>
                <div className="">
                    <SocietyFileTable
                        userId ={userId}
                        data={tableData?.map((item, index) => ({
                            FileName: item?.file_name,
                            status: item?.status,
                            Date: item?.created_at,
                            metaData: item?.metadata,
                            filePath: item?.file_path,
                            type: item?.type,
                           
                        }))}
                        columns={headCells}
                    />
                </div>

                <div className="border-b border-[#CCCCCC]"></div>
                <div className="flex justify-center mt-4 gap-4">
                    <Button
                        label="Cancel"
                        onClick={onCancel}
                        className="py-2 font-semibold h-9 bg-red"
                        type="button"
                    />
                </div>
            </div>
        </div>
    );
};

export default FileModal;
