import React, { useEffect, useState } from "react";
import { Link, createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    APP_DOWNLOAD_REPORT_ENDPOINT,
    PRINT_APP_DOWNLOAD_REPORT_ENDPOINT
} from "../../../Routing/routes";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ListHeader from "../../../components/ListHeader/ListHeader";
import { useDispatch, useSelector } from "react-redux";
import { downloadFamilyMembersDetailsList, getFamilyDetailsList } from "../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { downloadExcel } from "../../../utils/helpers/downloadMedia";
import FamilyInfoListTable from "../../../components/TableComponent/FamilyInfoListTable";
import ButtonIco from "../../../components/Button/ButtonPrint";
import { DownloadExt, PrinterIcon } from "../../../assets";
import { appDownloadReportsXl, getAppDownloadReports } from "../../../redux/actions/ReportActions/SocietyReportActions";
import AppDownloadReportsTable from "./AppDownloadReportsTable";
import ButtonG from "../../../components/Button/ButtonG";

const headCells = [
    { id: "sNo", label: "Sr. No.", sort: false, width: "60px" },
    { id: "name", label: "Name", sort: true, width: "170px" },
    { id: "email", label: "Email", sort: true, width: "220px" },
    { id: "mobile", label: "Contact no.", sort: true, width: "100px" },
    { id: "flat_no", label: "Flat", sort: true, width: "" },
    { id: "user_type", label: "User Type", sort: true },
    { id: "download", label: "Download Status", sort: true },
];


const AppDownloadReports = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { appDownloadReport, loading, error } = useSelector((state) => state.societyReportReducer)
    const { loginData } = useSelector(state => state.loginReducer)


    const handleDownloadExcel = () => {
        const onSucessCallback = (data) => {
            downloadExcel({ data, fileName: 'app_download_report.xlsx' })
        }
        const data = {
            societyId: loginData?.default_community?.community_id,
            onSucessCallback
        }
        dispatch(appDownloadReportsXl(data))
    }

    const handleClear = () => {
        navigate(APP_DOWNLOAD_REPORT_ENDPOINT)
    }

    const handlePrintData = () => {
        navigate({
            pathname: `${PRINT_APP_DOWNLOAD_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([...searchParams.entries()])}`,
        });
    };

    const getTableData = () => {
        const data = {
            societyId: loginData?.default_community?.community_id,
            page: searchParams.get("page") || 1,
            pageLimit: searchParams.get("pageLimit") || 10,
        }
        dispatch(getAppDownloadReports(data))
    }

    useEffect(() => {
        getTableData()
    }, [searchParams])

    useEffect(() => {
        getTableData()
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="App Download Report">
                        <ButtonG label='Clear' className='h-8' onClick={handleClear} />
                        <button
                            type="button"
                            onClick={handlePrintData}
                            className="flex text-red-650 border border-red-650 bg-white text-xs px-2 font-semibold items-center whitespace-nowrap rounded-lg h-8"
                        >
                            <PrinterIcon className="mr-2 fill-red-650" /> Print
                        </button>
                        <div className="flex text-sm gap-2">
                            <ButtonIco
                                icon={DownloadExt}
                                children="Download Excel"
                                className="h-8"
                                type='button'
                                onClick={handleDownloadExcel}
                            />
                        </div>
                    </ListHeader>
                </div>
                <div className="ml-1">
                    <AppDownloadReportsTable
                        loading={loading}
                        data={appDownloadReport?.appDownloadReports?.map((item, index) => ({
                            sNo: (((appDownloadReport?.current_page - 1) * appDownloadReport?.page_limit + 1) + index)?.toString()?.padStart(2, "0"),
                            id: index,
                            name: item?.name || '-',
                            email: item?.email || '-',
                            mobile: item?.contact_no || '-',
                            flat_no: item?.flat_no,
                            user_type: item?.user_type,
                            download: item?.Download || '-'
                        }))}
                        columns={headCells}
                        checkbox={false}
                        pagination={appDownloadReport?.appDownloadReports?.length}
                        totalCount={appDownloadReport?.total_count}
                        totalPages={appDownloadReport?.total_pages}
                        start={(appDownloadReport?.current_page - 1) * appDownloadReport?.page_limit + 1}
                        end={(appDownloadReport?.current_page - 1) * appDownloadReport?.page_limit + appDownloadReport?.appDownloadReports?.length}
                        currentPage={parseInt(appDownloadReport?.current_page)}
                        rowsPerPage={searchParams.get("pageLimit") || 10}
                        getTableData={getTableData}
                    >
                    </AppDownloadReportsTable>
                </div>

                {!appDownloadReport?.appDownloadReports?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default AppDownloadReports;
