import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  LOG_A_COMPLAINT_ENDPOINT,
  SOCIETY_PROFILE_ENDPOINT,
} from "../../../Routing/routes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown, faChevronUp } from "@fortawesome/free-solid-svg-icons";
import Layout from "../../Layout/Layout";
import { SocietyProfileAuditData } from "../../../consts/SocietyProfileAuditData";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useDispatch, useSelector } from "react-redux";
import { getSocietyProfileAuditLogs } from "../../../redux/actions/SetupRecordsActions/BuildingActions/SocietyProfileAction";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import parse from "html-react-parser";

import { logAComplaintAuditLog } from "../../../redux/actions/DeskActions/LogaComplaintActions";
const list = [
  { label: "Ticket Nature", id: "nature" },
  { label: "Ticket Type", id: "type" },
  { label: "Category", id: "category_name" },
  { label: "Sub Category", id: "subcategory_name" },
  { label: "Is Urgent ?", id: "isUrgent" },
  { label: "Description", id: "description" },
  { label: "(Wing No. - Flat No.)", id: "wing_flat" },
  { label: "Status", id: "status" },
];
const status = {
  P: "In-Progress",
  R: "Re-Open",
  H: "On-Hold",
  I: "Closed",
  A: "Open",
  T: "Transfer"
};
const Accordion = React.memo(
  ({ data, isOpen, toggleAccordion, label, descr, desc }) => {
    const oldData = data?.old_nominee_details;
    const newData = data?.new_nominee_details;

    return (
      <div className="border rounded-lg border-[#CCCCCC] my-2 overflow-hidden">
        <div
          className="flex justify-between cursor-pointer items-center bg-[#FDF2F3] rounded-t-lg p-3 border-b "
          onClick={toggleAccordion}
        >
          <div className="flex items-center">
            <button className="text-white text-sm font-semibold mr-3 w-5 h-5 bg-red-650 border rounded flex justify-center items-center">
              {isOpen ? (
                <FontAwesomeIcon icon={faChevronUp} size="xs" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="xs" />
              )}
            </button>
            <div className="text-[#000000] text-sm font-semibold">{label}</div>
          </div>
          <div>
            <div className="text-xs text-right text-[#888]">{desc}</div>
            <div className="text-xs text-right text-[#888]">{descr}</div>
          </div>
        </div>
        {isOpen && (
          <div className="p-3">
            <div className="flex justify-between p-2 border-b ">
              <div className="flex justify-between w-full text-sm">
                <div className="w-1/2"></div>
                <div className="w-1/2 font-semibold">Old Data</div>
                <div className="w-1/2 font-semibold">New Data</div>
              </div>
            </div>
            {list.map((item, index) => (
              <React.Fragment key={item + index}>
                {/* {item.label ? (
                  <div className="acord-section font-semibold text-[#222222] p-2 text-sm">
                    {item.label}
                  </div>
                ) : null} */}
                <div className="flex justify-between p-2">
                  <div
                    className="flex justify-between w-full text-sm"
                    key={index}
                  >
                    <div className="w-1/2 p-2">{item?.label}</div>
                    <div className="w-1/2 p-2">
                      {oldData?.[item?.id]
                        ? item?.id == "status"
                          ? status?.[oldData?.[item?.id]]
                          : item?.id == "description"
                          ? parse("<span>" + oldData?.[item?.id] + "</span>")
                          : oldData?.[item?.id]
                        : "-"}
                    </div>
                    <div className="w-1/2 p-2">
                      {newData?.[item?.id]
                        ? item?.id == "status"
                          ? status?.[newData?.[item?.id]]
                          : item?.id == "description"
                          ? parse("<span>" + newData?.[item?.id] + "</span>")
                          : newData?.[item?.id]
                        : "-"}
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
    );
  }
);

const LogAComplaintAuditLogs = () => {
  const navigate = useNavigate();
  const [openAccordionIndex, setOpenAccordionIndex] = useState(null);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, logAComplaintAuditLogData } = useSelector(
    (state) => state.logaComplaintReducer
  );

  const handleNavigation = (path) => () => {
    navigate(path);
  };

  const toggleAccordion = (index) => {
    setOpenAccordionIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  useEffect(() => {
    const sendRequest = {
      societyId: loginData?.default_community?.community_id,
    };
    dispatch(logAComplaintAuditLog(sendRequest));
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeaderBack
          onClick={() => navigate(-1)}
          title="Complaints Audit Logs"
        ></ListHeaderBack>
        <div className="border-b border-[#CCCCCC]"></div>
        <div>
          {loading && !logAComplaintAuditLogData ? (
            <Loader />
          ) : (
            logAComplaintAuditLogData &&
            logAComplaintAuditLogData?.map((item, index) => {
              return (
                <Accordion
                  label={`Audit ${index + 1} (${item?.audit}) ${
                    item?.new_nominee_details?.ticket
                  }`}
                  key={index}
                  data={item}
                  isOpen={index === openAccordionIndex}
                  toggleAccordion={() => toggleAccordion(index)}
                  desc={`Modified By Type ${item?.modified_type} On ${moment(
                    new Date(item?.modified_dateTime)
                  )?.format("MMM DD, YYYY hh:mm A")}`}
                  descr={`IP Address: ${item?.IpAddress} Session ID - ${item?.modified_sessionId}`}
                />
              );
            })
          )}
        </div>
      </div>
    </Layout>
  );
};

export default LogAComplaintAuditLogs;
