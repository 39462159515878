import React, { useEffect, useState } from "react";
import { Link, createSearchParams, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
    ADD_FROM_EXISITING_OCCUPANT_ENDPOINT,
    ADD_OCCUPANTS_ENDPOINT,
    ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT,
    BULK_UPLOAD_Family_ENDPOINT,
    EDIT_FAMILY_MEMBERS_ENDPOINT,
    FAMILY_INFO_ENDPOINT,
    VIEW_FAMILY_MEMBERS_ENDPOINT,
} from "../../../../Routing/routes";
import Search from "../../../../components/SearchBox/Search";
import { DeleteIcon, DownloadExt, EditIcon, ViewIcon } from "../../../../assets";
import Button from "../../../../components/Button/Button";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import Layout from "../../../Layout/Layout";
import OccupantInfoTable from "../../../../components/TableComponent/OccupantInfoTable";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { useDispatch, useSelector } from "react-redux";
import { deleteFamilyMember, downloadFamilyMembersDetailsList, downloadOccupantsDetailsList, getFamilyDetailsList, getOccupantsDetailsList, getTitleList } from "../../../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/OccupantsInfoAction";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import moment from "moment";
import { getFlatDetails, getWingsAndFlatDetails } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import ButtonG from "../../../../components/Button/ButtonG";
import GenericTable from "../../../../components/TableComponent/Table";
import DeleteModal from "../../../../components/Modal/DeleteModal";
import { Box, Tooltip } from "@mui/material";
import { getFamilyRolesList } from "../../../../redux/actions/SetupRecordsActions/ManageRolesAndRightsActions/ManageRolesAndRightsAction";
import FamilyInfoListTable from "../../../../components/TableComponent/FamilyInfoListTable";

const headCells = [
    { id: "sNo", label: "Sr. No.", sort: false, width: "60px" },
    { id: "roleAssign", label: "Role Assign", sort: true, width: "100px" },
    { id: "name", label: "Name", sort: true, width: "120px" },
    { id: "flat_no", label: "Flat No.", sort: true, width: "100px" },
    { id: "mobile", label: "Mobile", sort: true, width: "" },
    { id: "email", label: "Email", sort: true },
    { id: "gender", label: "Gender", sort: true },
    { id: "relationship", label: "Relationship", sort: true },
    {
        id: "action",
        label: "Action",
        sort: false,
    },
];


const FamilyInfo = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    const navigate = useNavigate();
    const dispatch = useDispatch()
    const { wingsData, flatsData } = useSelector(state => state.wingsReducer)
    const { familyRoleList } = useSelector(state => state.manageRoleAndRightsReducer)
    const { loading, familyList } = useSelector(state => state.occupantInfoReducer)
    const { loginData } = useSelector(state => state.loginReducer)


    const handleSearch = (text) => {
        if (text && text !== searchParams.get("search")) {
            searchParams.delete('page')
            searchParams.delete('pageLimit')
            searchParams.delete("search")
            navigate({
                pathname: `${FAMILY_INFO_ENDPOINT}`,
                search: `?${createSearchParams([...searchParams.entries(), ["search", text?.trim()]])}`,
            })
        }
    }

    const handelSelectTitle = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("titleId")) {
            searchParams.delete('page')
            searchParams.delete('pageLimit')
            searchParams.delete("titleId")
            navigate({
                pathname: `${FAMILY_INFO_ENDPOINT}`,
                search: `?${createSearchParams([...searchParams.entries(), ["titleId", selectedItem.value]])}`,
            })
        }
    }

    const handelSelectWing = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
            dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: selectedItem?.value }))
            searchParams.delete('page')
            searchParams.delete('pageLimit')
            searchParams.delete("wingId")
            searchParams.delete("flatId")
            navigate({
                pathname: `${FAMILY_INFO_ENDPOINT}`,
                search: `?${createSearchParams([...searchParams.entries(), ["wingId", selectedItem.value]])}`,
            })
        }
    }

    const handelSelectFlat = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
            searchParams.delete('page')
            searchParams.delete('pageLimit')
            searchParams.delete("flatId")
            navigate({
                pathname: `${FAMILY_INFO_ENDPOINT}`,
                search: `?${createSearchParams([...searchParams.entries(), ["flatId", selectedItem.value]])}`,
            })
        }
    }

    const handleDownloadExcel = () => {
        const onSucessCallback = (data) => {
            downloadExcel({ data, fileName: 'family_members_sheet.xlsx' })
        }
        const data = {
            societyId: loginData?.default_community?.community_id,
            searchText: searchParams.get("search") || '',
            titleId: searchParams.get("titleId") || '',
            wingId: searchParams.get("wingId") || '',
            flatId: searchParams.get("flatId") || '',
            onSucessCallback
        }
        dispatch(downloadFamilyMembersDetailsList(data))
    }

    const handleClear = () => {
        navigate(FAMILY_INFO_ENDPOINT)
    }

    const getTableData = () => {
        const data = {
            societyId: loginData?.default_community?.community_id,
            page: searchParams.get("page") || 1,
            searchText: searchParams.get("search") || '',
            pageLimit: searchParams.get("pageLimit") || 10,
            titleId: searchParams.get("titleId") || '',
            wingId: searchParams.get("wingId") || '',
            flatId: searchParams.get("flatId") || '',
        }
        dispatch(getFamilyDetailsList(data))
    }

    useEffect(() => {
        getTableData()
    }, [searchParams])

    useEffect(() => {
        getTableData()
        dispatch(getWingsAndFlatDetails({ societyId: loginData?.default_community?.community_id }))
        dispatch(getFamilyRolesList({ societyId: loginData?.default_community?.community_id }))
        if (searchParams.get("wingId")) {
            dispatch(getFlatDetails({ societyId: loginData?.default_community?.community_id, wingId: searchParams.get("wingId") }))
        }
    }, [])

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Family Info">
                        <div className="flex text-sm gap-2">
                            {/* <Link
                                to={ARCHIVE_MEMBERS_OCCUPANTS_ENDPOINT}
                                className="text-white"
                            >
                                <Button label="Archive Members" className="h-8" />
                            </Link>
                            <div className="border-r border-[#CCC]"></div>
                            <Link to={ADD_OCCUPANTS_ENDPOINT} className="text-white">
                                <Button label="Add Occupant" className="h-8" />
                            </Link> */}
                            <Link
                                to={BULK_UPLOAD_Family_ENDPOINT}
                                className="text-white"
                            >
                                <Button label="Bulk Upload Family" className="h-8" />
                            </Link>
                        </div>
                    </ListHeader>
                </div>
                <div className="p-1 flex justify-between gap-2 mt-1 mb-1">
                    <Search width="36" height="8" onclick={handleSearch} value={searchParams.get("search")} />
                    <div className="flex justify-between items-center gap-2">
                        {/* <p className="text-[13px]">Filter</p> */}
                        <Dropdown2
                            options={familyRoleList?.map((item) => ({
                                value: item?.family_role_id,
                                label: item?.title
                            })) || []}
                            placeholder="Select Title"
                            className="text-[11px] ms-4"
                            width="180px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handelSelectTitle(selectedItem)
                            }}
                            value={
                                searchParams.get("titleId") && {
                                    value: searchParams.get("titleId"),
                                    label: familyRoleList?.find((item) => item.family_role_id?.toString() === searchParams.get("titleId"))?.title
                                }
                            }
                        />
                        <Dropdown2
                            options={wingsData?.map((item) => ({
                                value: item?.wing_id,
                                label: item?.number
                            })) || []}
                            placeholder="Select Wing"
                            className="text-[11px] ms-4"
                            width="180px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handelSelectWing(selectedItem)
                            }}
                            value={
                                searchParams.get("wingId") && {
                                    value: searchParams.get("wingId"),
                                    label: wingsData?.find((item) => item.wing_id?.toString() === searchParams.get("wingId"))?.number
                                }
                            }
                        />
                        <Dropdown2
                            options={searchParams.get('wingId') && flatsData?.manage_flat_details?.map((item) => ({
                                value: item?.flat_id,
                                label: item?.flat_no
                            })) || []}
                            placeholder="Select Flat"
                            className="text-[11px] ms-4"
                            width="180px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handelSelectFlat(selectedItem)
                            }}
                            value={
                                searchParams.get("flatId") && {
                                    value: searchParams.get("flatId"),
                                    label: flatsData?.manage_flat_details?.find((item) => item.flat_id?.toString() === searchParams.get("flatId"))?.flat_no
                                }
                            }
                        />
                        <ButtonG label='Clear' className='h-8' onClick={handleClear} />

                        <div className="border-r border-[#CCC] h-full"></div>
                        <div className="flex gap-2">
                            <ButtonIco
                                icon={DownloadExt}
                                children="Download Excel"
                                className="h-8"
                                type='button'
                                onClick={handleDownloadExcel}
                            />
                        </div>
                    </div>
                </div>
                <div className="ml-1">
                    <FamilyInfoListTable
                        loading={loading}
                        data={familyList?.family_member_list?.map((item, index) => ({
                            sNo: (((familyList?.current_page - 1) * familyList?.page_limit + 1) + index)?.toString()?.padStart(2, "0"),
                            id: item?.id,
                            flatId: item?.memberid,
                            flat_no: item?.flat_no,
                            roleAssign: item?.family_role || '-',
                            name: item?.name || '-',
                            mobile: item?.contact_no || '-',
                            email: item?.email || '-',
                            gender: item?.gender || '-',
                            relationship: item?.relationship || '-',
                            userId: item?.userId || '-',
                            subscribeId: item?.subscribeId || '-',
                        })
                        )}
                        columns={headCells}
                        checkbox={false}
                        pagination={familyList?.family_member_list?.length}
                        totalCount={familyList?.total_count}
                        totalPages={familyList?.total_pages}
                        start={(familyList?.current_page - 1) * familyList?.page_limit + 1}
                        end={(familyList?.current_page - 1) * familyList?.page_limit + familyList?.family_member_list?.length}
                        currentPage={parseInt(familyList?.current_page)}
                        rowsPerPage={searchParams.get("pageLimit") || 10}
                        getTableData={getTableData}
                    >
                    </FamilyInfoListTable>
                </div>

                {!familyList?.family_member_list?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    );
};

export default FamilyInfo;
