import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../Layout/Layout";
import ListHeaderBack from "../../../components/ListHeader/ListHeaderBack";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import TimeSlotTable from "./TimeSlotTable";
import TimeSlotHourlyTable from "./TimeSlotHourlyTable";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { VIEW_TIME_SLOT_TYPE_ENDPOINT } from "../../../Routing/routes";
import { Form, Formik } from "formik";
import { AddTimeSlotValidation } from "../../../validationSchema/ClubSchema/AddTimeSlotSchema";
import { useDispatch } from "react-redux";
import { getFacilityList, updateTimeSlot } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useSelector } from "react-redux";
import { getTimeSlotTypeList } from "../../../redux/actions/ClubActions/AddTimeSlotAction";
import { CircularProgress } from "@mui/material";


export const rows = [];

const AddTimeSlot = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loginData } = useSelector(state => state.loginReducer)
  const { facilityList } = useSelector(state => state.bookAFacilityReducer)
  const { updateLoader } = useSelector(state => state.addTimeSlotReducer)

  const handleSubmit = (values, { resetForm }) => {
    if (values.timeSlot?.length > 0 || values.timeSlotHourly?.length > 0)
      dispatch(updateTimeSlot({ ...values, onSuccessCallback: () => { resetForm() } }))
  }

  const facilityOptions = useMemo(() => {
    return facilityList?.facilty_list
      ?.filter((item) => item.booking === "Applicable")
      ?.map((item) => ({
        label: item.facilities_name,
        value: item.id,
      }));
  }, [facilityList]);

  useEffect(() => {
    dispatch(getFacilityList({ societyId: loginData?.default_community?.community_id }))
    dispatch(getTimeSlotTypeList({ societyId: loginData?.default_community?.community_id }))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            facilityId: '',
            chargeOn: '',
            timeSlot: [{
              type: '',
              ticket: '',
              startTime: '',
              endTime: '',
              frequency: '',
              selectedDays: []
            }],
            timeSlotHourly: [{
              type: '',
              ticket: '',
              lessThan: '',
              hours: '',
            }]
          }}
          validationSchema={AddTimeSlotValidation}
          onSubmit={handleSubmit}
        >
          {
            ({ values, errors, setFieldValue }) => (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeaderBack
                    title="Add Time Slot"
                    onClick={() => {
                      navigate(-1);
                    }}
                  >
                    <ButtonG label="Cancel" type='buton' className="h-8" />
                    <Button label={
                      <span className="w-16 inline-block">
                        {
                          updateLoader ?
                            <CircularProgress sx={{ color: "white" }} size={17} />
                            :
                            <>Add</>
                        }
                      </span>
                    }
                      className="h-8" />
                  </ListHeaderBack>
                </div>
                <div className="flex justify-between items-center">
                  <div className="p-2 flex w-[35%] justify-between">
                    <div className="text-sm mt-2">Facility Name</div>
                    <div className="">
                      <DropdownFM2
                        options={facilityOptions}
                        placeholder="Select Facility"
                        className="text-[11px] ms-4"
                        width="192px"
                        height="32px"
                        onSelect={(selectedItem) => {
                          const selectedFacility = facilityList?.facilty_list?.find(item => item.id === selectedItem?.value);
                          const chargeOnValue = selectedFacility?.charge_on || '';
                          setFieldValue('facilityId', selectedItem.value)
                          setFieldValue('chargeOn', chargeOnValue)

                          const updateTicket = (array) =>
                            array.map((slot) => ({
                              ...slot,
                              ticket: chargeOnValue === "day" ? 1 : "",
                            }));

                          setFieldValue("timeSlot", updateTicket(values.timeSlot));
                          setFieldValue("timeSlotHourly", updateTicket(values.timeSlotHourly));
                          setFieldValue('rateChart', [{}])
                        }}
                        {...(values.facilityId === "" && { value: null })}
                        name='facilityId'
                      />
                    </div>
                  </div>
                  <Button label="Time Slot Type" className="h-8" onClick={() => { navigate(VIEW_TIME_SLOT_TYPE_ENDPOINT) }} />
                </div>
                <div className="border border-[#CCC] rounded-lg mt-2 min-h-[300px]">
                  <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                    Time Slot
                  </div>
                  <div className="mx-2">
                    <TimeSlotTable
                      values={values}
                      setFieldValue={setFieldValue}
                    ></TimeSlotTable>
                  </div>
                </div>

                <div className="border border-[#CCC] rounded-lg mt-2 min-h-[300px]">
                  <div className="border-b p-3 text-sm font-semibold bg-[#FDF2F3] flex justify-between items-center">
                    Time Slot Hourly
                  </div>
                  <div className="mx-2">
                    <TimeSlotHourlyTable
                      values={values}
                      setFieldValue={setFieldValue}
                    ></TimeSlotHourlyTable>
                  </div>
                </div>
              </Form>
            )
          }
        </Formik>
      </div>
    </Layout>
  );
};

export default AddTimeSlot;
