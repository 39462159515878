import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance2 from "../../../axiosInstance2";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

export async function generateInvoiceReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    invIds: action.data.invIds,
    is_draft: action.data.is_draft,
    status: action.data.status,
    ...action.data.invoiceData,
  };
  return axiosInstance2
    .post(`${apiEndpoints.GENERATE_INVOICE_API}`, { ...BODY })

    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function invoiceGeneratorListReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    from_date: action.data.from_invoice,
    to_date: action.data.to_invoice,
    invoice_date: action.data.invoice_date,
    payment_due_date: action.data.payment_due_date,
    debitnote_include_as_arrear: action.data.debitnote_include_as_arrear,
    generate: action.data.generate,
    flat_no: action.data.flat_no,
    typeid: action.data.invoice_type,
  };
  //   action.data.flat_no?.forEach((item, index) => {
  //     BODY[`flat_no[${index}]`] = item;
  //   });

  return axiosInstance2
    .post(apiEndpoints.INVOCIE_GENERATOR_LIST_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getBillPeriodReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    typeid: action.data.invoiceType,
    from_date: action.data.from_date,
  };

  return axiosInstance2
    .post(apiEndpoints.GET_BILL_PERIOD_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getCreditNoteAdjustmentReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    flatid: action.data.flatid,
    invoice_date: action.data.invoice_date,
    invoiceid: action.data.invoiceid,
    typeid: action.data.typeid,
  };

  return axiosInstance2
    .post(apiEndpoints.GET_CREDIT_NOTE_ADJUSTMENT_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function getDebitNoteAdjustmentReq(action) {
  const BODY = {
    pageid: action.data.societyId,
    flatid: action.data.flatid,
    invoice_date: action.data.invoice_date,
    memberid: action.data.memberid,
  };

  return axiosInstance2
    .post(apiEndpoints.GET_DEBIT_NOTE_ADJUSTMENT_API, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function downloadInvoiceExcelReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    typeid: action.data?.invoiceType,
  };

  return axiosInstance2
    .post(
      `${apiEndpoints.DOWNLOAD_INVOICE_EXCEL_API}`,
      { ...BODY },
      {
        responseType: "arraybuffer",
      }
    )
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}

export async function detailsGenerateInvoiceReq(action) {
  var bodyFormData = new FormData();
  bodyFormData.append("pageid", action.data?.societyId);
  bodyFormData.append("typeid", action.data?.invoiceType);
  bodyFormData.append("debitarrearinclude", action.data?.includeDebitNoteArrear);
  bodyFormData.append("excel", action.data?.file);
  return axiosInstance2
    .post(`${apiEndpoints.UPLOAD_INVOICE_EXCEL_FORMAT_API}`, bodyFormData)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        (errors.response?.data.success === true &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(
            errors.response?.data.message
          )) ||
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.error)
      ) {
        logOut();
      } else if (errors.response?.data.success === false) {
        notify("error", errors.response.data.message);
      } else {
        notify("warning", "Something went wrong");
      }
      return errors;
    });
}