export function descendingComparator(a, b, orderBy) {
    if (typeof a[orderBy] === 'string' && typeof b[orderBy] === 'string') {
        if (b[orderBy]?.toLowerCase() < a[orderBy]?.toLowerCase()) {
            return -1;
        }
        if (b[orderBy]?.toLowerCase() > a[orderBy]?.toLowerCase()) {
            return 1;
        }
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
    }
    return 0;
}

export function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

export const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) {
            return order;
        }
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export function onlyNumberKey(evt) {
    const charCode = evt.charCode;
    function isNumeric(charCode) {
        return (
            (charCode >= 48 && charCode <= 57)
        );
    }

    if (!isNumeric(charCode)) {
        evt.preventDefault();
    }
}

export function pasteOnlyNumberKey(evt) {
    var clipboardData, pastedData;

    clipboardData = evt.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    if (pastedData?.match(/[^\d]/)) {
        evt.preventDefault();
    }
}

export function onlyAlphaNumberKey(evt) {
    const charCode = evt.charCode;
    function isNumeric(charCode) {
        return (
            ((charCode >= 48 && charCode <= 57) || (charCode >= 65 && charCode <= 90) || (charCode >= 97 && charCode <= 122))
        );
    }

    if (!isNumeric(charCode)) {
        evt.preventDefault();
    }
}

export function pasteOnlyAlphaNumberKey(evt) {
    var clipboardData, pastedData;

    clipboardData = evt.clipboardData || window.clipboardData;
    pastedData = clipboardData.getData('Text');

    if (!pastedData?.match(/^[a-zA-Z0-9]*$/)) {
        evt.preventDefault();
    }
}


export function onlyDecimalKey(evt) {
    const charCode = evt.charCode;
    function isDecimal(charCode) {
        if ((!evt.target.value || evt.target.value?.includes('.')) && charCode === 46)
            return false

        return (
            ((charCode >= 48 && charCode <= 57) || charCode === 46)
        );
    }

    if (!isDecimal(charCode)) {
        evt.preventDefault();
    }
}

export function pasteOnlyDecimalKey(evt) {
    var clipboardData, pastedData;

    clipboardData = evt.clipboardData || window?.clipboardData;
    pastedData = clipboardData.getData('Text');

    if (!pastedData?.match(/^[0-9]+(\.[0-9]+)?$/)) {
        evt.preventDefault();
    }
}

export function onlyPhoneKey(evt) {
    const charCode = evt.charCode;
    function isPhoneKey(charCode) {
        if ((evt.target.value?.includes('+') && charCode === 43) || (evt.target.value?.includes('-') && charCode === 45))
            return false

        return (
            ((charCode >= 48 && charCode <= 57) || charCode === 43 || charCode === 45)
        );
    }

    if (!isPhoneKey(charCode)) {
        evt.preventDefault();
    }
}


export function removeZeroValues(obj) {
    for (const key in obj) {
        if (typeof obj[key] === 'object' && !Array.isArray(obj[key]) && obj[key] !== null) {
            obj[key] = removeZeroValues(obj[key]);
            if (Object.keys(obj[key]).length === 0) {
                delete obj[key];
            }
        } else if (obj[key] === 0) {
            delete obj[key];
        }
    }
    return obj;
}

export function validateDate(date) {
    if (date === null)
        return false;

    let d = new Date(date);
    return (d instanceof Date && !isNaN(d.valueOf()))
}

export function addPadZero(n) {
    return n?.toString()?.padStart(2, '0')
}

export function getOrdinal(number) {
    if (typeof number !== 'number' || isNaN(number)) {
        return number;
    }

    const suffixes = ['th', 'st', 'nd', 'rd'];
    const remainder = number % 100;
    const suffix = suffixes[(remainder - 20) % 10] || suffixes[remainder] || suffixes[0];

    return number + suffix;
}

export function calculateHourDifference(from, to) {
    console.log(to?.slice(0, 2), to?.slice(3))
    var startTime = new Date();
    startTime.setHours(from?.slice(0, 2), from?.slice(3), 0);

    var endTime = new Date();
    endTime.setHours(to?.slice(0, 2), to?.slice(3), 0);

    var timeDifference = endTime.getTime() - startTime.getTime();

    var hoursDifference = timeDifference / (1000 * 60 * 60);
    return hoursDifference
}

export function addHoursInTimeString(timeString, noOfHours) {
    var splitedTimeStr = timeString.split(':');
    var hours = parseInt(splitedTimeStr[0]);
    var minutes = splitedTimeStr[1].substring(0, 2);
    var nextHours = (hours + noOfHours) % 24;
    if (nextHours.toString().length == 1) {
        nextHours = "0" + nextHours;
    }
    return nextHours + ":" + minutes;
}

export function addHoursMinutesInTimeString(timeString, noOfHours) {
    var splitedTimeStr = timeString.split(':');
    var hours = parseInt(splitedTimeStr[0]);
    var minutes = splitedTimeStr[1].substring(0, 2);
    var splitedTimeStr2 = noOfHours.split(':');
    var hours2 = parseInt(splitedTimeStr2[0]);
    var minutes2 = splitedTimeStr2[1].substring(0, 2);

    if (parseInt(minutes) + parseInt(minutes2) > 45) {
        hours = hours + 1;
        minutes = (parseInt(minutes) + parseInt(minutes2) - 60).toString().padStart(2, 0);
    }
    else {
        hours = hours + hours2;
        minutes = (parseInt(minutes) + parseInt(minutes2)).toString().padStart(2, 0);
    }

    var nextHours = ((hours) % 24).toString().padStart(2, 0);
    // if (nextHours.toString().length == 1) {
    //     nextHours = "0" + nextHours;
    // }
    return nextHours + ":" + minutes;
}


export function getDateObjectFromTimeString(time) {
    const [hours, minutes] = time.split(':').map(Number);

    if (time) {
        const date = new Date();
        date.setHours(hours);
        date.setMinutes(minutes);
        date.setSeconds(0);
        date.setMilliseconds(0);
        return (date);
    }
    return null
}

export function checkFileSizeLessThan2(file) {
    if (typeof file === 'object')
        return file?.size < 2097152
    return true
}

export const encodeToBase64 = (str) => {
    const uint8Array = new TextEncoder().encode(str);
    let binary = '';
    uint8Array.forEach((byte) => {
        binary += String.fromCharCode(byte);
    });
    return btoa(binary);
};


// Function to calculate the distance between two points on the Earth in meters
export const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371e3; // Radius of the Earth in meters
    const φ1 = lat1 * (Math.PI / 180); // Convert latitude to radians
    const φ2 = lat2 * (Math.PI / 180); // Convert latitude to radians
    const Δφ = (lat2 - lat1) * (Math.PI / 180); // Difference in latitude
    const Δλ = (lon2 - lon1) * (Math.PI / 180); // Difference in longitude

    const a =
        Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // Return distance in meters
}

// Function to check if the user is within 100 meters
export const isWithin100Meters = (userLat, userLon, targetLat, targetLon) => {
    const distance = calculateDistance(parseFloat(userLat).toFixed(2), parseFloat(userLon).toFixed(2), targetLat.toFixed(2), targetLon.toFixed(2));
    return distance <= 150; // Check if distance is less than or equal to 100 meters
}
