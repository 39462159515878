import React, { useEffect, useState } from 'react';
import Layout from '../../Layout/Layout';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Search from "../../../components/SearchBox/Search";
import ListHeader from '../../../components/ListHeader/ListHeader';
import ButtonIco from '../../../components/Button/ButtonPrint';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import Dropdown2 from '../../../components/Dropdown/dropdown2';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { SOCIETY_EMAIL_REPORT_ENDPOINT } from '../../../Routing/routes';
import { getFlatDetails, getWingsAndFlatDetails } from '../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction';
import RangeDatePicker from '../../../components/DatePicker/RangeDatePicker';
import moment from 'moment';
import ButtonG from '../../../components/Button/ButtonG';
import { downloadSocietyEmailReport, getSocietyEmailReport } from '../../../redux/actions/ReportActions/SocietyReportActions';
import { downloadFileFromURL } from '../../../utils/helpers/downloadMedia';
import SocietyEmailReportsTable from './SocietyEmailReportsTable';
import { CircularProgress } from '@mui/material';
import { DownloadExt } from '../../../assets';

export const headCells = [
    { id: "sNo", label: "Sr. No.", width: "50px" },
    { id: "email", label: "Email", width: "200px" },
    { id: "subject", label: "Subject", sort: true, width: "170px" },
    { id: "flat", label: "Flat", sort: true, width: "100px" },
    { id: "amountDue", label: "Date(From-To)", sort: true, width: "170px" },
    { id: "status", label: "Status", sort: true, width: "170px" },
    { id: "", label: "", sort: false, width: "50px" },
];

const SocietyEmailReports = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
    const { societyEmailReport, loading, error } = useSelector((state) => state.societyReportReducer)
    const { downLoader } = useSelector((state) => state.downloadSocietyReportReducer)
    const navigate = useNavigate();

    const handleSearch = (text) => {
        if (text && text !== searchParams.get("searchBy")) {
            searchParams.delete("page");
            searchParams.delete("searchBy");
            navigate({
                pathname: SOCIETY_EMAIL_REPORT_ENDPOINT,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["searchBy", text?.trim()],
                ])}`,
            });
        } else {
            searchParams.delete("searchBy");
            navigate({
                pathname: SOCIETY_EMAIL_REPORT_ENDPOINT,
                search: `?${createSearchParams([...searchParams.entries()])}`,
            })
        }
    };

    const handleSelectWing = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("wingId")) {
            dispatch(
                getFlatDetails({
                    societyId: loginData?.default_community?.community_id,
                    wingId: selectedItem?.value,
                })
            );
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("wingId");
            searchParams.delete("flatId");
            navigate({
                pathname: `${SOCIETY_EMAIL_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["wingId", selectedItem.value],
                ])}`,
            });
        }
    };

    const handleSelectFlat = (selectedItem) => {
        if (selectedItem.value?.toString() !== searchParams.get("flatId")) {
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("flatId");
            navigate({
                pathname: `${SOCIETY_EMAIL_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["flatId", selectedItem.value],
                ])}`,
            });
        }
    };

    const handelSelectStatus = (selectedItem) => {
        searchParams.delete("page");
        searchParams.delete("status");

        navigate({
            pathname: `${SOCIETY_EMAIL_REPORT_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["status", selectedItem.value],
            ])}`,
        });
    };

    const handleStartDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !==
            searchParams.get("startDate")
        ) {
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("startDate");
            navigate({
                pathname: `${SOCIETY_EMAIL_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["startDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };

    const handleEndDate = (selectedDate) => {
        if (
            moment(selectedDate).format("YYYY-MM-DD") !== searchParams.get("endDate")
        ) {
            searchParams.delete("page");
            searchParams.delete("pageLimit");
            searchParams.delete("endDate");
            navigate({
                pathname: `${SOCIETY_EMAIL_REPORT_ENDPOINT}`,
                search: `?${createSearchParams([
                    ...searchParams.entries(),
                    ["endDate", moment(selectedDate).format("YYYY-MM-DD")],
                ])}`,
            });
        }
    };

    const handleClear = () => {
        navigate(`${SOCIETY_EMAIL_REPORT_ENDPOINT}`);
    };

    useEffect(() => {
        let societyId = loginData?.default_community?.community_id;
        dispatch(getWingsAndFlatDetails({ societyId }));
        if (searchParams.get("wingId")) {
            dispatch(
                getFlatDetails({ societyId, wingId: searchParams.get("wingId") })
            );
        }
    }, []);

    const handleDownloadExcel = () => {
        dispatch(
            downloadSocietyEmailReport({
                societyId: loginData?.default_community?.community_id,
                search_content: searchParams.get("searchBy") || "",
                compsrchwing: searchParams.get("wingId") || "",
                compflatid: searchParams.get("flatId") || "",
                search_from_date: searchParams.get("startDate") || "",
                search_to_date: searchParams.get("endDate") || "",
                status1: searchParams.get("status") || "",
                page: searchParams.get("page") || 0,
                limit: searchParams.get("pageLimit") || 10,
                export: 1,
                onSuccessCallback: (url) => {
                    downloadFileFromURL({ url, fileName: "sample.csv" });
                },
            })
        );
    };

    const getTableData = () => {
        dispatch(
            getSocietyEmailReport({
                societyId: loginData?.default_community?.community_id,
                search_content: searchParams.get("searchBy") || "",
                compsrchwing: searchParams.get("wingId") || "",
                compflatid: searchParams.get("flatId") || "",
                search_from_date: searchParams.get("startDate") || "",
                search_to_date: searchParams.get("endDate") || "",
                status1: searchParams.get("status") || "",
                page: searchParams.get("page") || 0,
                limit: searchParams.get("pageLimit") || 10,
                export: 0,
            })
        )
    }
    useEffect(() => {
        getTableData();
    }, [searchParams]);
    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Society Email Report">
                        <div className="flex gap-3 text-sm">
                            <Search height="8" width="48" placeholder="Search by Email"
                                onclick={handleSearch}
                                value={searchParams.get("searchBy")}
                            />
                            <div className="border-r border-[#CCC]"></div>
                            {downLoader ?
                                <ButtonIco className="justify-center w-32 " children={<CircularProgress sx={{ color: "#d32f2f" }} size={17} />} />
                                :
                                <ButtonIco icon={faDownload} children='Download Excel'
                                    onClick={handleDownloadExcel}
                                />
                            }
                        </div>
                    </ListHeader>
                </div>

                <div className="flex justify-between gap-2 py-1 my-1">
                    <div className="flex gap-4">
                        <Dropdown2
                            options={
                                wingsData?.map((item) => ({
                                    value: item?.wing_id,
                                    label: item?.number,
                                })) || []
                            }
                            placeholder="Select Wing"
                            className="text-[11px] ms-4"
                            width="172px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handleSelectWing(selectedItem);
                            }}
                            value={
                                searchParams.get("wingId") && {
                                    value: searchParams.get("wingId"),
                                    label: wingsData?.find(
                                        (item) =>
                                            item.wing_id?.toString() === searchParams.get("wingId")
                                    )?.number,
                                }
                            }
                        />
                        <Dropdown2
                            options={
                                (searchParams.get("wingId") &&
                                    flatsData?.manage_flat_details?.map((item) => ({
                                        value: item?.flat_id,
                                        label: item?.flat_no,
                                    }))) ||
                                []
                            }
                            placeholder="Select Flat"
                            className="text-[11px] ms-4"
                            width="172px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handleSelectFlat(selectedItem);
                            }}
                            value={
                                searchParams.get("flatId") && {
                                    value: searchParams.get("flatId"),
                                    label: flatsData?.manage_flat_details?.find(
                                        (item) =>
                                            item.flat_id?.toString() === searchParams.get("flatId")
                                    )?.flat_no,
                                }
                            }
                        />
                        <Dropdown2
                            options={[
                                { value: "All", label: "All" },
                                { value: "Delivered", label: "Delivered" },
                                { value: "Not Delivered", label: "Not Delivered" },
                            ]}
                            placeholder="Select Status"
                            className="text-[11px] ms-4"
                            width="175px"
                            height="32px"
                            onSelect={(selectedItem) => {
                                handelSelectStatus(selectedItem);
                            }}
                            value={
                                searchParams.get("status") && {
                                    value: searchParams.get("status"),
                                    label: searchParams.get("status")
                                }
                            }
                        />
                    </div>
                    <div className="flex items-center justify-between gap-3">
                        <div className="text-sm">Search By</div>
                        <RangeDatePicker
                            className={"w-[190px] h-8"}
                            defaultStartValue={
                                searchParams.get("startDate")
                                    ? new Date(searchParams.get("startDate"))
                                    : ""
                            }
                            defaultEndValue={
                                searchParams.get("endDate")
                                    ? new Date(searchParams.get("endDate"))
                                    : ""
                            }
                            onStartDateChange={handleStartDate}
                            onEndDateChange={handleEndDate}
                        />
                        <ButtonG label="Clear" className="h-8" onClick={handleClear} />
                    </div>
                </div>
                <SocietyEmailReportsTable
                    loading={loading}
                    data={societyEmailReport?.data?.map((item, index) => ({
                        sNo : (societyEmailReport?.meta?.current_page - 1) *
                        societyEmailReport?.meta?.per_page +
                        1 + index,
                        id: item?.id,
                        email: item?.email,
                        subject: item?.subject,
                        flat: item?.flatno,
                        date: item?.datetime,
                        status: item?.status,
                        details: item?.mail_status,
                    }))}
                    columns={headCells}
                    checkbox={false}
                    pagination={societyEmailReport?.data?.length}
                    totalCount={societyEmailReport?.meta?.total}
                    totalPages={societyEmailReport?.meta?.last_page}
                    start={
                        (societyEmailReport?.meta?.current_page - 1) *
                        societyEmailReport?.meta?.per_page +
                        1
                    }
                    end={
                        (societyEmailReport?.meta?.current_page - 1) *
                        societyEmailReport?.meta?.per_page +
                        societyEmailReport?.data?.length
                    }
                    currentPage={Number(societyEmailReport?.meta?.current_page )}
                    rowsPerPage={searchParams.get("pageLimit") || 10}
                    getTableData={getTableData}
                />
                {!societyEmailReport?.data?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default SocietyEmailReports;