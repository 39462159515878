import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Checkbox from "@mui/material/Checkbox";
import { visuallyHidden } from "@mui/utils";
import "./Table.css";
import { DeleteIcon, EditIcon, PrinterIcon, SortIcon } from "../../assets";
import styled from "@emotion/styled";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import Pagination from "../Pagination/Pagination";
import { BillingPagination } from "../Pagination/Pagination";
import {
  stableSort,
  getComparator,
} from "../../utils/helpers/universalFunctions";
import {
  Link,
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { CircularProgress, Tooltip } from "@mui/material";
import Loader from "../Loader/Loader";
import Button from "../Button/Button";
import Dropdown2 from "../Dropdown/dropdown2";
import {
  EDIT_DRAFT_INVOICE_ENDPOINT,
  EDIT_DRAFT_INVOICE_ENDPOINT_MULTI,
  MANAGE_INVOICE_DRAFTS_ENDPOINT,
  PRINT_INVOICE_BILL_ENDPOINT,
  PRINT_INVOICE_BILL_MULTI_ENDPOINT,
} from "../../Routing/routes";
import { useSelector } from "react-redux";
import DeleteModal from "../Modal/DeleteModal";
import { useDispatch } from "react-redux";
import {
  deleteInvoice,
  getManageInvoiceActionsList,
} from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { notify } from "../../utils/notification";
import { getPermission } from "../../utils/helpers/getPermissions";
import * as permissions from "../../Routing/permissions";

function ActionComponent({ id,reload }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { loginData } = useSelector((state) => state.loginReducer);
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleDelete = () => {
    let data = {
      invIds: [parseInt(id)],
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: () => {
        setShowDeleteModal(false);
        reload()
      },
    };
    dispatch(deleteInvoice(data));
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
       {getPermission(loginData, [permissions.EDIT_DRAFT_INVOICE]) && <Tooltip title="Edit" arrow>
        <EditIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => {
            navigate(`${EDIT_DRAFT_INVOICE_ENDPOINT}/${id}`);
          }}
        />
      </Tooltip>}
      {getPermission(loginData, [permissions.PRINT_INVOICE]) && <Tooltip title="Print" arrow>
        <Link target="_blank" to={`${PRINT_INVOICE_BILL_ENDPOINT}/${id}`}>
          <PrinterIcon
            className="fill-[#555] hover:fill-red-650 me-1.5"
          />
        </Link>
      </Tooltip>}
      {getPermission(loginData, [permissions.DELETE_DRAFT_INVOICE]) && <Tooltip title="Delete" arrow>
        <DeleteIcon
          className="fill-[#555] hover:fill-red-650 me-1.5"
          onClick={() => handleDeleteClick()}
        />
      </Tooltip>}
      <DeleteModal
        isOpen={showDeleteModal}
        onCancel={() => setShowDeleteModal(false)}
        onDelete={handleDelete}
      />
    </Box>
  );
}

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const ManageInvoiceDraftTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  //props are required if pagination is true
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  reload
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [selected, setSelected] = useState([]);
  const [searchParams, setSearchParams] = useSearchParams();
  const [action, setAction] = useState("");

  const { manageInvoiceActionsList, updateLoader } = useSelector(
    (state) => state.manageInvoiceReducer
  );
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked ? data.map((item) => item.id) : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${MANAGE_INVOICE_DRAFTS_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };

  const handleApply = ({id})=>{
    if(action === 'Delete'){
      if (getPermission(loginData, [permissions.DELETE_INVOICE])){
        let data = {
          invIds: selected,
          societyId: loginData?.default_community?.community_id,
          onSuccessCallback: () => {
            setSelected([])
            reload()
          },
        };
        dispatch(deleteInvoice(data))
      } else notify("warning", "You don't have the access!");
    }

    if(action === 'Post'){
      if (getPermission(loginData, [permissions.EDIT_DRAFT_INVOICE])){
        let data={
          invid: selected,
        }
        navigate(EDIT_DRAFT_INVOICE_ENDPOINT_MULTI, {
          state : data
        })
      }else notify("warning", "You don't have the access!");
    }

    if(action === 'Print'){
      if (getPermission(loginData, [permissions.PRINT_INVOICE])){
        let data= {
          invid: selected,
        }
        navigate(PRINT_INVOICE_BILL_MULTI_ENDPOINT, {
          state: data
        })
      }else notify("warning", "You don't have the access!");
    }
  }

  const isSelected = (id) => selected.includes(id);

  useEffect(() => {
    dispatch(getManageInvoiceActionsList({ act: "status" }));
  }, []);

  useEffect(() => {
    setSelected([]);
  }, [searchParams]);

  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      {!!selected?.length && (
        <div className="flex justify-end items-center border-t border-[#CCC] gap-2 p-2">
          <div className="flex items-center gap-2">
            <Dropdown2
              options={
                manageInvoiceActionsList?.data?.draftInvoiceAction
                  ? Object.entries(
                      manageInvoiceActionsList?.data?.draftInvoiceAction
                    )?.map((item) => ({
                      value: item?.[0],
                      label: item?.[1],
                    }))
                  : []
              }
              placeholder="Choose Action"
              className="text-[11px] ms-4"
              width="160px"
              height="32px"
              onSelect={(selectedItem) => {
                setAction(selectedItem.value);
              }}
            />
            <Button
              label={
                <span className="inline-block w-8">
                  {updateLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Apply"
                  )}
                </span>
              }
              className="h-8 px-4 font-semibold"
              type="button"
              onClick={handleApply}
            />
          </div>
        </div>
      )}
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {!!checkbox && (
                <TableCellStyled
                  size="small"
                  sx={{ pl: "0 !important", pr: 2, width: 36 }}
                >
                  <Checkbox
                    size="small"
                    indeterminate={false}
                    checked={
                      data?.length > 0 && selected?.length === data?.length
                    }
                    onChange={handleSelectAllClick}
                    inputProps={{
                      "aria-label": "select all",
                    }}
                    sx={{
                      p: 0,
                      color: "#AAAAA",
                      "&.Mui-checked": {
                        color: "#CF001C",
                      },
                    }}
                    disabled={loading || !data}
                  />
                </TableCellStyled>
              )}
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sortDirection={orderBy === column.id ? order : false}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  <TableSortLabel
                    active={orderBy === column.id}
                    direction={orderBy === column.id ? order : "asc"}
                    onClick={() => {
                      if (column.sort) {
                        handleRequestSort(column.id);
                      }
                    }}
                    IconComponent={SortIcon}
                    sx={{
                      ".MuiTableSortLabel-icon": {
                        opacity: "1 !important",
                      },
                      svg: {
                        flexShrink: 0,
                      },
                    }}
                    hideSortIcon={!column.sort}
                  >
                    {column.label}
                    {orderBy === column.id ? (
                      <Box component="span" sx={visuallyHidden}>
                        {order === "desc"
                          ? "sorted descending"
                          : "sorted ascending"}
                      </Box>
                    ) : null}
                  </TableSortLabel>
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none" }}
                  colSpan={columns?.length + 1}
                >
                  <Loader />
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  stableSort(data, getComparator(order, orderBy))?.map(
                    (row, rowIndex) => {
                      const isRowSelected = isSelected(row?.id);

                      return (
                        <TableRowStyled
                          key={row?.id}
                          role="checkbox"
                          aria-checked={isRowSelected}
                          tabIndex={-1}
                          selected={isRowSelected}
                        >
                          {!!checkbox && (
                            <TableCellStyled
                              size="small"
                              sx={{ pl: "0 !important", zIndex: 0 }}
                              onClick={(event) => handleClick(event, row?.id)}
                            >
                              <Checkbox
                                checked={selected?.includes(row?.id)}
                                onChange={(e) => handleClick(e, row?.id)}
                                size="small"
                                color="primary"
                                inputProps={{
                                  "aria-labelledby": `generic-table-checkbox-${row?.id}`,
                                }}
                                sx={{
                                  p: 0,
                                  color: "#AAAAA",
                                  "&.Mui-checked": {
                                    color: "#CF001C",
                                  },
                                }}
                              />
                            </TableCellStyled>
                          )}

                          {/* Serial Number */}
                          <TableCellStyled
                            size="small"
                            align="left"
                            onClick={(event) => handleClick(event, row?.id)}
                          >
                            {row?.sNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.memberName}
                          </TableCellStyled>

                          <TableCellStyled
                            size="small"
                            align="left"
                            // sx={{ textTransform: "capitalize" }}
                          >
                            {row.wingTitle} - {row.flatNo}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row.invoiceDate}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {row?.savedOn}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <div>
                              <b>Amount: </b> {row.amount}
                            </div>
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            {<p>{row?.status}</p>}
                          </TableCellStyled>

                          <TableCellStyled size="small" align="left">
                            <ActionComponent id={row.id} reload={reload} />
                          </TableCellStyled>
                        </TableRowStyled>
                      );
                    }
                  )}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!pagination && data && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            totalCount={totalCount}
          />
          <BillingPagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ManageInvoiceDraftTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ManageInvoiceDraftTable;
