import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import ListHeader from "../../../../components/ListHeader/ListHeader"
import Layout from "../../../Layout/Layout"
import { useSelector } from "react-redux"
import Select from "react-select";
import { Formik, Form } from "formik"
import Button from "../../../../components/Button/Button"
import Dropdown from "../../../../components/Dropdown/Dropdown"
import { getInvoiceTypeList } from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction"
import Dropdown2 from "../../../../components/Dropdown/dropdown2"
import { getManageInvoiceCurrent } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions"
import { notify } from "../../../../utils/notification"
import { getDebitNoteCurrent } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction"
import { createSearchParams } from "react-router-dom"
import { MAANGE_BULK_ADD_REQUEST_ENDPOINT, MAANGE_BULK_JOBS_LIST_ENDPOINT } from "../../../../Routing/routes"
import { Navigate } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker"
import moment from "moment"
import Modal from "../../../../components/Modal/Modal"
import { getManageInvoiceDraft } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions"
import { getDebitNoteDraft } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction"
import invoiceCalculation from "../../../../utils/helpers/invoiceCalculation"
import DebitCalculation from "../../../../utils/helpers/draftDebitCaculation"
import { getEditInvoiceDetails } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions"
import { editSingleDebitNoteDetails } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageDebitNoteAction"
import { typeMap, buttonConfig, childrenType } from "../../../../consts/DropDownOptions";
import { getBulkCreateInvoice, getAllTransactionsReport, getProjectWiseSocietyDetails } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions"
import MultiSelect from "../../../../components/MultiSelect/MultiSelect"
import { getManageAdvanceList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageAdvanceNoteAction"
import { getManageCreditList } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageCreditNoteAction"

const ManageBulkRequest = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState()
    const [searchParams, setSearchParams] = useSearchParams();
    const { typeLists } = useSelector((state) => state.invoiceSettingReducer);
    const [invoiceTypeList, setInvoiceTypeList] = useState([]);
    const [invIds, setInvIds] = useState([]);
    const [societies, setSocieties] = useState([]);
    const [invoiceData, setInvoiceData] = useState();
    const [societyList, setSocietyList] = useState()
    const [isFetched, setIsFetched] = useState(false);
    const { loginData } = useSelector((state) => state.loginReducer);
    const [isDeleting, setIsDeleting] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const { BulkManageInvoice, manageInvoiceCurrent, BulkLoader, manageInvoiceDraft, projectWiseSocietyDetails, loading } =
        useSelector((state) => state.manageInvoiceReducer);
    const { manageDebitNoteCurrent, manageDebitNoteDraft, editSingleDebitNoteDetail, updateLoader, Debitloading } = useSelector(
        (state) => state.manageDebitNoteReducer
    );
    const { uploadLoader, editInvoiceDetails } = useSelector(
        (state) => state.manageInvoiceReducer
    );
    const { advanceNoteList } = useSelector(
        (state) => state.manageAdvanceNoteReducer
    );
    const { creditNoteList } = useSelector(
        (state) => state.manageCreditNoteReducer
    );
    const { invoiceStatusList } = useSelector((state) => state.myInvoiceReducer);
    const [selectedInvoiceType, setSelectedInvoiceType] = useState(
        searchParams.get("typeid") || 1
    );
    const type = searchParams.get("type");
    const buttonDetails = buttonConfig?.find((item) => item.value === type)?.label;
    const leftContent = typeMap?.find((item) => item.value === type)?.label || "";
    const commonParams = {
        societyId: loginData?.default_community?.community_id,
        pageLimit: 10000,
        InvoiceType: selectedInvoiceType,
    };
    const children = childrenType?.find((item) => item.value === type)?.label || "";
    const handelSelectInvoiceType = (selectedItem) => {
        setSelectedInvoiceType(selectedItem.value);
        searchParams.delete("page");
        searchParams.delete("typeid");
        navigate({
            pathname: `${MAANGE_BULK_ADD_REQUEST_ENDPOINT}`,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["typeid", selectedItem.value],
            ])}`,
        });
    };
    useEffect(() => {
        if (type === "POST_DRAFT_INVOICE" || type === "DELETE_DRAFT_INVOICE") {
            dispatch(
                getManageInvoiceDraft({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            );

            if (editInvoiceDetails?.invid) {
                let updatedDetails = { ...editInvoiceDetails };
                editInvoiceDetails.invid.forEach((id) => {
                    const data = invoiceCalculation(editInvoiceDetails, id);
                    Object.assign(updatedDetails, data);
                });

                if (JSON.stringify(updatedDetails) !== JSON.stringify(editInvoiceDetails)) {
                    setInvoiceData(updatedDetails);
                }
            }
        }
    }, [type, editInvoiceDetails, loginData, selectedInvoiceType]);
    useEffect(() => {
        if (type === "POST_DRAFT_DEBITNOTE" || type === "DELETE_DRAFT_DEBITNOTE") {
            dispatch(
                getDebitNoteDraft({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            );
        } else if (type === "DELETE_CURRENT_INVOICE" || type == "SYNC_INVOICE" || type == "SYNC_INVOICE_RECEIPT") {
            dispatch(
                getManageInvoiceCurrent({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            );
        } else if (type === "DELETE_CURRENT_DEBITNOTE" || type == "SYNC_DEBITNOTE") {
            dispatch(
                getDebitNoteCurrent({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            );
        } else if (type === "ALL_TRANSACTION_REPORT") {
            dispatch(
                getProjectWiseSocietyDetails({
                    societyid: loginData?.default_community?.community_id,
                })
            )
        } else if (type === "SYNC_ADVANCE") {
            dispatch(
                getManageAdvanceList({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            )
        } else if (type === "SYNC_CREDITNOTE") {
            dispatch(
                getManageCreditList({
                    societyId: loginData?.default_community?.community_id,
                    pageLimit: 10000,
                    InvoiceType: selectedInvoiceType,
                })
            )
        }
    }, [type, editInvoiceDetails, loginData, selectedInvoiceType]);
    useEffect(() => {
        const type = searchParams.get("type");
        if (!isFetched) {
            let fetchedInvIds = [];
            let fetchSocieties = [];

            if (type === "POST_DRAFT_INVOICE" || type === "DELETE_DRAFT_INVOICE") {
                fetchedInvIds = manageInvoiceDraft?.data?.invoice_list?.map((item) => item?.id) || [];
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                    dispatch(
                        getEditInvoiceDetails({
                            invIds: fetchedInvIds,
                            societyId: loginData?.default_community?.community_id,
                            is_draft: 1,
                        })
                    );
                    setIsFetched(true);
                }
            } else if (type === "POST_DRAFT_DEBITNOTE" || type === "DELETE_DRAFT_DEBITNOTE") {
                fetchedInvIds = manageDebitNoteDraft?.data?.debitnote_details?.map((item) => item?.id) || [];
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                    dispatch(
                        editSingleDebitNoteDetails({
                            invid: fetchedInvIds,
                            societyId: loginData?.default_community?.community_id,
                        })
                    );
                    setIsFetched(true);
                }
            } else if (type === "DELETE_CURRENT_INVOICE" || type === "SYNC_INVOICE" && manageInvoiceCurrent?.data) {
                fetchedInvIds = manageInvoiceCurrent?.data?.map((item) => item?.id) || [];
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                    setIsFetched(true);
                }
            } else if (type === "DELETE_CURRENT_DEBITNOTE" || type === "SYNC_DEBITNOTE" && manageDebitNoteCurrent?.data) {
                fetchedInvIds = manageDebitNoteCurrent?.data?.debitnote_details?.map((item) => item?.id) || [];
                if (fetchedInvIds.length > 0) {
                    console.log("fetch invids", fetchedInvIds);
                    setInvIds(fetchedInvIds);
                    setIsFetched(true);
                }
            } else if (type === "ALL_TRANSACTION_REPORT" && projectWiseSocietyDetails?.data) {
                fetchSocieties = projectWiseSocietyDetails?.data?.map((item) => item?.id) || []
                console.log('fetchSocieties', fetchSocieties)
                if (fetchSocieties.length > 0) {
                    setSocietyList(fetchSocieties)
                    setIsFetched(true);
                }
            } else if (type === "SYNC_ADVANCE" && advanceNoteList?.list) {
                fetchedInvIds = advanceNoteList?.list?.map((item) => item?.id) || []
                console.log('advance InvIds', fetchedInvIds)
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                    setIsFetched(true);
                }
            } else if (type === "SYNC_CREDITNOTE" && creditNoteList?.data) {
                fetchedInvIds = creditNoteList?.data?.map((item) => item?.id) || []
                console.log('credit InvIds', fetchedInvIds)
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                    setIsFetched(true);
                }
            }
        }
    }, [
        searchParams,
        isFetched,
        manageInvoiceDraft?.data,
        manageInvoiceCurrent?.data,
        manageDebitNoteDraft?.data,
        manageDebitNoteCurrent?.data,
        projectWiseSocietyDetails?.data,
        advanceNoteList?.list,
        creditNoteList?.data
    ]);

    const onSuccessCallback = () => {
        const successMessages = {
            DELETE_CURRENT_INVOICE: "Delete Current Invoices task triggered successfully.",
            DELETE_CURRENT_DEBITNOTE: "Delete Current Debit note task triggered successfully.",
            POST_DRAFT_DEBITNOTE: "Post Draft Debit note task triggered successfully.",
            DELETE_DRAFT_DEBITNOTE: "Delete Draft debit note task triggered successfully.",
            POST_DRAFT_INVOICE: "Post Draft Invoice Invoice task triggered successfully.",
            DELETE_DRAFT_INVOICE: "Delete Draft invoice task triggered successfully.",
            SYNC_INVOICE: "Sync invoice task triggered successfully.",
            SYNC_DEBITNOTE: "Sync debit note task triggered successfully.",
            SYNC_ADVANCE: "Sync Advance task triggered successfully.",
            SYNC_CREDITNOTE: "Sync credit note task triggered successfully.",
        };

        console.log(successMessages[type] || "Task completed successfully!");
        notify("success", successMessages[type] || "Task completed successfully.");
        setIsModal(false);
        setIsLoading(false)
        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
    };

    const onFailureCallback = (errorMessage) => {
        const failureMessages = {
            DELETE_CURRENT_INVOICE: "Failed to triggered task delete current invoices.",
            DELETE_CURRENT_DEBITNOTE: "Failed to triggered task delete current debit note.",
            POST_DRAFT_DEBITNOTE: "Failed to triggered task post draft debit note.",
            DELETE_DRAFT_DEBITNOTE: "Failed to triggered task delete draft debit note.",
            POST_DRAFT_INVOICE: "Failed to triggered task post draft invoice.",
            DELETE_DRAFT_INVOICE: "Failed to triggered task delete draft invoice.",
            SYNC_INVOICE: "Failed to triggered task sync invoice.",
            SYNC_DEBITNOTE: "Failed to triggered task sync debit note.",
            SYNC_ADVANCE: "Failed to triggered task sync advance.",
            SYNC_CREDITNOTE: "Failed to triggered task sync credit note.",
        };

        console.log(errorMessage || failureMessages[type] || "task failed!");
        notify("error", failureMessages[type] || "Task failed.");
        setIsLoading(false)
        setIsModal(false);
        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
    };
    useEffect(() => {
        dispatch(
            getInvoiceTypeList({
                societyId: loginData?.default_community?.community_id,
            })
        );
    }, [dispatch, loginData]);

    useEffect(() => {
        if (typeLists) {
            setInvoiceTypeList(typeLists);
        }
    }, [typeLists]);

    useEffect(() => {
        if (type === "DELETE_CURRENT_INVOICE") {
            if (manageInvoiceCurrent && manageInvoiceCurrent?.data) {
                const fetchedInvIds =
                    manageInvoiceCurrent?.data?.map((item) => item?.id) || [];
                if (fetchedInvIds.length > 0) {
                    setInvIds(fetchedInvIds);
                }
            }
        } else if (type === "DELETE_CURRENT_DEBITNOTE" || type === "SYNC_DEBITNOTE") {
            if (manageDebitNoteCurrent && manageDebitNoteCurrent?.data) {
                const fetchedInvIds =
                    manageDebitNoteCurrent?.data?.debitnote_details?.map(
                        (item) => item?.id
                    ) || [];
                if (fetchedInvIds.length > 0) {
                    console.log("fetch invids", fetchedInvIds);
                    setInvIds(fetchedInvIds);
                }
            }
        }
    }, [manageInvoiceCurrent, manageDebitNoteCurrent]);
    const HandleSync = () => {
        if (!invIds || invIds.length === 0) {
            if (type === "SYNC_INVOICE") {
                notify('error', "No invoice found for sync")
            } else if (type === "SYNC_DEBITNOTE") {
                notify('error', 'No Debit note found for sync')
            } else if (type === "SYNC_ADVANCE") {
                notify('error', 'No advance found for sync')
            } else if (type == "SYNC_CREDITNOTE") {
                notify('error', "No credit note found for sync")
            }
        }
        const jobType = type;
        const apiMethod = "POST";
        const isPicked = "NO";
        const priority = "HIGH";
        const source = "BILLING_API"
        const meta_data = {
            "id": searchParams.get("typeid") || 1,
            "billName": (invoiceTypeList?.find(
                (item) => item.id === parseInt(selectedInvoiceType)
            ) || {}).invoice_title,
        }
        let apiName;
        let requestData;
        if (type === "SYNC_ADVANCE" || type === "SYNC_CREDITNOTE") {
            apiName = `/api/auth/accounts/creditAccountSync`;
            requestData = {
                invids: invIds,
                societyId: loginData?.default_community?.community_id,
            }
        }
        else if (type === "SYNC_DEBITNOTE") {
            apiName = `/api/auth/accounts/debitnoteAccountSync`;
            requestData = {
                invids: invIds,
                societyId: loginData?.default_community?.community_id,
            }
        } else if (type === "SYNC_INVOICE") {
            apiName = `/api/auth/accounts/invoiceAccountSync`;
            requestData = {
                invids: invIds,
                societyId: loginData?.default_community?.community_id,
            };
        }
        if (invIds.length > 0) {
            setIsLoading(true);
            dispatch(
                getBulkCreateInvoice({
                    societyId: loginData?.default_community?.community_id,
                    requestData: requestData,
                    jobType: jobType,
                    apiMethod: apiMethod,
                    isPicked: isPicked,
                    priority: priority,
                    source: source,
                    apiName: apiName,
                    metaData: meta_data,
                    onSuccessCallback: onSuccessCallback,
                    onFailureCallback: onFailureCallback,
                })
            );
        }
    }
    const HandleDelete = () => {
        if (!invIds || invIds.length === 0) {
            if (type === "DELETE_DRAFT_INVOICE") {
                notify("error", "No Draft Invoices found for deletion.");
                return;
            } else if (type === "DELETE_DRAFT_DEBITNOTE") {
                notify("error", "No Draft Debit Note found for deletion.");
                return;
            } else if (type === "DELETE_CURRENT_INVOICE") {
                notify('error', "No Current Invoices found for deletion")
            }
            else if (type === "DELETE_CURRENT_DEBITNOTE") {
                notify('error', "No Current Debit Note found for deletion")
            }
        }
        const jobType = type;
        const apiMethod = "POST";
        const isPicked = "NO";
        const priority = "HIGH";
        const source = "BILLING_API"
        const meta_data = {
            "id": searchParams.get("typeid") || 1,
            "billName": (invoiceTypeList?.find(
                (item) => item.id === parseInt(selectedInvoiceType)
            ) || {}).invoice_title,
        }
        let apiName;
        let requestData;
        if (type === "DELETE_DRAFT_INVOICE" || type === "DELETE_CURRENT_INVOICE") {
            apiName = `/api/auth/deleteInvoice`;
            requestData = {
                invid: invIds,
                pageid: loginData?.default_community?.community_id,
            };
        } else if (type === "DELETE_DRAFT_DEBITNOTE") {
            apiName = `/api/auth/deleteDraftDebitNoteV1`;
            requestData = {
                invids: invIds,
                id: loginData?.default_community?.community_id,
            };
        }
        else if (type === "DELETE_CURRENT_DEBITNOTE") {
            apiName = `/api/auth/deleteDebitNote`;
            requestData = {
                invIds: invIds,
                id: loginData?.default_community?.community_id,
            };
        }
        if (invIds.length > 0) {
            setIsLoading(true);
            dispatch(
                getBulkCreateInvoice({
                    societyId: loginData?.default_community?.community_id,
                    requestData: requestData,
                    jobType: jobType,
                    apiMethod: apiMethod,
                    isPicked: isPicked,
                    priority: priority,
                    source: source,
                    apiName: apiName,
                    metaData: meta_data,
                    onSuccessCallback: onSuccessCallback,
                    onFailureCallback: onFailureCallback,
                })
            );
        }
    };

    const handlePost = () => {
        if (!invIds || invIds.length === 0) {
            if (type === "POST_DRAFT_INVOICE") {
                notify("error", "No Draft Invoices found for post.");
                return;
            } else if (type === "POST_DRAFT_DEBITNOTE") {
                notify("error", "No Draft Debit Note found for post.");
                return;
            }
        }
        const jobType = type;
        const apiMethod = "POST";
        const isPicked = "NO";
        const priority = "HIGH";
        const source = "BILLING_API"
        const meta_data = {
            "id": searchParams.get("typeid") || 1,
            "billName": (invoiceTypeList?.find(
                (item) => item.id === parseInt(selectedInvoiceType)
            ) || {}).invoice_title,
        }
        let apiName;
        let requestData;
        if (type === "POST_DRAFT_INVOICE") {
            apiName = `/api/auth/postDraftInvoices`;
            requestData = {
                invIds: invIds,
                pageid: loginData?.default_community?.community_id,
                invoice_type: searchParams.get("typeid") || 1,
            };
        } else if (type === "POST_DRAFT_DEBITNOTE") {
            let data;
            if (editSingleDebitNoteDetail) {
                const { debitnote_header, ...otherDetails } = editSingleDebitNoteDetail;
                const headerData = debitnote_header;
                const detailsData = otherDetails;
                data = DebitCalculation(
                    editSingleDebitNoteDetail,
                    headerData,
                    detailsData
                );
                console.log("Debit Note Calculation Data", data);
            }
            apiName = `/api/auth/updateDebitNote`;
            requestData = {
                debitnote_details: data,
                generationStatus: "postDraftBill",
                pageid: loginData?.default_community?.community_id,
            };
        }

        if (invIds.length > 0) {
            setIsLoading(true);
            dispatch(
                getBulkCreateInvoice({
                    societyId: loginData?.default_community?.community_id,
                    requestData: requestData,
                    jobType: jobType,
                    apiMethod: apiMethod,
                    isPicked: isPicked,
                    priority: priority,
                    source: source,
                    metaData: meta_data,
                    apiName: apiName,
                    onSuccessCallback: () => {
                        if (searchParams.get("type") === "POST_DRAFT_INVOICE") {
                            console.log("Invoice posted successfully!");
                            notify("success", "Post Draft Invoices task triggered successfully.");
                        } else if (searchParams.get("type") === "POST_DRAFT_DEBITNOTE") {
                            console.log("Debit Note posted successfully!");
                            notify("success", "Post Draft Debit Note task triggered successfully.");
                        }
                        setIsModal(false);
                        setIsDeleting(false);
                        setIsLoading(false);
                        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
                    },
                    onFailureCallback: (errorMessage) => {
                        if (type === "POST_DRAFT_INVOICE") {
                            console.log("Failed to post invoice:", errorMessage);
                            notify("error", "Post Draft Invoices task failed to triggered.");
                        } else if (type === "POST_DRAFT_DEBITNOTE") {
                            console.log("Failed to post invoice:", errorMessage);
                            notify("error", "Post Draft Debit Note task failed to triggered.");
                        }
                        setIsDeleting(false);
                        setIsModal(false);
                        setIsLoading(false);
                        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
                    },
                })
            );
        }
        const isDeleteDisabled = isDeleting;
        const isLoadingDisabled = isLoading;
    };
    const handleGenerate = (Values) => {
        if (!societyList || societyList.length === 0) {
            if (type === "ALL_TRANSACTION_REPORT") {
                notify("error", "No Soceity Name found for Genereate.");
                return;
            }
        }
        const jobType = type;
        const apiMethod = "POST";
        const isPicked = "NO";
        const priority = "HIGH";
        const source = "BILLING_API"
        let meta_data
        let apiName;
        let requestData;
        if (type === "ALL_TRANSACTION_REPORT") {
            apiName = `/api/allTransactionsReport`;

            requestData = {
                society_ids: Values.SocietyIds,
                from_date: Values.fromDate,
                to_date: Values.toDate
            };
            meta_data = requestData
        }
        if (societyList.length > 0) {
            setIsLoading(true);
            dispatch(
                getBulkCreateInvoice({
                    societyId: loginData?.default_community?.community_id,
                    requestData: requestData,
                    jobType: jobType,
                    apiMethod: apiMethod,
                    isPicked: isPicked,
                    priority: priority,
                    source: source,
                    metaData: meta_data,
                    apiName: apiName,
                    onSuccessCallback: () => {
                        if (type === "ALL_TRANSACTION_REPORT") {
                            console.log("Invoice posted successfully!");
                            notify("success", "All Transaction Report task triggered successfully.");
                        }
                        setIsModal(false);
                        setIsDeleting(false);
                        setIsLoading(false);
                        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
                    },
                    onFailureCallback: (errorMessage) => {
                        if (type === "ALL_TRANSACTION_REPORT") {
                            console.log("Failed to Generate all Transaction Report:", errorMessage);
                            notify("error", "All Transaction Report task failed to triggered.");
                        }
                        setIsDeleting(false);
                        setIsModal(false);
                        setIsLoading(false);
                        navigate(`${MAANGE_BULK_JOBS_LIST_ENDPOINT}?type=${searchParams.get("type")}`);
                    },
                })
            );
        }
        const isDeleteDisabled = isDeleting;
        const isLoadingDisabled = isLoading;
    };

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-4">
                <div className="border-b border-[#CCC] w-full">
                    <ListHeader leftContent={leftContent} >
                    </ListHeader>
                </div>
                <div className="mb-6 flex flex-col">
                    {type != 'ALL_TRANSACTION_REPORT' && (
                        <div className="flex items-center space-x-4">
                            <span className="py-4 font-semibold text-gray-700">Select Invoice Type</span>
                            <Dropdown2
                                options={(invoiceTypeList || []).map((item) => ({
                                    label: item?.invoice_title,
                                    value: item?.id,
                                }))}
                                placeholder="Invoice Type"
                                className="text-sm border border-gray-300 rounded-lg py-1 px-3 focus:ring-2 focus:ring-blue-500"
                                height="30px"
                                onSelect={handelSelectInvoiceType}
                                value={{
                                    value: selectedInvoiceType,
                                    label: (invoiceTypeList?.find(
                                        (item) => item.id === parseInt(selectedInvoiceType)
                                    ) || {}).invoice_title,
                                }}
                            />
                        </div>
                    )}
                    {
                        type == "ALL_TRANSACTION_REPORT" && (
                            <Formik
                                initialValues={{
                                    societyIds: [],
                                    fromDate: '',
                                    toDate: ''
                                }}
                                onSubmit={Values => {
                                    // handleGenerate(Values);
                                }}
                            >
                                {formik => (
                                    <>
                                        <div className="flex flex-col gap-4 mt-4">
                                            <div className="flex items-center gap-4">
                                                <span className="font-semibold text-gray-700 w-[150px]">Select Societies</span>
                                                <MultiSelect
                                                    options={
                                                        projectWiseSocietyDetails?.data?.map((item) => ({
                                                            label: item?.title,
                                                            value: item.id,
                                                        })) || []
                                                    }
                                                    width="220px"
                                                    height={"fit-content"}
                                                    placeholder="Select Societies"
                                                    className="text-xs"
                                                    isMulti={true}
                                                    onSelect={(selectedItem) => {
                                                        formik.setFieldValue(
                                                            "SocietyIds",
                                                            selectedItem.map((item) => item.value)
                                                        );
                                                    }}
                                                    value={formik.values?.projectWiseSocietyDetails?.data?.map((item) => ({
                                                        label: projectWiseSocietyDetails?.data?.find(
                                                            (data) => data?.id === item?.id
                                                        ).title,
                                                        value: item?.id,
                                                    }))}
                                                />
                                            </div>
                                            <div className="flex items-center gap-4">
                                                <span className="font-semibold text-gray-700 w-[150px]">From Date </span>
                                                <DatePickerComponentFM
                                                    defaultValue={
                                                        formik.values.fromDate
                                                            ? new Date(formik.values.fromDate)
                                                            : null
                                                    }
                                                    name="fromDate"
                                                    className="w-56 mb-2 h-9"
                                                    onDateChange={(selectedDate) => {
                                                        formik.setFieldValue(
                                                            "fromDate",
                                                            moment(selectedDate).format("YYYY-MM-DD")
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <div className="flex items-center gap-4">
                                                <span className="font-semibold text-gray-700 w-[150px]">To Date</span>
                                                <DatePickerComponentFM
                                                    defaultValue={
                                                        formik.values.toDate
                                                            ? new Date(formik.values.toDate)
                                                            : null
                                                    }
                                                    name="toDate"
                                                    className="w-56 mb-2 h-9"
                                                    onDateChange={(selectedDate) => {
                                                        formik.setFieldValue(
                                                            "toDate",
                                                            moment(selectedDate).format("YYYY-MM-DD")
                                                        );
                                                    }}
                                                />
                                            </div>
                                            <Modal
                                                isOpen={isModal}
                                                onClose={() => setIsModal(false)}
                                                title="Are you sure?"
                                                children={children}
                                                onConfirm={() => {
                                                    handleGenerate(formik.values);
                                                }}
                                                onCancel={() => setIsModal(false)}
                                                loading={isLoading}
                                                confirmTitle='Generate'
                                            />
                                        </div>
                                    </>
                                )}
                            </Formik>
                        )
                    }
                </div>
                <div
                    className=""
                    style={{
                        width: "400px",
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Button
                        label="Back"
                        className="h-8 bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 transition duration-300 mr-4"
                        onClick={() => navigate(MAANGE_BULK_JOBS_LIST_ENDPOINT)}
                    />  <Button
                        label={buttonDetails}
                        className="h-8 bg-blue-500 text-white rounded-lg px-4 py-2 hover:bg-blue-600 transition duration-300"
                        onClick={() => setIsModal(true)}
                        disabled={isLoading}
                    />
                </div>
                {type != 'ALL_TRANSACTION_REPORT' && (
                    <Modal
                        isOpen={isModal}
                        onClose={() => setIsModal(false)}
                        title="Are you sure?"
                        children={children}
                        onConfirm={() => {
                            if (buttonDetails === 'Post') {
                                handlePost();
                            } else if (buttonDetails === 'Delete') {
                                HandleDelete();
                            } else if (buttonDetails === "Sync") {
                                HandleSync();
                            }
                        }}
                        onCancel={() => setIsModal(false)}
                        loading={isLoading}
                        confirmTitle={buttonDetails}
                    />
                )}
            </div>

        </Layout>
    );
}
export default ManageBulkRequest