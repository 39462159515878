import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import { useDispatch } from "react-redux";
import { getOpeningBalanceData, updateOpeningBalanceData } from "../../../redux/actions/FinanceActions/BillingReportsAction";
import { useSelector } from "react-redux";
import OpeningBalanceTable from "./OpeningBalanceTable";
import { CircularProgress } from "@mui/material";
import Button from "../../../components/Button/Button";
import Dropdown2 from "../../../components/Dropdown/dropdown2";
import {
  getInvoiceTypeList,
} from "../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import { useNavigate } from "react-router-dom";
import { BULK_UPLOAD_OPENING_BALANCE_ENDPOINT } from "../../../Routing/routes";
import { Form, Formik } from "formik";
import { exportToExcel } from 'react-json-to-excel';

export const headCells = [
  { id: "id", label: "Sr. No.", width: "70px" },
  { id: "wingNo", label: "Wing No.", sort: false, width: "70px" },
  { id: "flatNo", label: "Flat No.", sort: true, width: "80px" },
  { id: "principal", label: "Principle Arrears", sort: false, width: "100px" },
  { id: "interest", label: "Intrest Arrears", sort: false, width: "110px" },
  {
    id: "current",
    label: "Current Period Interest",
    sort: false,
    width: "140px",
  },
];

const rows = [];

const OpeningBalance = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const [openingData, setOpeningData] = useState([])
  const { loginData } = useSelector(state => state.loginReducer)
  const { loading, openingBalanceData, updateLoader } = useSelector(state => state.billingReportsReducer)
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const [invoiceType, setInvoicetype] = useState(1);
  const getTableData = () => {
    dispatch(getOpeningBalanceData({ societyId: loginData?.default_community?.community_id, invoiceType: invoiceType }))
  }
  const handleNavigation = (path) => () => {
    navigate(path);
  };

  useEffect(() => {
    getTableData()
  }, [])
  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
  }, []);
  useEffect(() => {
    if (openingBalanceData) {
      setOpeningData(openingBalanceData)
    }
  }, [openingBalanceData]);
  const excelDataProcess = (exportData) => {
    if (exportData) {
      const exceldata = exportData ? exportData?.map(
        (item, index) => ({
          'Wing no.': item?.wingNo,
          'Flat no.': item?.flatNo,
          'Day Book': item?.daybook,
          'Principal Arrear': item?.principal,
          'Interest Arrear': item?.interest,
          'Current Period Interest': item?.current,
        })
      ) : [];
      exportToExcel(exceldata, 'opening_balance');
    }
  }
  return (
    <Layout>

      <Formik
        enableReinitialize
        initialValues={
          openingData
            ? openingData?.map((item, index) => ({
              id: item?.id,
              wingNo: item?.wing_no || '',
              flatNo: item?.flat_no || '',
              daybook: item?.daybook || '',
              principal: item?.principalArrears || 0,
              interest: item?.interestArrears || 0,
              current: item?.currentPeriodInterest || 0,
            })) : []
        }
        onSubmit={(values) =>
          dispatch(
            updateOpeningBalanceData({
              opeinngBalance: values,
              societyId: loginData?.default_community?.community_id,
            })
          )
        }
      // validationSchema={UtilityReadingValidation}
      //
      // innerRef={(f) => (formRef.current = f)}
      >
        {({ values, setFieldValue, dirty }) => {
          return (
            <Form>
              <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div>
                  <ListHeader leftContent="Opening Balance">
                    {/* <span className="text-base text-[15px]">Invoice Type</span> */}

                    <Dropdown2
                      options={
                        typeLists
                          ? typeLists?.map(
                            (item) => ({
                              label: item?.invoice_title,
                              value: item?.id,
                            })
                          )
                          : []
                      }
                      placeholder="Invoice type"
                      className="text-[11px] me-2"
                      width="224px"
                      height="36px"
                      name="invoice_type"
                      onSelect={(selectedItem) => {
                        setInvoicetype(selectedItem.value);
                        dispatch(getOpeningBalanceData({ societyId: loginData?.default_community?.community_id, invoiceType: selectedItem.value }))
                      }}
                      value={
                        typeLists && {
                          value: invoiceType,
                          label: typeLists ? typeLists?.find((item) => item?.id === parseInt(invoiceType))?.invoice_title : "",
                        }
                      }
                    />
                    <Button label={
                      <span className="inline-block w-8">
                        {
                          updateLoader ?
                            <CircularProgress sx={{ color: "white" }} size={17} />
                            :
                            'Save'
                        }
                      </span>
                    }
                      type='submit'
                      className="px-5 mr-2 h-9"
                      disabled={loading || updateLoader}
                       />
                    <Button
                      label="Bulk Upload Opening Balance"
                      className="px-5 h-9"
                      type='button'
                      onClick={handleNavigation(BULK_UPLOAD_OPENING_BALANCE_ENDPOINT)}
                    />
                     <Button
                      label="Download Opening Balance"
                      className="px-5 h-9"
                      type='button'
                      onClick={() => values && excelDataProcess(values)}
                    />
                  </ListHeader>
                </div>
                <div className="ml-1">
                  <OpeningBalanceTable
                    loading={loading}
                    openingData={values}
                    setFieldValue={setFieldValue}
                    setOpeningData={setOpeningData}
                    columns={headCells}
                    checkbox={false}
                    pagination={false}
                    getTableData={getTableData}
                  ></OpeningBalanceTable>

                  {!openingData?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                      No Records
                    </div>
                  )}
                </div>
              </div>
            </Form>
          )
        }}
      </Formik>

    </Layout>
  );
};

export default OpeningBalance;
