import * as type from "../../actions/index.types";

export function viewBookingReducer(
  state = {
    loading: false,
    updateLoader: false,
    bookingList: null,
    bookingStatusList: null,
    bookingDetailsData: null,
    facilityReport: null,
    facilityAudit: null,
    glMappingList: null,
    bankList: null,
    onlinepaymentLinkData: null,
    invoiceDetails: null,
    error: null,
  },
  action
) {
  switch (action.type) {
    
    case type.GET_BOOKING_LIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_BOOKING_LIST_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        bookingList: action.payload,
      };
    case type.GET_BOOKING_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        bookingList: null,
      };

    case type.GET_BOOKING_STATUS_LIST:
    return {
        ...state,
        error: null,
    };
    case type.GET_BOOKING_STATUS_LIST_SUCCESS:
    return {
        ...state,
        error: null,
        bookingStatusList: action.payload,
    };
    case type.GET_BOOKING_STATUS_LIST_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        bookingStatusList: null,
    };

    case type.GET_BOOKING_DETAILS:
        return {
          ...state,
          loading: true,
          error: null,
        };
    case type.GET_BOOKING_DETAILS_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        bookingDetailsData: action.payload,
    };
    case type.GET_BOOKING_DETAILS_FAILURE:
        return {
          ...state,
          loading: false,
          error: action?.message || "Somthing went wrong.",
          bookingDetailsData: null,
        };
  
    case type.MAKE_BOOKING_ADJUSTMENT:
      return {
          ...state,
          updateLoader: true,
      };
    case type.MAKE_BOOKING_ADJUSTMENT_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.MAKE_BOOKING_ADJUSTMENT_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };
      case type.MAKE_BOOKING_PAYMENT:
      return {
          ...state,
          updateLoader: true,
      };
    case type.MAKE_BOOKING_PAYMENT_SUCCESS:
    return {
        ...state,
        updateLoader: false,
    };
    case type.MAKE_BOOKING_PAYMENT_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

    case type.GET_FACILITY_REPORT:
    return {
        ...state,
        error: null,
        loading: true
    };
    case type.GET_FACILITY_REPORT_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        facilityReport: action.payload,
    };
    case type.GET_FACILITY_REPORT_FAILURE:
    return {
        ...state,
        error: action?.message || "Somthing went wrong.",
        facilityReport: null,
        loading: false,
    };

    case type.GET_ALL_FACILITY_AUDIT_LOG:
    return {
        ...state,
        loading: true,
        error: null,
    };
    case type.GET_ALL_FACILITY_AUDIT_LOG_SUCCESS:
    return {
        ...state,
        error: null,
        loading: false,
        facilityAudit: action.payload,
    };
    case type.GET_ALL_FACILITY_AUDIT_LOG_FAILURE:
        return {
            ...state,
            loading: false,
            error: action?.message || "Somthing went wrong.",
            facilityAudit: null,
        };


    case type.CHANGE_BOOKING_STATUS:
      return {
          ...state,
          updateLoader: true,
      };
    case type.CHANGE_BOOKING_STATUS_SUCCESS:
      if(action.payload.type=== 'view'){
        return {
          ...state,
          updateLoader: false,
          bookingDetailsData: {
            ...state.bookingDetailsData,
            status: action.payload.status
          }
        }
      }else if(action.payload.status === 'C' || action.payload.status === 'RC' || action.payload.status === 'A')
        return {
          ...state,
          updateLoader: false,
          bookingList: {
            ...state.bookingList,
            booking_list: state.bookingList?.booking_list?.map( (booking) => booking?.id === action.payload.bookingId
              ? {...booking, status: action.payload.status}
              : booking
          )
          }
        }
      else if (action.payload.status === 'D')
        return {
          ...state,
          updateLoader: false,
          // bookingList: {
          //   ...state.bookingList,
          //   booking_list: state.bookingList?.booking_list?.filter( (booking) => booking?.id !== action.payload.bookingId).map((item, index) => ({
          //     ...item,
          //     sNo: ((state.bookingList?.current_page - 1) * (10) + 1 + index)
          //     ?.toString()
          //     ?.padStart(2, "0"),
          //   }))
          // }
        }

    case type.CHANGE_BOOKING_STATUS_FAILURE:
      return {
          ...state,
          updateLoader: false,
      };

    case type.GET_BOOKING_GL_MAPPING_LIST:
      return {
          ...state,
          error: null,
      };
      case type.GET_BOOKING_GL_MAPPING_LIST_SUCCESS:
        action.payload?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase();
          
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; 
        });
      return {
          ...state,
          error: null,
          glMappingList: action.payload,
      };
      case type.GET_BOOKING_GL_MAPPING_LIST_FAILURE:
      return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          glMappingList: null,
      };

      
      case type.GET_BOOKING_BANK_ACCOUNT_LIST:
      return {
          ...state,
          error: null,
      };
      case type.GET_BOOKING_BANK_ACCOUNT_LIST_SUCCESS:
        action.payload?.sort((a, b) => {
          const nameA = a.name.toUpperCase(); 
          const nameB = b.name.toUpperCase();
          
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0; 
        });
      return {
          ...state,
          error: null,
          bankList: action.payload,
      };
      case type.GET_BOOKING_BANK_ACCOUNT_LIST_FAILURE:
      return {
          ...state,
          error: action?.message || "Somthing went wrong.",
          bankList: null,
      };

    case type.GET_BOOKING_INVOICE:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case type.GET_BOOKING_INVOICE_SUCCESS:
      return {
        ...state,
        error: null,
        loading: false,
        invoiceDetails: action.payload,
      };
    case type.GET_BOOKING_INVOICE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action?.message || "Somthing went wrong.",
        invoiceDetails: null,
      };

    default:
      return state;
    }
}
