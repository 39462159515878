import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader/Loader";
import moment from "moment";
import { geSocietyParkingReport } from "../../../redux/actions/ReportActions/SocietyReportActions";

const headCells = [
  { id: "sNo", label: "Sr. No.", width: "70px" },
  { id: "flatDetails", label: "Flat Details", width: "150px" },
  { id: "memberName", label: "Member Name", sort: true, width: "150px    " },
  { id: "parkingType", label: "Parking Type", width: "150px" },
  { id: "qrCode", label: "QR Code", width: "100px" },
  { id: "stickerNo", label: "Parking Sticker No.", width: "150px" },
  { id: "parkingNo", label: "Parking No.", width: "150px" },
  { id: "vehicleType", label: "Vehicle Type", width: "150px" },
  { id: "vehicleNumber", label: "Vehicle Number", width: "150px" },
  { id: "vehicleBrand", label: "Vehicle Brand", width: "150px " },
  { id: "vehicleModel", label: "Vehicle Model", width: "150px " },
  { id: "year", label: "Year", width: "150px    " },
];
const vehicleTypeList = [
  { value: "2", label: "2 Wheelers" },
  {
    value: "4",
    label: "4 Wheelers",
  },
  { value: "Other", label: "Other" },
];

const PrintSocietyParkingReport = () => {
  const [parkingList, setParkingList] = useState([]);
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { societyParkingReport, loading, error } = useSelector(
    (state) => state.societyReportReducer
  );
  const getTableData = () => {
    dispatch(
      geSocietyParkingReport({
        societyId: loginData?.default_community?.community_id,
        flatId: searchParams.get("flatId") || "",
        wingId: searchParams.get("wingId") || "",
        parkingType: searchParams.get("parkingType") || "",
        vehicleType: searchParams.get("vehicleType") || "",
        searchText: searchParams.get("searchText") || "",
        page: searchParams.get("page") || 1,
        limit: searchParams.get("pageLimit") || 10,
      })
    );
  };

  useEffect(() => {
    getTableData();
    setParkingList([]);
    const afterprint = (e) => {
      e.preventDefault();
    };
    window.addEventListener("afterprint", afterprint);
    return () => {
      window.removeEventListener("afterprint", afterprint);
    };
  }, []);

  useEffect(() => {
    if (!loading && societyParkingReport?.parking_list?.length > 0) {
      setTimeout(function () {
        window.print();
      }, 2000);
    }
  }, [dispatch]);

  useEffect(() => {
    setParkingList(
      societyParkingReport?.parking_list?.map((item, index) => ({
        sNo: (
          (societyParkingReport?.current_page_no - 1) *
            societyParkingReport?.page_limit +
          1 +
          index
        )
          ?.toString()
          ?.padStart(2, "0"),
        id: item?.id,
        flatDetails: item.flat_details,
        memberName: item.member_name,
        parkingType: item.parking_type_title || "-",
        qrCode: item?.qr_image || "-",
        stickerNo: item?.parking_lot || "-",
        parkingNo: item?.parking_no || "-",
        vehicleType:
          vehicleTypeList.find((item2) => item2?.value == item?.vehicle_type)
            ?.label || "-",
        vehicleNumber: item?.vehicle_no || "-",
        vehicleBrand: item?.vehicle_brand || "-",
        vehicleModel: item?.model || "-",
        year: item?.year || "-",
      }))
    );
  }, [societyParkingReport.parking_list]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : (
        // <>{parse(chequeDepositSlipReport)}</>
        <div className="m-1">
          {parkingList?.length > 0 && (
            <table className="table-auto mx-auto border-collapse border border-black break-all">
              <caption className="caption-top  mt-4 text-center">
                <h1
                  style={{
                    textDecoration: "underline",
                    fontSize: "22px",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  {loginData?.default_community?.community_name}
                </h1>
                <div className="text-[11px] ">
                  (REG No.{loginData?.default_community?.regn_no} Date{" "}
                  {moment(
                    new Date(loginData?.default_community?.date_of_regn)
                  )?.format("DD-MM-YYYY")}
                  )
                </div>
                <div className="text-[13px] mb-5">
                  {loginData?.default_community?.office_address}
                </div>
              </caption>
              <thead>
                <tr>
                  {headCells.length &&
                    headCells?.map((item) => (
                      <td
                        className="text-sm p-1 font-semibold border border-black"
                        key={item?.id}
                        style={{ width: item.width || "fit-content" }}
                      >
                        {item?.label}
                      </td>
                    ))}
                </tr>
              </thead>
              <tbody>
                {parkingList?.map((row, rowIndex) => (
                  <React.Fragment key={rowIndex}>
                    {headCells.length && (
                      <tr className="text-[#222] text-[11px]">
                        {headCells?.map((item, headIndex) => {
                          return (
                            <td className={`p-1 border border-black align-top`}>
                              {item?.id == "qrCode" ? (
                                <img
                                  src={row[item?.id]}
                                  style={{
                                    width: "50px",
                                    height: "auto",
                                  }}
                                  alt=""
                                />
                              ) : (
                                row[item?.id]
                              )}
                            </td>
                          );
                        })}
                      </tr>
                    )}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          )}
          {!parkingList?.length && !loading && (
            <div className="text-[11px] my-10 text-center italic">
              No Records
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default PrintSocietyParkingReport;
