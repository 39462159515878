import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import ListHeader from '../../../components/ListHeader/ListHeader';
import { createSearchParams, useNavigate, useSearchParams } from 'react-router-dom';
import Layout from '../../Layout/Layout';
import { CircularProgress } from '@mui/material';
import SocietyDownloadHistoryTable from "./SocietyDownloadHistoryTable";
import { downloadUploadHistories } from "../../../redux/actions/ReportActions/SocietyReportActions";
// import { SOCIETY_HISTORY_REPORT_ENDPOINT } from "../../../Routing/routes";

export const headCells = [
    { id: "sNo", label: "Sr. No.", width: "50px" },
    // { id: "uuid", label: "Upload ID", width: "200px" },
    { id: "date", label: "Date", width: "90px" },
    { id: "Module Type", label: "Module Type", width: "70px" },
    { id: "Sub type", label: "Sub Type", sort: true, width: "120px" },
    { id: "Action Type", label: "Type", sort: true, width: "70px" },
    { id: "File status", label: "Status", sort: true, width: "70px" },
    { id: "User Action", label: "Action", sort: false, width: "40px" },
];
const SocietyDownloadHistory = () => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const navigate = useNavigate();
    const { societyDownloadHistory, loading } = useSelector((state) => state.societyReportReducer)
    console.log('societyDownloadHistory', societyDownloadHistory)
    useEffect(() => {
        dispatch(
            downloadUploadHistories({
                societyId: loginData?.default_community?.community_id,
                page: searchParams.get("page") || 1,
                limit: searchParams.get("pageLimit") || 10,
            })
        )
    }, [searchParams])
    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] roundedF-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Import Export History">
                    </ListHeader>
                </div>
                <SocietyDownloadHistoryTable
                    loading={loading}
                    data={societyDownloadHistory?.data?.map((item, index) => ({

                        sNo: (societyDownloadHistory?.meta?.current_page - 1) *
                            societyDownloadHistory?.meta?.per_page +
                            1 +
                            index,
                        mainId:item?.id ,
                        date: item?.created_at,
                        status: item?.status,
                        actionType: item?.action_type,
                        moduleType: item?.module_type,
                        subModuleType: item?.sub_module_type,
                        File: item?.files || [],
                        errorDetails: item?.error_details,
                        metaData: item?.metadata,
                        userId : item?.user_id
                    }))}
                    columns={headCells}
                    checkbox={false}
                    pagination={societyDownloadHistory?.data?.length}
                    totalCount={societyDownloadHistory?.meta?.total}
                    totalPages={societyDownloadHistory?.meta?.last_page}
                    start={(societyDownloadHistory?.meta?.current_page -1) * societyDownloadHistory?.meta?.per_page + 1}
                    end={(societyDownloadHistory?.meta?.current_page -1) * societyDownloadHistory?.meta?.per_page + societyDownloadHistory?.data?.length}
                    currentPage={Number(societyDownloadHistory?.meta?.current_page)}
                    rowsPerPage={searchParams.get("pageLimit") || 10}
                />
                {!societyDownloadHistory?.data?.length && !loading && (
                    <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                        No Records
                    </div>
                )}
            </div>
        </Layout>
    )
}

export default SocietyDownloadHistory;