import React, { useEffect, useState } from "react";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import ButtonIco from "../../../../components/Button/ButtonPrint";
import { DownloadExt, UploadEx } from "../../../../assets";
import Button from "../../../../components/Button/Button";
import { useNavigate } from "react-router-dom";
import Checkbox from "../../../../components/Checkbox/Checkbox";
import DropdownFM2 from "../../../../components/Dropdown/dropdown2";
import { DatePickerComponentFM } from "../../../../components/DatePicker/DatePicker";
import ButtonG from "../../../../components/Button/ButtonG";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { getFlatDetailsWithUserName } from "../../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import { invoiceGeneratorSchema } from "../../../../validationSchema/financeSchema/billingManagementSchema";
import {
  downloadGenerateInvoiceExcelFormat,
  getBillPeriod,
  invoiceGeneratorList,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/GenerateInvoiceAction";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import moment from "moment";
import { MultiSelectFM } from "../../../../components/MultiSelect/MultiSelect";
import SelectModel from "../../../../components/Modal/InvoiceTypeListModal";
import { GENERATE_CURRENT_INVOICE_ENDPOINT, UPLOAD_GENERATE_INVOICE_ENDPOINT } from "../../../../Routing/routes";
import { downloadExcel } from "../../../../utils/helpers/downloadMedia";
import { CircularProgress } from "@mui/material";

const GenerateInvoice = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const { loading } = useSelector(
    (state) => state.generateInvoiceReducer
  );
  const [invoiceTypeList, setInvoiceTypeList] = useState([]);
  const [checked, setChecked] = useState(false);
  const [invoiceTypeId, setInvoiceTypeId] = useState(1)
  const [isOpen, setIsOpen] = useState(false)

  const handleChecked = (event, setFieldValue, key) => {
    setChecked(event.target.checked);
    if (event.target.checked) {
      setFieldValue(key, "Yes");
    } else {
      setFieldValue(key, "No");
    }
  };

  const { loginData } = useSelector((state) => state.loginReducer);
  const { flatsDetailsWithUsername } = useSelector(
    (state) => state.wingsReducer
  );
  const { billPeriod, updateLoader } = useSelector((state) => state.generateInvoiceReducer);

  const handleDownloadSampleAndFormatExcel = () => {
    if(invoiceTypeId){
      const onSucessCallback = (data) => {
        var invoiceName=invoiceTypeList.find((item) => item.id === invoiceTypeId).invoice_title
        downloadExcel({ data, fileName: `${invoiceName} Excel Sample.xlsx` })
        setIsOpen(false)
      };
      const data = {
        invoiceType : invoiceTypeId,
        societyId: loginData?.default_community?.community_id,
        onSucessCallback,
      };
      dispatch(downloadGenerateInvoiceExcelFormat(data));
    }
  };

  const handleSaveClick = async (values, { resetForm }) => {
    let sendRequest = {
      ...values,
      societyId: loginData?.default_community?.community_id,
      onSuccessCallback: () => {
        resetForm();
      },
    };
  };

  useEffect(() => {
    const data = { societyId: loginData?.default_community?.community_id };
    dispatch(getFlatDetailsWithUserName(data));
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))
  }, []);
  useEffect(() => {
    if (typeLists) {
      setInvoiceTypeList(typeLists)
    }
  }, [typeLists])

  const handleGenerateClick = async (formikValues, billPeriod, loginData) => {
    try {
      await dispatch(
        invoiceGeneratorList({
          ...formikValues,
          ...billPeriod,
          societyId: loginData?.default_community?.community_id,
          onSuccessCallback: () => {
            navigate(GENERATE_CURRENT_INVOICE_ENDPOINT);
          },
        })
      );
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <Formik
          initialValues={{
            invoice_type : 1,
            from_date: "",
            to_date: "",
            invoice_date: "",
            payment_due_date: "",
            debitnote_include_as_arrear: "Yes",
            generate: "Yes",
            flat_no: [],
          }}
          enableReinitialize
          validationSchema={invoiceGeneratorSchema}
          onSubmit={handleSaveClick}
        >
          {(formik) => {
            return (
              <Form>
                <div className="border-b border-[#CCC]">
                  <ListHeader leftContent="Generate Invoice">
                    <div className="flex gap-3 text-sm">
                      <button
                        onClick={handleNavigation(UPLOAD_GENERATE_INVOICE_ENDPOINT)}
                        className="flex items-center px-2 text-xs font-semibold bg-white border rounded-lg text-red-650 border-red-650 whitespace-nowrap"
                      >
                        <div className="border-r border-[#CCC]"></div>
                        <UploadEx className="mr-2 fill-red-650" /> Upload Excel
                        Format
                      </button>
                      <ButtonIco
                        icon={DownloadExt}
                        children="Download Excel Format"
                        onClick={() => {
                          setIsOpen(true);
                        }}
                      />
                      <div className="border-r border-[#CCC]"></div>
                      <ButtonG
                        label="Cancel"
                        className="h-8"
                        onClick={handleNavigation(-1)}
                        type="button"
                      />
                      <Button
                        label={
                          <span className="inline-block w-12">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : ("Generate")}
                          </span>
                        }
                        className="h-8 px-4"
                        type="submit"
                        disabled={loading||updateLoader}
                        onClick={() => {
                          handleGenerateClick(
                            formik.values,
                            billPeriod,
                            loginData
                          )
                        }
                        }
                      />

                      {/* <Button label="Save as Draft" type={"button"} /> */}
                    </div>
                  </ListHeader>
                </div>

                <div className="flex flex-col justify-between w-full p-3">
                <div className="flex gap-[80px] items-center my-3">
                    <div className="text-[15px]">Invoice type</div>
                    <div className="flex items-center justify-start gap-4">
                      <DropdownFM2
                        options={
                          invoiceTypeList
                            ? invoiceTypeList?.map(
                              (item) => ({
                                label: item?.invoice_title,
                                value: item?.id,
                              })
                            )
                            : []
                        }
                        placeholder="Invoice type"
                        className="text-[11px] "
                        width="224px"
                        height="32px"
                        name="invoice_type"
                        onSelect={(selectedItem) => {
                          if(formik.values.from_date){
                            dispatch(
                              getBillPeriod({
                                from_date:
                                  moment(formik.values.from_date).format("YYYY-MM-DD"),
                                societyId:
                                  loginData?.default_community?.community_id,
                                invoiceType : selectedItem.value
                              })
                            );
                          }
                          
                          formik.setFieldValue(
                            "invoice_type",
                            selectedItem.value
                          );
                        }}
                        value={
                          formik.values.invoice_type && {
                            value: formik.values.invoice_type,
                            label: invoiceTypeList ? invoiceTypeList?.find((item) => item?.id === parseInt(formik.values.invoice_type))?.invoice_title : "",
                          }
                        }
                      />
                    </div>
                  </div>
                  <div className="flex justify-between w-[60%]">
                    <div className="text-[15px]">Flat No.</div>
                    <div className="flex flex-col">
                      <Checkbox
                        text="Generate All Invoice"
                        className="text-[13px]"
                        initialchecked={formik.values.generate === "Yes"}
                        onChange={(e) => {
                          handleChecked(e, formik.setFieldValue, "generate");
                        }}
                      />
                      <Checkbox
                        text="Include Outstanding Debitnote As Debitnote Arrears In The Bill"
                        className="text-[13px]"
                        initialchecked={
                          formik.values.debitnote_include_as_arrear === "Yes"
                        }
                        onChange={(e) => {
                          handleChecked(
                            e,
                            formik.setFieldValue,
                            "debitnote_include_as_arrear"
                          );
                        }}
                      />
                    </div>
                  </div>
                  {formik.values.generate !== "Yes" && (
                    <div className="flex items-center w-[42%] mt-3">
                      <div className="w-1/2"></div>
                      <div className="flex gap-4 mr-5 w-52">
                        <MultiSelectFM
                          name="flat_no"
                          options={
                            flatsDetailsWithUsername?.map((item) => ({
                              value: item?.flat_id,
                              label: item?.wing_flat_name,
                            })) || []
                          }
                          width="224px"
                          height={"fit-content"}
                          placeholder="Select Wings and Flat"
                          className="text-xs"
                          onSelect={(selectedItem) => {
                            formik.setFieldValue(
                              "flat_no",
                              selectedItem.map((item) => item.value)
                            );
                          }}
                          isMulti={true}
                        />
                      </div>
                    </div>
                  )}
                  <div className="flex gap-[92px] items-center mt-3">
                    <div className="text-[15px]">From Date</div>
                    <div className="flex items-center justify-start gap-4">
                      <DatePickerComponentFM
                        className="w-56 h-9"
                        name={`from_date`}
                        onDateChange={(selectedDate) => {
                          dispatch(
                            getBillPeriod({
                              from_date:
                                moment(selectedDate).format("YYYY-MM-DD"),
                              societyId:
                                loginData?.default_community?.community_id,
                              invoiceType : formik.values.invoice_type
                            })
                          );
                          formik.setFieldValue(`from_date`, moment(selectedDate).format("YYYY-MM-DD"));
                        }}
                        // minDate={new Date()}
                        defaultValue={
                          formik.values.from_date
                            ? new Date(formik.values.from_date)
                            : null
                        }
                      />
                      <span className="text-xs">
                        {billPeriod &&
                         (billPeriod?.to_invoice === billPeriod?.from_invoice ? `${billPeriod?.billperiod}` : `To ${moment(billPeriod?.to_invoice).format("DD/MM/YYYY")}  (${billPeriod?.billperiod})`)}
                      </span>
                    </div>
                  </div>
                  <div className="flex gap-[80px] items-center mt-4 w-[60%]">
                    <div className="text-[15px]">Invoice Date</div>
                    <div className="flex items-center justify-start gap-4">
                      <DatePickerComponentFM
                        className="w-56 h-9"
                        name={`invoice_date`}
                        onDateChange={(selectedDate) => {
                          formik.setFieldValue(
                            `invoice_date`,
                            moment(selectedDate).format("YYYY-MM-DD")
                          );
                        }}
                        // minDate={new Date()}
                        defaultValue={
                          formik.values.invoice_date
                            ? new Date(formik.values.invoice_date)
                            : null
                        }
                      />
                    </div>
                  </div>
                  <div className="flex gap-9 items-center mt-4 w-[60%]">
                    <div className="text-[15px]">Payment Due Date</div>
                    <div className="">
                      <DatePickerComponentFM
                        className="w-56 h-9"
                        name={`payment_due_date`}
                        onDateChange={(selectedDate) => {
                          formik.setFieldValue(
                            `payment_due_date`, moment(selectedDate).format("YYYY-MM-DD")
                          );
                        }}
                        // minDate={new Date()}
                        defaultValue={
                          formik.values.payment_due_date
                            ? new Date(formik.values.payment_due_date)
                            : null
                        }
                      />
                    </div>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <SelectModel
      isOpen={isOpen}
      onClose={()=>setIsOpen(false)}
      selectList={invoiceTypeList ? invoiceTypeList?.map((item) => ({
        value: item.id,
        label: item.invoice_title,
      })) : []}
      setSelectId={setInvoiceTypeId}
      selectId={invoiceTypeId}
      onSubmit={handleDownloadSampleAndFormatExcel}
      loading={false}
      headTitle="Download Sample"
      />    
    </Layout>
  );
};

export default GenerateInvoice;
