import * as type from "../../actions/index.types";

export function EinvoiceReducer(
    state = {
        loading: false,
        EinvoiceReport: [],
        msg: null,
        error: null,
      },
      action
){
    switch(action.type){
        case type.GET_EINVOICE_REPORTS_NEW:
            return { EinvoiceReport: [], loading: true, error: null };
        case type.GET_EINVOICE_REPORTS_NEW_SUCCESS:
            return { loading: false, EinvoiceReport: action.payload };
        case type.GET_EINVOICE_REPORTS_NEW_FAILURE:
            return { EinvoiceReport: [], loading: false, error: action.payload };
        default:
            return state;
    }
}


