import React from "react";
import Calendar from "react-calendar";
// import 'react-calendar/dist/BookingCalender.css';
import './BookingCalender.css';
import moment from "moment";
import { CrossIcon, RightIcon } from "../../assets";

const BookingCalender = ({ selectedDate, calenderData, handleDateChange }) => (
<div className="booking-calender">
    <Calendar
      onClickDay={handleDateChange}
      value={null}
      activeStartDate={selectedDate}
      locale="en-US"
      formatShortWeekday={(locale, date) =>
        new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date)
      }
      formatDay={(locale, date) => {
        return new Intl.DateTimeFormat(locale, { day: '2-digit' }).format(date);
      }} 
      tileClassName={({date})=>{
        let day= date.getDate()
        let month = date.getMonth()+1
        if(month < 10){
          month = '0' + month;
        }
        if(day < 10){
          day = '0'+ day
        }
        const realDate = day+'-'+month+'-'+date.getFullYear();
        if(calenderData.find(val=>val.date===realDate)){
          return 'booking-data';
        }
      }}
      tileContent={({date})=>{
        let day= date.getDate()
        let month = date.getMonth()+1
        if(month < 10){
          month = '0' +month;
        }
        if(day < 10){
          day='0'+day
        }
        const realDate = day+'-'+month+'-'+date.getFullYear();
        let data =calenderData.find(val=>val.date===realDate);
        if(data){
          return <>
            <div className="booking-content overflow-y-auto max-h-[150px] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-200"
            style={{ maxHeight: '155px'}}
            >
              <div className="date mb-2">{moment(realDate, 'DD-MM-YYYY').format('MMM DD, YYYY')}</div>
              {
                data?.entries?.map((item, index)=>(
                  <React.Fragment key={index}>
                    {
                      item.status==="A" ?
                      <div className="text-[#03C708] fill-[#03C708] font-semibold text-xs flex items-center">
                        <div className="mr-1">
                          <RightIcon/>
                        </div>
                        {item?.time}
                      </div>
                      :
                      <div className="text-red-500 fill-red-500 font-semibold text-xs flex items-center">
                        <div className="mr-1">
                          <CrossIcon/>
                        </div>
                        {item?.time}
                      </div>
                    }
                    <div className="text-blue-500 text-xs">Booked By {item.name}</div>
                    {
                      data?.entries?.length -1 !== index &&
                      <div className="border-b my-2"></div>
                    }
                  </React.Fragment>
                ))
              }
            </div>
          </>;
        }
      }}
    />
</div>
);

export default BookingCalender;
