import * as type from "../../actions/index.types";

export function EinvoiceGenerateReducer(
    state = {
        Eloading: false,
        EinvoiceGenerate: [],
        msg: null,
        error: null,
        processingInvoiceIds: {}, 
      },
      action
){
    switch(action.type){
        case type.POST_E_INVOICE_GENERATOR:
            return { ...state,
                processingInvoiceIds: {...state.processingInvoiceIds,[action?.data?.invoiceId]: true,},
                EinvoiceGenerate: [], Eloading: true, error: null };

        case type.POST_E_INVOICE_GENERATOR_SUCCESS:
            return { ...state,
                processingInvoiceIds: {...state.processingInvoiceIds,[action?.data?.invoiceId]: false,},
                Eloading: false, EinvoiceGenerate: action.payload };

        case type.POST_E_INVOICE_GENERATOR_FAILURE:
            return { ...state, 
                processingInvoiceIds: {...state.processingInvoiceIds,[action?.data?.invoiceId]: false},
                EinvoiceGenerate: [], Eloading: false, error: action?.message || "Something went wrong." };
        default:
            return state;
    }
}