import React, { useEffect, useRef, useState } from "react";
import {
  TextInput,
  TextInputFM,
} from "../../../components/InputFields/TextInput";
import { TextAreaFM } from "../../../components/InputFields/TextArea";
import { Formik, Form, ErrorMessage, FieldArray, Field } from "formik";
import {
  MainVisitorTypeOptions,
  ProofTypeOptions,
  societyStaffVehicleTypeOptions,
} from "../../../consts/DropDownOptions";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import Checkbox from "../../../components/Checkbox/Checkbox";
import { DeleteIcon } from "../../../assets";
import Button from "../../../components/Button/Button";
import ButtonG from "../../../components/Button/ButtonG";
import CaptureImage from "../../../components/CaptureImage/CaptureImage";
import { useDispatch } from "react-redux";
import { MainVisitorFormPublicValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import { useSelector } from "react-redux";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import {
  getFlatDetails,
  getWingsAndFlatDetails,
} from "../../../redux/actions/SetupRecordsActions/BuildingActions/WingsAndFlatsAction";
import {
  adNewVisitor,
  getFlatMemberList,
  getVistorData,
  getVistorPurposeList,
  sendOTPToVisitor,
} from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { notify } from "../../../utils/notification";
import {
  isWithin100Meters,
  onlyPhoneKey,
  pasteOnlyNumberKey,
} from "../../../utils/helpers/universalFunctions";
import ImageComponent from "../../../components/ImageComponent/ImageComponent";
import { CircularProgress } from "@mui/material";
import countries from "../../../consts/countries";
import { useSearchParams } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";
import {
  decryptData,
  decryptUrlData,
} from "../../../utils/helpers/encryptionDecryption";

const MainVisitorFormPublic = () => {
  const [currentPosition, setCurrentPosition] = useState(false);
  const [currentPositionMsg, setCurrentPositionMsg] = useState("");

  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { purposeList, updateLoader, mobilPinCheckData, existingVisitorData } =
    useSelector((state) => state.visitorAndVehicleInAndOutReducer);
  const { ipAddress } = useSelector((state) => state.ipAddressReducer);
  const formRef = useRef();
  const handleSaveClick = (values, { resetForm, initialValues }) => {
    dispatch(
      adNewVisitor({
        ...values,
        status: "Pending",
        phone: "+" + values?.mobileCountryCode + "-" + values?.mobileNo,
        ipAddress,
        societyId: searchParams?.get("societyId"),
        latitude: searchParams?.get("latitude"),
        longitude: searchParams?.get("longitude"),
        gate_id: searchParams?.get("id"),
        forPublicUse: 1,
        onSuccessCallback: () => {
          resetForm();
          getLocation();
        },
      })
    );
  };

  const handleDelete = (arrayHelpers, index, entry) => {
    arrayHelpers.remove(index);
  };

  const handleSendPin = (validPhoneNumber, phoneNumber) => {
    if (!phoneNumber) notify("warning", "Please enter contact number.");
    else if (!validPhoneNumber)
      dispatch(
        sendOTPToVisitor({
          phoneNumber: phoneNumber?.replace("+", ""),
          societyId: searchParams.get("societyId"),
          forPublicUse: 1,
        })
      );
    else notify("warning", "Please a valid contact number.");
  };

  const handleSendPIN = (setFieldValue, pin) => {
    if (pin !== mobilPinCheckData?.otp) notify("error", "Invalid OTP");
    else {
      notify("success", "PIN Valid");
      setFieldValue("mobilePINCheck", "Yes");
    }
  };

  const handleBlurOnContact = (error, number) => {
    if (!error)
      dispatch(
        getVistorData({ mobileNo: number?.replace("+", ""), forPublicUse: 1 })
      );
  };

  let watchId;
  const getLocation = async () => {
    if (navigator.geolocation) {
      watchId = await navigator.geolocation.getCurrentPosition(
        showPosition,
        showError,
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      notify("warning", "Geolocation is not supported by this browser");
    }
  };
  function showPosition(position) {
    let getCurrentPosition = "";
    getCurrentPosition = position.coords;
    const userLatitude = getCurrentPosition.latitude;
    const userLongitude = getCurrentPosition.longitude;
    const latitude = searchParams?.get("latitude");
    const longitude = searchParams?.get("longitude");

    if ((userLatitude, userLongitude, latitude, longitude)) {
      const result = isWithin100Meters(
        latitude,
        longitude,
        userLatitude,
        userLongitude
      );
      if (result) setCurrentPosition(true);
      else {
        const msg = "Please stay within 100 meters of the gate";
        notify("warning", msg, 10000);
        setCurrentPositionMsg(msg);
        setCurrentPosition(false);
      }
    } else {
      setCurrentPositionMsg("");
    }
  }

  const showError = (error) => {
    switch (error.code) {
      case error.PERMISSION_DENIED:
        notify("warning", "User denied the request for Geolocation", 10000);
        break;
      case error.POSITION_UNAVAILABLE:
        notify("warning", "Location information is unavailable", 10000);
        break;
      case error.TIMEOUT:
        notify("warning", "The request to get user location timed out", 10000);
        break;
      case error.UNKNOWN_ERROR:
        notify("warning", "An unknown error occurred", 10000);
        break;
      default:
        notify(
          "warning",
          "Geolocation is not supported by this browser",
          10000
        );
        break;
    }
  };
  useEffect(() => {
    const societyId = searchParams.get("societyId") || "";
    const societyType = searchParams.get("societyType") || "";

    dispatch(
      getWingsAndFlatDetails({ societyId, societyType, forPublicUse: 1 })
    );
    dispatch(getVistorPurposeList({ societyId, forPublicUse: 1 }));
    setCurrentPositionMsg(false);
    setCurrentPosition(false);
    getLocation();
  }, [searchParams]);

  useEffect(() => {
    const metaDescription = document.createElement("meta");
    metaDescription.name = "viewport";
    metaDescription.content = "width=device-width,initial-scale=1";

    // Check if a meta tag with the name 'description' already exists
    const existingMeta = document.querySelector('meta[name="viewport"]');
    if (existingMeta) {
      existingMeta.parentNode.replaceChild(metaDescription, existingMeta);
    } else {
      document.head.appendChild(metaDescription);
    }

    let values = decryptUrlData(decodeURIComponent(searchParams.get("key")));

    values = values?.split("&");
    if (values?.length > 0) {
      values.map((item) => {
        searchParams.set(item.split("=")[0], item.split("=")[1]);
      });
      setSearchParams(searchParams);
    }
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  useEffect(() => {
    let { values } = formRef?.current;

    formRef?.current?.setValues({
      visitorId: existingVisitorData?.visitor_id || "",
      photo: existingVisitorData?.profile_pic || "",
      useOld: !!existingVisitorData?.profile_pic || false,
      firstName: existingVisitorData?.first_name || "",
      lastName: existingVisitorData?.last_name || "",
      email: existingVisitorData?.email || "",

      mobileCountryCode: "91",
      countryISO: "IN",
      countryId: 1,
      mobileNo: values?.mobileNo,

      phone: values.phone,
      count: values.count,
      idProofType: values.idProofType,
      idProofNo: values.idProofNo,
      vehicleType: existingVisitorData?.vehicle_type || "",
      vehicleNumber: values.vehicleNumber,
      visitorType: values?.visitorType || "",
      from: existingVisitorData?.vfrom || "",
      laptop: "No",
      belongings: existingVisitorData?.belongings || "",
      whereToVisit: values.whereToVisit?.map((item) => ({
        wingId: item.phone,
        flatList: item.flatList,
        flatId: item.phone,
        memberId: item.phone,
        memberMobileNo: item.phone,
        memberList: item.memberList,
      })),
      purposeId: values.purposeId,
      purposeName: values.purposeName,
      pin: values.pin,
      mobilePINCheck: "No",
      addAsFrequentVisitor: values.addAsFrequentVisitor,
      sendSMS: values.sendSMS,
      comment: values.comment,
    });
  }, [existingVisitorData]);

  return (
    <div className="min-h-[100vh] flex flex-wrap items-center justify-center">
      <Formik
        initialValues={{
          photo: existingVisitorData?.profile_pic || "",
          useOld: !!existingVisitorData?.profile_pic || false,
          firstName: existingVisitorData?.first_name || "",
          lastName: existingVisitorData?.last_name || "",
          email: existingVisitorData?.email || "",
          mobileCountryCode: "91",
          countryISO: "IN",
          countryId: 1,
          mobileNo: existingVisitorData?.mobile_number || "",
          phone: "",
          count: 1,
          idProofType: "",
          idProofNo: "",
          vehicleType: existingVisitorData?.vehicle_type || "",
          vehicleNumber: "",
          visitorType: "Visitor",
          from: existingVisitorData?.vfrom || "",
          laptop: "No",
          belongings: existingVisitorData?.belongings || "",
          whereToVisit: [
            {
              wingId: "",
              flatList: [],
              flatId: "",
              memberId: "",
              memberMobileNo: "",
              memberList: [],
            },
          ],
          purposeId: "",
          purposeName: "",
          pin: "",
          mobilePINCheck: "No",
          addAsFrequentVisitor: 0,
          sendSMS: 0,
          comment: "",
        }}
        onSubmit={handleSaveClick}
        validationSchema={MainVisitorFormPublicValidation}
        innerRef={(f) => (formRef.current = f)}
      >
        {({ values, errors, resetForm, setFieldTouched, setFieldValue }) => {
          return searchParams?.get("latitude") &&
            searchParams?.get("longitude") &&
            currentPosition ? (
            <div className="my-3 w-full max-w-[998px] bg-white mx-auto border border-[#CCC] rounded-lg ">
              <div className="p-3 border-b border-[#ccc] flex flex-wrap justify-between gap-2 mb-2">
                <h1>
                  Society Name:{" "}
                  <span className="font-semibold">
                    {searchParams.get("societyTitle") || "-"}
                  </span>
                </h1>
                <h1>
                  Gate Name:{" "}
                  <span className="font-semibold">
                    {" "}
                    {searchParams.get("gateTitle") || "-"}
                  </span>
                </h1>
              </div>
              <Form>
                {
                  <>
                    <div className=" p-2">
                      <div className="flex flex-wrap ">
                        <div className="sm:w-1/2 w-full sm:pr-2.5">
                          <div className="flex flex-wrap pb-2 sm:hidden ">
                            {existingVisitorData?.profile_pic_url && (
                              <div className="w-1/2 ">
                                <span className="mt-2 text-sm">
                                  {existingVisitorData?.profile_pic_url && (
                                    <>
                                      <ImageComponent
                                        defaultUrl={
                                          existingVisitorData?.profile_pic_url
                                        }
                                        width={32}
                                        height={32}
                                      />
                                      <Checkbox
                                        text={"Use Old"}
                                        className={"text-xs mt-2"}
                                        initialchecked={values.useOld}
                                        onChange={(e) => {
                                          setFieldValue(
                                            "photo",
                                            e.target.checked
                                              ? existingVisitorData?.profile_pic
                                              : ""
                                          );
                                          setFieldValue(
                                            "useOld",
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </>
                                  )}
                                </span>
                              </div>
                            )}
                            <div className="w-1/2 ">
                              <CaptureImage
                                image={values.photo}
                                setImage={(url) => {
                                  setFieldValue("photo", url);
                                }}
                                className={"!h-auto flex-wrap gap-2"}
                                className2={"pb-[75%] w-full me-0"}
                                videoClassName={
                                  "!w-full !h-full absolute start-0 top-0"
                                }
                                canvasClassName={"!w-full !h-full"}
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">First Name</span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <TextInput
                                placeholder="Enter First Name"
                                className="w-full"
                                name="firstName"
                                onChange={(e) => {
                                  setFieldValue(`firstName`, e.target.value);
                                }}
                                value={values.firstName}
                              />
                              <div
                                className={`text-red-500 text-xs italic h-auto text-wrap break-words`}
                              >
                                {<ErrorMessage name={`firstName`} />}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-wrap ">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">Country</span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <DropdownFM2
                                options={
                                  countries?.map((item) => ({
                                    label: `${item?.name?.toUpperCase()} (${
                                      item?.phonecode
                                    })`,
                                    value: item?.phonecode?.replace("+", ""),
                                    iso: item.iso,
                                    id: item?.id,
                                  })) || []
                                }
                                width={"100%"}
                                className="text-xs"
                                placeholder={"Select Country"}
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    `mobileCountryCode`,
                                    selectedItem.value
                                  );
                                  setFieldValue(`countryId`, selectedItem.id);
                                  setFieldValue(`countryISO`, selectedItem.iso);
                                }}
                                height="38px"
                                name={`mobileCountryCode`}
                                value={
                                  values.mobileCountryCode && {
                                    label: `${countries
                                      ?.find(
                                        (item) =>
                                          item.phonecode?.replace("+", "") ===
                                          values?.mobileCountryCode?.replace(
                                            "+",
                                            ""
                                          )
                                      )
                                      ?.name?.toUpperCase()} (+${
                                      values.mobileCountryCode
                                    })`,
                                    value: values.mobileCountryCode,
                                    iso: values.countryISO,
                                    id: values.countryId,
                                  }
                                }
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                Contact Number
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <div
                                className={`w-full mb-2 text-sm border border-[#CCCCCC] rounded-md p-2 bg-[#F9FAFC]`}
                              >
                                <input
                                  type="text"
                                  placeholder="Enter Number"
                                  className={`
                                  ${
                                    values?.mobilePINCheck == "Yes"
                                      ? "pointer-events-none opacity-75"
                                      : ""
                                  } 
                                  bg-[#F9FAFC] outline-none border-none w-full placeholder-italic text-black not-italic text-xs font-semibold`}
                                  autoComplete="off"
                                  maxLength={16}
                                  value={values.mobileNo}
                                  onChange={(e) => {
                                    setFieldValue(
                                      "phone",
                                      "+" +
                                        values?.mobileCountryCode +
                                        "-" +
                                        e.target.value
                                    );
                                    setFieldValue("mobileNo", e.target.value);
                                  }}
                                  onKeyPress={onlyPhoneKey}
                                  onPaste={pasteOnlyNumberKey}
                                  onBlur={(e) => {
                                    setFieldTouched("mobileNo", true);
                                    handleBlurOnContact(
                                      errors.mobileNo,
                                      values.mobileCountryCode +
                                        "-" +
                                        values.mobileNo
                                    );
                                    setFieldValue("mobileNo", e.target.value);
                                  }}
                                />
                              </div>
                              {errors?.mobileNo && (
                                <div
                                  className={`text-red-500 text-xs italic  text-wrap break-words`}
                                >
                                  <ErrorMessage name={"mobileNo"} />
                                </div>
                              )}

                              {mobilPinCheckData?.phone != values?.mobileNo ? (
                                <span
                                  className="text-red-650 underline cursor-pointer text-xs mb-2"
                                  onClick={() => {
                                    handleSendPin(errors.phone, values.phone);
                                    setFieldValue("mobilePINCheck", "No");
                                  }}
                                >
                                  Send Pin
                                </span>
                              ) : (
                                values?.mobilePINCheck == "No" &&
                                mobilPinCheckData?.phone ==
                                  values?.mobileNo && (
                                  <>
                                    <TextInputFM
                                      label=""
                                      placeholder="Enter Pin"
                                      className={`w-full ${
                                        values?.mobilePINCheck == "Yes"
                                          ? "pointer-events-none opacity-75"
                                          : ""
                                      }`}
                                      name="pin"
                                      type="number"
                                      maxLength={6}
                                    />

                                    <Button
                                      type="button"
                                      className={
                                        values.pin?.length < 6
                                          ? "opacity-50 "
                                          : ""
                                      }
                                      disabled={values.pin?.length < 6}
                                      onClick={() => {
                                        handleSendPIN(
                                          setFieldValue,
                                          values.pin
                                        );
                                      }}
                                      label="Check Pin"
                                    />
                                  </>
                                )
                              )}
                              {mobilPinCheckData?.phone == values?.mobileNo &&
                                values?.mobilePINCheck == "No" && (
                                  <div>
                                    <span className="text-red-650 text-xs mb-2">
                                      OTP Sent Successfully
                                    </span>
                                  </div>
                                )}
                              {mobilPinCheckData?.phone == values?.mobileNo &&
                                values?.mobilePINCheck == "Yes" && (
                                  <div>
                                    <span className="text-red-650 text-xs mb-2">
                                      <CheckBoxIcon fontSize="small" /> OTP
                                      Varified
                                    </span>
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="flex flex-wrap ">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                Id Proof Type
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <DropdownFM2
                                options={ProofTypeOptions}
                                width={"100%"}
                                className="text-xs"
                                placeholder="Select ID Proof Type"
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    `idProofType`,
                                    selectedItem.value
                                  );
                                }}
                                height="38px"
                                name={`idProofType`}
                                value={
                                  values.idProofType === ""
                                    ? null
                                    : {
                                        label: ProofTypeOptions?.find(
                                          (item) =>
                                            item?.value === values.idProofType
                                        )?.label,
                                        value: values.idProofType,
                                      }
                                }
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                ID Proof Number
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <TextInput
                                type="alphaNumber"
                                maxLength={12}
                                placeholder="Enter Number"
                                className="w-full"
                                name="idProofNo"
                                onChange={(e) => {
                                  setFieldValue(`idProofNo`, e.target.value);
                                }}
                                value={values.idProofNo}
                              />
                              <div
                                className={`text-red-500 text-xs italic h-auto text-wrap break-words`}
                              >
                                {<ErrorMessage name={`idProofNo`} />}
                              </div>
                            </div>
                          </div>

                          <div className="flex flex-wrap ">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">Vehicle Type</span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <DropdownFM2
                                options={societyStaffVehicleTypeOptions}
                                width={"100%"}
                                className="text-xs"
                                placeholder="Select Vehicle Type"
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    `vehicleType`,
                                    selectedItem.value
                                  );
                                }}
                                height="38px"
                                name={`vehicleType`}
                                value={{
                                  label: societyStaffVehicleTypeOptions?.find(
                                    (item) => item?.value === values.vehicleType
                                  )?.label,
                                  value: values.vehicleType,
                                }}
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                Vehicle Number
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <TextInput
                                type="alphaNumber"
                                maxLength={10}
                                placeholder="Enter Number"
                                className="w-full"
                                name="vehicleNumber"
                                onChange={(e) => {
                                  setFieldValue(
                                    `vehicleNumber`,
                                    e.target.value
                                  );
                                }}
                                value={values.vehicleNumber}
                              />
                              <div
                                className={`text-red-500 text-xs italic h-auto text-wrap break-words`}
                              >
                                {<ErrorMessage name={`vehicleNumber`} />}
                              </div>
                            </div>
                          </div>
                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">Coming From</span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <TextInput
                                placeholder="Enter First "
                                className="w-full"
                                name="from"
                                onChange={(e) => {
                                  setFieldValue(`from`, e.target.value);
                                }}
                                value={values.from}
                              />
                              <div
                                className={`text-red-500 text-xs italic h-auto text-wrap break-words`}
                              >
                                {<ErrorMessage name={`from`} />}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="sm:w-1/2 w-full sm:ps-2.5 ">
                          <div className="sm:flex flex-wrap pb-2 hidden">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                {existingVisitorData?.profile_pic_url && (
                                  <>
                                    <ImageComponent
                                      defaultUrl={
                                        existingVisitorData?.profile_pic_url
                                      }
                                      width={32}
                                      height={32}
                                    />
                                    <Checkbox
                                      text={"Use Old"}
                                      className={"text-xs mt-2"}
                                      initialchecked={values.useOld}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "photo",
                                          e.target.checked
                                            ? existingVisitorData?.profile_pic
                                            : ""
                                        );
                                        setFieldValue(
                                          "useOld",
                                          e.target.checked
                                        );
                                      }}
                                    />
                                  </>
                                )}
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <CaptureImage
                                image={values.photo}
                                setImage={(url) => {
                                  setFieldValue("photo", url);
                                }}
                                className={"!h-auto flex-wrap gap-2"}
                                className2={"pb-[75%] w-full me-0"}
                                videoClassName={
                                  "!w-full !h-full absolute start-0 top-0"
                                }
                                canvasClassName={"!w-full !h-full"}
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap ">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">
                                Visit Purpose
                              </span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <DropdownFM2
                                options={
                                  purposeList?.map((item) => ({
                                    value: item?.purpose_id,
                                    label: item?.purpose,
                                  })) || []
                                }
                                width={"100%"}
                                className="text-xs"
                                placeholder={"Select Visit"}
                                onSelect={(selectedItem) => {
                                  setFieldValue(
                                    `purposeId`,
                                    selectedItem.value
                                  );
                                  setFieldValue(
                                    `purposeName`,
                                    selectedItem.label
                                  );
                                }}
                                height="36px"
                                name={`purposeId`}
                                {...(values.purposeId === "" && {
                                  value: null,
                                })}
                              />
                            </div>
                          </div>

                          <div className="flex flex-wrap pb-2">
                            <div className="sm:w-1/2 w-full">
                              <span className="mt-2 text-sm">Comment</span>
                            </div>
                            <div className="sm:w-1/2 w-full">
                              <div
                                className={` bg-[#F9FAFC] text-sm border border-[#CCC] rounded-md p-2 `}
                              >
                                <Field
                                  as="textarea"
                                  placeholder={"Enter Comment"}
                                  className="bg-[#F9FAFC] outline-none border-none placeholder-italic h-full w-full text-black not-italic resize-none text-xs font-semibold"
                                  name={"comment"}
                                  rows={4 || 1}
                                />
                              </div>
                              <div
                                className={`text-red-500 text-xs italic h-auto text-wrap break-words`}
                              >
                                {<ErrorMessage name={`comment`} />}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC]"></div>
                    <div className="text-sm flex flex-wrap justify-between p-2 py-4 sm:pr-5">
                      <div className="sm:w-1/4 sm:mb-0 mb-2">
                        Select Flat Details
                      </div>
                      <div className="sm:w-3/4 w-full">
                        <FieldArray
                          name="whereToVisit"
                          render={(arrayHelpers) => (
                            <div>
                              {values.whereToVisit &&
                                values.whereToVisit.length > 0 &&
                                values.whereToVisit.map((entry, index) => (
                                  <AddDuplication
                                    key={index}
                                    index={index}
                                    onDelete={() => {
                                      handleDelete(arrayHelpers, index, entry);
                                    }}
                                    values={values}
                                    setFieldValue={setFieldValue}
                                  />
                                ))}
                              <button
                                type="button"
                                onClick={() =>
                                  arrayHelpers.push({
                                    wingId: "",
                                    flatList: [],
                                    flatId: "",
                                    memberList: [],
                                    memberId: "",
                                  })
                                }
                                className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
                              >
                                Add More
                              </button>
                            </div>
                          )}
                        ></FieldArray>
                      </div>
                    </div>
                    <div className="border-b border-[#CCC] "></div>

                    <div className=" p-4 flex gap-2 justify-center">
                      <ButtonG
                        label="Clear All"
                        type="button"
                        onClick={() => {
                          resetForm();
                          setFieldValue("profilePic", "");
                        }}
                        className="px-4 h-8"
                      />
                      <Button
                        className="h-8"
                        label={
                          <span className="w-20 inline-block">
                            {updateLoader ? (
                              <CircularProgress
                                sx={{ color: "white" }}
                                size={17}
                              />
                            ) : (
                              "Save"
                            )}
                          </span>
                        }
                      />
                    </div>
                  </>
                }
              </Form>
            </div>
          ) : (
            <h1 className="min-h-[100vh] w-full flex justify-center items-center  flex-wrap text-[40px] font-semibold">
              {currentPositionMsg ? currentPositionMsg : <Loader />}
            </h1>
          );
        }}
      </Formik>
      {/* </div> */}
    </div>
  );
};

export const AddDuplication = ({ values, index, onDelete, setFieldValue }) => {
  const dispatch = useDispatch();
  const [searchParams, setSearchParams] = useSearchParams();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { wingsData, flatsData } = useSelector((state) => state.wingsReducer);
  const { memberList } = useSelector(
    (state) => state.visitorAndVehicleInAndOutReducer
  );

  useEffect(() => {
    if (values.whereToVisit[index].wingId === flatsData?.wing?.wing_id)
      setFieldValue(
        `whereToVisit.${index}.flatList`,
        flatsData?.manage_flat_details
      );
  }, [flatsData]);

  useEffect(() => {
    if (values.whereToVisit[index].flatId === memberList?.flat_id)
      setFieldValue(`whereToVisit.${index}.memberList`, memberList?.flat_users);
  }, [memberList]);

  return (
    <div className="flex flex-wrap ">
      <div className="sm:w-[20%] w-[40%] sm:pr-2 pr-0 ps-0">
        <DropdownFM2
          options={
            wingsData?.map((item) => ({
              value: item?.wing_id,
              label: item?.number,
            })) || []
          }
          width={"100%"}
          className="text-xs"
          placeholder={"Wing"}
          onSelect={(selectedItem) => {
            if (selectedItem.value !== values.whereToVisit[index].wingId) {
              setFieldValue(`whereToVisit.${index}.wingId`, selectedItem.value);
              setFieldValue(`whereToVisit.${index}.flatId`, "");
              setFieldValue(`whereToVisit.${index}.memberId`, "");
              dispatch(
                getFlatDetails({
                  societyId: searchParams.get("societyId"),
                  wingId: selectedItem?.value,
                  forPublicUse: 1,
                })
              );
            }
          }}
          height="36px"
          name={`whereToVisit.${index}.wingId`}
          {...(!values.whereToVisit[index].wingId && { value: null })}
        />
      </div>
      <div className="sm:w-[25%] w-[50%] px-2">
        <DropdownFM2
          options={
            values.whereToVisit[index].wingId
              ? values.whereToVisit[index].flatList?.map((item) => ({
                  value: item?.flat_id,
                  label: item?.flat_no,
                }))
              : []
          }
          name={`whereToVisit.${index}.flatId`}
          width="100%"
          height="36px"
          placeholder="Flat/Unit"
          onSelect={(selectedItem) => {
            if (selectedItem.value !== values.whereToVisit[index].flatId) {
              setFieldValue(`whereToVisit.${index}.flatId`, selectedItem.value);
              setFieldValue(`whereToVisit.${index}.memberId`, "");
              dispatch(
                getFlatMemberList({
                  flatId: selectedItem?.value,
                  forPublicUse: 1,
                })
              );
            }
          }}
          {...(!values.whereToVisit[index].flatId && { value: null })}
        />
      </div>
      <div className="sm:w-[30%] w-[90%] px-2 ps-0 sm:ps-2">
        <DropdownFM2
          options={
            values.whereToVisit[index].flatId
              ? values.whereToVisit[index].memberList?.map((item) => ({
                  value: item?.member_id,
                  label: item?.name,
                  memberMobileNo: `+${
                    item?.mobile_country_code ? item?.mobile_country_code : "91"
                  }-${item?.mobile_no}`,
                }))
              : []
          }
          name={`whereToVisit.${index}.memberId`}
          width="100%"
          height="36px"
          placeholder="Member Name"
          onSelect={(selectedItem) => {
            setFieldValue(`whereToVisit.${index}.memberId`, selectedItem.value);
            setFieldValue(
              `whereToVisit.${index}.memberMobileNo`,
              selectedItem.memberMobileNo
            );
          }}
          {...(!values.whereToVisit[index].memberId && { value: null })}
        />
      </div>
      <div className="sm:w-[2%]">
        {values.whereToVisit?.length > 1 && (
          <DeleteIcon
            onClick={onDelete}
            className="fill-red-650 mt-3 cursor-pointer"
          />
        )}
      </div>
    </div>
  );
};

export default MainVisitorFormPublic;
