import React, { useEffect } from "react";
import Routers from "./Routing/Routers";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch, useSelector } from "react-redux";
import { relogin } from "./redux/actions/AuthActions/LoginAction";
import ScrollToTop from './components/ScrollToTop/ScrollToTop'
import webTokenStorer from "./utils/webStorer";
import './App.css';
import { getIPAddresss } from './redux/actions/IPAddressActions/IPAddressAction'

function App() {
  const dispatch = useDispatch();
  const { loading } = useSelector(state => state.loginReducer)
  const { ipAddress } = useSelector(state => state.ipAddressReducer)

  useEffect(() => {
    dispatch(getIPAddresss())
    const localData = webTokenStorer.getToken()
    if (localData && ipAddress && !loading) {
      let data = {
        "society_id": localData?.society_id,
        "user_role": localData?.user_role,
        "house_unique_id": localData?.house_unique_id,
        ipAddress,
        pwd: localData?.pwd,
      }
      dispatch(relogin(data))
    }
  }, [ipAddress])

  return (
    <div className="App">
      <ScrollToTop />
      <Routers />
      <ToastContainer />
    </div>
  );
}

export default App;
