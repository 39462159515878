import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { useDispatch } from "react-redux";
import { getDownloadFileFromGcs } from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        fontWeight: 600,
        zIndex: 0,
        color: "#AAAAAA",
        fontSize: "13px",
        padding: "12px 8px 12px 0",
        wordBreak: "break-word",
        textAlign: "center",
    },
    [`&.${tableCellClasses.body}`]: {
        borderBottom: "none",
        fontSize: "13px",
        padding: "12px 8px 12px 3px",
        wordBreak: "break-word",
        cursor: "pointer",
        textAlign: "center",
        display: "table-cell",
        justifyContent: "center",
    },
}));

const SocietyFileTable = ({
    loading,
    data,
    columns,
    userId
}) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const handleDownload = (responseData) => {
        dispatch(
            getDownloadFileFromGcs({
                path: responseData,
                onSuccessCallback: (excelData) => {
                    try {
                        var url = window.URL.createObjectURL(new Blob([excelData]));
                        var a = document.createElement('a');
                        a.href = url;
                        a.download = responseData;
                        document.body.appendChild(a);
                        a.click();
                        a.remove();
                    } catch (e) {
                        console.error("Error in downloading file", e);
                    }
                },
                onFailureCallback: (responseData) => {
                    console.error("Download failed. Invalid format or issue with file.");
                },
            })
        );
    };
    return (
        <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
            <TableContainer
                className="border-t border-[#CCC] "
            >
                <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                            {columns?.map((column) => (
                                <TableCellStyled
                                    size="small"
                                    key={column.id + "heading"}
                                    // align={column.numeric ? "right" : "left"}
                                    align="center"
                                    sx={{
                                        width: column.width || "fit-content",
                                        minWidth: column.width || "fit-content",
                                        alignCenter: column.align
                                    }}
                                >
                                    {column.label}
                                </TableCellStyled>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {!!data?.length &&
                                data.map((row, rowIndex) => (
                                    <TableRowStyled
                                        key={row?.id || rowIndex}
                                        role="row"
                                        tabIndex={-1}
                                    >
                                        <TableCellStyled size="small" align="left">
                                            {row?.Date}
                                        </TableCellStyled>
                                        <TableCellStyled size="small" align="left">
                                            {row?.type}
                                        </TableCellStyled>
                                        <TableCellStyled size="small" align="left">
                                            {row?.FileName}
                                        </TableCellStyled>
                                        <TableCellStyled size="small" align="left">
                                            {userId} 
                                        </TableCellStyled>
                                        <TableCellStyled className="centered-cell">
                                            {row?.filePath && (
                                                <button
                                                    className="download-btn hover:fill-red-650 ms-2"
                                                    type="button"
                                                    onClick={() => {
                                                        handleDownload(row?.filePath);
                                                    }}
                                                >
                                                    <DownloadIcon
                                                        fontSize="small"
                                                        style={{
                                                            color: "#cf001c ",
                                                            transition: "color 0.3s ease",
                                                        }}
                                                        className="hover:text-red-600"
                                                    />
                                                </button>
                                            )}

                                        </TableCellStyled>

                                    </TableRowStyled>
                                ))}
                        </>
                    </TableBody>
                </Table>
            </TableContainer>

        </Box>
    );
};

SocietyFileTable.propTypes = {
    columns: PropTypes.array.isRequired,
    defaultSortField: PropTypes.string,
};

export default SocietyFileTable;
