import { notify } from "../../../../utils/notification";
import apiEndpoints from "../../../api";
import axiosInstance2 from "../../../axiosInstance2";
import { logOut } from "../../../../utils/helpers/logOut";
import { errorMsg } from "../../../../consts/responseMessages";

export async function getDebitNoteCurrentReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invoice_no: action.data?.subscriber_name,
    subscriber_name: "",
    payment_status: action.data?.status,
    from_amount: action.data?.from_amount,
    to_amount: action.data?.to_amount,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    page: action.data?.page,
    limit: action.data?.pageLimit,
    search_by: action.data?.searchBy,
    // bill_type: action.data?.bill_type,
    debitor_type: action.data?.debitor_type,
    typeid: action.data?.InvoiceType,
    wing_id: action.data?.wingId,
    flat_id: action.data?.flatId
  };
  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_DEBIT_NOTE_CURRENT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebitNoteActionListtReq(action) {

  return axiosInstance2
    .get(`${apiEndpoints.MANAGE_DEBIT_NOTE_ACTION_LIST_API}`)
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function bulkVerifyPostDebitNoteUpdateReq(action) {
  const BODY = {
    pageid: action.data?.society_id,
    paymentid: action.data?.receipt_id,
    bankid: action.data?.bank_id
  }

  return axiosInstance2
    .post(`${apiEndpoints.BULK_VERIFY_POST_MANAGE_DEBIT_NOTE_UPDATE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function resendDebitNoteCurrentEmailSmsReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invIds
  }

  return axiosInstance2
    .post(`${apiEndpoints.RESEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function bulkMakePaymentDebitNoteSaveReq(action) {
  let BODY = {
    pageid: action.data?.societyId,
    bankid: action.data?.bankId,
    paymentdetail: {}
  }
  action.data?.invoiceList?.map((item, index) => BODY["paymentdetail"][item.invoiceid] = {
    membername: item?.member_name,
    wing_no: item?.wing_no || "",
    flat_no: item?.flat_no || "",
    invoiceid: item?.invoiceid,
    invoice_total: item?.invoice_total,
    invoice_date: item?.invoice_date,
    invoice_no: item?.invoice_no,
    member_name: item?.member_name,
    modeofpayment: item?.modeofpayment || "",
    dueamount: item?.dueamount,
    amount: item?.amount,
    chequenumber: item?.chequenumber || "",
    chequedate: item?.chequedate || "",
    bankname: item?.bankname || "",
    branchname: item?.branchname || "",
    paymentdate: item?.paymentdate || "",
    cleardate: item?.cleardate || "",

  })


  return axiosInstance2
    .post(`${apiEndpoints.BULK_MAKE_PAYMENT_MANAGE_DEBIT_NOTE_SAVE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebitNoteReceiptPrintReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invid,
  };

  return axiosInstance2
    .post(`${apiEndpoints.DEBIT_NOTE_RECEIPT_PRINT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function resendDebitNoteReceiptEmailSmsReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invIds,
    notificationType: action.data?.notificationType
  }

  return axiosInstance2
    .post(`${apiEndpoints.RESEND_DEBIT_NOTE_RECEIPT_EMAIL_SMS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function deleteDebitNoteReceiptReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invIds,
  }

  return axiosInstance2
    .delete(`${apiEndpoints.DELETE_DEBIT_NOTE_RECEIPT_API}`, { data: BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getSingleDebitNoteReq(action) {
  const BODY = {
    id: action.data?.societyId,
    debit_note_id: action.data?.debit_note_id,
  };
  return axiosInstance2
    .post(`${apiEndpoints.SINGLE_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function rejectSingleReceiptDebitNoteReq(action) {
  const BODY = {
    id: action.data?.societyId,
    paymentId: action.data?.paymentInvoiceId,
    sendRejectEmail: action.data?.sendRejectEmail ? 1 : 0,
    reasonType: action.data?.reasonType,
    reason: action.data?.reason,
  }

  return axiosInstance2
    .delete(`${apiEndpoints.REJECT_SINGLE_RECEIPT_DEBIT_NOTE_API}`, { data: BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}


export async function editSingleDebitNoteDetailsReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    debitnoteid: action.data?.invid,
  };

  return axiosInstance2
    .post(`${apiEndpoints.EDIT_SINGLE_DEBIT_NOTE_DETAILS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function editSingleDebitNoteSaveReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    // postDraftBill for post draft debit note or else keep empty for edit
    // reviseBill for update current debit note
    generationStatus: "reviseBill",
    debitnote_details: {}
  };
  BODY.debitnote_details[Object.entries(action.data?.data)[0]?.[0]] = {
    id: action.data?.id,
    debitor_id: action.data?.issuedTo == "tenant" ? action.data?.tenantId : action.data?.issuedTo == "vendor" ? action.data?.nonMemberId : "",
    issued_type: action.data?.issuedTo.toLowerCase(),
    invoice_date: action.data?.invoiceDate,
    due_date: action.data?.dueDate,
    debit_code: action.data?.debitAccount,
    debitnote_header: action.data?.data?.debitnote_header?.header,
    journal_id: Object.entries(action.data?.data)[0]?.[1]?.journal_id,
    gst_registration_no: Object.entries(action.data?.data)[0]?.[1]?.journal_id,
    bill_type: "debitnote",
    wing_id: action.data?.issuedTo == "member" ? action.data?.wingId : "",
    flat_id: action.data?.issuedTo == "member" ? action.data?.flatId : "",
    sgst_amount: action.data?.sgst_amount ? action.data?.sgst_amount : 0,
    cgst_amount: action.data?.cgst_amount ? action.data?.cgst_amount : 0,
    igst_amount: action.data?.igst_amount ? action.data?.igst_amount : 0,

    // roundoff_amount: action.data?.roundoff == 1 ? Math.round(Number(action.data?.subTotal) + (Number(action.data?.interest) + Number(action.data?.principal_arrear) + Number(action.data?.interest_arrear))) - (Number(action.data?.subTotal) + (Number(action.data?.interest) + Number(action.data?.principal_arrear) + Number(action.data?.interest_arrear))) : 0,
    roundoff_amount: action.data?.roundoff == 1 ? Math.round(Number(action.data?.subTotal)) - Number(action.data?.subTotal) : 0,

    head_detail: [
      ...action.data?.details?.map(item => ({
        id: item?.id,
        cause: item?.cause,
        amount: item?.amount,
        orderid: item?.orderid,
        GLmapping: item?.GLmapping,
        hsn_code: item?.hsn_code,
        gstapplicable: item?.gstapplicable ? item?.gstapplicable : "N",
        servicegoods: item?.servicegoods,
        from_date: item?.from_date ? item?.from_date : "",
        to_date: item?.from_date ? item?.to_date : "",
        description: item?.description ? item?.description : "",
        tax_amount: item?.gstapplicable == "Y" ? (action.data?.cgst_rate && action.data?.sgst_rate) ? Number(item?.amount) * (Number(action.data?.cgst_rate) + Number(action.data?.sgst_rate)) / 100 : 0 : 0,
        igst_amount: item?.gstapplicable == "Y" ? (action.data?.igst_rate) ? Number(item?.amount) * Number(action.data?.igst_rate) / 100 : 0 : 0,
      }))
    ],

    principal_arrear: action.data?.principal_arrear,
    interest_arrear: action.data?.interest_arrear,
    interest: action.data?.interest,

    lastdebitnote: Object.entries(action.data?.data)[0]?.[1]?.jointmembersid,
    send_email: (action.data?.sendSMS == "Y") && (action.data?.is_draft == 0) ? 1 : 0,
    send_sms: (action.data?.sendEmail == "Y") && (action.data?.is_draft == 0) ? 1 : 0,

  }


  return axiosInstance2
    .post(`${apiEndpoints.EDIT_SINGLE_DEBIT_NOTE_SAVE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}



export async function adjustPaymentDetailReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
  };
  return axiosInstance2
    .post(`${apiEndpoints.ADJUST_PAYMENT_DETAILS_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function adjustPaymentSaveReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
    adjustment_date: action.data?.date,
    debitcode: action.data?.debitac,
    amount: action.data?.amount,
    remarks: action.data?.remark
  };
  return axiosInstance2
    .post(`${apiEndpoints.ADJUST_PAYMENT_SAVE_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function makePaymentDetailReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    invid: action.data?.invid,
    payid: action.data?.payid || "",
  };
  return axiosInstance2
    .post(`${apiEndpoints.MAKE_PAYMENT_DETAILS_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}
export async function makePaymentSaveReq(action) {

  const BODY = {
    pageid: action.data?.societyId,
    invid: action?.data?.invid,
    amount: action?.data?.amount,
    // 'chq_society_bank': action?.data?.chq_society_bank,
    bankid: action?.data?.bankid,
    date: action?.data?.date,
    transaction_number: action?.data?.transaction_number,
    cheque_number: action?.data?.cheque_number,
    bankname: action?.data?.bankname,
    branchname: action?.data?.branchname,
    paymentdate: action?.data?.paymentdate,
    cleardate: action?.data?.cleardate,
    remark: action?.data?.remark,
    modeofpayment: action?.data?.modeofpayment,
    payid: action?.data?.payid || null,
    resonType: action?.data?.resonType || null
  }

  return axiosInstance2
    .post(`${apiEndpoints.MAKE_PAYMENT_SAVE_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function sendDebitNoteCurrentEmailSmsReq(action) {

  const BODY = {
    id: action.data?.societyId,
    invIds: [...action.data?.invIds],
    notificationType: action.data?.notificationType  //notificationType must be one of from EMAIL,SMS,BOTH
  }

  return axiosInstance2
    .post(`${apiEndpoints.SEND_DEBIT_NOTE_CURRENT_EMAIL_SMS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function deleteDebitNoteCurrentReq(action) {

  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.id
  }

  return axiosInstance2
    .post(`${apiEndpoints.DELETE_DEBIT_NOTE_CURRENT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function uploadDebitNoteCurrentExcelReq(action) {
  var bodyFormData = new FormData();
  bodyFormData.append('pageid', action.data?.societyId);
  bodyFormData.append('typeid', action.data?.invoiceType);
  bodyFormData.append('excel', action.data?.file);

  return axiosInstance2.post(apiEndpoints.UPLOAD_DEBIT_NOTE_CURRENT_EXCEL_API, bodyFormData)
    .then(response => {
      return response;
    }).catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        if (errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
          notify('error', "Columns name doesn't match");
        else
          notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors

    })
}


export async function downloadDebitNoteCurrentExcelReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
  }

  return axiosInstance2
    .post(`${apiEndpoints.DOWNLOAD_DEBIT_NOTE_CURRENT_EXCEL_API}`, { ...BODY }, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function uploadDebitNoteCurrentPaymentReq(action) {
  var bodyFormData = new FormData();
  bodyFormData.append('pageid', action.data?.societyId);
  bodyFormData.append('excel', action.data?.file);

  return axiosInstance2.post(apiEndpoints.UPLOAD_DEBIT_NOTE_CURRENT_PAYMENT_API, bodyFormData)
    .then(response => {
      return response;
    }).catch((errors) => {
      console.log("errors", errors);
      if (errors.response?.data.statusCode === 400 && errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
        logOut()
      } else if (errors.response?.data.statusCode === 400) {
        if (errors.response?.data?.type === 'COLS_DATA_NOT_FOUND')
          notify('error', "Columns name doesn't match");
        else
          notify('error', errors.response.data.message);
      } else if (errors.response.data.statusCode === 401) {
        notify('error', errors.response.data.message);
        logOut()
      } else {
        notify('warning', "Something went wrong");
      }
      return errors

    })
}
export async function downloadDebitNoteCurrentPaymentReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
  }

  return axiosInstance2
    .post(`${apiEndpoints.DOWNLOAD_DEBIT_NOTE_CURRENT_PAYMENT_API}`, { ...BODY }, {
      responseType: "arraybuffer",
    })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebitNotePreviousReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invoice_no: action.data?.invoiceno,
    subscriber_name: "",
    search_by: action.data?.searchBy,
    payment_status: action.data?.status,
    wing_id: action.data?.wingId,
    flat_id: action.data?.flatId,
    from_amount: action.data?.from_amount,
    to_amount: action.data?.to_amount,
    from_date: action.data?.from_date,
    to_date: action.data?.to_date,
    // invoice_type: action.data?.bill_type,
    debitor_type: action.data?.debitor_type,
    page: action.data?.page,
    limit: action.data?.pageLimit,
    typeid: action.data?.InvoiceType,
    export: 0
  };
  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_DEBIT_NOTE_PREVIOUS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebitNoteDraftReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invoice_no: action.data?.invoiceno,
    subscriber_name: action.data?.subscriber_name,
    search_by: action.data?.searchBy,
    payment_status: "",
    wing_id: action.data?.wingId,
    from_date: action.data?.from_date,
    invoice_type: action.data?.bill_type,
    flat_id: action.data?.flatId,
    to_date: action.data?.to_date,
    debitor_type: action.data?.debitor_type,
    from_amount: "",
    to_amount: "",
    page: action.data?.page,
    limit: action.data?.pageLimit,
    typeid: action.data?.InvoiceType,
  }


  return axiosInstance2
    .post(`${apiEndpoints.MANAGE_DEBIT_NOTE_DRAFT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function editSingleDebitNoteDraftSaveReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    generationStatus: (action.data?.is_draft == 1) ? "postDraftBill" : "",
    debitnote_details: { ...action.data?.mainData }
  };


  return axiosInstance2
    .post(`${apiEndpoints.EDIT_SINGLE_DEBIT_NOTE_SAVE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebitNoteInvoiceReq(action) {
  const BODY = {
    id: action.data?.societyId,
    invIds: action.data?.invid,
  };
  return axiosInstance2
    .post(`${apiEndpoints.DEBIT_NOTE_INVOICE_PRINT_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

// export async function getSingleDebitNoteListReq(action) {
//   const BODY = {
//     id: action.data?.societyId,
//     // invid: action.data?.invid,
//   };
//   return axiosInstance2
//     .post(`${apiEndpoints.SINGLE_DEBIT_NOTE_LIST_API}`, { ...BODY })
//     .then((response) => {
//       return response;
//     })
//     .catch((errors) => {
//       console.log("errors", errors);
//       if (
//         errors.response?.data.statusCode === 400 &&
//         errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
//       ) {
//         logOut();
//       } else if (errors.response?.data.statusCode === 501) {
//         notify("warning", errors.response.data.message);
//       }
//       return errors;
//     });
// }

// export async function getDebitActionsListReq(action) {
//   const BODY = {
//   };
//   return axiosInstance2
//     .get(`${apiEndpoints.DEBIT_NOTE_ACTIONS_LIST_API}`, { ...BODY })
//     .then((response) => {
//       return response;
//     })
//     .catch((errors) => {
//       console.log("errors", errors);
//       if (
//         errors.response?.data.statusCode === 400 &&
//         errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
//       ) {
//         logOut();
//       } else if (errors.response?.data.statusCode === 501) {
//         notify("warning", errors.response.data.message);
//       }
//       return errors;
//     });
// }

export async function getDebiteNoteTarrifListReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    typeid: action.data?.invoiceType,
  };
  return axiosInstance2
    .post(`${apiEndpoints.DEBIT_NOTE_TARRIF_LIST_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebiteNoteFlatWingWiseListReq(action) {
  const BODY = {
    id: action.data?.id,
    wing_no: action.data?.wing_no
  };
  return axiosInstance2
    .post(`${apiEndpoints.DEBIT_NOTE_FLAT_WING_WISE_LIST_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}

export async function getDebiteNoteArrearDetailsReq(action) {
  const BODY = {
    pageid: action.data?.id,
    invoice_date: action.data?.invoice_date,
    due_date: action.data?.due_date,
    // Bill_type: action.data?.bill_type,
    issue_type: action.data?.issue_type,
    typeid: action.data?.invoiceType,
  };
  if (action.data?.flat_id) {
    BODY.flat_id = action.data?.flat_id
  } else {
    BODY.debitor_id = action.data?.debitor_id
  }
  BODY.issue_type = action.data?.issue_type.toLowerCase() == "member" ? "member" : action.data?.issue_type.toLowerCase() == "vendor" ? "nonmember" : "tenant";
  return axiosInstance2
    .post(`${apiEndpoints.DEBIT_NOTE_ARREAR_DETAILS_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        console.log("errors2", errors.response.data.message);
        // notify("warning", errors.response.data.message);
      }
      return errors;
    });
}
// export async function getDebiteNotePreviousDateReq(action) {
//   const BODY = {
//     pageid: action.data?.id,
//     typeid: action.data?.invoiceType,
//   };
//   if (action.data?.flat_id) {
//     BODY.flat_id = action.data?.flat_id
//   } else {
//     BODY.debitor_id = action.data?.debitor_id
//   }
//   return axiosInstance2
//     .post(`${apiEndpoints.DEBIT_NOTE_PREVIOUS_DATE_DETAILS_API}`, { ...BODY })
//     .then((response) => {
//       return response;
//     })
//     .catch((errors) => {
//       console.log("errors", errors);
//       if (
//         errors.response?.data.statusCode === 400 &&
//         errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
//       ) {
//         logOut();
//       } else if (errors.response?.data.statusCode === 501) {
//         console.log("errors2", errors.response.data.message);
//         // notify("warning", errors.response.data.message);
//       }
//       return errors;
//     });
// }

export async function addDebiteNoteReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    is_draft: action.data?.is_draft == 1 ? 1 : 0,
    data: [{
      typeid: action.data?.invoiceType,
      invoice_date: action.data?.invoiceDate,
      due_date: action.data?.dueDate,
      issued_type: action.data?.issuedTo.toLowerCase(),
      bill_type: "debitnote",
      flat_id: action.data?.issuedTo == "Member" ? action.data?.flatId : "",
      debitor_id: action.data?.issuedTo == "Tenant" ? action.data?.tenantId : action.data?.issuedTo == "Vendor" ? action.data?.nonMemberId : "",
      debit_code: action.data?.debitAccount,
      send_email: (action.data?.sendSMS == "Y") && (action.data?.is_draft == 0) ? 1 : 0,
      send_sms: (action.data?.sendEmail == "Y") && (action.data?.is_draft == 0) ? 1 : 0,
      lastdebitnote: action.data?.lastdebitnote,

      head_details: [
        ...action.data?.details?.map(item => ({
          id: item?.id,
          cause: item?.cause,
          amount: item?.amount,
          orderid: item?.orderid,
          GLmapping: item?.GLmapping,
          hsn_code: item?.hsn_code,
          gstapplicable: item?.gstapplicable,
          servicegoods: item?.servicegoods,
          from_date: item?.fromDate ? item?.fromDate : "",
          to_date: item?.toDate ? item?.toDate : "",
          description: item?.description ? item?.description : "",
          tax_amount: item?.gstapplicable == "Y" ? (action.data?.cgst_rate && action.data?.cgst_head && action.data?.sgst_rate && action.data?.sgst_head) ? Number(item?.amount) * (Number(action.data?.cgst_rate) + Number(action.data?.sgst_rate)) / 100 : 0 : 0,
          igst_amount: item?.gstapplicable == "Y" ? (action.data?.igst_rate && action.data?.igst_head) ? Number(item?.amount) * Number(action.data?.igst_rate) / 100 : 0 : 0,
        }))
      ],

      cgst_head: action.data?.cgst_head ? action.data?.cgst_head : null,
      sgst_head: action.data?.sgst_head ? action.data?.sgst_head : null,
      igst_head: action.data?.igst_head ? action.data?.igst_head : null,

      sgst_amount: action.data?.sgst_amount ? action.data?.sgst_amount : 0,
      cgst_amount: action.data?.cgst_amount ? action.data?.cgst_amount : 0,
      igst_amount: action.data?.igst_amount ? action.data?.igst_amount : 0,

      roundoff_amount: action.data?.roundoff == 1 ? (Math.round(Number(action.data?.subTotal)) - Number(action.data?.subTotal)) : 0,
      subtotal: action.data?.subTotal,
      principal_arrear: action.data?.principal_arrear,
      interest_arrear: action.data?.interest_arrear,
      interest: action.data?.interest,
      grantotal: Number(action.data?.subTotal) + (Number(action.data?.interest) + Number(action.data?.principal_arrear) + Number(action.data?.interest_arrear)) + (action.data?.roundoff == 1 ? (Math.round(Number(action.data?.subTotal)) - Number(action.data?.subTotal)) : 0),

    }
    ],

  }

  return axiosInstance2
    .post(`${apiEndpoints.ADD_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}
export async function addMultipleDebiteNoteReq(action) {
  const BODY = {
    pageid: action.data?.societyId,
    is_draft: action.data?.is_draft === 1 ? 1 : 0,
    data: action.data?.debitList
  }
  return axiosInstance2
    .post(`${apiEndpoints.ADD_DEBIT_NOTE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      return errors;
    });
}


