const CryptoJS = require("crypto-js");

export const encryptUrlData = (data) => {
    if (typeof data !== 'string') {
        console.log('Data to encrypt should be a string');
    }
    return CryptoJS.AES.encrypt(data, process.env.REACT_APP_ENCRYPTION_KEY).toString();
}

export const decryptUrlData = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        // If decryption failed or data is invalid UTF-8, return an error or null
        if (!decryptedData) {
            console.log('Decryption failed or data is not valid UTF-8');
        }

        return decryptedData;
    } catch (error) {
        console.error('Error decrypting data:', error.message);
        return null; // Or throw the error depending on your use case
    }
}

export const encryptData = (data) => {
    try {
        return CryptoJS.AES.encrypt(JSON.stringify(data), process.env.REACT_APP_ENCRYPTION_KEY).toString();
    } catch (error) {
        console.error('Error encrypting data:', error.message);
        return null;
    }
}

export const decryptData = (encryptedData) => {
    try {
        const bytes = CryptoJS.AES.decrypt(encryptedData, process.env.REACT_APP_ENCRYPTION_KEY);
        const decryptedData = bytes.toString(CryptoJS.enc.Utf8);

        // If decryption fails, return null or throw an error
        if (!decryptedData) {
            console.error('Decryption failed or data is not valid UTF-8');
        }

        // Parse the decrypted data as JSON
        return JSON.parse(decryptedData);
    } catch (error) {
        console.error('Error decrypting data:', error.message);
        return null; // Return null or rethrow depending on your use case
    }
}
