import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { visuallyHidden } from "@mui/utils";
import "../../../../components/TableComponent/Table.css";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Loader from "../../../../components/Loader/Loader";
import ItemsPerPage from "../../../../components/ItemsPerPage/ItemsPerPage";
import Pagination from "../../../../components/Pagination/Pagination";
import { E_INVOICE_REPORT_ENDPOINT } from "../../../../Routing/routes";
import { createSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { BillingPagination } from "../../../../components/Pagination/Pagination";
import { postEinvoiceGenerate } from "../../../../redux/actions/FinanceActions/EinvoiceActions";

import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@mui/material";



const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    cursor: "pointer",
  },
}));

const EinvoiceTable = ({
  loading,
  data,
  columns,
  checkbox = true,
  pagination = true,

  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
  getTableData = false,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [selected, setSelected] = useState([]);
  const [items, setItems] = useState([]);

  const { Eloading, EinvoiceGenerate, msg,  error, processingInvoiceIds } = useSelector(
    (state) => state.EinvoiceGenerateReducer
  );

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    const newSelected = event.target.checked
      ? data.map((item) => item.employeeRoleId)
      : [];
    setSelected(newSelected);
  };

  const handleClick = (event, id) => {
    const newSelected = selected.includes(id)
      ? selected.filter((selectedId) => selectedId !== id)
      : [...selected, id];
    setSelected(newSelected);
  };

  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${E_INVOICE_REPORT_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };

  const retryFailedInvoice = (invoice_id, societyid) => {
    setItems([]);
    let newItem = [invoice_id];
    setItems((prevItems) => {
      if (!prevItems.includes(invoice_id)) {
        return [...prevItems, invoice_id];
      }
      return prevItems;
    });
  
    dispatch(
      postEinvoiceGenerate({ societyId: societyid, invoiceId: invoice_id     })
    );
  };

  const isSelected = (id) => selected.includes(id);

  console.log("processingInvoiceIds", processingInvoiceIds);
console.log("items", items);
  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className="border-t border-[#CCC]"
        sx={{ minHeight: data?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align={column.numeric ? "right" : "left"}
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                  }}
                >
                  {column.label}
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none", textAlign: "center" }}
                  colSpan={columns?.length}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Loader />
                  </Box>
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!data?.length &&
                  data.map((row, rowIndex) => (
                    <TableRowStyled
                      key={row?.id || rowIndex}
                      role="row"
                      tabIndex={-1}
                    >
                      <TableCellStyled size="small" align="left">
                        {row?.sNo}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.irn}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.ackNo}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.ackDate}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.docNo}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.docDate}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.docType}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.invValue}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.recipientGstin}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.status
                          ? row.status
                            .split("_") 
                            .map(
                              (word) =>
                                word.charAt(0).toUpperCase() +
                                word.slice(1).toLowerCase()
                            ) 
                            .join(" ") 
                          : ""}
                      </TableCellStyled>
                      {row.response ?
                        <TableCellStyled size="small" align="left">
                          {(() => {
                            try {
                              let parsedResponse = row.response;
                              try {
                                parsedResponse = JSON.parse(row.response
                                  .replace(/""/g, '","')
                                  .replace(/}({)/g, '},$1')
                                  .replace(/^"|"$/g, "")
                                  .trim());
                              } catch (jsonError) {
                                parsedResponse = [{ errorCodes: "N/A", errorMsg: row.response }];
                              }
                              return Array.isArray(parsedResponse)
                                ? parsedResponse.map((err, i) => (
                                  <div key={i}>
                                    <strong>Error Code:</strong> {err.errorCodes} <br />
                                    <strong>Message:</strong> {err.errorMsg}
                                  </div>
                                ))
                                : <div><strong>Message:</strong> {parsedResponse[0].errorMsg}</div>;
                            } catch (error) {
                              return "Invalid response format";
                            }
                          })()}
                        </TableCellStyled>
                        : ""
                      }
                      {row.response ?
                        <TableCellStyled size="small" align="left">
                          <button
                            className="text-red-650 font-semibold border border-red-650 bg-white text-xs h-8 px-2 items-center rounded-lg"
                            onClick={() => { retryFailedInvoice(row.invoice_id, row.societyid) }}
                          >
                            {Eloading && items.includes(row.invoice_id) ? (
                              
                                <CircularProgress sx={{ color: "Black" }} size={17} />
                            
                            ) : ("Retry")}
                          </button>
                        </TableCellStyled>
                        : ""
                      }
                    </TableRowStyled>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!pagination && data?.length > 0 && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
            totalCount={totalCount}
          />
          <BillingPagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

EinvoiceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default EinvoiceTable;
