import { call, put, takeLatest } from 'redux-saga/effects'
import * as types from '../../actions/index.types'
import * as req from '../../../axios/request/GateManagementReqs/ParkingManagementReqs'
import { notify } from '../../../utils/notification';

function* getParkingTypeList(action) {
    try {
        const result = yield call(req.getParkingTypeListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_PARKING_TYPE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_PARKING_TYPE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* getParkingTypeDetails(action) {
    try {
        const result = yield call(req.getParkingTypeDetailsReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_PARKING_TYPE_DETAILS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_PARKING_TYPE_DETAILS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateParkingTypeDetails(action) {
    try {
        const result = yield call(req.addUpdateParkingTypeDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_PARKING_TYPE_DETAILS_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
            const newList = yield call(req.getParkingTypeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_PARKING_TYPE_LIST_SUCCESS, payload: newList.data });
        } else {
            yield put({ type: types.ADD_UPDATE_PARKING_TYPE_DETAILS_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}

function* deleteParkingTypeDetails(action) {
    try {
        const result = yield call(req.deleteParkingTypeDetailsReq, action);
        if (result.statusCode === 200) {
            notify('success', 'Data deleted successfully.');
            action.data?.onSuccessCallback()
            yield put({ type: types.DELETE_PARKING_TYPE_DETAILS_SUCCESS, payload: action.data });
            const newList = yield call(req.getParkingTypeListReq, action)
            if (newList.statusCode === 200)
                yield put({ type: types.GET_PARKING_TYPE_DETAILS_SUCCESS, payload: newList.data });

        }
        else if (result.response.data.message) {
            notify('error', result.response.data.message);
        }
    } catch (e) {
        console.log(e)
    }
}

function* getParkingChargesList(action) {
    try {
        const result = yield call(req.getParkingChargesListReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_PARKING_CHARGE_LIST_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_PARKING_CHARGE_LIST_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}

function* addUpdateParkingCharge(action) {
    try {
        const result = yield call(req.addUpdateParkingChargeReq, action);
        if (result.statusCode === 200) {
            notify('success', result?.message)
            yield put({ type: types.ADD_UPDATE_PARKING_CHARGE_LIST_SUCCESS, payload: action.data });
            action.data?.onSuccessCallback?.()
        } else {
            yield put({ type: types.ADD_UPDATE_PARKING_CHARGE_LIST_FAILURE });
        }
    } catch (e) {
        console.log(e)
    }
}


function* getParkingChargeAudit(action) {
    try {
        const result = yield call(req.getParkingChargeAuditReq, action);
        if (result.statusCode === 200) {
            yield put({ type: types.GET_PARKING_CHARGE_AUDIT_LOGS_SUCCESS, payload: result.data });
        } else {
            yield put({ type: types.GET_PARKING_CHARGE_AUDIT_LOGS_FAILURE, message: result?.response?.data?.message });
        }
    } catch (e) {
        console.log(e)
    }
}


export default function* parkingManagementSaga() {
    yield takeLatest(types.GET_PARKING_TYPE_LIST, getParkingTypeList);
    yield takeLatest(types.GET_PARKING_TYPE_DETAILS, getParkingTypeDetails);
    yield takeLatest(types.ADD_UPDATE_PARKING_TYPE_DETAILS, addUpdateParkingTypeDetails);
    yield takeLatest(types.DELETE_PARKING_TYPE_DETAILS, deleteParkingTypeDetails);
    yield takeLatest(types.GET_PARKING_CHARGE_LIST, getParkingChargesList);
    yield takeLatest(types.ADD_UPDATE_PARKING_CHARGE_LIST, addUpdateParkingCharge);
    yield takeLatest(types.GET_PARKING_CHARGE_AUDIT_LOGS, getParkingChargeAudit);

}