import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader/Loader";
import ItemsPerPage from "../ItemsPerPage/ItemsPerPage";
import { createSearchParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import Pagination from "../Pagination/Pagination";
import DownloadIcon from '@mui/icons-material/Download';
import { MAANGE_BULK_JOBS_LIST_ENDPOINT } from "../../Routing/routes";
import Modal2 from "../Modal/Modal2";
import { ViewIcon } from "../../assets";
import { useDispatch } from "react-redux";
import { getDownloadFileFromGcs } from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import { useSelector } from "react-redux";
import { getBulkManageInvoice } from "../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";

const TableRowStyled = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: #ffffff;
  }
  &:nth-of-type(even) {
    background-color: #f4f5f8;
  }
  &:nth-of-type(odd):hover {
    background-color: #ffffff;
  }
  &:nth-of-type(even):hover {
    background-color: #f4f5f8;
  }
`;

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    fontWeight: 600,
    zIndex: 0,
    color: "#AAAAAA",
    fontSize: "13px",
    padding: "12px 8px 12px 0",
    wordBreak: "break-word",
    textAlign: "center",
  },
  [`&.${tableCellClasses.body}`]: {
    borderBottom: "none",
    fontSize: "13px",
    padding: "12px 8px 12px 3px",
    wordBreak: "break-word",
    cursor: "pointer",
    textAlign: "center",
    display: "table-cell", // Default display
    justifyContent: "center",
  },
}));

const ManageBulkInvoiceTable = ({
  loading,
  tableData,
  columns,
  pagination = true,
  totalCount,
  totalPages,
  start,
  end,
  currentPage,
  rowsPerPage,
}) => {
  const navigate = useNavigate();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState(null);
  const { loginData } = useSelector((state) => state.loginReducer);
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpenModal, setIsOpenModal] = useState();
  const dispatch = useDispatch()
  const { downloadFile } =
    useSelector((state) => state.manageInvoiceReducer);
  console.log('downloadFile', downloadFile)
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };
  const handleChangePage = (newPage) => {
    searchParams.delete("page");
    navigate({
      pathname: `${MAANGE_BULK_JOBS_LIST_ENDPOINT}`,
      search: `?${createSearchParams([
        ...searchParams.entries(),
        ["page", newPage],
      ])}`,
    });
  };

  const handleChangeRowsPerPage = (value) => {
    if (rowsPerPage !== value) {
      searchParams.delete("pageLimit");
      navigate({
        pathname: `${MAANGE_BULK_JOBS_LIST_ENDPOINT}`,
        search: `?${createSearchParams([
          ...searchParams.entries(),
          ["pageLimit", value],
        ])}`,
      });
    }
  };
  const handleDownload = (responseData) => {
    dispatch(
      getDownloadFileFromGcs({
        path: responseData,
        onSuccessCallback: (excelData) => {
          try {
            var url = window.URL.createObjectURL(new Blob([excelData]));
            var a = document.createElement('a');
            a.href = url;
            a.download = responseData;
            document.body.appendChild(a);
            a.click();
            a.remove();
            dispatch(getBulkManageInvoice({
              societyid: loginData?.default_community?.community_id,
              page: searchParams.get("page") || 1,
              limit: searchParams.get("pageLimit") || 10,
              jobType: searchParams.get("type") || "",
            }));
          } catch (e) {
            console.error("Error in downloading file", e);
          }
        },
        onFailureCallback: (responseData) => {
          console.error("Download failed. Invalid format or issue with file.");
        },
      })
    );
  };


  return (
    <Box sx={{ width: "100%", fontSize: "13px" }} className="table-component">
      <TableContainer
        className={isOpenModal ? "blurred-table" : "border-t border-[#CCC]"}
        style={{
            transition: "filter 0.3s ease",
            filter: isOpenModal ? "blur(4px)" : "none",
        }}
        sx={{ minHeight: tableData?.length ? "500px" : "none" }}
      >
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns?.map((column) => (
                <TableCellStyled
                  size="small"
                  key={column.id + "heading"}
                  align="center"
                  sx={{
                    width: column.width || "fit-content",
                    minWidth: column.width || "fit-content",
                    alignCenter: column.align
                  }}
                >
                  {column.label}
                </TableCellStyled>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCellStyled
                  sx={{ border: "none", textAlign: "center" }}
                  colSpan={columns?.length}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "200px",
                    }}
                  >
                    <Loader />
                  </Box>
                </TableCellStyled>
              </TableRow>
            ) : (
              <>
                {!!tableData?.length &&
                  tableData.map((row, rowIndex) => (
                    <TableRowStyled
                      key={rowIndex}
                      role="row"
                      tabIndex={-1}
                    >
                      <TableCellStyled size="small" align="left">
                        {row?.sNo}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.date}
                      </TableCellStyled>
                      {row?.billName && (
                        <TableCellStyled size="small" align="left">
                          {row?.billName}
                        </TableCellStyled>
                      )}
                      <TableCellStyled size="small" align="left">
                        {row?.type}
                      </TableCellStyled>
                      <TableCellStyled size="small" align="left">
                        {row?.status}
                      </TableCellStyled>
                      <TableCellStyled size="small" className="centered-cell">
                        {(row?.message && row?.message !== "null") || row?.responseData ? (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              textAlign: "center",
                            }}
                          >
                            {row?.message && row?.message !== "null" && (
                              <>
                                <ViewIcon
                                  className="fill-[555] hover:fill-red-650 me-1.5"
                                  onClick={() => setIsOpenModal(true)}
                                />
                              </>
                            )}
                            {row?.responseData && (

                              <button
                                className="download-btn hover:fill-red-650 ms-2"
                                type="button"
                                onClick={() => {
                                  handleDownload(row?.responseData);
                                }}
                              >
                                <DownloadIcon
                                  fontSize="small"
                                  style={{
                                    color: "#cf001c ",
                                    transition: "color 0.3s ease",
                                  }}
                                  className="hover:text-red-600"
                                />
                              </button>
                            )}
                          </div>
                        ) : (
                          <></>
                        )}
                      </TableCellStyled>
                    </TableRowStyled>
                  ))}
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!!tableData?.length && isOpenModal &&
        tableData.map((row, rowIndex) => (
          row?.message && row?.message !== "null" && (
            <Modal2
              isOpen={isOpenModal}
              onClose={() => setIsOpenModal(false)}
              title="Error Message"
              children={row?.message}
              onCancel={() => setIsOpenModal(false)}
            />
          )))}
      {!!pagination && tableData?.length > 0 && (
        <div className="flex flex-row justify-between mt-3 pt-1 border-t border-[#ccc] text-[14px]">
          <ItemsPerPage
            rowsPerPage={rowsPerPage}
            onChange={handleChangeRowsPerPage}
          />
          <Pagination
            totalCount={totalCount}
            totalPages={totalPages}
            start={start}
            end={end}
            currentPage={currentPage}
            onChange={handleChangePage}
          />
        </div>
      )}
    </Box>
  );
};

ManageBulkInvoiceTable.propTypes = {
  columns: PropTypes.array.isRequired,
  defaultSortField: PropTypes.string,
  rowsPerPageOptions: PropTypes.array,
};

export default ManageBulkInvoiceTable;
