import React, { useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import Button from "../../../../components/Button/Button";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import { UploadEx } from "../../../../assets";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { clearBulkUploadError } from "../../../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import {
  uploadGenerateInvoiceExcel,
} from "../../../../redux/actions/FinanceActions/BillingManagementActions/GenerateInvoiceAction";
import { GENERATE_CURRENT_INVOICE_ENDPOINT } from "../../../../Routing/routes";
import UploadExcelTable from "./UplaodExcelTable";
import { useNavigate } from "react-router-dom";
import {
  getInvoiceTypeList,
} from "../../../../redux/actions/SetupRecordsActions/BillingAndInvoiceSetupActions/InvoiceSettingAction";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import Checkbox from "../../../../components/Checkbox/Checkbox";

const GenerateUploadExcelFormat = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [invoiceType, setInvoicetype] = useState(1);
  const [includeDebitNoteArrear, setIncludeDebitNoteArrear] = useState(1);
  const inputFile = useRef(null);
  const [uploadingExcelData, setUploadingExcelData] = useState([]);
  const { loginData } = useSelector((state) => state.loginReducer);
  const { uploadLoader, error } = useSelector(
    (state) => state.generateInvoiceReducer
  );
  const { typeLists } = useSelector(
    (state) => state.invoiceSettingReducer
  );
  const handleNavigation = (path) => () => {
    navigate(path);
  };
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    const file = event.dataTransfer.files[0];
    if (file) {
      setFile(file);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };
  const onUpload = async () => {
    try {
      await dispatch(
        uploadGenerateInvoiceExcel({
          societyId: loginData?.default_community?.community_id,
          invoiceType: invoiceType || 1,
          includeDebitNoteArrear : includeDebitNoteArrear,
          file,
          uploadingExcelData,
          onSuccessCallback: () => {
            navigate(GENERATE_CURRENT_INVOICE_ENDPOINT);
          },
          onRequestEndCallback: () => {
            setUploadingExcelData([]);
            dispatch(clearBulkUploadError());
            setFile(null);
            inputFile.current.value = "";
            inputFile.current.type = "text";
            inputFile.current.type = "file";
          },
        })
      );
    } catch (error) {
      console.error("Error generating invoice:", error);
    }
  };
  useEffect(() => {
    return () => dispatch(clearBulkUploadError());
  }, []);
  useEffect(() => {
    dispatch(getInvoiceTypeList({
      societyId: loginData?.default_community?.community_id,
    }))

  }, [])


  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
        <div className="border-b border-[#CCC]">
          <ListHeader leftContent="Upload Invoice Excel">
            <Button
              label="Cancel"
              className="h-8"
              onClick={handleNavigation(-1)}
              type="button"
            />
            <div className="border-r border-[#CCC]"></div>
            <Button
              label={
                <span className="inline-block w-12 text-enter">
                  {uploadLoader ? (
                    <CircularProgress sx={{ color: "white" }} size={17} />
                  ) : (
                    "Upload"
                  )}
                </span>
              }
              className="h-8 px-4 disabled:opacity-50"
              disabled={(!file) || uploadLoader}
              onClick={onUpload}
            />
          </ListHeader>
        </div>
        <div className="w-full mt-4">
          <p className="mt-6 mb-4 text-sm font-semibold text-center">
          </p>
          {/* <div className="flex gap-[80px] items-center my-3"> */}
            <div className="flex items-center justify-center gap-4 mb-3">
              <span className="text-[15px]">Invoice Type</span>
              <Dropdown2
                options={
                  typeLists
                    ? typeLists?.map(
                      (item) => ({
                        label: item?.invoice_title,
                        value: item?.id,
                      })
                    )
                    : []
                }
                placeholder="Invoice type"
                className="text-[11px] "
                width="224px"
                height="32px"
                name="invoice_type"
                onSelect={(selectedItem) => {
                  setInvoicetype(selectedItem.value);
                }}
                value={
                  invoiceType && {
                    value: invoiceType,
                    label: typeLists ? typeLists?.find((item) => item?.id === parseInt(invoiceType))?.invoice_title : "",
                  }
                }
              />
            </div>
          {/* </div> */}
          <div className="">
            <div
              onDrop={handleDrop}
              onDragOver={handleDragOver}
              className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center p-9 bg-[#F9FAFC] cursor-pointer"
            >

              <div className="flex flex-col justify-center">
                <div className="flex justify-center">
                  <UploadEx className="w-6" />
                </div>
                <input
                  type="file"
                  id="fileInput"
                  className="hidden"
                  accept={
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  }
                  onChange={handleFileChange}
                  ref={inputFile}
                />
                <label
                  htmlFor="fileInput"
                  className="block text-sm font-semibold"
                >
                  Upload .xlsx file
                </label>
                <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
              </div>
            </div>
            <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibol text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
              {file && `File Name: ${file?.name}`}
            </div>
            <div className="flex items-center justify-center gap-4 mb-3">
              <Checkbox
                //text={"SMS"}
                //className={"w-28"}
                initialchecked={includeDebitNoteArrear===1}
                // name='includeDebitNoteArrear'
                onChange={(e) =>setIncludeDebitNoteArrear(e.target.checked ? 1 : 0)}
              />
              <span className="text-[15px]">Include Outstanding Debitnote As Debitnote Arrears In The Bill</span>
              
            </div>
            {error && !uploadLoader && (
              <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                Error:
                <br />
                {typeof error === "string"
                  ? error
                  : Object.entries(error)?.map((item) => (
                    <div>
                      {/* {item[1]?.includes('Opening') ? '' : `Row ${item[0]}: `}   */}
                      {item[1]}
                    </div>
                  ))}
              </div>
            )}
          </div>
        </div>

      </div>
    </Layout>
  );
};

export default GenerateUploadExcelFormat;
