import { notify } from "../../../utils/notification";
import apiEndpoints from "../../api";
import axiosInstance2 from "../../axiosInstance2";
import { logOut } from "../../../utils/helpers/logOut";
import { errorMsg } from "../../../consts/responseMessages";

export async function getEinvoiceReq(action){
    const BODY = {
      societyId: action.data?.societyId,
        billType: action.data?.billType,
        status: action.data?.status,
        fromDate: action.data?.fromDate,
        toDate: action.data?.toDate,
        page: action.data?.page ,
        limit: action.data?.limit,
      };
      return axiosInstance2
      .post(`${apiEndpoints.E_INVOICE_REPORT_API}`, { ...BODY })
      .then((response) => {
        return response;
      })
      .catch((errors) => {
        console.log("errors", errors);
        if (
          errors.response?.data.statusCode === 400 &&
          errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
        ) {
          logOut();
        } else if (errors.response?.data.statusCode === 501) {
          notify("warning", errors.response.data.message);
        }
        return errors;
      });
}


export async function getEinvoiceGenerateReq(action){
  const BODY = {
    societyId: action.data?.societyId,
    invoiceId: action.data?.invoiceId
  };
    return axiosInstance2
    .post(`${apiEndpoints.E_INVOICE_GENERATE_API}`, { ...BODY })
    .then((response) => {
      return response;
    })
    .catch((errors) => {
      console.log("errors", errors);
      if (
        errors.response?.data.statusCode === 400 &&
        errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)
      ) {
        logOut();
      } else if (errors.response?.data.statusCode === 501) {
        notify("warning", errors.response.data.message);
      }
      else if (errors.response?.data.statusCode === 500) {
        notify("error", errors.response.data.msg);
      }
      return errors;
    });
}