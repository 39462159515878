import { notify } from '../../../utils/notification';
import apiEndpoints from '../../api';
import axiosInstance from '../../axiosInstance'
import { logOut } from '../../../utils/helpers/logOut'
import { errorMsg } from '../../../consts/responseMessages';
const { detect } = require('detect-browser');
const browser = detect();


export async function societyForgotPasswordReq(action) {
    const BODY = {
        "email": action.data.email
    }

    return axiosInstance.post(apiEndpoints.SOCIETY_FORGOT_PASSWORD_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 404) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function loginReq(action) {
    const BODY = {
        "email": action.data.email,
        "password": action.data.password,
        "ip": action.data.ipAddress,
        "device_type": action.data.deviceType || "laptop",
        "os_version": action.data.os || browser.os,
        "app_version": action.data.version || browser.version,
        "phone_imei": action.data.iemi || "",
        "phone_brand": action.data.phoneBrand || "",
        "auto_login": action.data.autoLogin ? true : false
    }

    return axiosInstance.post(apiEndpoints.LOGIN_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 404 || errors.response?.data.statusCode === 400) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function reloginReq(action) {
    const BODY = {
        "community_id": action.data?.society_id,
        "house_unique_id": action.data?.house_unique_id || 0,
        "user_type": action.data?.user_role,
        "ip": action.data?.ipAddress || '0.0.1',
        "pwd": action.data?.pwd
    }

    return axiosInstance.post(apiEndpoints.RELOGIN_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400) {
                if (!action.data.firstLogin || errorMsg.INVALID_ACCESS_TOKEN.includes(errors.response?.data.message)) {
                    logOut()
                } else {
                    notify('error', errors.response.data.message);
                }
            } else if (errors.response.data.statusCode === 404) {
                notify('error', errors.response.data.message);
                logOut()
            } else {
                notify('warning', "Something went wrong");
            }
            return errors

        })
}

export async function logoutReq(action) {
    return axiosInstance.put(apiEndpoints.LOGOUT_API, {})
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            return errors
        })
}

export async function passwordResetReq(action) {
    const BODY = {
        "reset_token": action.data.token,
        "password": action.data.password,
        "confirm_password": action.data.confirmPassword
    }

    return axiosInstance.post(apiEndpoints.PASSWORD_RESET_API, { ...BODY })
        .then(response => {
            return response;
        }).catch((errors) => {
            console.log("errors", errors);
            if (errors.response?.data.statusCode === 400 || errors.response.data.statusCode === 404) {
                notify('error', errors.response.data.message);
            } else {
                notify('warning', "Something went wrong");
            }
            return errors
        })
}