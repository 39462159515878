import * as type from '../../../actions/index.types'

export function bulkUploadReducer(state = {
  uploadLoader: false,
  error: null
}, action) {

  switch (action.type) {
    case type.UPLOAD_STAFF_EXCEL_SHEET:
      return {
        ...state,
        uploadLoader: true,
        error: null
      }
    case type.UPLOAD_STAFF_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        error: action.payload.error
      }
    case type.UPLOAD_STAFF_EXCEL_SHEET_FAILURE:
      return {
        ...state,
        uploadLoader: false,
        error: action?.payload?.error || ''
      }


    case type.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET:
      return {
        ...state,
        uploadLoader: true,
        error: null
      }
    case type.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        error: action.payload.error
      }
    case type.UPLOAD_TO_UPDATE_OLD_OCCUPANT_EXCEL_SHEET_FAILURE:
      return {
        ...state,
        uploadLoader: false,
        error: action?.payload?.error || ''
      }

    case type.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET:
      return {
        ...state,
        uploadLoader: true,
        error: null
      }
    case type.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        error: action.payload.error
      }
    case type.UPLOAD_NEW_OCCUPANT_EXCEL_SHEET_FAILURE:
      return {
        ...state,
        uploadLoader: false,
        error: action?.payload?.error || ''
      }

    case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL:
      return {
        ...state,
        uploadLoader: true,
        error: null
      }
    case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        error: action.payload.error
      }
    case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE:
      return {
        ...state,
        uploadLoader: false,
        error: action?.payload?.error || ''
      }

      case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL:
    return {
      ...state,
      uploadLoader: true,
      error: null
    }
  case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_SUCCESS:
    return {
      ...state,
      uploadLoader: false,
      error: action.payload.error
    }
  case type.ADD_FLAT_WISE_FAMILY_MEMBER_THROUGH_EXCEL_FAILURE:
    return {
      ...state,
      uploadLoader: false,
      error: action?.payload?.error || ''
    }

    case type.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET:
      return {
        ...state,
        uploadLoader: true,
        errorMsg: null,
        error: []
      }
    case type.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_SUCCESS:
      return {
        ...state,
        uploadLoader: false,
        errorMsg: null,
        error: action.payload.error
      }
    case type.UPLOAD_OCCUPANT_OPENING_BALANCE_EXCEL_SHEET_FAILURE:
      return {
        ...state,
        uploadLoader: false,
        error: action?.payload?.error || [],
        errorMsg: action?.payload?.errorMsg || ''
      }



    case type.UPLOAD_CLEAR_ERROR:
      return {
        ...state,
        error: ''
      }

    default:
      return state
  }
}