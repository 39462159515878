    export default function DebitCalculation(data , header , detail) {
        if(!data ){
            console.log('data not found ');
            return null;
        }
        console.log('debitnote header',data?.debitnote_header)
        const cgst_head = header.cgst_head
        const cgst_rate = header.cgst_rate
        const gst_applicable = header.gst_applicable
        const igst_head = header.igst_head
        const igst_rate = header.igst_rate
        const interest_gst = header.interest_gst
        const interest_service = header.interest_service
        const roundoff = header.roundoff
        const sgst_rate = header.sgst_rate
        console.log('header dataa',cgst_head,cgst_rate,gst_applicable,igst_head,igst_rate,interest_service     
        )
        const payloadData = {}
        for (const flatid in detail ){
            const flatData = detail[flatid]
            payloadData[flatid] = {
                bill_type: flatData.bill_type,
                cgst_amount: flatData.cgst_amount,
                debit_code: flatData.debit_code,
                debit_glname: flatData.debit_glname,
                debitcauses: flatData.debitcauses,
                debitnote_header: flatData.debitnote_header,
                debitor_id: flatData.debitor_id,
                due_date: flatData.due_date,
                flat_id: flatData.flat_id,
                flat_no: flatData.flat_no,
                flatarea: flatData.flatarea,
                grand_total: calculateGrandTotal(flatData), 
                gst_registration_no: flatData.gst_registration_no,
                head_detail: HeadDetails(flatData.head_detail , igst_rate ), 
                id: flatData.id,
                igst_amount: flatData.igst_amount,
                interest: flatData.interest,
                interest_arrear: flatData.interest_arrear,
                invoice_date: flatData.invoice_date,
                invoice_no: flatData.invoice_no,
                issued_type: flatData.issued_type,
                joint_member: flatData.joint_member,
                jointmembersid: flatData.jointmembersid,
                journal_id: flatData.journal_id,
                lastdebitnote: flatData.lastdebitnote,
                member_name: flatData.member_name,
                principal_arrear: flatData.principal_arrear,
                roundoff_amount: flatData.roundoff_amount,
                send_email: 0, 
                send_sms: 0, 
                sgst_amount: flatData.sgst_amount,
                status: flatData.status,
                sub_total: calculateSubTotal(flatData), 
                unit: flatData.unit,
                wing_id: flatData.wing_id,
            } 
        }
        return payloadData;
    }

    function calculateGrandTotal(flatData) {
        const principalArrear = parseFloat(flatData.principal_arrear || 0);
        const igstAmount = parseFloat(flatData.igst_amount || 0);
        const interestArrear = parseFloat(flatData.interest_arrear || 0);
        const cgstAmount = parseFloat(flatData.cgst_amount || 0);
        const sgstAmount = parseFloat(flatData.sgst_amount || 0);
        const roundoffAmount = parseFloat(flatData.roundoff_amount || 0);
        const headDetailSum = Array.isArray(flatData.head_detail)
        ? flatData.head_detail.reduce((sum, head) => sum + parseFloat(head.amount || 0), 0)
        : 0;
        const grandTotal = principalArrear + igstAmount + interestArrear + cgstAmount + roundoffAmount + headDetailSum + sgstAmount;

    return grandTotal.toFixed(2);
    }

    function HeadDetails(headDetails, igstRate) {
        if (!Array.isArray(headDetails)) {
            console.error("headDetails is not an array:", headDetails);
            return [];
        }
    
        const parsedIgstRate = parseFloat(igstRate || 0) / 100;  
    
        return headDetails.map(detail => {
            const amount = parseFloat(detail.amount || 0);
    
            return {
                ...detail,
                igst_amount: detail.gstapplicable === "Y" 
                    ? (amount * parsedIgstRate).toFixed(2) 
                    : 0,
                // tax_amount: detail.gstapplicable === "Y" 
                //     ? (amount * parsedIgstRate * 2).toFixed(2) // Assuming CGST + SGST equals IGST * 2
                //     : 0,
            };
        });
    }
    

    function calculateSubTotal(flatData) {
        const headDetailSum = Array.isArray(flatData.head_detail)
        ? flatData.head_detail.reduce((sum, head) => sum + parseFloat(head.amount || 0), 0)
        : 0;
        const igstAmount = parseFloat(flatData.igst_amount || 0);
        const cgstAmount = parseFloat(flatData.cgst_amount || 0);
        const sgstAmount = parseFloat(flatData.sgst_amount || 0);
        const subTotal = igstAmount + cgstAmount + sgstAmount + headDetailSum
        return subTotal
    }