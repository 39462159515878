import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import Loader from '../../../components/Loader/Loader'
import { downloadNDC } from '../../../redux/actions/ReportActions/SocietyReportActions';
import {useSearchParams} from "react-router-dom";

export function PrintNDC() {
    const [searchParams, setSearchParams] = useSearchParams();
    const dispatch = useDispatch()
    const {loginData} = useSelector(state=>state.loginReducer)
    const { NDCloading, NDCData } = useSelector(
        (state) => state.societyReportReducer
      );
  
    useEffect(()=>{
        dispatch(downloadNDC({
            societyId: loginData?.default_community?.community_id,
            memberGL: atob(searchParams.get("memberGL")) || "",
            ndcDate: atob(searchParams.get("ndcDate")) || "",
            onSuccessCallback: ()=>{
                setTimeout(function() {
                    window.print()
                }, 5000); 
            }
        }))
    }, [searchParams])
   

    return (
    <div className="flex items-center justify-center min-h-screen bg-white ">
        {
            NDCloading || !NDCData ?
            <Loader/>
            :
            <>
              <div className='w-[800px] p-4 whitespace-pre-wrap' dangerouslySetInnerHTML={{__html: NDCData}} ></div>
            </>
        }
    </div>
  )
}
export default PrintNDC