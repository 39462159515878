import { decryptData, encryptData } from "./helpers/encryptionDecryption";

const getStorer = (rememberMe) => {
  //return rememberMe ? localStorage : sessionStorage;
  return localStorage
};


const webTokenStorer = {
  saveToken: ({ access_token, society_id, house_unique_id, user_role, pwd = "", rememberMe }) => {
    //const storer = getStorer(rememberMe);
    const storer = getStorer(true);
    const data = {
      access_token,
      society_id,
      house_unique_id,
      user_role,
      pwd
    }
    storer.setItem("UserData", JSON.stringify(data))
  },

  removeToken: () => {
    localStorage.removeItem("UserData");
    //sessionStorage.clear();
  },

  getToken: () => {
    const data = localStorage.getItem('UserData') || sessionStorage.getItem('UserData');
    if (data) {
      return JSON.parse(data)
    }
    return null;
  },

  // checkRememberMe: () => {
  //   return localStorage.getItem('UserData') ? true : false;
  // },
};

export const webCredentialsStorer = {

  saveCredentials: ({ id, pwd }) => {
    const data = {
      id,
      pwd
    }
    localStorage.setItem("EGv_hy", encryptData(data))
  },

  removeCredentials: () => {
    localStorage.removeItem("EGv_hy")
  },

  getCredentials: () => {
    try {
      const data = localStorage.getItem('EGv_hy');
      if (data) {
        return decryptData(data)
      }
      return null;
    } catch (error) {
      console.log(error)
      localStorage.removeItem("EGv_hy")
    }
  },
};

export default webTokenStorer;

