import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { EMPLOYEE_ENDPOINT } from "../../../../../Routing/routes";
import Layout from "../../../../Layout/Layout";
import ListHeaderBack from "../../../../../components/ListHeader/ListHeaderBack";
import { useDispatch } from "react-redux";
import { getEmployeeDetails } from "../../../../../redux/actions/SetupRecordsActions/StaffManagementActions/EmployeeManagementAction";
import { useSelector } from "react-redux";
import Loader from "../../../../../components/Loader/Loader";


const ViewEmployee = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { loginData } = useSelector((state) => state.loginReducer);
  const {loading, employeeDetails} = useSelector(state=>state.employeeManagementReducer)
  const {id} = useParams()

  const handleBackClick = () => {
    navigate(EMPLOYEE_ENDPOINT);
  };

  useEffect(()=>{
    dispatch(getEmployeeDetails({employeeId: id, societyId: loginData?.default_community?.community_id}))
  }, [])

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 mb-5">
        <div className="border-b border-[#CCC]">
          <ListHeaderBack
            onClick={handleBackClick}
            title="View Employee Details"
          ></ListHeaderBack>
        </div>
        {
          loading && id !== employeeDetails?.emp_id?.toString() ?
          <Loader/>
          :
          <table className="mx-2">
            <tbody className="text-sm leading-8">
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Name:</td>
                  <td className="capitalize">{employeeDetails?.emp_name || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">User Name:</td>
                  <td>{employeeDetails?.username || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Type:</td>
                  <td>{employeeDetails?.type || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Phone Number:</td>
                  <td>{employeeDetails?.phone ? `+${employeeDetails?.mobile_country_code}-${employeeDetails?.phone}` : '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Email Address:</td>
                  <td>{employeeDetails?.email || '-'}</td>
                </tr>
                <tr>
                  <td className="text-[#AAAAAA] font-semibold w-32">Address:</td>
                  <td>{employeeDetails?.address || '-'}</td>
                </tr>
            </tbody>
          </table>
        }
      </div>
    </Layout>
  );
};

export default ViewEmployee;
