import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as CloseIcon } from "../../../assets/icons/closeIcon.svg";
import Button from "../../../components/Button/Button";
import { TextInputFM } from "../../../components/InputFields/TextInput";
import { DeleteIcon } from "../../../assets";
import { useDispatch } from "react-redux";
import {
  addUpdateSocietyEntryGateReq,
  deleteSocietyEntryGate,
  deleteVistorPurposeReq,
  getSocietyEntryGate,
} from "../../../redux/actions/GateManagementActions/VisitorAndVehicleInAndOutAction";
import { useSelector } from "react-redux";
import { FieldArray, Formik, Form } from "formik";
import { AddGateValidation } from "../../../validationSchema/gateManagementSchema/visitorAndVehicleInAndOutSchema";
import Loader from "../../../components/Loader/Loader";
import { CircularProgress } from "@mui/material";
import moment from "moment";
import QrCodeGenerateModal from "../../../components/Modal/QrCodeGenerateModal";
import { notify } from "../../../utils/notification";
import {
  encryptData,
  encryptUrlData,
} from "../../../utils/helpers/encryptionDecryption";

const AddGateModal = ({ isOpen, onClose }) => {
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loadingModal, updateLoader, societyGateList, purposeList } =
    useSelector((state) => state.visitorAndVehicleInAndOutReducer);
  const [currentLocation, setCurrentLocation] = useState({});
  const [qrCodeModal, setQrCodeModal] = useState({ open: false, data: "" });

  const handleQrCodeModal = (id, gateTitle, latitude, longitude) => {
    let value = { ...qrCodeModal };
    value.open = true;
    value.data = `id=${id}&societyId=${loginData?.default_community?.community_id}&societyTitle=${loginData?.default_community?.community_name}&societyType=${loginData?.default_community?.society_type}&gateTitle=${gateTitle}&latitude=${latitude}&longitude=${longitude}`;
    value.data = encodeURIComponent(encryptUrlData(value.data.toString()));
    value.data = `${window.location.origin}/add-visitor?key=${value.data}`;
    console.log(value.data);
    setQrCodeModal(value);
  };
  const handleQrCodeCloseModal = () => {
    setQrCodeModal({ open: false, data: "" });
  };
  const handleDelete = (arrayHelpers, index, gate) => {
    if (gate.gateId) {
      dispatch(
        deleteSocietyEntryGate({
          gateId: gate.gateId,
          onSuccessCallback: () => {
            arrayHelpers.remove(index);
          },
        })
      );
    } else {
      arrayHelpers.remove(index);
    }
  };

  const handleDeletePurpose = (purposeId) => {
    dispatch(
      deleteVistorPurposeReq({
        purposeId,
        societyId: loginData?.default_community?.community_id,
      })
    );
  };
  let watchId;

  const getLocation = async () => {
    if (navigator.geolocation) {
      watchId = await navigator.geolocation.getCurrentPosition(
        showPosition,
        (error) => {
          console.error("Error obtaining location", error);
        },
        {
          enableHighAccuracy: true,
          timeout: 10000,
          maximumAge: 0,
        }
      );
    } else {
      notify("warning", "Geolocation is not supported by this browser");
    }
  };

  function showPosition(position) {
    const lat = position.coords.latitude;
    const lon = position.coords.longitude;
    // Only update if the position has changed significantly
    setCurrentLocation({
      latitude: lat,
      longitude: lon,
    });
  }
  useEffect(() => {
    dispatch(
      getSocietyEntryGate({
        societyId: loginData?.default_community?.community_id,
      })
    );
    setCurrentLocation({});
    getLocation();
    return () => {
      navigator.geolocation.clearWatch(watchId);
    };
  }, []);

  return (
    <>
      <div
        className={`fixed top-0 left-0 w-full h-full max-h-[100vh] flex items-center justify-center flex-wrap overflow-y-auto bg-gray-800 bg-opacity-60 z-20 ${
          isOpen ? "block" : "hidden"
        }`}
      >
        <div className="bg-white rounded-lg w-full max-w-[768px] mx-auto px-2 min-h-[550px] h-fit">
          <div className="flex justify-between p-2 gap-8 items-center -mb-2">
            <h2 className="p-2 text-lg font-semibold mb-2 items-center text-center w-[80%] flex justify-start flex-wrap">
              Add Gate
              {currentLocation?.latitude && (
                <div className="text-sm text-start">
                  Current <span>Latitude : {currentLocation?.latitude} </span>
                  <span>Longitude : {currentLocation?.longitude}</span>
                </div>
              )}
            </h2>
            <div className="w-1/2 flex justify-end">
              <button
                onClick={onClose}
                className="bg-[#EAEAEA] rounded-full p-2 items-center mb-2 w-9 h-9 "
              >
                <CloseIcon className="fill-current text-gray-600 hover:text-gray-800 ml-1 w-3 h-3 cursor-pointer" />
              </button>
            </div>
          </div>
          <div className="border-b border-[#CCCCCC]"></div>
          {loadingModal ? (
            <Loader />
          ) : (
            <>
              <Formik
                enableReinitialize
                initialValues={{
                  gates: societyGateList?.map((item) => ({
                    gateId: item?.gate_id,
                    name: item?.entry_gate,
                    latitude: item?.latitude,
                    longitude: item?.longitude,
                  })) || [
                    {
                      gateId: 0,
                      name: "",
                      latitude: "",
                      longitude: "",
                    },
                  ],
                }}
                onSubmit={(values) =>
                  dispatch(
                    addUpdateSocietyEntryGateReq({
                      ...values,
                      societyId: loginData?.default_community?.community_id,
                      onSuccessCallback: () => {
                        dispatch(
                          getSocietyEntryGate({
                            societyId:
                              loginData?.default_community?.community_id,
                          })
                        );
                      },
                    })
                  )
                }
                validationSchema={AddGateValidation}
              >
                {({ values, dirty }) => {
                  return (
                    <Form>
                      <div className="p-2 h-[180px] overflow-y-scroll">
                        <table className="border-0 w-full">
                          <tbody>
                            <tr className="text-sm font-semibold ">
                              <td className="py-2">Gates</td>
                              <td className="py-2">Latitude</td>
                              <td className="py-2">Longitude</td>
                              <td className="py-2 w-[100px]">Barcode</td>
                              <td className="py-2">Action</td>
                            </tr>

                            <FieldArray
                              name="gates"
                              render={(arrayHelpers) => (
                                <>
                                  {values.gates?.map((gate, index) => (
                                    <tr>
                                      <td className="align-top">
                                        <TextInputFM
                                          label=""
                                          placeholder="Enter Gate Number"
                                          name={`gates.${index}.name`}
                                        />
                                      </td>
                                      <td className="align-top">
                                        <TextInputFM
                                          label=""
                                          placeholder="Enter Latitude"
                                          name={`gates.${index}.latitude`}
                                        />
                                      </td>
                                      <td className="align-top">
                                        <TextInputFM
                                          label=""
                                          placeholder="Enter Longitude"
                                          name={`gates.${index}.longitude`}
                                        />
                                      </td>

                                      <td className="text-sm pb-2">
                                        {societyGateList?.[index]?.latitude &&
                                        societyGateList?.[index]?.longitude ? (
                                          <span
                                            className=" cursor-pointer  text-red-650"
                                            onClick={() =>
                                              handleQrCodeModal(
                                                societyGateList?.[index]
                                                  ?.gate_id,
                                                societyGateList?.[index]
                                                  ?.entry_gate,
                                                societyGateList?.[index]
                                                  ?.latitude,
                                                societyGateList?.[index]
                                                  ?.longitude
                                              )
                                            }
                                          >
                                            View
                                          </span>
                                        ) : (
                                          "-"
                                        )}
                                      </td>

                                      <td className="align-middle">
                                        <DeleteIcon
                                          className="fill-red-650 mx-auto cursor-pointer"
                                          onClick={() => {
                                            handleDelete(
                                              arrayHelpers,
                                              index,
                                              gate
                                            );
                                          }}
                                        />
                                      </td>
                                    </tr>
                                  ))}
                                  <tr>
                                    <td colSpan={5}>
                                      <button
                                        type="button"
                                        label="Add More"
                                        onClick={() =>
                                          arrayHelpers.push({
                                            gateId: 0,
                                            name: "",
                                          })
                                        }
                                        className="text-red-650 font-semibold border-2 border-dashed border-red-650 w-24 h-8 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center text-xs"
                                      >
                                        Add More
                                      </button>
                                    </td>
                                  </tr>
                                </>
                              )}
                            ></FieldArray>
                          </tbody>
                        </table>
                      </div>
                      <div className="border-b"></div>
                      <div className="flex justify-center my-2">
                        <Button
                          label={
                            <span className="w-8 inline-block">
                              {updateLoader ? (
                                <CircularProgress
                                  sx={{ color: "white" }}
                                  size={17}
                                />
                              ) : (
                                "Save"
                              )}
                            </span>
                          }
                          className="h-8 font-semibold"
                          type={"submit"}
                          disabled={loadingModal || updateLoader || !dirty}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
              <div className="m-2 h-56 overflow-y-scroll border rounded overflow-hidden text-sm">
                <table className=" w-full text-left">
                  <thead className="border-b py-2 sticky top-0 bg-white">
                    <tr>
                      <th className="text-[#AAAAAA] p-2">SNo</th>
                      <th className="text-[#AAAAAA] p-2">Purpose</th>
                      <th className="text-[#AAAAAA] p-2">Date</th>
                      <th className="text-[#AAAAAA] p-2">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {purposeList?.map((item, index) => (
                      <tr key={index}>
                        <td className="p-2">
                          {(index + 1)?.toString()?.padStart(2, "0")}
                        </td>
                        <td className="p-2">{item.purpose}</td>
                        <td className="p-2">
                          {moment(item.date)?.format("DD-MM-YYYY")}
                        </td>
                        <td className="p-2">
                          {item.society_id ===
                            loginData?.default_community?.community_id && (
                            <DeleteIcon
                              onClick={() => {
                                handleDeletePurpose(item.purpose_id);
                              }}
                              className="fill-red-650 mt-3 ml-2 cursor-pointer"
                            />
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </>
          )}
        </div>
      </div>
      <QrCodeGenerateModal
        isOpen={qrCodeModal?.open}
        onClose={handleQrCodeCloseModal}
        qrValue={qrCodeModal?.data}
      />
    </>
  );
};

export default AddGateModal;
