import React, { useState } from "react";
import { TextInput, TextInputFM } from "../../../components/InputFields/TextInput";
import { DropdownFM2 } from "../../../components/Dropdown/dropdown2";
import { useSelector } from "react-redux";
import { FieldArray } from "formik";
import {
  Hoursoptions,
  TimeSlotLessThanOptions,
} from "../../../consts/DropDownOptions";
import { DeleteIcon } from "../../../assets";
import DeleteModal from "../../../components/Modal/DeleteModal";
import { deleteTimeSlot } from "../../../redux/actions/ClubActions/BookAFacilityAction";
import { useDispatch } from "react-redux";
import { getPermission } from "../../../utils/helpers/getPermissions";
import * as permissions from "../../../Routing/permissions";

import { TimeSelectorFM2 } from "../../../components/TimeSelector/TimeSelector";
import moment from "moment";
import { getDateObjectFromTimeString } from "../../../utils/helpers/universalFunctions";

export const headCells = [
  {
    id: "type",
    label: "Type",
    width: "100px",
  },
  { id: "ticket", label: "Ticket/Passes", width: "100px" },
  {
    id: "lessThan",
    label: "Less Than/Greater Than",
    width: "150px",
  },
  {
    id: "hours",
    label: "Hours",
    width: "250px",
  },
];

function EditTimeSlotHourlyTable({ values, setFieldValue }) {
  const dispatch = useDispatch();
  const { typeList } = useSelector((state) => state.addTimeSlotReducer);
  const { updateLoader } = useSelector((state) => state.bookAFacilityReducer);
  const [deleteModal, setDeleteModal] = useState({ id: "", open: false });
  const { loginData } = useSelector((state) => state.loginReducer);

  const handleDelete = (id, arrayHelpers, index) => {
    if (id) {
      dispatch(
        deleteTimeSlot({
          ...values,
          timeSlotId: id,
          onSuccessCallback: () => {
            arrayHelpers.remove(index);
          },
        })
      );
    } else {
      arrayHelpers.remove(index);
    }
  };

  return (
    <table className="w-full">
      <thead className="font-semibold text-sm text-[#AAA]">
        <tr>
          {headCells?.map((item, index) => (
            <td className="p-1 w-28 py-2 mb-2 border-b border-[#DDD]  ">
              {item.label}
            </td>
          ))}
          <td className="p-1 w-28 pb-2 mb-2 border-b border-[#DDD]  "></td>
        </tr>
      </thead>
      <tbody>
        <tr className="h-2"></tr>
        <FieldArray
          name="timeSlotHourly"
          render={(arrayHelpers) => (
            <>
              {values.timeSlotHourly &&
                values.timeSlotHourly.length > 0 &&
                values.timeSlotHourly.map((slot, index) => (
                  <tr className="align-top">
                    <td className="px-1">
                      <DropdownFM2
                        options={
                          typeList?.map((item) => ({
                            label: item.title,
                            value: item.id,
                          })) || []
                        }
                        placeholder="Select Type"
                        className="text-[11px] ms-4"
                        width="120px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.type`,
                            selectedItem.label
                          );
                        }}
                        value={
                          values.timeSlotHourly[index]?.type
                            ? {
                              label: typeList?.find(
                                (item) =>
                                  item?.title ==
                                  values.timeSlotHourly[index]?.type
                              )?.title,
                              value: typeList?.find(
                                (item) =>
                                  item.title ===
                                  values.timeSlotHourly[index]?.type
                              )?.id,
                            }
                            : null
                        }
                        name={`timeSlotHourly.${index}.type`}
                      />
                    </td>
                    <td className="px-1">
                      {values.chargeOn === "day" && slot?.ticket === 1
                        ?
                        <TextInput
                          label=""
                          placeholder="Enter"
                          className="w-28 h-9"
                          type="number"
                          value={1}
                          name={`timeSlot.${index}.ticket`}
                          disabled={true}
                        />
                        :
                        <TextInputFM
                          label=""
                          placeholder="Enter"
                          className="w-28 h-9"
                          type="number"
                          name={`timeSlotHourly.${index}.ticket`}
                        />
                      }
                    </td>
                    <td className="px-1">
                      <DropdownFM2
                        options={TimeSlotLessThanOptions}
                        placeholder="Select"
                        className="text-[11px] ms-4"
                        width="140px"
                        height="36px"
                        onSelect={(selectedItem) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.lessThan`,
                            selectedItem.value
                          );
                        }}
                        value={
                          values.timeSlotHourly[index]?.lessThan
                            ? {
                              label: TimeSlotLessThanOptions?.find(
                                (item) =>
                                  item.value ===
                                  values.timeSlotHourly[index]?.lessThan
                              )?.label,
                              value: values.timeSlotHourly[index]?.lessThan,
                            }
                            : null
                        }
                        name={`timeSlotHourly.${index}.lessThan`}
                      />
                    </td>
                    <td className="px-1">
                      <TimeSelectorFM2
                        width={"110px"}
                        height={"37px"}
                        setTime={(value) => {
                          setFieldValue(
                            `timeSlotHourly.${index}.hours`,
                            moment(new Date(value)).format("HH:mm")
                          );
                        }}
                        name={`timeSlotHourly.${index}.hours`}
                        minutesStep={15}
                        time={getDateObjectFromTimeString(
                          values.timeSlotHourly[index].hours
                        )}
                        minTime={getDateObjectFromTimeString("00:15")}
                      />
                    </td>
                    <td>
                      {getPermission(
                        loginData,
                        permissions.DELETE_TIME_SLOT_PERMISSION
                      ) && (
                          <DeleteIcon
                            onClick={
                              slot.id
                                ? () =>
                                  setDeleteModal({ id: slot.id, open: true })
                                : () => {
                                  handleDelete(slot.id, arrayHelpers, index);
                                }
                            }
                            className="fill-red-650 mt-3 ms-6 cursor-pointer"
                          />
                        )}
                      <DeleteModal
                        title="Delete Time Slot"
                        loading={updateLoader}
                        isOpen={deleteModal.open && slot.id === deleteModal.id}
                        onCancel={() => setDeleteModal(false)}
                        onDelete={() => {
                          handleDelete(slot.id, arrayHelpers, index);
                        }}
                        text="Do you really want to delete this time slot?"
                      />
                    </td>
                  </tr>
                ))}
              <tr>
                <td colSpan={7}>
                  <div className="h-8 my-2 flex justify-center">
                    <button
                      type="button"
                      label="Add More"
                      onClick={() =>
                        arrayHelpers.push({
                          type: "",
                          ticket: values?.chargeOn === "day" ? 1 : "",
                          lessThan: "",
                          hours: "",
                        })
                      }
                      className="text-red-650 font-semibold border-2 border-dashed border-red-650 p-2 bg-[#FDF6F6] flex justify-center rounded-md items-center"
                    >
                      Add More
                    </button>
                  </div>
                </td>
              </tr>
            </>
          )}
        ></FieldArray>
      </tbody>
    </table>
  );
}

export default EditTimeSlotHourlyTable;
