import { MAANGE_BULK_JOBS_LIST_ENDPOINT } from "../../../../Routing/routes";
import { useNavigate, useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Layout from "../../../Layout/Layout";
import ListHeader from "../../../../components/ListHeader/ListHeader";
import Button from "../../../../components/Button/Button";
import { SelectTypes } from "../../../../consts/DropDownOptions";
import Dropdown2 from "../../../../components/Dropdown/dropdown2";
import { getBulkManageInvoice, getDownloadFileFromGcs } from "../../../../redux/actions/FinanceActions/BillingManagementActions/ManageInvoiceActions";
import ManageBulkInvoiceTable from "../../../../components/TableComponent/ManageBulkInvoiceTable";
import { createSearchParams } from "react-router-dom";
import { MAANGE_BULK_ADD_REQUEST_ENDPOINT } from "../../../../Routing/routes";

const ManageBulk = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const { loginData } = useSelector((state) => state.loginReducer);
    const { BulkManageInvoice, BulkLoader, manageInvoiceDraft, loading } =
        useSelector((state) => state.manageInvoiceReducer);

    const columns = [
        { id: "id", label: "Sr. No.", width: "30px" },
        { id: "Date", label: "Date", width: "70px" },
        // { id: "task Id", label: "Task ID", width: "150px" },
        { id: "Task Type", label: "Task Type", width: "100px" },
        { id: "Task Status", label: "Status", width: "60px" },
        { id: "Task View", label: "View", width: "20px" },
    ];

    if (searchParams.get("type") !== "ALL_TRANSACTION_REPORT") {
        columns.splice(2, 0, { id: "Invoice Task Type", label: "Invoice type", width: "70px" });
    }
    useEffect(() => {
        dispatch(
            getBulkManageInvoice({
                societyid: loginData?.default_community?.community_id,
                page: searchParams.get("page") || 1,
                limit: searchParams.get("pageLimit") || 10,
                jobType: searchParams.get("type") || "",
            })
        );
    }, [searchParams]);
    const handleStatusType = (selectedItem) => {
        searchParams.delete("type");
        searchParams.delete("page");
        navigate({
            pathname: MAANGE_BULK_JOBS_LIST_ENDPOINT,
            search: `?${createSearchParams([
                ...searchParams.entries(),
                ["type", selectedItem],
           
            ])}`,
        });
    };

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent={'Task List'}>
                        <div className="flex justify-between items-center gap-3">
                        <div className="text-sm font-bold">Select Task Type</div>
                            <Dropdown2
                                options={SelectTypes}
                                placeholder="Select Task Type"
                                className="text-center"
                                style={{
                                    textAlign: 'center',
                                }}
                                width="200px"
                                height="32px"
                                onSelect={(selectedItem) => {
                                    handleStatusType(selectedItem.value);
                                }}
                                value={
                                    searchParams.get("type") && {
                                        value: searchParams.get("type"),
                                        label: SelectTypes?.find(
                                            (item) => item.value == searchParams.get("type")
                                        )?.label,
                                    }
                                }
                            />
                        </div>
                        <Button
                            label="Add Request"
                            className="h-8 mx-1"
                            onClick={() => navigate(`${MAANGE_BULK_ADD_REQUEST_ENDPOINT}?type=${searchParams.get("type")}`)}
                            disabled={!searchParams.get("type")}
                        />

                    </ListHeader>

                </div>

                <div>
                    <ManageBulkInvoiceTable
                        columns={columns}
                        loading={BulkLoader}
                        checkbox={false}
                        tableData={BulkManageInvoice?.data?.map((item, index) => {
                            const metaData = item.meta_data
                                ? JSON.parse(item.meta_data)
                                : null;
                            if (searchParams.get("type") === "ALL_TRANSACTION_REPORT") {
                                let responseData = null;

                                if (item.response_data) {
                                    try {
                                        responseData = JSON.parse(item.response_data); 
                                    } catch (error) {
                                        console.error("Invalid JSON in response_data:", error.message, item.response_data);
                                        responseData = null; 
                                    }
                                }
                                return {
                                    sNo:
                                        (BulkManageInvoice?.meta?.current_page - 1) *
                                        BulkManageInvoice?.meta?.per_page +
                                        1 +
                                        index,
                                    // id: item.uuid,
                                    date: item.created_at,
                                    type: item.job_type,
                                    status: item.job_status,
                                    message: item.error_message,
                                    responseData:  responseData?.data?.file
                                };
                            } else {
                                return {
                                    sNo:
                                        (BulkManageInvoice?.meta?.current_page - 1) *
                                        BulkManageInvoice?.meta?.per_page +
                                        1 +
                                        index,
                                    // id: item.uuid,
                                    date: item.created_at,
                                    type: item.job_type,
                                    status: item.job_status,
                                    message: item.error_message,
                                    billName: metaData?.billName || ''
                                };
                            }
                        })}
                        pagination={BulkManageInvoice?.data?.length}
                        totalCount={BulkManageInvoice?.meta?.total}
                        totalPages={BulkManageInvoice?.meta?.last_page}
                        start={
                            (BulkManageInvoice?.meta?.current_page - 1) *
                            BulkManageInvoice?.meta?.per_page +
                            1
                        }
                        end={
                            (BulkManageInvoice?.meta?.current_page - 1) *
                            BulkManageInvoice?.meta?.per_page +
                            BulkManageInvoice?.data?.length
                        }
                        currentPage={Number(BulkManageInvoice?.meta?.current_page )}
                        rowsPerPage={searchParams.get("pageLimit") || 10}
                    />
                    {!BulkManageInvoice?.data?.length && !BulkLoader && (
                        <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
                            No Records
                        </div>
                    )}
                </div>
            </div >
        </Layout >
    )
}
export default ManageBulk;