import * as types from '../index.types';


export function getEinvoiceReport(data){
    return {
        type: types.GET_EINVOICE_REPORTS_NEW,
        data
    };
}   


export function postEinvoiceGenerate(data){
    return {
        type: types.POST_E_INVOICE_GENERATOR,
        data
    };
}   

