import React, { useEffect, useState } from "react";
import ListHeader from "../../../components/ListHeader/ListHeader";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Button/Button";
import ManageParkingTypeTable from "../../../components/TableComponent/ManageParkingTypeTable";
import AddParkingType from "./AddParkingTypeModal";
import { useDispatch } from "react-redux";
import { getParkingTypeList } from "../../../redux/actions/GateManagementActions/ParkingManagementAction";
import { useSelector } from "react-redux";
import * as permissions from "../../../Routing/permissions";
import { getPermission } from "../../../utils/helpers/getPermissions";

export const headCells = [
  { id: "sNo", label: "Sr. No.", width: "80px" },
  { id: "parkingType", label: "Parking Type", sort: true, width: "400px" },
  { id: "action", label: "Action", width: "100px" },
];

const ManageParkingType = () => {
  const [addParkingType, setAddParkingType] = useState(false);
  const dispatch = useDispatch();
  const { loginData } = useSelector((state) => state.loginReducer);
  const { loading, parkingTypeList } = useSelector(
    (state) => state.parkingManagementReducer
  );

  const handleAddParkingType = () => {
    setAddParkingType(true);
  };

  useEffect(() => {
    dispatch(
      getParkingTypeList({
        societyId: loginData?.default_community?.community_id,
      })
    );
  }, []);

  return (
    <Layout>
      <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2">
        <ListHeader leftContent="Manage Parking Type">
          <div className="border-r border-[#CCC]"></div>
          {getPermission(loginData, [
            permissions.ADD_PARKING_TYPE_IN_OCCUPANTS_PERMISSION,
          ]) && (
            <Button label="Add Parking Type" onClick={handleAddParkingType} />
          )}
        </ListHeader>
        <div className="ml-1">
          <ManageParkingTypeTable
            loading={loading}
            data={
              parkingTypeList?.map((item, index) => ({
                sNo: (index + 1)?.toString()?.padStart(),
                parkingType: item?.title,
                parkingTypeId: item?.parking_type_id,
                societyId: item?.society_id,
                isDelete: item?.society_id != -1 && item?.isShow != 1,
              })) || []
            }
            columns={headCells}
            checkbox={false}
            pagination={true}
          ></ManageParkingTypeTable>
        </div>
        {!parkingTypeList?.length && !loading && (
          <div className="text-sm text-[#AAAAAA] my-10 text-center italic">
            No Records
          </div>
        )}
      </div>
      <AddParkingType
        isOpen={addParkingType}
        onCancel={() => {
          setAddParkingType(false);
        }}
      />
    </Layout>
  );
};

export default ManageParkingType;
