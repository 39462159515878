import React, { useEffect, useRef, useState } from "react";
import { UploadEx } from "../../assets";
import { CircularProgress } from "@mui/material";
import Button from "../../components/Button/Button";
import Layout from "../Layout/Layout";
import ListHeader from "../../components/ListHeader/ListHeader";
import { downloadExcel } from "../../utils/helpers/downloadMedia";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { addFlatWiseFamilyMemberThroughExcel, downloadExcelFormat } from "../../redux/actions/SetupRecordsActions/BulkUploadActions/BulkUploadAction";
import {
    clearUploadError,
    downloadTenantsDetailsList,
    uploadTenantsDetails,
} from "../../redux/actions/SetupRecordsActions/Occupants&TenantsActions/TenantsInfoAction";

// builk family upload nav placement fix 
const BulkUploadFamily = () => {
    const dispatch = useDispatch();
    const [file, setFile] = useState();
    const inputFile = useRef(null);
    const { loginData } = useSelector((state) => state.loginReducer);
    const { ipAddress } = useSelector((state) => state.ipAddressReducer);
    const { uploadLoader, error } = useSelector(
        (state) => state.bulkUploadReducer
    );

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            setFile(file);
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDownloadExcel = () => {
        const onSucessCallback = (data) => {
            downloadExcel({ data, fileName: "Tenant Excel Sample.xlsx" });
        };
        const data = {
            societyId: loginData?.default_community?.community_id,
            onSucessCallback,
            isSample: true,
        };
        dispatch(downloadTenantsDetailsList(data));
    };

    const handleDownloadFormatExcel = () => {
        const onSucessCallback = (data) => {
            downloadExcel({ data, fileName: "Family Excel Format.xlsx" });
        };
        const data = {
            societyId: loginData?.default_community?.community_id,
            flag: "flat_wise_family_add",
            onSucessCallback,
        };
        dispatch(downloadExcelFormat(data));
    };

    const onUpload = () => {
        let data = {
            societyId: loginData?.default_community?.community_id,
            ipAddress,
            file,
            sessionId: loginData?.session_id,
            onRequestEndCallback: () => {
                setFile(null);
                inputFile.current.value = "";
                inputFile.current.type = "text";
                inputFile.current.type = "file";
            },
        };
        dispatch(addFlatWiseFamilyMemberThroughExcel(data));
    };

    useEffect(() => {
        return () => dispatch(clearUploadError());
    }, []);

    return (
        <Layout>
            <div className="w-full h-full bg-white border border-[#CCC] rounded-lg p-2 px-3">
                <div className="border-b border-[#CCC]">
                    <ListHeader leftContent="Family Excel Upload">
                        <div className="border-r border-[#CCC]"></div>
                        <Button
                            label={
                                <span className="w-12 text-enter inline-block">
                                    {uploadLoader ? (
                                        <CircularProgress sx={{ color: "white" }} size={17} />
                                    ) : (
                                        "Upload"
                                    )}
                                </span>
                            }
                            className="px-4 h-8 disabled:opacity-50"
                            disabled={!file}
                            onClick={onUpload}
                        />
                    </ListHeader>
                </div>
                <div className="w-full mt-4">
                    <p className="font-semibold text-sm text-center mt-6 mb-4">
                        {/* Note: Please do not leave any field empty except annual property
            tax, if it is not applied */}
                    </p>
                    <div className="">
                        <div
                            onDrop={handleDrop}
                            onDragOver={handleDragOver}
                            className="w-[60%] ml-48 flex justify-center border-dashed border-2 border-[#CCC] rounded-md text-center bg-[#F9FAFC] cursor-pointer"
                        >
                            <label
                                htmlFor="fileInput"
                                className="block w-full cursor-pointer p-9"
                            >
                                <div className="flex justify-center flex-col">
                                    <div className="flex justify-center">
                                        <UploadEx className="w-6" />
                                    </div>
                                    <input
                                        type="file"
                                        id="fileInput"
                                        className="hidden"
                                        accept={
                                            ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                        }
                                        onChange={handleFileChange}
                                        ref={inputFile}
                                    />
                                    <label
                                        htmlFor="fileInput"
                                        className="block font-semibold text-sm"
                                    >
                                        Upload .xlsx file
                                    </label>
                                    <p className="text-[#AAAAAA] text-[13px]">or drag and drop</p>
                                </div>
                            </label>
                        </div>
                        <div className="w-[60%] ml-48 my-3 text-sm h-4 font-semibold text-nowrap text-[#AAAAAA] text-ellipsis overflow-hidden ">
                            {file && `File Name: ${file?.name}`}
                        </div>
                        <div className="w-[60%] ml-48 flex justify-between text-red-650 cursor-pointer text-xs font-semibold">
                            {/* <div onClick={handleDownloadExcel}>Download Sample</div> */}
                            <div onClick={handleDownloadFormatExcel}>Download Format</div>
                        </div>

                        {error && !uploadLoader && (
                            <div className="w-[60%] mt-4 ml-48 text-red-650 cursor-pointer text-wrap break-all text-xs font-semibold text-justify">
                                Error:
                                <br />
                                {error?.split(". ")?.map((item) => (
                                    <div>{item}</div>
                                ))}
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default BulkUploadFamily;
